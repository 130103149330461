import { AuthContext } from 'Auth';
import './styles.css';
import React, { useContext, useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';

const StakingCalculator = props => {
  const [cripto, setActiveCripto] = useState('');
  function toggleCripto(criptoName) {
    if (criptoName === cripto) {
      setActiveCripto('');
      return;
    }
    setActiveCripto(criptoName);
  }

  const [investAmount, setInvestAmount] = useState(0);
  function changeInvestAmount(amount) {
    if (amount === '') {
      setInvestAmount('');
      return;
    }
    if (!isNumeric(amount)) return;
    setInvestAmount(amount);
  }

  const isNumeric = n => {
    const regex = /^[1-9]\d*(\.\d+)?$/;
    console.log(n);
    return (!isNaN(parseFloat(n)) && isFinite(n)) || regex.test(n);
  };

  const [period, setActivePeriod] = useState('');
  function togglePeriod(periodSelected) {
    if (periodSelected === period) {
      setActivePeriod('');
      return;
    }
    setActivePeriod(periodSelected);
  }

  const [coefficient, setCoefficient] = useState();
  function changeCoefficient(maxCoefficient) {
    setCoefficient(maxCoefficient);
  }

  let { currentToken } = useContext(AuthContext);

  // Get General Info of Currencies
  const [dataReturn, setDataReturn] = useState([]);

  const getStakingRatesTable = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/public/staking/rates/table';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setDataReturn(responseJson.data);
        console.log(responseJson.data);
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    getStakingRatesTable();
  }, [currentToken]);

  let gain = investAmount * coefficient;
  let gainRounded = gain.toFixed(5);

  //Get Rates By Currency
  const [ratesReturn, setRatesReturn] = useState([]);

  const getStakingRates = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/public/staking/rate/';
    let url = `${agentebtcapi}${endpoint}${cripto}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setRatesReturn(responseJson.data.rates);
        console.log('Rates');
        console.log(responseJson.data.rates);
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    if (cripto !== '') getStakingRates();
  }, [cripto]);

  return (
    <div className="modal">
      <div
        onClick={() => {
          props.toggleFunction();
        }}
        className="overlay"
      ></div>
      <div className="modal-content-ganancias">
        <div
          className="modal-exit-ganancias"
          onClick={() => {
            props.toggleFunction();
          }}
        >
          <IoIosClose></IoIosClose>
        </div>
        <div className="modal-ganancias-header">
          <div className="modal-ganancias-header-text">
            ¿Cuánto podría ganar inviertiendo?
          </div>
        </div>

        <div className="modal-ganancias-body">
          <div className="modal-ganancias-body-coin">
            <div className="modal-ganancias-section-title">
              ¿Qué criptomoneda deseas invertir?
            </div>
            <div className="modal-ganancias-coin-content">
              {dataReturn.map((item, index) => {

                // Backlog: Change this so it comes from the API instead of hardcoded
                if (item.currency === 'USD') {
                  item.image = 'https://i.imgur.com/z3HA1co.png';
                }

                return (
                  <div
                    key={index}
                    className={
                      cripto === item.currency
                        ? 'coin-content-cripto-item selected'
                        : 'coin-content-cripto-item'
                    }
                    onClick={() => {
                      toggleCripto(item.currency);
                    }}
                  >
                    <img className="modal-cripto-img" src={item.image} />
                    {item.currency}
                  </div>
                );
              })}
            </div>
          </div>

          {cripto !== '' && (
            <div className="modal-ganancias-body-amount">
              <div className="modal-ganancias-section-title">
                ¿Cuánto deseas invertir?
              </div>
              <div className="modal-ganancias-amount-content">
                <input
                  type="text"
                  className="inputText"
                  required
                  onChange={e => {
                    changeInvestAmount(e.target.value);
                  }}
                  value={investAmount}
                />
                <span className="floating-label">{cripto}</span>
              </div>
            </div>
          )}

          {cripto !== '' && (
            <div className="modal-ganancias-body-time">
              <div className="modal-ganancias-section-title">
                ¿Durante qué tiempo desea invertirlos?
              </div>
              <div className="modal-ganancias-time-content">
                {ratesReturn.map((item, index) => {
                  let classCss = 'ganancias-time-inner';
                  if (period === item.time) {
                    classCss = `${classCss} selected`;
                  }
                  // if (index === 0 || index%4 === 0) {
                  //   classCss = `${classCss} cripto-far-left-radius`
                  // }
                  // if (index === ratesReturn.length-1) {
                  //   classCss = `${classCss} cripto-far-right-radius`
                  // }

                  classCss = `${classCss} cripto-far-right-radius cripto-far-left-radius`;

                  classCss = `${classCss} cripto-width-elements-`;
                  if (ratesReturn.length === 1) {
                    classCss = `${classCss}1`;
                  }
                  if (ratesReturn.length === 2) {
                    classCss = `${classCss}2`;
                  }
                  if (ratesReturn.length === 3) {
                    classCss = `${classCss}3`;
                  }
                  if (ratesReturn.length >= 4) {
                    classCss = `${classCss}4`;
                  }

                  return (
                    <div
                      key={index}
                      className={classCss}
                      onClick={() => {
                        togglePeriod(item.time);
                        changeCoefficient(item.maxCoefficient);
                      }}
                    >
                      {item.time}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {period && cripto != '' && (
            <div className="modal-ganancias-body-calculation">
              <div className="ganancias-calculation-header">
                Tus posibles rendimientos:
              </div>
              <div className="ganancias-calculation-amount">
                {gainRounded} {cripto}
              </div>
            </div>
          )}
          <div className="ganancias-footer">
            Este cálculo está hecho a solo fines informativos y referenciales
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingCalculator;
