

const DecimalsNum = ( _crypto ) => {
    switch( _crypto ){
        case 'ALIC'  : return 2;
        case 'BTC'   : return 8;
        case 'ETH'   : return 8;
        case 'SHIB'  : return 4;
        case 'DOGE'  : return 4;
        case 'USDT'  : return 4;
        case 'SOL'   : return 4;
        case 'MATIC' : return 4;
        case 'ADA'   : return 4;
        case 'PEN'   : return 2;
        case 'USD'   : return 2;
    }
    return 8;
}

export default DecimalsNum;