import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Auth from './pages/Auth/index.js';
import App from './pages/App/index';
import { AuthProvider } from './Auth';
import Styles from './styles/Macro';
import 'styles/all.css';

const Main = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Styles.AppContainer>
          <Switch>
            <Route path="/auth" component={Auth} />
            <Route path="/app" component={App} />
            <Route exact path="/" component={Auth}>
              <Redirect to="/auth" />
            </Route>
          </Switch>
        </Styles.AppContainer>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default Main;
