import React, { useContext, useState, useEffect } from 'react';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import ImageUploader from 'react-images-upload';
import ReactFlagsSelect from 'react-flags-select';
import { css } from 'glamor';
import http from 'http-common';
import { Spinner } from '@chakra-ui/react';
import ReactGA from 'react-ga';
import { Line } from 'rc-progress';
import {
  Heading,
  Image,
  Stack,
  Button,
  IconButton,
  Text,
  Input,
  useMediaQuery,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-datepicker/dist/react-datepicker.css';
import './level1.css';

import { Box, useColorModeValue } from '@chakra-ui/react';

import { FiChevronLeft, FiChevronRight, FiAlertTriangle } from 'react-icons/fi';
import { AuthContext } from 'Auth';
import { Selector } from 'styles/common/index.js';
import { Clear, DetailsOperation, DetailTitle } from 'styles/Styled';

const CustomInput = ({
  onChange,
  placeholder,
  value,
  isSecure,
  id,
  onClick,
}) => (
  <input
    onChange={onChange}
    placeholder={placeholder}
    value={value}
    isSecure={isSecure}
    id={id}
    onClick={onClick}
    style={{
      width: '100%',
      borderWidth: 1,
      borderColor: 'lightgrey',
      paddingLeft: 12,
      height: 32,
    }}
  />
);

export default function ValidationLevelOne({ history }) {
  const { currentToken } = useContext(AuthContext);

  const [actualPage, setActualPage] = useState('');
  const [imageFundFront, setImageFundFront] = useState('');
  const [imageFundBack, setImageFundBack] = useState('');

  const [nombres, setNombres] = useState('');
  const [numeroDeDocumento, setNumeroDeDocumento] = useState('');
  const [sexo, setSexo] = useState('');
  const [tipoDeDocumento, setTipoDeDocumento] = useState('');
  const [domicilio, setDomicilio] = useState('');
  const [residencia, setResidencia] = useState('');
  const [nacionalidad, setNacionalidad] = useState('');
  const [fechaDeNacimiento, setFechaDeNacimiento] = useState(new Date());
  const [phoneNumber, setPhoneNumber] = useState('');
  const [civil, setCivil] = useState('');
  const [industria, setIndustria] = useState('');
  const [actividad, setActividad] = useState('');
  const [RUC, setRUC] = useState('');
  const [loadingButtonFront, setLoadingbuttonFront] = useState(false);
  const [loadingButtonBack, setLoadingbuttonBack] = useState(false);
  const [progressFront, setProgressFront] = useState(0);
  const [progressBack, setProgressBack] = useState(0);
  const [
    isFirstLevelApprovalPending,
    setIsFirstLevelApprovalPending,
  ] = useState('');
  const [isClicked, setIsclicked] = useState(false);
  const [isClickedCancel, setIsclickedCancel] = useState(false);

  const handleSelectDocument = option => {
    setTipoDeDocumento(option.value);
  };

  const onImageFront = files => {
    if (files.length === 1) {
      setImageFundFront(files[files.length - 1]);
    } else {
      setImageFundFront('');
    }
  };

  const onImageBack = files => {
    if (files.length === 1) {
      setImageFundBack(files[files.length - 1]);
    } else {
      setImageFundBack('');
    }
  };

  const DocumentOptions = [
    { value: 'dni', label: 'DNI' },
    // { value: 'carné_de_extranjería', label: 'Carné de extranjería' },
    // { value: 'pasaporte', label: 'Pasaporte' },
  ];
  const DocumentOptionsObject = {
    dni: { value: 'dni', label: 'DNI' },
    // carné_de_extranjería: {
    //   value: 'carné_de_extranjería',
    //   label: 'Carné de extranjería',
    // },
    // pasaporte: { value: 'pasaporte', label: 'Pasaporte' },
  };

  const handleSelectSexo = option => {
    setSexo(option.value);
  };

  const SexoOptions = [
    { value: 'masculino', label: 'Masculino' },
    { value: 'femenino', label: 'Femenino' },
    { value: 'prefiero_no_decirlo', label: 'Prefiero no decirlo' },
  ];

  const SexoOptionsObject = {
    masculino: { value: 'masculino', label: 'Masculino' },
    femenino: { value: 'femenino', label: 'Femenino' },
    prefiero_no_decirlo: {
      value: 'prefiero_no_decirlo',
      label: 'Prefiero no decirlo',
    },
  };

  const handleSelectCivil = option => {
    setCivil(option.value);
  };

  const CivilOptions = [
    { value: 'soltero', label: 'Soltero/a' },
    { value: 'casado', label: 'Casado/a' },
    { value: 'divorciado', label: 'Divorciado/a' },
    { value: 'viudo', label: 'Viudo/a' },
  ];

  const CivilOptionsObject = {
    soltero: { value: 'soltero', label: 'Soltero/a' },
    casado: { value: 'casado', label: 'Casado/a' },
    divorcia: { value: 'divorciado', label: 'Divorciado/a' },
    viudo: { value: 'viudo', label: 'Viudo/a' },
  };

  const handleSelectActividad = option => {
    setActividad(option.value);
  };

  const ActividadOptions = [
    { value: 'empresario', label: 'Empresario' },
    { value: 'empleado', label: 'Empleado' },
    { value: 'inversionista_y_apoderado', label: 'Inversionista y Apoderado' },
  ];

  const ActividadOptionsObject = {
    empresario: { value: 'empresario', label: 'Empresario' },
    empleado: { value: 'empleado', label: 'Empleado' },
    inversionista_y_apoderado: {
      value: 'inversionista_y_apoderado',
      label: 'Inversionista y Apoderado',
    },
  };

  const handleSelectIndustria = option => {
    setIndustria(option.value);
  };

  const IndustriaOptions = [
    { value: 'artista_o_artesano', label: 'Artista o artesano/a' },
    { value: 'rentista_de_capital', label: 'Rentista de capital' },
    { value: 'policia_o_militar', label: 'Policia o militar' },
    {
      value: 'agricultor_ganadero_o_pescador',
      label: 'Agricultor/a, ganadero/a o pescador/a',
    },
    { value: 'técnico', label: 'Técnico/a' },
    { value: 'otro', label: 'Otro' },
    { value: 'economista', label: 'Economista' },
    { value: 'profesor', label: 'Profesor/a' },
    { value: 'programador', label: 'Programador/a' },
    { value: 'amo_de_casa', label: 'Amo/a de casa' },
    { value: 'médico', label: 'Médico/a' },
    { value: 'arquitecto', label: 'Arquitecto/a' },
    { value: 'administrador', label: 'Administrador/a' },
    { value: 'abogado', label: 'Abogado/a' },
    { value: 'empresario', label: 'Empresario/a' },
    { value: 'comerciante', label: 'Comerciante' },
    { value: 'estudiante', label: 'Estudiante' },
    { value: 'ingeniero', label: 'Ingeniero/a' },
  ];

  const IndustriaOptionsObject = {
    artista_o_artesano: {
      value: 'artista_o_artesano',
      label: 'Artista o artesano/a',
    },
    rentista_de_capital: {
      value: 'rentista_de_capital',
      label: 'Rentista de capital',
    },
    policia_o_militar: {
      value: 'policia_o_militar',
      label: 'Policia o militar',
    },
    agricultor_ganadero_o_pescador: {
      value: 'agricultor_ganadero_o_pescador',
      label: 'Agricultor/a, ganadero/a o pescador/a',
    },
    técnico: { value: 'técnico', label: 'Técnico/a' },
    otro: { value: 'otro', label: 'Otro' },
    economista: { value: 'economista', label: 'Economista' },
    profesor: { value: 'profesor', label: 'Profesor/a' },
    programador: { value: 'programador', label: 'Programador/a' },
    amo_de_casa: { value: 'amo_de_casa', label: 'Amo/a de casa' },
    médico: { value: 'médico', label: 'Médico/a' },
    arquitecto: { value: 'arquitecto', label: 'Arquitecto/a' },
    administrador: { value: 'administrador', label: 'Administrador/a' },
    abogado: { value: 'abogado', label: 'Abogado/a' },
    empresario: { value: 'empresario', label: 'Empresario/a' },
    comerciante: { value: 'comerciante', label: 'Comerciante' },
    estudiante: { value: 'estudiante', label: 'Estudiante' },
    ingeniero: { value: 'ingeniero', label: 'Ingeniero/a' },
  };

  const getUser = () => {
    let data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    currentToken &&
      fetch(url, data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw { type: 'non-user' };
          }
        })
        .then(responseJson => {
          setNombres(responseJson.name ? responseJson.name : '');
          setNumeroDeDocumento(responseJson.dni ? responseJson.dni : '');
          setSexo(responseJson.gender ? responseJson.gender : '');
          setTipoDeDocumento(
            responseJson.typeDocument ? responseJson.typeDocument : ''
          );
          setDomicilio(responseJson.address ? responseJson.address : '');
          setNacionalidad(
            responseJson.nationality ? responseJson.nationality : ''
          );
          setFechaDeNacimiento(
            responseJson.birthday ? responseJson.birthday : ''
          );
          setImageFundFront(responseJson.dniFront ? responseJson.dniFront : '');
          setImageFundBack(responseJson.dniBack ? responseJson.dniBack : '');
          setCivil(responseJson.civilStatus ? responseJson.civilStatus : '');
          setIndustria(responseJson.industry ? responseJson.industry : '');
          setActividad(responseJson.activity ? responseJson.activity : '');
          setRUC(responseJson.ruc ? responseJson.ruc : '');
          setIsFirstLevelApprovalPending(
            responseJson.isFirstLevelApprovalPending
              ? responseJson.isFirstLevelApprovalPending
              : ''
          );
          setResidencia(
            responseJson.residenceCountry ? responseJson.residenceCountry : ''
          );
          if (actualPage === '') {
            setActualPage(
              responseJson.firstLevelStep ? responseJson.firstLevelStep : 1
            );
          }
        })
        .catch(error => {
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });
        });
  };

  const validateUser = async (newProp, goToNextPage) => {
    let body = { ...newProp };
    var formData = new FormData();
    for (let key in body) {
      formData.append(key, body[key]);
    }

    let endpoint = '/client/user/firstlevel/update';
    let url = `${agentebtcapi}${endpoint}`;

    http
      .put(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then(response => {
        if ((response.type = 'user-updated')) {
          toast('Los datos fueron actualizados con éxito', {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            bodyClassName: css({ fontFamily: 'Montserrat' }),
          });
          if (goToNextPage) {
            setActualPage(actualPage + 1);
          }
        } else {
          swal({
            text:
              'Lo sentimos, hubo un error en el sistema y no pudimos actualizar los datos. Intente nuevamente más adelante.',
            button: false,
            timer: 1000,
          });
        }
      });
  };

  const requestLevel1 = () => {
    setIsclicked(true);
    let data = {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user/firstlevel/requestvalidation';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          setIsclicked(false);
          getUser();

          toast('Solicitud de validación NIVEL 1 enviada exitosamente :)', {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            bodyClassName: css({ fontFamily: 'Montserrat' }),
          });

          return response.json();
        } else {
          setIsclicked(false);
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {})
      .catch(error => {
        setIsclicked(true);
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  const cancelRequestLevel1 = () => {
    setIsclickedCancel(true);
    let data = {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user/firstlevel/cancelrequestvalidation';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          setIsclickedCancel(false);
          getUser();
          return response.json();
        } else {
          setIsclickedCancel(false);
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setIsclickedCancel(false);
        getUser();
      })
      .catch(error => {
        setIsclickedCancel(false);
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  const onDropDNIFront = files => {
    //toast("Preparando foto delantera de documento, espere unos segundos ...", {position: toast.POSITION.TOP_CENTER, hideProgressBar: false, bodyClassName: css({fontFamily: 'Montserrat'})});

    setLoadingbuttonFront(true);

    if (files === undefined) {
      swal({
        text:
          'Tu imagen no cumple los requisitos, debe ser menor a 5MB y de los siguientes formatos: JPG, JPEG o PNG. Intenta recortarla o comprimirla.',
        button: false,
        timer: 5000,
      });

      return false;
    }

    let body = { dniFront: files };
    var formData = new FormData();
    for (let key in body) {
      formData.append(key, body[key]);
    }

    let endpoint = '/client/user/firstlevel/update';
    let url = `${agentebtcapi}${endpoint}`;

    http
      .put(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgressFront(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(response => {
        if ((response.type = 'user-updated')) {
          setLoadingbuttonFront(false);
          setActualPage(actualPage + 1);
        } else {
          swal({
            text:
              'Lo sentimos, hubo un error en el sistema y no pudimos actualizar los datos. Intente nuevamente más adelante.',
            button: false,
            timer: 1000,
          });
        }
      });
  };

  const onDropDNIBack = files => {
    //toast("Preparando foto delantera de documento, espere unos segundos ...", {position: toast.POSITION.TOP_CENTER, hideProgressBar: false, bodyClassName: css({fontFamily: 'Montserrat'})});

    setLoadingbuttonBack(true);

    if (files === undefined) {
      swal({
        text:
          'Tu imagen no cumple los requisitos, debe ser menor a 5MB y de los siguientes formatos: JPG, JPEG o PNG. Intenta recortarla o comprimirla.',
        button: false,
        timer: 5000,
      });

      return false;
    }

    let body = { dniBack: files };
    var formData = new FormData();
    for (let key in body) {
      formData.append(key, body[key]);
    }

    let endpoint = '/client/user/firstlevel/update';
    let url = `${agentebtcapi}${endpoint}`;

    http
      .put(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgressBack(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(response => {
        if ((response.type = 'user-updated')) {
          setLoadingbuttonBack(false);
          setActualPage(actualPage + 1);
        } else {
          swal({
            text:
              'Lo sentimos, hubo un error en el sistema y no pudimos actualizar los datos. Intente nuevamente más adelante.',
            button: false,
            timer: 1000,
          });
        }
      });
  };

  useEffect(() => {
    getUser();
  }, [currentToken, actualPage]);

  const RenderSteps = () => {
    if (actualPage === 1) {
      return (
        <>
          <Stack align={'center'} pt={10}>
            <Heading
              fontSize={'16'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#4D72F1' }}
            >
              Verificando tu cuenta en Nivel 1
            </Heading>
          </Stack>
          <Box align={'center'}>
            <Stack
              spacing="24px"
              align={'center'}
              mb={5}
              mt={5}
              w={{ base: '70%', md: '30%' }}
            >
              <Image
                src={require('../../../../../images/ModalImage1.png')}
                alt="Validation Image"
                w={200}
              />
              <Text fontSize={'12'}>¿Tienes tus documentos a la mano?</Text>
              <Text fontSize={'12'}>
                Vamos a pedir una foto del frente y del dorso
              </Text>
              <Stack flexDirection="row">
                <FiAlertTriangle size={40} color="#FFCA36" />
                <Text fontSize={'12'}>
                  Haz coincidir los bordes de tu DNI o carné de extranjería con
                  las guías (en archivos PNG, JPG).
                </Text>
              </Stack>
              <Stack flexDirection="row">
                <FiAlertTriangle size={40} color="#FFCA36" />
                <Text fontSize={'12'}>
                  Asegúrate que la foto no esté movida o borrosa y que tus datos
                  se lean correctamente
                </Text>
              </Stack>
            </Stack>
          </Box>
          <Stack align={'center'}>
            <Button
              colorScheme="blue"
              onClick={() => {
                ReactGA.event({
                  category: 'Validación',
                  action: 'Inicio de validación nivel 1',
                });
                if (actualPage < 5) setActualPage(actualPage + 1);
              }}
            >
              Siguiente
            </Button>
          </Stack>
        </>
      );
    }
    if (actualPage === 2) {
      return (
        <>
          <Stack align={'center'} pt={10}>
            <Heading
              fontSize={'16'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#4D72F1' }}
            >
              Subir tus documentos
            </Heading>
          </Stack>
          <Box align={'center'}>
            <Text fontSize={14} mt={5}>
              Necesitas subir las fotos de tu DNI en esta sección. Se recomienda
              que sea visible
            </Text>
            <Stack align={'center'} mb={5} mt={5}>
              <Heading fontSize={18}>Foto del documento (frontal)</Heading>
              <Stack flexDirection={{ base: 'column', md: 'row' }}>
                {imageFundFront && (
                  <Box mr={5}>
                    {/* <img
                      style={{ width: 400, maxWidth: '100%' }}
                      src={`${agentebtcapi}/public/image/dni/${imageFundFront}`}
                    /> */}
                  </Box>
                )}
                <Box>
                  <ImageUploader
                    withPreview={true}
                    key={actualPage}
                    withIcon={true}
                    buttonText="Busca archivos"
                    onChange={onImageFront}
                    imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                    maxFileSize={5242880}
                    withLabel={true}
                    label="Arrastra y suelta aquí o"
                    fileContainerStyle={{
                      borderWidth: 2,
                      borderColor: 'gray',
                      width: 250,
                      borderStyle: 'dashed',
                    }}
                    buttonStyles={{
                      backgroundColor: '#fff',
                      color: '#4D72F1',
                      fontWeight: 'bold',
                    }}
                  />
                  {loadingButtonFront && (
                    <>
                      <Line
                        percent={progressFront}
                        strokeWidth="4"
                        strokeColor="#486FEE"
                      />

                      <Clear></Clear>

                      <DetailsOperation>
                        <DetailTitle>
                          Subiendo documento... {progressFront}%
                        </DetailTitle>
                      </DetailsOperation>
                      <Clear></Clear>
                    </>
                  )}
                  <Text fontSize={10} color="gray" style={{ marginTop: 0 }}>
                    Tipos de archivos aceptados: .jpg, .jpeg, .gif, .png
                  </Text>
                </Box>
                <Box>
                  <Stack flexDirection="row" mt={2} ml={4}>
                    <div
                      style={{
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        backgroundColor: '#7B827C',
                        alignSelf: 'center',
                        marginRight: 10,
                      }}
                    />
                    <Text style={{ marginTop: 0 }}>Documento frontal</Text>
                  </Stack>
                  <Stack flexDirection="row" mt={2} ml={4}>
                    <div
                      style={{
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        backgroundColor: '#7B827C',
                        alignSelf: 'center',
                        marginRight: 10,
                      }}
                    />
                    <Text style={{ marginTop: 0 }}>Documento dorso</Text>
                  </Stack>
                  <Stack flexDirection="row" mt={2} ml={4}>
                    <div
                      style={{
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        backgroundColor: '#7B827C',
                        alignSelf: 'center',
                        marginRight: 10,
                      }}
                    />
                    <Text style={{ marginTop: 0 }}>Datos personales</Text>
                  </Stack>
                  <Stack flexDirection="row" mt={2} ml={4}>
                    <div
                      style={{
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        backgroundColor: '#7B827C',
                        alignSelf: 'center',
                        marginRight: 10,
                      }}
                    />
                    <Text style={{ marginTop: 0 }}>
                      Verifica tu información
                    </Text>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Box>
          <Stack align={'center'}>
            <Button
              colorScheme="blue"
              onClick={() => {
                if (actualPage < 5) {
                  onDropDNIFront(imageFundFront);
                }
              }}
              disabled={imageFundFront === ''}
            >
              Siguiente
            </Button>
          </Stack>
        </>
      );
    }
    if (actualPage === 3) {
      return (
        <>
          <Stack align={'center'} pt={10}>
            <Heading
              fontSize={'16'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#4D72F1' }}
            >
              Subir tus documentos
            </Heading>
          </Stack>
          <Box align={'center'}>
            <Text fontSize={14} mt={5}>
              Necesitas subir las fotos de tu DNI en esta sección. Se recomienda
              que sea visible
            </Text>
            <Stack align={'center'} mb={5} mt={5}>
              <Heading fontSize={18}>Foto del documento (dorso)</Heading>
              <Stack flexDirection={{ base: 'column', md: 'row' }}>
                {imageFundBack && (
                  <Box mr={5}>
                    {/* <img
                      style={{ width: 400, maxWidth: '100%' }}
                      src={`${agentebtcapi}/public/image/dni/${imageFundBack}`}
                    /> */}
                  </Box>
                )}
                <Box>
                  <ImageUploader
                    withPreview={true}
                    key={actualPage}
                    withIcon={true}
                    buttonText="Browse files"
                    onChange={onImageBack}
                    imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                    maxFileSize={5242880}
                    withLabel={true}
                    label="Drag and Drop here or"
                    fileContainerStyle={{
                      borderWidth: 2,
                      borderColor: 'gray',
                      width: 250,
                      borderStyle: 'dashed',
                    }}
                    buttonStyles={{
                      backgroundColor: '#fff',
                      color: '#4D72F1',
                      fontWeight: 'bold',
                    }}
                  />
                  {loadingButtonBack && (
                    <>
                      <Line
                        percent={progressBack}
                        strokeWidth="4"
                        strokeColor="#486FEE"
                      />

                      <Clear></Clear>

                      <DetailsOperation>
                        <DetailTitle>
                          Subiendo documento... {progressBack}%
                        </DetailTitle>
                      </DetailsOperation>
                      <Clear></Clear>
                    </>
                  )}
                  <Text fontSize={10} color="gray" style={{ marginTop: 0 }}>
                    Accepted file types: .jpg, .jpeg, .gif, .png
                  </Text>
                </Box>
                <Box>
                  <Stack flexDirection="row" mt={2} ml={4}>
                    <div
                      style={{
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        backgroundColor: '#94DA9F',
                        alignSelf: 'center',
                        marginRight: 10,
                      }}
                    />
                    <Text style={{ marginTop: 0 }}>Documento frontal</Text>
                  </Stack>
                  <Stack flexDirection="row" mt={2} ml={4}>
                    <div
                      style={{
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        backgroundColor: '#7B827C',
                        alignSelf: 'center',
                        marginRight: 10,
                      }}
                    />
                    <Text style={{ marginTop: 0 }}>Documento dorso</Text>
                  </Stack>
                  <Stack flexDirection="row" mt={2} ml={4}>
                    <div
                      style={{
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        backgroundColor: '#7B827C',
                        alignSelf: 'center',
                        marginRight: 10,
                      }}
                    />
                    <Text style={{ marginTop: 0 }}>Datos personales</Text>
                  </Stack>
                  <Stack flexDirection="row" mt={2} ml={4}>
                    <div
                      style={{
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        backgroundColor: '#7B827C',
                        alignSelf: 'center',
                        marginRight: 10,
                      }}
                    />
                    <Text style={{ marginTop: 0 }}>
                      Verifica tu información
                    </Text>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Box>
          <Stack align={'center'}>
            <Button
              colorScheme="blue"
              onClick={() => {
                if (actualPage < 5) {
                  onDropDNIBack(imageFundBack);
                }
              }}
              disabled={imageFundBack === ''}
            >
              Siguiente
            </Button>
          </Stack>
        </>
      );
    }
    if (actualPage === 4) {
      return (
        <>
          <Stack align={'center'} pt={10}>
            <Heading
              fontSize={'16'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#4D72F1' }}
            >
              Datos de identidad
            </Heading>
          </Stack>
          <Box align={'center'}>
            <Text fontSize={14} mt={5}>
              Ten en cuenta que esto es una declaración jurada. Por favor
              completar con datos reales.
            </Text>
            <Stack
              align={'center'}
              mb={5}
              mt={5}
              flexDirection={{ base: 'column', xl: 'row' }}
              style={{ alignItems: 'baseline' }}
            >
              <Box
                flexDirection="column"
                width={{ base: '95%', md: '35%' }}
                px={3}
              >
                <Text mb="8px" align="start">
                  Nombre Completo
                </Text>
                <Input
                  placeholder="Nombre Completo"
                  size="sm"
                  value={nombres}
                  onChange={event => setNombres(event.target.value)}
                />
                <Text mb="8px" align="start" mt={3}>
                  Número de DNI
                </Text>
                <Input
                  placeholder="Número de DNI"
                  size="sm"
                  value={numeroDeDocumento}
                  onChange={event => setNumeroDeDocumento(event.target.value)}
                />
              </Box>
              <Box
                flexDirection="column"
                width={{ base: '95%', md: '35%' }}
                px={3}
              >
                <Text mb="8px" align="start">
                  Sexo
                </Text>
                <Selector
                  required
                  placeholder="Sexo"
                  options={SexoOptions}
                  value={SexoOptionsObject[sexo]}
                  onChange={handleSelectSexo}
                />
                <Text mb="8px" align="start" mt={3}>
                  Tipo de documento
                </Text>
                <Selector
                  required
                  placeholder="Tipo de documento"
                  options={DocumentOptions}
                  value={DocumentOptionsObject[tipoDeDocumento]}
                  onChange={handleSelectDocument}
                />
              </Box>
              <Box flexDirection="column" width={{ base: '95%', md: '35%' }}>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#94DA9F',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Documento frontal</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#94DA9F',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Documento dorso</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#7B827C',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Datos personales</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#7B827C',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Datos adicionales</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#7B827C',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Verifica tu información</Text>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Stack align={'center'}>
            <Button
              colorScheme="blue"
              onClick={() => {
                if (actualPage < 7) {
                  const bodyRequest = {
                    name: nombres,
                    dni: numeroDeDocumento,
                    typeDocument: tipoDeDocumento,
                    gender: sexo,
                    firstLevelStep: actualPage + 1,
                  };
                  validateUser(bodyRequest, true);
                }
              }}
              disabled={
                nombres === '' ||
                numeroDeDocumento === '' ||
                tipoDeDocumento === '' ||
                sexo === ''
              }
            >
              Siguiente
            </Button>
          </Stack>
        </>
      );
    }
    if (actualPage === 5) {
      return (
        <>
          <Stack align={'center'} pt={10}>
            <Heading
              fontSize={'16'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#4D72F1' }}
            >
              Datos de identidad
            </Heading>
          </Stack>
          <Box align={'center'}>
            <Text fontSize={14} mt={5}>
              Ten en cuenta que esto es una declaración jurada. Por favor
              completar con datos reales.
            </Text>
            <Stack
              align={'center'}
              mb={5}
              mt={5}
              flexDirection={{ base: 'column', xl: 'row' }}
              style={{ alignItems: 'baseline' }}
            >
              <Box
                flexDirection="column"
                width={{ base: '95%', md: '35%' }}
                px={3}
              >
                <Text mb="8px" align="start">
                  Domicilio (calle y número)
                </Text>
                <Input
                  placeholder="Ingresa tu domicilio"
                  size="sm"
                  value={domicilio}
                  onChange={event => setDomicilio(event.target.value)}
                />
                <Text mb="8px" align="start" mt={3}>
                  País de residencia
                </Text>
                <ReactFlagsSelect
                  required
                  countries={[
                    'PE',
                    'AR',
                    'CL',
                    'CO',
                    'BO',
                    'CR',
                    'DO',
                    'EC',
                    'ES',
                    'GT',
                    'HN',
                    'MX',
                    'NI',
                    'PA',
                    'PY',
                    'SV',
                    'PR',
                    'UY',
                    'VE',

                    'BE',
                    'BG',
                    'CZ',
                    'DK',
                    'DE',
                    'EE',
                    'IE',
                    'GR',
                    'ES',
                    'FR',
                    'HR',
                    'IT',
                    'CY',
                    'LV',
                    'LT',
                    'LU',
                    'HU',
                    'MT',
                    'NL',
                    'AT',
                    'PL',
                    'PT',
                    'RO',
                    'SI',
                    'SK',
                    'FI',
                    'SE',
                    'IS',
                    'NO',
                    'LI',
                    'CH',
                    'UK',
                    'TR',
                    'RS',
                    'AL',
                    'GE',
                    'AM',
                    'RU',
                    'UA',

                  ]}
                  customLabels={{
                    PE: 'Perú',
                    AR: 'Argentina',
                    BO: 'Bolivia',
                    ES: 'España',
                    VE: 'Venezuela',
                  }}
                  searchPlaceholder="Busca..."
                  placeholder="Ingresa el país donde resides..."
                  searchable
                  selected={residencia}
                  fullWidth
                  onSelect={code => setResidencia(code)}
                />
              </Box>
              <Box
                flexDirection="column"
                width={{ base: '95%', md: '35%' }}
                px={3}
              >
                <Text mb="8px" align="start" mt={3}>
                  Fecha de nacimiento
                </Text>
                <DatePicker
                  showYearDropdown
                  dateFormat="dd/MM/yyyy"
                  selected={
                    fechaDeNacimiento ? Date.parse(fechaDeNacimiento) : ''
                  }
                  onChange={date => setFechaDeNacimiento(date)}
                  wrapperClassName="datePicker"
                  customInput={<CustomInput />}
                />
                <Text mb="8px" align="start" mt="8px">
                  Nacionalidad
                </Text>
                <ReactFlagsSelect
                  required
                  countries={[
                    'PE',
                    'AR',
                    'CL',
                    'CO',
                    'BO',
                    'CR',
                    'DO',
                    'EC',
                    'ES',
                    'GT',
                    'HN',
                    'MX',
                    'NI',
                    'PA',
                    'PY',
                    'SV',
                    'PR',
                    'UY',
                    'VE',

                    'BE',
                    'BG',
                    'CZ',
                    'DK',
                    'DE',
                    'EE',
                    'IE',
                    'GR',
                    'ES',
                    'FR',
                    'HR',
                    'IT',
                    'CY',
                    'LV',
                    'LT',
                    'LU',
                    'HU',
                    'MT',
                    'NL',
                    'AT',
                    'PL',
                    'PT',
                    'RO',
                    'SI',
                    'SK',
                    'FI',
                    'SE',
                    'IS',
                    'NO',
                    'LI',
                    'CH',
                    'UK',
                    'TR',
                    'RS',
                    'AL',
                    'GE',
                    'AM',
                    'RU',
                    'UA',
                  ]}
                  customLabels={{
                    PE: 'Perú',
                    AR: 'Argentina',
                    BO: 'Bolivia',
                    ES: 'España',
                    VE: 'Venezuela',
                  }}
                  searchPlaceholder="Busca..."
                  placeholder="Ingresa tu nacionalidad"
                  searchable
                  selected={nacionalidad}
                  fullWidth
                  onSelect={code => setNacionalidad(code)}
                />
              </Box>
              <Box flexDirection="column" width={{ base: '95%', md: '35%' }}>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#94DA9F',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Documento frontal</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#94DA9F',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Documento dorso</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#7B827C',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Datos personales</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#7B827C',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Datos adicionales</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#7B827C',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Verifica tu información</Text>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Stack align={'center'}>
            <Button
              colorScheme="blue"
              onClick={() => {
                if (actualPage < 7) {
                  const bodyRequest = {
                    nationality: nacionalidad,
                    residenceCountry: residencia,
                    birthday: fechaDeNacimiento,
                    address: domicilio,
                    firstLevelStep: actualPage + 1,
                  };
                  validateUser(bodyRequest, true);
                }
              }}
              disabled={
                nacionalidad === '' ||
                residencia === '' ||
                fechaDeNacimiento === '' ||
                domicilio === ''
              }
            >
              Siguiente
            </Button>
          </Stack>
        </>
      );
    }
    if (actualPage === 6) {
      return (
        <>
          <Stack align={'center'} pt={10}>
            <Heading
              fontSize={'16'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#4D72F1' }}
            >
              Datos adicionales
            </Heading>
          </Stack>
          <Box align={'center'}>
            <Text fontSize={14} mt={5}>
              Ten en cuenta que esto es una declaración jurada. Por favor
              completar con datos reales.
            </Text>
            <Stack
              align={'center'}
              mb={5}
              mt={5}
              flexDirection={{ base: 'column', xl: 'row' }}
              style={{ alignItems: 'baseline' }}
            >
              <Box
                flexDirection="column"
                width={{ base: '95%', md: '35%' }}
                px={3}
              >
                <Text mb="8px" align="start">
                  Estado civil
                </Text>
                <Selector
                  required
                  placeholder="Ingresa tu estado civil actual"
                  options={CivilOptions}
                  value={CivilOptionsObject[civil]}
                  onChange={handleSelectCivil}
                />
                <Text mb="8px" align="start" mt={3}>
                  Industria
                </Text>
                <Selector
                  required
                  placeholder="Ingresa tu industria"
                  options={IndustriaOptions}
                  value={IndustriaOptionsObject[industria]}
                  onChange={handleSelectIndustria}
                />
              </Box>
              <Box
                flexDirection="column"
                width={{ base: '95%', md: '35%' }}
                px={3}
              >
                <Text mb="8px" align="start">
                  Actividad
                </Text>
                <Selector
                  required
                  placeholder="Ingresa tu actividad"
                  options={ActividadOptions}
                  value={ActividadOptionsObject[actividad]}
                  onChange={handleSelectActividad}
                />
                <Text mb="8px" align="start" mt={3}>
                  RUC (opcional)
                </Text>
                <Input
                  placeholder="Ingresa tu nro RUC"
                  size="sm"
                  value={RUC}
                  onChange={event => setRUC(event.target.value)}
                />
              </Box>
              <Box flexDirection="column" width={{ base: '95%', md: '35%' }}>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#94DA9F',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Documento frontal</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#94DA9F',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Documento dorso</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#94DA9F',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Datos personales</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#7B827C',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Datos adicionales</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#7B827C',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Verifica tu información</Text>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Stack align={'center'}>
            <Button
              colorScheme="blue"
              onClick={() => {
                if (actualPage < 7) {
                  const bodyRequest = RUC
                    ? {
                        civilStatus: civil,
                        industry: industria,
                        activity: actividad,
                        ruc: RUC,
                        firstLevelStep: actualPage + 1,
                      }
                    : {
                        civilStatus: civil,
                        industry: industria,
                        activity: actividad,
                        firstLevelStep: actualPage + 1,
                      };
                  validateUser(bodyRequest, true);
                }
              }}
              disabled={civil === '' || industria === '' || actividad === ''}
            >
              Siguiente
            </Button>
          </Stack>
        </>
      );
    }
    if (actualPage === 7) {
      return (
        <>
          <Stack align={'center'} pt={10}>
            <Heading
              fontSize={'16'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#4D72F1' }}
            >
              Verifica tu información
            </Heading>
          </Stack>
          <Box align={'center'}>
            <Text fontSize={14} mt={5}>
              Ten en cuenta que esto es una declaración jurada. Por favor
              completar con datos reales.
            </Text>
            <Stack
              align={'center'}
              mb={5}
              mt={5}
              flexDirection={{ base: 'column', xl: 'row' }}
              style={{ alignItems: 'baseline' }}
            >
              <Box width={{ base: '95%', md: '35%' }} px={3}>
                <Text mb="8px" align="start">
                  Nombre Completo: <strong>{nombres && nombres}</strong>
                </Text>
                <Text mb="8px" align="start" mt={3}>
                  Número de DNI:{' '}
                  <strong>{numeroDeDocumento && numeroDeDocumento}</strong>
                </Text>
                <Text mb="8px" align="start" mt={3}>
                  Domicilio: <strong>{domicilio && domicilio}</strong>
                </Text>
                <Text mb="8px" align="start" mt={3}>
                  País de residencia:{' '}
                  <strong>{residencia && residencia}</strong>
                </Text>
                <Text mb="8px" align="start">
                  Estado civil: <strong>{civil && civil}</strong>
                </Text>
              </Box>
              <Box width={{ base: '95%', md: '35%' }} px={3}>
                <Text mb="8px" align="start">
                  Sexo: <strong>{sexo && sexo}</strong>
                </Text>
                <Text mb="8px" align="start">
                  Tipo de documento:{' '}
                  <strong>
                    {tipoDeDocumento &&
                      DocumentOptionsObject[tipoDeDocumento].label}
                  </strong>
                </Text>
                <Text mb="8px" align="start">
                  Nacionalidad: <strong>{nacionalidad && nacionalidad}</strong>
                </Text>
                <Text mb="8px" align="start">
                  Fecha de nacimiento:{' '}
                  <strong>
                    {fechaDeNacimiento &&
                      new Intl.DateTimeFormat('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }).format(Date.parse(fechaDeNacimiento))}
                  </strong>
                </Text>
                <Text mb="8px" align="start">
                  Actividad:{' '}
                  <strong>
                    {actividad && ActividadOptionsObject[actividad].label}
                  </strong>
                </Text>
                <Text mb="8px" align="start" mt={3}>
                  Industria:{' '}
                  <strong>
                    {industria && IndustriaOptionsObject[industria].label}
                  </strong>
                </Text>
                <Text mb="8px" align="start" mt={3}>
                  RUC: <strong>{RUC && RUC}</strong>
                </Text>
              </Box>
              <Box flexDirection="column" width={{ base: '95%', md: '35%' }}>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#94DA9F',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Documento frontal</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#94DA9F',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Documento dorso</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#94DA9F',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Datos personales</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: '#94DA9F',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Datos adicionales</Text>
                </Stack>
                <Stack flexDirection="row" mt={2} ml={4}>
                  <div
                    style={{
                      borderRadius: 30,
                      width: 20,
                      height: 20,
                      backgroundColor: isFirstLevelApprovalPending
                        ? '#94DA9F'
                        : '#7B827C',
                      alignSelf: 'center',
                      marginRight: 10,
                    }}
                  />
                  <Text style={{ marginTop: 0 }}>Verifica tu información</Text>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Stack align={'center'}>
            {isFirstLevelApprovalPending && (<>
              <Text style={{ marginTop: 0 }}>Información enviada correctamente.</Text>
              <Text style={{ marginTop: 0 }}>Tu validación está <strong>en proceso</strong>, recibirás una respuesta en máximo 1 día hábil.</Text>
              <Button
                colorScheme="blue"
                onClick={() => {
                  history.push('/app/profile')
                }}
              >
                {isClickedCancel ? <Spinner size="lg" /> : 'Entendido'}
              </Button></>
            )}
            { !isFirstLevelApprovalPending && 
            <Button
              colorScheme="blue"
              onClick={() => {
                requestLevel1();
              }}
              disabled={
                isFirstLevelApprovalPending ||
                civil === '' ||
                industria === '' ||
                actividad === '' ||
                nacionalidad === '' ||
                residencia === '' ||
                fechaDeNacimiento === '' ||
                domicilio === '' ||
                nombres === '' ||
                numeroDeDocumento === '' ||
                tipoDeDocumento === '' ||
                sexo === '' ||
                imageFundFront === '' ||
                imageFundBack === ''
              }
            >
              {isClicked ? <Spinner size="lg" /> : 'Confirmar datos'}
            </Button>
            }
          </Stack>
        </>
      );
    }
  };

  return (
    <>
      <Stack align={'center'}>
        <Heading fontSize={'24'} fontWeight={'bold'} fontFamily={'body'}>
          Validación Nivel 1
        </Heading>
      </Stack>
      <Stack
        align={'end'}
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
        mb={3}
        mr={3}
      >
        <IconButton
          variant="outline"
          onClick={() => {
            if (actualPage > 1) setActualPage(actualPage - 1);
          }}
          aria-label="open menu"
          disabled={isFirstLevelApprovalPending}
          icon={<FiChevronLeft />}
        />
        <Text style={{ marginTop: 0, paddingInline: 15 }}>
          Paso {actualPage} de 7
        </Text>
        <IconButton
          disabled={isFirstLevelApprovalPending}
          variant="outline"
          onClick={() => {
            if (actualPage < 7) setActualPage(actualPage + 1);
          }}
          aria-label="open menu"
          style={{ marginTop: 0 }}
          icon={<FiChevronRight />}
        />
      </Stack>
      <Box
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
        pb={5}
        mb={10}
        paddingInline={10}
        w={'100%'}
        align={{ base: 'center', md: 'flex-start' }}
        style={{ overflow: 'visible' }}
      >
        {RenderSteps()}
      </Box>
    </>
  );
}
