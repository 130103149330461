import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
// Styled components
import { Wrapper, Heading2, Space, MediumImg, CenterBox } from 'styles/Styled';
import notFound from 'images/not-found.png';

const NotFound = () => {
  return (
    <Wrapper>
      <Space />
      <Heading2>No encontrado</Heading2>
      <CenterBox>
        <MediumImg src={notFound} alt="" />
      </CenterBox>
      <Space />
      <p>
        Lo sentimos, el contenido que estás buscando no está disponible en
        nuestra web.
      </p>
      <p>Prueba utilizando otro término de búsqueda</p>
      <p>
        <Link to="/" className="selectedLink">
          Volver al inicio
        </Link>
      </p>
      <Space />
    </Wrapper>
  );
};

export default withRouter(NotFound);
