import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from "PrivateRoute";
import NewOperation from "./NewOperation/index.js";
import EditOperation from "./EditOperation/index.js";
import Tracking from "./Tracking/index.js";

const Operation = () => {
  return (
    <Switch>
      <PrivateRoute path="*/new" component={NewOperation} />
      <PrivateRoute path="*/edit/:operationId" component={EditOperation} />
      <PrivateRoute path="*/tracking/:operationId" component={Tracking} />
    </Switch>
  );
};

export default Operation;
