import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';

import agentebtcapi from 'agentebtcapi.js';
import swal from 'sweetalert';
import { AuthContext } from 'Auth';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { Header1 } from 'styles/common/index';
import StyledAuth from '../styles';

import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';

import {
  TitleModal,
  BodyModal,
  FooterModal,
  BtnPrimaryLg,
} from 'styles/Styled';

const Login = ({ history, location }) => {
  const {
    currentToken,
    expiration,
    updateToken,
    updateExpiration,
  } = useContext(AuthContext);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const isAuthenticated =
    currentToken && expiration && parseInt(expiration) - Date.now() > 1000;

  const [passwordTypeOne, setPasswordTypeOne] = useState(false);
  const tooglePasswordOne = () => setPasswordTypeOne(!passwordTypeOne);

  const [emailValue, setEmailValue] = useState('');

  // Modals
  const [openResend, setOpenResend] = useState(false);

  if (isAuthenticated) history.push('/app/profile');

  const values = queryString.parse(location.search);

  if (values.show_message == 'account_verified') {
    swal({
      text: 'Muchas gracias. Su cuenta ha sido verificada',
      button: false,
      timer: 4000,
    });
  }

  const resendEmail = async () => {
    const jsonBody = JSON.stringify({ email: emailValue });

    let data = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: jsonBody,
    };
    let endpoint = '/auth/resendtoken';
    let url = `${agentebtcapi}${endpoint}`;
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          swal({
            text: '¡Correo enviado correctamente! Revisa tu bandeja de entrada',
            button: true,
            timer: 3000,
          });
          setOpenResend(false);

          return response.json();
        } else {
          swal({
            text:
              'Hubo un error al reenviar enlace de activación. Si el error persiste escríbenos a Soporte.',
            button: false,
            timer: 5000,
          });
          setOpenResend(false);
        }
      })
      .catch(error => {
        swal({
          text:
            'Hubo un error al reenviar enlace de activación. Si el error persiste escríbenos a Soporte.',
          button: false,
          timer: 5000,
        });
        setOpenResend(false);
      });
  };

  const handleLogin = e => {
    e.preventDefault();
    setButtonEnabled(false);
    swal({ text: 'Iniciando sesión...', button: false, timer: 1000 });

    const { email, password } = e.target.elements;

    setEmailValue(email.value);

    let body = { email: email.value, password: password.value };
    let data = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body), // body data type must match "Content-Type" header
    };
    let endpoint = '/auth/login';
    let url = `${agentebtcapi}${endpoint}`;

    fetch(url, data)
      .then(response => {
        setButtonEnabled(true);

        if (response.ok) {
          history.push('/app/profile');
          return response.json();
        } else if (response.status === 403) {
          throw { type: 'not-confirmed' };
        } else {
          throw { type: 'incorrect-data' };
        }
      })
      .then(responseJson => {
        setButtonEnabled(true);

        if (!responseJson.token) {
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });
        } else {
          swal({ text: 'Ingresando...', button: false, timer: 1000 });
          updateToken(responseJson.token);
          updateExpiration(responseJson.dateExpiration);
        }
      })
      .catch(error => {
        setButtonEnabled(true);

        switch (error.type) {
          case 'incorrect-data':
            swal({
              text: 'Usuario o contraseña incorrecto',
              button: false,
              timer: 1000,
            });
            break;
          case 'not-confirmed':
            //swal({text: "Por favor, revisa tu correo para confirmar tu cuenta", button: false, timer: 10000});

            setOpenResend(true);
            break;
          default:
            swal({
              text: 'Ha habido un error en nuestro sistema',
              button: false,
              timer: 1000,
            });
        }
      });
  };

  const onOpenModalResend = () => {
    setOpenResend(true);
  };

  const onCloseModalResend = () => {
    setOpenResend(false);
  };

  return (
    <>
      <StyledAuth.SingleCard>
        <Header1
          style={{ fontSize: 40, color: '#3E5158', marginBlockStart: 0 }}
        >
          Iniciar sesión
        </Header1>
        <StyledAuth.AuthForm onSubmit={handleLogin}>
          <div style={{ width: '100%' }}>
            <StyledAuth.Label>Correo electrónico</StyledAuth.Label>
          </div>
          <StyledAuth.AuthInput name="email" type="email" />
          <div style={{ width: '100%' }}>
            <StyledAuth.Label>Crear contraseña</StyledAuth.Label>
          </div>
          <StyledAuth.PassWrapper>
            <StyledAuth.AuthInputPass
              name="password"
              type={passwordTypeOne ? 'text' : 'password'}
            ></StyledAuth.AuthInputPass>
            <StyledAuth.AuthIcon onClick={tooglePasswordOne}>
              <FontAwesomeIcon icon={passwordTypeOne ? faEye : faEyeSlash} />
            </StyledAuth.AuthIcon>
          </StyledAuth.PassWrapper>

          <StyledAuth.AuthButton type="submit">
            Iniciar sesión
          </StyledAuth.AuthButton>
        </StyledAuth.AuthForm>

        <StyledAuth.AuthText>
          <StyledAuth.AuthLink style={{ color: '#1A4BEA' }} to="/auth/recover">
            ¿Olvidaste tu contraseña?
          </StyledAuth.AuthLink>
        </StyledAuth.AuthText>

        <StyledAuth.AuthText2>
          ¿Aún no estás registrado?{' '}
          <StyledAuth.AuthLink style={{ color: '#1A4BEA' }} to="/auth/register">
            Crea una cuenta
          </StyledAuth.AuthLink>
        </StyledAuth.AuthText2>
      </StyledAuth.SingleCard>

      <Modal
        open={openResend}
        onClose={onCloseModalResend}
        center
        closeOnOverlayClick={false}
      >
        <BodyModal>
          <TitleModal> Necesitas confirmar tu cuenta </TitleModal>

          <FooterModal>
            {' '}
            Se te ha enviado un correo con un enlace de activación, accede a el
            para continuar. De no haber recibido el e-mail puedes volver a
            solicitarlo aquí:{' '}
          </FooterModal>

          <BtnPrimaryLg onClick={() => resendEmail()}>
            Reenviar correo
          </BtnPrimaryLg>
        </BodyModal>
      </Modal>
    </>
  );
};

export default withRouter(Login);
