import { Link } from 'react-router-dom';
import Select from 'react-select';
import colors from './common/colors';
import styled from 'styled-components';

/*= ====== NAVIGATION ======= */

export const StyledSelect = styled(Select)`
  height: 0px;
  display: hidden;
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserEmail = styled.span`
  color: #f2a900;
  font-weight: 600;

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }
`;

export const Heading1 = styled.h1`
  font-size: 54px;
  font-family: Poppins, sans-serif;
  color: #f2a900;
  font-weight: 600;
  margin: 0;

  @media (max-width: 320px) {
    font-size: 38px;
  }
`;

export const HeadingPages = styled.h1`
  font-size: 48px;
  font-family: Poppins, sans-serif;
  color: #f2a900;
  font-weight: 600;
  margin: 0;
  text-align: center;

  @media (max-width: 640px) {
    font-size: 38px;
  }

  @media (max-width: 320px) {
    font-size: 32px;
  }
`;

export const SubHeading = styled.h2`
  font-family: Poppins, sans-serif;
  text-align: center;
  font-size: 24px;
  margin-top: 0;

  @media (max-width: 640px) {
    font-size: 19px;
  }

  @media (max-width: 320px) {
    font-size: 17px;
  }
`;

export const Heading2 = styled.h2`
  font-family: Poppins, sans-serif;
  text-align: center;
  font-size: 24px;
  font-weight: 400;

  @media screen and (max-width: 640px) {
    font-size: 18px;
    font-weight: 200;
  }
`;

export const Heading3 = styled.h3`
  font-family: Poppins, sans-serif;
  font-size: 20px;
  color: #f2a900;
  font-weight: 400;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const TextSaldo = styled.h3`
  font-family: Poppins, sans-serif;
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const TextCuponAviso = styled.p`
  font-family: Poppins, sans-serif;
  font-size: 15px;
  color: #20c997;
  font-weight: 400;
  text-align: center;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const TextCuponAvisoVenc = styled.p`
  font-family: Poppins, sans-serif;
  font-size: 15px;
  color: #fcc419;
  font-weight: 400;
  text-align: center;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const Tagline = styled.h5`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
`;

export const Space = styled.div`
  min-height: 46px;
  padding: 6px 0;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  padding: 0 32px;
  margin: 0;

  @media (max-width: 640px) {
    padding: 0 24px;
  }

  @media (max-width: 320px) {
    padding: 0 16px;
  }
`;

export const WrapperMin = styled.div`
  width: 100%;
  max-width: 960px;
  box-sizing: border-box;
  padding: 0 32px;
  margin: 0 auto;

  @media (max-width: 640px) {
    padding: 0 24px;
  }

  @media (max-width: 320px) {
    padding: 0 16px;
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  padding: 0 32px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 120px 0;
`;

export const Section = styled.section`
  display: flex;
  padding: 60px 0;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media (max-width: 640px) {
    margin-top: 55px;
    padding-bottom: 32px;
  }
`;

/* ====== COMO FUNCIONA ====== */
export const BoxContainer = styled.div`
  padding: 0 24px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding: 0 48px;
  }

  @media (max-width: 640px) {
    padding: 0;
  }
`;

export const BoxColumn = styled.div`
  flex: 0 1 48%;

  @media (max-width: 768px) {
    flex: 0 1 100%;
  }
`;

export const BoxHeading = styled.h3`
  flex: 0 1 48%;
  text-align: center;
  font-weight: 400;
  font-weight: 600;
  font-size: 22px;

  @media (max-width: 320px) {
    font-size: 19px;
  }
`;

export const Box = styled.div`
  border-radius: 4px;
  flex: 0 1 100%;
  padding: 16px 0;
  box-sizing: border-box;
`;

export const Steps = styled.ol`
  list-style: none;
  margin: 0;
  padding: 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

export const WalletKey = styled.p`
  font-size: 16px;
  background: #f2a900;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 8px;

  @media (max-width: 640px) {
    font-size: 14px;
  }
  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

/* ====== AGENTES BTC ====== */
export const Agentes = styled.section`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const Agente = styled.div`
  padding: 16px;
  box-sizing: border-box;
  flex: 0 1 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const AgenteText = styled.div`
  flex: 0 1 44%;
  margin-left: 30px;
  @media (max-width: 640px) {
    flex: 0 1 100%;
    flex-wrap: wrap;
  }
`;

export const AgenteContact = styled.ul`
  padding: 0;
  @media (max-width: 640px) {
    flex-wrap: wrap;
    font-size: 12px;
  }
`;

export const AgenteLogo = styled.img`
  width: 100%;
  max-width: 100px;
  margin-left: -20px;
  @media (max-width: 640px) {
    flex-wrap: wrap;
    margin: 0px 10%;
  }
`;

export const Map = styled.img`
  width: 100%;
  max-width: 420px;
  padding: 10px;
  border: 1px solid #f2a900;
  border-radius: 4px;
`;

export const Img = styled.img`
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
`;

export const ImgFigure = styled.figure`
  margin: 0;
  text-align: center;
`;

export const ImgNormal = styled.img`
  width: 100%;
  max-width: 220px;
  margin-top: 24px;
`;

export const Line = styled.div`
  width: 100%;
  padding-bottom: 10px;
  border-top: 1px solid rgba(117, 117, 117, 0.5);
`;

export const List = styled.ul`
  margin: 0;
  padding: 0 24px;
  list-style-type: none;
  justify-content: space-between;
  align-items: center;
  display: flex;

  @media (max-width: 640px) {
    padding: 0;
  }
`;

export const ListColumn = styled.ul`
  margin: 0;
  padding: 10px 24px;
  list-style-type: none;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 640px) {
    padding: 0;
  }
`;

export const BoxBitcoin = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 24px;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media screen and (max-width: 640px) {
    padding: 0px;
  }
`;

export const BoxPricing = styled.ul`
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  max-width: 360px;
  border: 3px solid #f2a900;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  color: #757575;
`;

export const BoxCalculator = styled.ul`
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  max-width: 360px;
  border: 3px solid #f2a900;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  color: #757575;
`;

export const BoxCurrency = styled.li`
  display: flex;
  justify-content: space-between;
  flex: 0 1 46%;
`;

export const InputBox = styled.div`
  display: flex;
  background: rgba(240, 240, 240, 0.87) !important;
  box-sizing: border-box;
  border-radius: 4px;
  flex: 0 1 36%;
  justify-content: center;
  align-items: center;
`;

export const InputValue = styled.input`
  background: transparent;
  border: none;
  padding: 0 12px 0 12px;
  color: #757575;
`;

export const CatcherHelp = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SectionBox = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const CenterBox = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FaqImg = styled.img`
  width: 100%;
  max-width: 480px;
  flex: 0 1 30%;

  @media (max-width: 640px) {
    max-width: 320px;
  }

  @media (max-width: 320px) {
    max-width: 260px;
  }
`;

export const MediumImg = styled.img`
  width: 100%;
  max-width: 240px;
  flex: 0 1 30%;
`;

export const SectionText = styled.h5`
  flex: 0 1 50%;
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 768px) {
    flex: 0 1 50%;
  }

  @media (max-width: 640px) {
    flex: 0 1 100%;
    text-align: center;
  }
`;

export const SectionEdit = styled.h5`
  font-size: 18px;
  font-weight: 400;
  margin-left: 40px;
  display: flex;
  align-items: flex-end;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LoginInput = styled.input`
  border: 1px solid #f2a900;
  padding: 8px 10px;
  border-radius: 8px;
  margin: 8px 0;
  outline: none;
`;

export const LoginPasswordAd = styled.p`
  font-size: 9px;
  font-weight: 700;
  margin: 0;
`;

export const LoginText = styled.p`
  margin-bottom: 0;
`;

export const LoginButton = styled.button`
  background: #f2a900;
  border: none;
  padding: 10px 12px;
  color: #fff;
  border-radius: 24px;
  font-size: 20px;
  cursor: pointer;
  margin-top: 8px;
  outline: none;

  &:disabled {
    background: #f2a90085;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const Advantages = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  flex-wrap: wrap;
`;

export const Card = styled.li`
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  text-align: center;
  flex: 1;
  max-width: 300px;
  padding: 24px;
  padding-top: 36px;
  margin: 0 16px;

  @media (max-width: 768px) {
    flex: 0 1 100%;
    padding: 16px;
    margin: 8px;
    max-width: 480px;
  }
`;

export const CardHeading = styled.h3`
  font-size: 22px;
  font-weight: 400;
`;

export const CardIcon = styled.img`
  width: 100;
`;

export const StatsHeading = styled.h5`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
`;

export const StatsPrice = styled.p`
  margin: 0;
  color: #f2a900;
  font-weight: 600;
  font-size: 28px;
  font-family: Poppins, sant-serif;

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }
`;

export const StatsCurrency = styled.span`
  font-weight: 300;
  font-size: 18px;
  color: #ffffff;
`;

export const StatsBox = styled.div`
  border: 5px solid #f2a900;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 16px;
  padding-right: 25px;
  margin-right: -20px;
  background: transparent;
  display: flex;
  align-items: center;
  z-index: 10;
  @media screen and (max-width: 640px) {
    margin-top: 10px;
    padding: 6px;
    padding-right: 25px;
    font-size: 10px;
  }
`;

export const FeesTable = styled.table`
  width: 100%;
  fotnt-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;

  @media (max-width: 640px) {
    font-size: 12px;
  }
`;

export const FeesHead = styled.th`
  border-bottom: 1px solid #ddd;
`;

export const Bullet = styled.span`
  display: inline-block;
  font-size: 24px;
  background: #f2a900;
  border-radius: 50%;
  padding: 3px 14px;
  color: #ffffff;
`;

/* ===============================
        DASHBOARD PERFIL
=============================== */

export const Main = styled.div`
  flex: 0 1 79%;
`;

/* ===============================
        TRACKEO OPERACION
=============================== */

export const TrackBox = styled.div`
  width: 100%;
  max-width: 380px;
  margin: 120px auto 60px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-betwween;
`;

export const TrackStep = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  font-family: Poppins, sans-serif;
`;

export const TrackTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TrackContent = styled.div`
  display: flex;
  margin-left: 18px;
  padding: 12px 0 16px 22px;
  border-left: 1px solid #cdd6e0;
  font-size: 14px;
  align-items: center;
`;

export const ProfileInfo = styled.span`
  font-weight: 400;
  font-size: 20px;

  @media (max-width: 640px) {
    font-size: 14px;
    flex: 0 1 80%;
    padding-left: 10px;
    padding-top: 15px;
  }

  @media (max-width: 320px) {
    font-size: 16px;
  }
`;

export const ProfileList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 100%;
  flex-wrap: wrap;
  padding: 0;
`;

export const ProfileItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  flex: 0 1 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  margin-left: 10px;
`;

export const ProfileText = styled.h3`
  display: flex;
  flex: 0 1 65%;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 8px 0;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex: 0 1 75%;
  }

  @media (max-width: 640px) {
    flex: 0 1 100%;
  }

  @media (max-width: 320px) {
    font-size: 16px;
  }
`;

export const ProfileBalance = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 110px 0;
  box-sizing: border-box;
  border: 4px solid #f2aa00;
  border-radius: 50%;
  flex-basis: 280px;
`;

export const BalanceItem = styled.div`
  font-size: 18px;
`;

export const AccountBox = styled.div`
  border: 1px solid #f2aa00;
  border-radius: 8px;
  flex: 0 1 86%;
  box-sizing: border-box;
  padding: 0 24px;
  margin-top: 24px;
  font-weight: 400;

  @media screen and (max-width: 640px) {
    margin-left: 10px;
  }

  @media screen and (max-width: 320px) {
    flex: 0 1 84%;
  }
`;

export const EditButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: ${colors.dark_blue_agente_btc};
  font-family: Montserrat;
  font-weight: 600;
  flex: 0 1 35%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @media (max-width: 768px) {
    flex: 0 1 25%;
  }

  @media (max-width: 640px) {
    padding: 0;
  }

  @media (max-width: 320px) {
    font-size: 18px;
  }
`;

export const AlertButton = styled.button`
  border: none;
  outline: none;
  background-color: #f2a900;
  color: ${colors.dark_blue_agente_btc};
  font-family: Montserrat;
  text-decoration: none;
  padding: 16px 32px;
  font-weight: 600;
  border-radius: 12px;
  font-size: 22px;
  margin: 12px 0;
  flex: 0 1 20%;
  text-align: center;
  cursor: pointer;
  @media (max-width: 768px) {
    flex: 0 1 30%;
  }

  @media (max-width: 640px) {
    flex: 0 1 60%;
    padding: 12px 0;
    border: none;
    font-size: 18px;
  }
`;

/* ====== MODALS ====== */
export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;

`;

export const CloseModalIcon = styled.span`
  color: #000;
  @media (max-width: 1100px) {
    color: #000;
  }
  @media (max-width: 900px) {
    color: #000;
  }

  @media (max-width: 770px) {
    color: #000;
  }

  @media (max-width: 640px) {
    color: #fff;
  }
`;

export const ModalWrapperOut = styled.div`
  position: absolute;
  background-color: red;
  width: 100%;
  height: 300px;
  top: 0;
  left: 0;
  align-items: center;
`;

export const Modal = styled.div`
  position: fixed;
  display: inline-flex;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  max-height: 300px;
  background-color: #fff;
  box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 45%;
  @media (max-width: 1100px) {
    width: 55%;
  }
  @media (max-width: 900px) {
    width: 65%;
  }

  @media (max-width: 770px) {
    width: 75%;
  }

  @media (max-width: 640px) {
    display: block;
    left: 50%;
    width: 50%;
    max-height: none;
    top: 300px;
  }
`;

export const ModalHeaderKasnt = styled.p`
  color: #3e5158;
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 10px;
  @media (max-width: 1260px) {
    font-size: 20px;
  }
  @media (max-width: 640px) {
    font-size: 20px;
    
  }
`;
export const ModalHeaderKasnt2 = styled.p`
  color: #3e5158;
  text-align: center;
  margin-bottom: 10px;
  background-color: #ffc400;
  font-size: 30px;
  border-radius: 10px;
  vertical-align: text-top;
  font-weight: 400;
  @media (max-width: 1260px) {
    font-size: 20px;
  }
  @media (max-width: 640px) {
    font-size: 20px;
    margin-top: -20px;
  }
`;

export const ModalWrapperKasnt = styled.div`
  padding-top: 25px;
  padding-left: 15px;
  width: 45%;
  backgroung-color: red;
  @media (max-width: 640px) {
    width: 100%;
    padding-left: 0px;
  }
`;

export const ModalButtonKasnt = styled.div`
  margin-top: 5px;
  @media (max-width: 1260px) {
    margin-top: 10px;
  }
  @media (max-width: 640px) {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const ModalButtonBoxKasnt = styled.div`
  background: #f2a900;
  border: none;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  padding: 6px 18px;
  border-radius: 24px;
  @media (max-width: 1260px) {
    padding: 3px 9px;
    font-size: 12px;
  }
  @media (max-width: 640px) {
    font-size: 10px;
  }
`;

export const ModalImgKasnt = styled.img`
  height: 300px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0px;
  @media (max-width: 640px) {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 15px;
    height:200px;
    margin: 0px auto;
  }
`;

export const ModalKasnetOne = styled.div`
    position: fixed;
    display: inline-flex;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    height : auto;
    background-color: #fff;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 60%;
    background-color: red;
    @media (max-width: 1100px) {
        width: 55%;
    }
    @media (max-width: 900px) {
        width: 65%;
    }

    @media (max-width: 770px) {
        width: 75%;
    }

    @media (max-width: 640px) {
        display: block;
        left: 50%;
        max-height: none;
    }
`;

export const ModalKasnetOneAdvice = styled.div`
    position: fixed;
    display: inline-flex;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    height : auto;
    background-color: #fff;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-flow : row wrap;
    justify-content: center;
    padding: 2rem 1rem 2rem 1rem;
    width: 50%;
    background-color: white;
    @media (max-width: 1100px) {
        width: 55%;
    }
    @media (max-width: 900px) {
        width: 65%;
    }

    @media (max-width: 770px) {
        width: 75%;
    }

    @media (max-width: 640px) {
        display: block;
        left: 50%;
        max-height: none;
    }
`;

export const ModalImgKasntOne = styled.img`
    height: auto;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
    max-width: 100%;
    width : 100%;
    @media (max-width: 640px) {
        height: auto;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 15px;
    }
`;

export const CloseModal = styled.span`
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
`;

export const ModalText = styled.p`
  font-size: 18px;
  text-align: center;
  color: #757575;

  @media (max-width: 320px) {
    font-size: 16px;
  }
  @media (max-width: 640px) {
    font-size: 18px;
    margin-bottom: 5px;
  }
`;

export const ModalButton = styled.button`
  background: #f2a900;
  border: none;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  padding: 6px 18px;
  border-radius: 24px;
`;

/* ====== CURRENCY CALCULATOR ====== */

export const MySelect = styled.select`
  flex: 0 1 44%;
  border: none;
  border-bottom: 1px solid #f2aa00;
  outline: none;
  color: #757575;

  @media screen and (max-width: 640px) {
    margin: 10px;
  }
`;

export const MyLabel = styled.label`
  font-size: 16px;
`;

export const VisorCoins = styled.span`
  margin: 1px auto;
  readonly: yes;
  color: #757575;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    font-size: 24px;
    width: 200px;
  }
`;

export const Warning = styled.span`
  width: 600px;
  -webkit-user-modify: read-only;
  color: #f3a306;
  font-size: 14px;
  margin-bottom: 0;
`;

export const AmountCurrency = styled.span`
  margin: 0;
  color: #f2a900;
  font-weight: 600;
  font-family: Poppins, sant-serif;
`;

export const AmountText = styled.p`
  position: relative;
`;

export const Tab = styled.button`
  flex: 0 1 48%;
  cursor: pointer;
  padding: 12px;
  border: none;
  background: transparent;
  border-radius: 5px;
  color: ${colors.dark_blue_agente_btc};
  outline: none;
  opacity: 0.8;
  font-weight: bold;
  font-family: Montserrat, sant-serif;
  font-size: 24px;
  @media screen and (max-width: 640px) {
    font-size: 20px;
    width: 100%;
  }

  @media (max-width: 320px) {
    width: auto;
    flex: 0 1 100%;
    margin-bottom: 12px;
  }
`;

export const Tab1 = styled.button`
  flex: 0 1 50%;
  cursor: pointer;
  padding: 15px 12px;
  background: transparent;
  outline: none;
  opacity: 0.8;
  font-weight: bold;
  font-family: Montserrat, sant-serif;
  font-size: 24px;
  border: 0px;
  border-bottom: 2px solid grey;
  @media screen and (max-width: 640px) {
    font-size: 20px;
    width: 100%;
  }

  @media (max-width: 320px) {
    width: auto;
    flex: 0 1 100%;
    margin-bottom: 12px;
  }
`;

export const SelectCurrency = styled.div`
  display: flex;
  justify-content: space-around;
  width: 30%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    margin: 12px auto 0;
    font-size: 12px;
  }
  @media screen and (max-width: 640px) {
    width: 40%;
  }
  @media screen and (max-width: 320px) {
    width: 50%;
  }
`;

export const DisplayImage = styled.img`
  display: flex;
  justify-content: flex-start;
  flex: 1 1 100%;
  order: 1;
  margin: auto;
  margin-bottom: 10px;
  max-width: 240px;
`;

export const BTCPartnersBox = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #f2a900;
  border-left: 0;
  border-right: 0;
  padding: 24px;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

export const BTCPartners = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 50%;

  @media (max-width: 640px) {
    flex-direction: column;
    flex: 0 1 100%;
  }
`;

export const BTCText = styled.div`
  flex: 0 1 50%;
  font-weight: 600;
  font-weight: 600;
  font-size: 20px;
  font-family: Poppins, sans-serif;
  @media (max-width: 640px) {
    font-size: 14px;
  }
`;

export const LogoPartner = styled.img`
  width: 100%;
  max-width: 140px;

  @media (max-width: 768px) {
    max-width: 100px;
  }

  @media (max-width: 640px) {
    width: 25%;
    height: 20%;
  }

  @media (max-width: 640px) {
    width: auto;
    height: auto;
    margin: 16px 0;
  }
`;

/* ===============================
        FEATURE: WALLET
=============================== */

export const GraphContent = styled.div`
  width: 75%;
  @media (max-width: 640px) {
    width: 100%;
  }
`;

export const WalletContent = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  padding: 5px;
  height: 100%;
  width: 100%;
  flex-flow: row wrap;

  @media (min-width: 640px) {
    flex-flow: row nowrap;
  }
`;

export const WalletBox = styled.div`
  width: 48%;
  padding: 10px;
  margin: 1%;
  box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
`;

export const WalletBoxLeft = styled.div`
  width: 55%;
  padding: 5px 20px;
  margin: 1% 1%;
  margin-left: 5%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;

  @media (max-width: 640px) {
    margin: 2% 6%;
    width: 100%;
  }
`;

export const WalletBoxRight = styled.div`
  width: 40%;
  padding: 5px 20px;
  margin: 1% 1%;
  margin-right: 5%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  @media (max-width: 640px) {
    margin: 2% 6%;
    width: 100%;
  }
`;

export const TitleBox = styled.h4`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: #3c4a5b;
  line-height: 0.5;
  margin-block-start: 0.33em;
  margin-block-end: 0.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 700;
  text-align: left;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const TextBox = styled.p`
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  color: #3c4a5b;
  font-weight: 300;
  text-align: center;
  padding: 20px 0px;
`;

export const DisplayCount = styled.h4`
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  color: #486fee;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 600;
  text-align: left;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const DescriptionBox = styled.p`
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  color: #3c4a5b;
  font-weight: 400;
  text-align: left;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;

export const ButtonContent = styled.div`
  display: inline-block;
`;

export const BtnPrimary = styled.button`
  background-color: #486fee;
  width: 100px;
  border: 1px solid transparent;
  font-weight: 500;
  display: inline-block;
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(24, 28, 32, 0.05), 0 1px 2px 0 #b8c1c9,
    0 1px 0 0 #2196c8;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: 5px 0px;
  &:disabled {
    opacity: 0.8;
  }
`;

export const BtnSecondary = styled(BtnPrimary)`
  background-color: #ffffff;
  color: #486fee;
`;

export const BtnSecondaryCenter = styled(BtnSecondary)`
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
  white-space: nowrap;
`;

export const BtnPrimaryLg = styled(BtnPrimary)`
  width: 50%;
  padding: 15px 20px;
  margin: 0 auto;
  margin-top: 30px;
  display: block;
`;

export const BtnPrimaryFull = styled(BtnPrimaryLg)`
  width: 100%;
`;

export const ListLinks = styled.ul`
  margin: 0;
  padding: 10px 0px;
  list-style-type: none;
  color: #707070;
`;

export const ListLinksItem = styled.li`
  cursor: pointer;
  text-decoration: underline;
  margin: 0;
  padding: 8px 0px;
`;

// Listado de transacciones recientes

export const ListRowDiv = styled.div`
  display: flex;
  color: #707070;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 15px;
`;

export const ListColumnDiv = styled.div`
  flex: 1;
  text-align: center;
`;

export const ColAmount = styled.span`
  color: #486fee;
`;

export const ColStatus = styled.span`
  color: #486fee;
  text-transform: uppercase;
`;

export const ColState = styled.span`
  color: #099268;
`;

export const ColText = styled.span`
  color: #707070;
`;

export const ColTextLight = styled(ColText)`
  font-weight: 300;
`;

export const ColButton = styled(Link)`
  background: #edfaff;
  border: none;
  color: #1ab6ff;
  cursor: pointer;
  outline: none;
  padding: 7px 18px;
  border-radius: 4px;
  margin-top: -4px;
  font-weight: 600;
  text-decoration: none;
`;

// Modales

export const BodyModal = styled.div`
  padding: 0 10px;
  max-width: 410px;
  font-family: Montserrat, sans-serif;
`;

export const TitleModal = styled.h3`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: #3c4a5b;
  font-weight: 600;
  text-align: center;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const ImageModal = styled.img`
  margin: 0 auto;
  display: block;
  float: none;
  padding: 5px 0;
  width: 150px;
`;

export const DetailsOperation = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  color: #353c43;
  font-weight: 400;
  line-height: 0.2;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const DetailTitle = styled.p`
  color: #353c43;
  font-weight: 700;
  float: left;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;

export const DetailAmount = styled.p`
  font-size: 13px;
  color: #353c43;
  float: right;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;

export const Clear = styled.div`
  clear: both;
`;

export const Margin20 = styled.div`
  margin-top: 20px;
`;

export const FooterModal = styled.p`
  font-family: Montserrat, sans-serif;
  margin: 20px 0 0;
  font-size: 13px;
  text-align: center;
  color: #758698;
  opacity: 0.7;
  font-weight: 400;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const LabelModal = styled.label`
  display: block;
  padding-right: 20px;
  padding-top: 10px;
`;

export const DescriptionModal = styled.p`
  display: block;

  padding-top: 10px;
  color: #000;
  font-size: 14px;
`;

export const AlertModal = styled.p`
  display: block;
  font-size: 14px;

  padding: 8px 35px 8px 14px;
  margin-bottom: 18px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #c09853;
`;

export const DangerModal = styled.p`
  display: block;
  font-size: 14px;

  padding: 8px 35px 8px 14px;
  margin-bottom: 18px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: rgba(234, 57, 67, 0.1);
  border: 1px solid #fbeed5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #ea3943;
`;

export const LabelSpan = styled.span`
  display: block;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #353c43;

  .alert {
    color: #e55675;
  }
`;

export const LabelSpanAlert = styled(LabelSpan)`
  color: #e55675;
`;

export const InputModal = styled.input`
  width: 100%;
  padding: 6px 10px;
  background-color: #f9f9fb;
  background-image: none;
  box-shadow: none;
  border: 1px solid #d6dce1;
  border-radius: 3px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  -webkit-transition: border-color 0.3s ease-out;
  transition: border-color 0.3s ease-out;
`;

// Stepper

export const ContentDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColDiv = styled.div``;

export const ContentDivLeft = styled.div`
  display: flex;
  align-items: left;
  padding: 15px 0px;
`;

export const ContentIcons = styled.div`
  padding: 10px 5px;
  padding-right: 10px;
  color: #000;
`;

export const IconCircle = styled(Link)`
  padding: 15px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 30px;
  color: #000;
  cursor: pointer;
  opacity: 0.5;
  box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.06);
  &:hover {
    opacity: 1;
    color: #486fee;
  }
`;

export const StepperTitle = styled.div`
  font-weight: 600;
  font-size: 13px;
  color: #121212;
  padding: 5px 0px;
`;

export const StepperSub = styled.div`
  font-weight: 300;
  font-size: 13px;
  color: #121212;
`;

export const PercentageText = styled.div`
  font-weight: 300;
  font-size: 13px;
  color: #121212;
  margin-top: 20px;
`;

export const CheckLabel = styled.label`
  font-family: Montserrat;
  font-size: 13px;
  text-align: left;
  display: inline;
  width: 95%;
  color: ${colors.dark_blue_agente_btc};
`;

export const CheckInput = styled.input`
  height: 15px;
  width: 5%;
  font-family: Montserrat;
  font-size: 13px;
  border: none;
  margin-top: 10px;
  color: ${colors.dark_blue_agente_btc};
  box-sizing: border-box;
  font-weight: 400;
  outline: none;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;


/* Deposita con efectivo */

export const DepositTitle = styled.p`
  font-size: 38px;
  color: #1552F0;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

export const DepositaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 5px;

  @media screen and (min-width: 1135px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const UbicaAgenteContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;

  @media (max-width: 768px) {  
    margin-bottom:5em;
  }
`;


export const UbicaAgenteVideo = styled.div`
position: relative;
padding-bottom: 56.25%;
height: 0;
overflow: hidden;
`;

export const ResponsiveIframe = styled.iframe`
  border-radius: 10px; 
  width: 400px;
  height: 250px; 
  overflow: hidden;
`;
