import React, { useEffect, useState } from 'react';
export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const [token, setCurrentToken] = useState(
    localStorage.getItem('token-agente-btc')
  );
  const [expiration, setExpiration] = useState(
    localStorage.getItem('token-expires-agente-btc')
  );

  const updateToken = token => {
    localStorage.setItem('token-agente-btc', token);
    setCurrentToken(token);
  };

  const updateExpiration = expiration => {
    localStorage.setItem('token-expires-agente-btc', expiration);
    setExpiration(expiration);
  };

  const clearAuthentication = () => {
    updateToken('');
    updateExpiration('');
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        currentToken: token,
        updateToken: updateToken,
        expiration: expiration,
        updateExpiration: updateExpiration,
        clearAuthentication: clearAuthentication,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
