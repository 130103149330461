import React from 'react';
import Calculator from '../../../../components/Calculator';
import CalculatorV2 from '../../../../components/CalculatorV2/CalculatorV2';
import { Box } from '@chakra-ui/react';

const CalculatorPage = () => {
  return (
    <Box align={'center'} w="100%" pt={10}>
      <CalculatorV2 selected="BUY" />
      {/* <Calculator selected="sell" /> */}
    </Box>
  );
};

export default CalculatorPage;
