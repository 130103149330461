import styled from 'styled-components';

export const Visor = styled.div`
  position: relative;
  height: 60px;
  width: 60%;
  font-size: 34px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  display: flex;
  &:hover {
    border: 1px solid #b3b3b3;
  }
  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media screen and (max-width: 640px) {
    text-align: center;
    font-size: 24px;
  }

  @media screen and (max-width: 320px) {
    height: 60px;
    line-height: 60px;
  }
`;

export const EtiquetaPrecioFiat = styled.div`
  width: 100%;
  position: absolute;
  right: 0px;
  height: 100%;
  font-weight: 600;
  font-size: 28px;
  border-radius: 0px 5px 5px 0px;
  color: rgb(191, 191, 191);
  font-family: Montserrat;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-content: start;
  flex-direction: column;
  text-align: left;
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`;

export const CryptoIcon = styled.img`
  width: 40px;
  display: inline-block;
  padding-right: 15px;
`;

export const EtiquetaNombre = styled.div`
  font-size: 12px;
  font-weight: 400;
  padding-left: 20px;
`;
