export const getAllowedBanksList = selectedOption => {
  const allowedBanksOptions = {
    PEN: [
      { value: 'Interbank', label: 'Interbank' },
      { value: 'BCP', label: 'BCP' },
      { value: 'BanBif', label: 'BanBif' },
    ],
    USD: [
      { value: 'Interbank', label: 'Interbank' },
      { value: 'BCP', label: 'BCP' },
      { value: 'BanBif', label: 'BanBif' },
      { value: 'Banco Ficohsa', label: 'Banco Ficohsa' },
      {
        value: 'Banco Atlántida',
        label: 'Banco Atlántida',
      },
    ],
    HNL: [
      { value: 'Banco Ficohsa', label: 'Banco Ficohsa' },
      {
        value: 'Banco Atlántida',
        label: 'Banco Atlántida',
      },
    ],
  };

  const allowedBanks = allowedBanksOptions[selectedOption] || [
    { value: 'Interbank', label: 'Interbank' },
    { value: 'BCP', label: 'BCP' },
    { value: 'BanBif', label: 'BanBif' },
  ];
  return allowedBanks;
};
