import React, { useContext, useState, useEffect } from 'react';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { css } from 'glamor';
import ReactFlagsSelect from 'react-flags-select';
import {
  Heading,
  Text,
  Input,
  Stack,
  Box,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthContext } from 'Auth';
import ReactGA from 'react-ga';
import analyticsCode from 'analyticsCode.js';

let theme = createTheme({
  palette: {
    primary: {
      main: '#1A4BEA',
      dark: '#1A4BEA',
      light: '01fff0',
    },
  },
});

const SettingsProfil = () => {
  const [user, setDataUser] = useState({});
  const [userName, setUserName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [phone, setPhone] = useState('');
  const [dni, setDni] = useState('');
  const [couponCode, setCouponCode] = useState('');
  let { currentToken } = useContext(AuthContext);

  const [isSmallerThan640] = useMediaQuery('(max-width: 640px)');

  ReactGA.initialize(analyticsCode);

  const color = useColorModeValue('white', 'gray.800');
  const [pais, setPais] = useState('');
  const [residencia, setResidencia] = useState('');
  const [distrito, setDistrito] = useState('');
  const [address, setAddress] = useState('');

  const getUser = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setDataUser(responseJson);
        setPhone(responseJson.phone);
        setDni(responseJson.dni);
        setPais(
          responseJson.residenceCountry ? responseJson.residenceCountry : ''
        );
        setResidencia(
          responseJson.residenceCity ? responseJson.residenceCity : ''
        );
        setDistrito(
          responseJson.residenceDistrict ? responseJson.residenceDistrict : ''
        );
        setAddress(responseJson.address);
        setUserName(responseJson.name);
        setBirthday(responseJson.birthday);
        /*if(responseJson.hasRegisterCoupon){
                getCoupon(responseJson.registerCouponId);
              }*/
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    getUser();
  }, [currentToken]);

  const updateData = newProp => {
    /*const wallet = newProp.wallet;
    if(wallet){
      if(!(25 <= wallet.length && wallet.length <= 34) || !(wallet.startsWith('1') || wallet.startsWith('3') || wallet.startsWith('bc1'))){
        swal({text: "La dirección de la wallet ingresada no es de Bitcoin", button: false, timer: 4000});
        return;
      }
    }*/
    toast('Actualizando datos...', {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      bodyClassName: css({ fontFamily: 'Montserrat' }),
    });
    let body = { ...user, ...newProp };
    var formData = new FormData();

    const encodeFormData = data => {
      return Object.keys(data)
        .map(
          key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&');
    };

    for (let key in body) {
      formData.append(key, body[key]);
    }
    let data = {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeFormData(body), // body data type must match "Content-Type" header
    };
    let endpoint = '/client/user/regular/update';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          getUser();
        }
        return response.json();
      })
      .then(responseJson => {
        toast(responseJson.msg, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          bodyClassName: css({ fontFamily: 'Montserrat' }),
        });
      })
      .catch(error => {
        swal({
          text:
            'Lo sentimos, hubo un error en el sistema y no pudimos actualizar los datos. Intente nuevamente más adelante.',
          button: false,
          timer: 1000,
        });
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        bg={color}
        boxShadow={'2xl'}
        rounded={'md'}
        pb={5}
        mb={10}
        paddingInline={10}
        w={'100%'}
        align={{ base: 'center', md: 'center' }}
      >
        <Heading fontSize={'24'} fontWeight={'bold'} color="#1A4BEA" p={10}>
          Información Personal
        </Heading>
        <Stack
          align={'center'}
          justify={'center'}
          justifyContent="space-around"
          mb={5}
          mt={5}
          sx={{
            flexDirection: isSmallerThan640 ? 'column' : 'row',
            alignItems: isSmallerThan640 ? 'center' : 'baseline',
          }}
        >
          <Box
            flexDirection="column"
            style={{
              width: isSmallerThan640 ? '100%' : '40%',
            }}
            px={3}
          >
            <Text mb="4px" align="start" color="#C0C0C0" fontSize={'12'}>
              Nombre completos
            </Text>
            <Box
              style={{
                backgroundColor: 'lightgrey',
                borderRadius: 5,
                padding: 8,
              }}
            >
              {userName}
            </Box>
            <Text mb="4px" align="start" mt={3} color="#C0C0C0" fontSize={'12'}>
              E-mail
            </Text>
            <Box
              style={{
                backgroundColor: 'lightgrey',
                borderRadius: 5,
                padding: 8,
              }}
            >
              {user.email}
            </Box>
          </Box>
          <Box
            flexDirection="column"
            style={{ width: isSmallerThan640 ? '100%' : '40%' }}
            px={3}
          >
            <Text mb="4px" align="start" mt={3} color="#C0C0C0" fontSize={'12'}>
              Número de teléfono
            </Text>
            <Input
              size="xs"
              style={{
                borderColor: 'lightgrey',
                borderWidth: 1,
                borderRadius: 5,
                padding: 8,
                width: '100%',
              }}
              value={phone}
              onChange={event => setPhone(event.target.value)}
            />
            <Stack
              align={'center'}
              justify={'left'}
              mb={5}
              mt={5}
              ml={10}
              flexDirection={'row'}
              style={{ alignItems: 'baseline' }}
            >
              <Box
                style={{
                  borderColor: 'gray',
                  borderLeftWidth: 1,
                  borderBottomWidth: 1,
                  height: 30,
                  width: 60,
                  marginRight: 8,
                }}
              />
              <Button
                variant="contained"
                size="small"
                onClick={() => updateData({ phone, dni, coupon: couponCode })}
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        </Stack>
        <Stack
          align={'center'}
          justify={'center'}
          justifyContent="space-around"
          mb={5}
          mt={5}
          sx={{ flexDirection: isSmallerThan640 ? 'column' : 'row' }}
        >
          <Box
            flexDirection="column"
            style={{ width: isSmallerThan640 ? '100%' : '40%' }}
            px={3}
          >
            <Text mb="4px" align="start" color="#C0C0C0" fontSize={'12'}>
              Documento de Identidad
            </Text>
            <Box
              style={{
                backgroundColor: 'lightgrey',
                borderRadius: 5,
                padding: 8,
              }}
            >
              {dni ? dni : 'Por favor valide su cuenta'}
            </Box>
          </Box>
          <Box
            flexDirection="column"
            style={{ width: isSmallerThan640 ? '100%' : '40%' }}
            px={3}
          ></Box>
        </Stack>
        <Heading fontSize={'24'} fontWeight={'bold'} color="#1A4BEA" p={10}>
          Dirección Residencial
        </Heading>
        <Box
          paddingInline={50}
          sx={{ width: isSmallerThan640 ? '100%' : '80%' }}
        >
          <Text mb="8px" align="start">
            País
          </Text>
          <ReactFlagsSelect
            required
            countries={[
              'PE',
              'AR',
              'CL',
              'CO',
              'BO',
              'CR',
              'DO',
              'EC',
              'ES',
              'GT',
              'HN',
              'MX',
              'NI',
              'PA',
              'PY',
              'SV',
              'PR',
              'UY',
              'VE',

              'BE',
              'BG',
              'CZ',
              'DK',
              'DE',
              'EE',
              'IE',
              'GR',
              'ES',
              'FR',
              'HR',
              'IT',
              'CY',
              'LV',
              'LT',
              'LU',
              'HU',
              'MT',
              'NL',
              'AT',
              'PL',
              'PT',
              'RO',
              'SI',
              'SK',
              'FI',
              'SE',
              'IS',
              'NO',
              'LI',
              'CH',
              'UK',
              'TR',
              'RS',
              'AL',
              'GE',
              'AM',
              'RU',
              'UA',

            ]}
            customLabels={{
              PE: 'Perú',
              AR: 'Argentina',
              BO: 'Bolivia',
              ES: 'España',
              VE: 'Venezuela',
            }}
            searchPlaceholder="Busca..."
            placeholder="Ingresa el país donde resides..."
            searchable
            selected={pais}
            fullWidth
            onSelect={code => setPais(code)}
          />
          <Text mb="8px" align="start">
            Ciudad de residencia
          </Text>
          <Input
            placeholder="Ciudad de residencia"
            size="xs"
            style={{
              borderColor: 'lightgrey',
              borderWidth: 1,
              borderRadius: 5,
              padding: 8,
              width: '100%',
            }}
            value={residencia}
            onChange={event => setResidencia(event.target.value)}
          />
          <Text mb="8px" align="start">
            Distrito de residencia
          </Text>
          <Input
            placeholder="Distrito de residencia"
            size="xs"
            style={{
              borderColor: 'lightgrey',
              borderWidth: 1,
              borderRadius: 5,
              padding: 8,
              width: '100%',
            }}
            value={distrito}
            onChange={event => setDistrito(event.target.value)}
          />
          <Text mb="8px" align="start">
            Domicilio (calle y número)
          </Text>
          <Input
            placeholder="Domicilio"
            size="xs"
            style={{
              borderColor: 'lightgrey',
              borderWidth: 1,
              borderRadius: 5,
              padding: 8,
              width: '100%',
            }}
            value={address}
            onChange={event => setAddress(event.target.value)}
          />
          <Stack
            align={'center'}
            justify={'right'}
            mb={10}
            mt={10}
            flexDirection={'row'}
            style={{ alignItems: 'baseline' }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                updateData({
                  address,
                  residenceDistrict: distrito,
                  residenceCity: residencia,
                  residenceCountry: pais,
                })
              }
            >
              Guardar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default SettingsProfil;
