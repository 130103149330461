import React, { useContext, useState, useEffect } from 'react';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import Button from '@mui/material/Button';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Heading,
  Text,
  Input,
  Stack,
  Box,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Selector } from 'styles/common/index.js';
import { AuthContext } from 'Auth';
import ReactGA from 'react-ga';
import analyticsCode from 'analyticsCode.js';

let theme = createTheme({
  palette: {
    primary: {
      main: '#1A4BEA',
      dark: '#1A4BEA',
      light: '01fff0',
    },
  },
});

const BankAcc = () => {
  const [user, setDataUser] = useState({});
  let { currentToken } = useContext(AuthContext);

  const [editPEN, setEditPEN] = useState(false);
  const [editUSD, setEditUSD] = useState(false);
  const [editHNL, setEditHNL] = useState(false);

  // Bank Accounts
  const [havePenAccount, setHavePenAccount] = useState(false);
  const [haveUsdAccount, setHaveUsdAccount] = useState(false);
  const [haveHnlAccount, setHaveHnlAccount] = useState(false);
  const [isInternationalBankUSD, setIsInternationalBankUSD] = useState(false);
  const [isInternationalBankPEN, setIsInternationalBankPEN] = useState(false);
  const [isInternationalBankHNL, setIsInternationalBankHNL] = useState(false);

  const [isSmallerThan640] = useMediaQuery('(max-width: 640px)');

  const [numeroDeCuentaPEN, setNumeroDeCuentaPEN] = useState('');
  const [numeroDeCuentaHNL, setNumeroDeCuentaHNL] = useState('');
  const [numeroDeCuentaUSD, setNumeroDeCuentaUSD] = useState('');

  const [CCIPEN, setCCIPEN] = useState('');
  const [CCIUSD, setCCIUSD] = useState('');
  const [CCIHNL, setCCIHNL] = useState('');

  const [PENBankID, setPENBankID] = useState('');
  const [USDBankID, setUSDBankID] = useState('');
  const [HNLBankID, setHNLBankID] = useState('');

  const [bankNameBaPEN, setBankNameBaPEN] = useState('');
  const [bankNameBaUSD, setBankNameBaUSD] = useState('');
  const [bankNameBaHNL, setBankNameBaHNL] = useState('');

  const [addressBaPEN, setAddressBaPEN] = useState('');
  const [addressBaUSD, setAddressBaUSD] = useState('');
  const [addressBaHNL, setAddressBaHNL] = useState('');

  const [swiftBaPEN, setSwiftBaPEN] = useState('');
  const [swiftBaUSD, setSwiftBaUSD] = useState('');
  const [swiftBaHNL, setSwiftBaHNL] = useState('');

  ReactGA.initialize(analyticsCode);

  const color = useColorModeValue('white', 'gray.800');
  const [bancoPEN, setBancoPEN] = useState('');

  const handleSelectBancoPEN = option => {
    setBancoPEN(option.value);
    if (option.value === 'other') {
      setIsInternationalBankPEN(true);
    } else {
      setIsInternationalBankPEN(false);
    }
  };

  const BancoPENOptions = [
    { value: 'bcp', label: 'Banco de Crédito del Perú (BCP)' },
    { value: 'interbank', label: 'Interbank' },
    { value: 'scotiabank', label: 'Scotiabank' },
    { value: 'bbva', label: 'Banco Continental (BBVA)' },
    { value: 'pichincha', label: 'Banco Pichincha' },
    { value: 'banbif', label: 'Banco Banbif' },
    { value: 'mibanco', label: 'Mibanco' },
    { value: 'other', label: 'Otro (Internacional)' },
  ];

  const BancoPENOptionsObject = {
    bcp: { value: 'bcp', label: 'Banco de Crédito del Perú (BCP)' },
    interbank: { value: 'interbank', label: 'Interbank' },
    scotiabank: { value: 'scotiabank', label: 'Scotiabank' },
    bbva: { value: 'bbva', label: 'Banco Continental (BBVA)' },
    pichincha: { value: 'pichincha', label: 'Banco Pichincha' },
    banbif: { value: 'banbif', label: 'Banco Banbif' },
    mibanco: { value: 'mibanco', label: 'Mibanco' },
    other: { value: 'other', label: 'Otro (Internacional)' },
  };

  const [bancoUSD, setBancoUSD] = useState('');

  const handleSelectBancoUSD = option => {
    setBancoUSD(option.value);
    if (option.value === 'other') {
      setIsInternationalBankUSD(true);
    } else {
      setIsInternationalBankUSD(false);
    }
  };

  const BancoUSDOptions = [
    { value: 'bcp', label: 'Banco de Crédito del Perú (BCP)' },
    { value: 'interbank', label: 'Interbank' },
    { value: 'scotiabank', label: 'Scotiabank' },
    { value: 'bbva', label: 'Banco Continental (BBVA)' },
    { value: 'pichincha', label: 'Banco Pichincha' },
    { value: 'banbif', label: 'Banco Banbif' },
    { value: 'mibanco', label: 'Mibanco' },
    { value: 'other', label: 'Otro (Internacional)' },
  ];

  const BancoUSDOptionsObject = {
    bcp: { value: 'bcp', label: 'Banco de Crédito del Perú (BCP)' },
    interbank: { value: 'interbank', label: 'Interbank' },
    scotiabank: { value: 'scotiabank', label: 'Scotiabank' },
    bbva: { value: 'bbva', label: 'Banco Continental (BBVA)' },
    pichincha: { value: 'pichincha', label: 'Banco Pichincha' },
    banbif: { value: 'banbif', label: 'Banco Banbif' },
    mibanco: { value: 'mibanco', label: 'Mibanco' },
    other: { value: 'other', label: 'Otro (Internacional)' },
  };


  // HNL BANKS 
  const [bancoHNL, setBancoHNL] = useState('');

  const handleSelectBancoHNL = option => {
    setBancoHNL(option.value);
    if (option.value === 'other') {
      setIsInternationalBankHNL(true);
    } else {
      setIsInternationalBankHNL(false);
    }
  };

  const BancoHNLOptions = [
    { value: 'bancoatlantida', label: 'Banco Atlántida' },
    { value: 'bachonduras', label: 'BAC Credomatic' },
    { value: 'bancoficohsa', label: 'Banco Ficohsa' },
    // { value: 'bancodavivienda', label: 'Banco Davivienda' }
  ];

  const BancoHNLOptionsObject = {
    bancoatlantida: { value: 'bancoatlantida', label: 'Banco Atlántida' },
    bachonduras: { value: 'bachonduras', label: 'BAC Credomatic' },
    bancoficohsa: { value: 'bancoficohsa', label: 'Banco Ficohsa' },
    // bancodavivienda: { value: 'bancodavivienda', label: 'Banco Davivienda' }
  };

  const getUser = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setDataUser(responseJson);
        /*if(responseJson.hasRegisterCoupon){
                getCoupon(responseJson.registerCouponId);
              }*/
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    getUser();
    getBankAccounts();
    // eslint-disable-next-line
  }, [currentToken]);

  const getBankAccounts = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/user/bank`;
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(responseJson => {
        if (responseJson.pen != 0) setHavePenAccount(true);
        if (responseJson.usd != 0) setHaveUsdAccount(true);
        if (responseJson.hnl != 0) setHaveHnlAccount(true);
        responseJson.banks.forEach(bank => {
          if (bank.currency === 'PEN') {
            setBancoPEN(bank.name);
            setCCIPEN(bank.cci);
            setNumeroDeCuentaPEN(bank.accountNumber);
            setPENBankID(bank._id);
            if (bank.name === 'other') {
              setIsInternationalBankPEN(true);
            }
            setAddressBaPEN(bank.address);
            setSwiftBaPEN(bank.swift);
          } else if (bank.currency === 'USD') {
            setBancoUSD(bank.name);
            setCCIUSD(bank.cci);
            setNumeroDeCuentaUSD(bank.accountNumber);
            setUSDBankID(bank._id);
            if (bank.name === 'other') {
              setIsInternationalBankUSD(true);
            }
            setAddressBaUSD(bank.address);
            setSwiftBaUSD(bank.swift);
          } else if (bank.currency === 'HNL') {
            setBancoHNL(bank.name);
            setCCIHNL(bank.cci);
            setNumeroDeCuentaHNL(bank.accountNumber);
            setHNLBankID(bank._id);
            if (bank.name === 'other') {
              setIsInternationalBankHNL(true);
            }
            setAddressBaHNL(bank.address);
            setSwiftBaHNL(bank.swift);
          }
        });
      });
  };

  const createBankAccount = async (
    currencyBa,
    numberBa,
    cciBa,
    bankNameBa,
    addressBa,
    swiftBa
  ) => {
    // Validación
    if (bankNameBa == '') {
      swal({
        text: 'Elige un banco antes de crear la cuenta',
        button: false,
        timer: 5000,
      });
      return;
    }
    if (numberBa == '') {
      swal({
        text: 'Debes colocar un número de cuenta bancaria',
        button: false,
        timer: 5000,
      });
      return;
    }
    if (cciBa == '' && bankNameBa != 'other') {
      swal({
        text: 'Debes colocar el código interbancario de tu cuenta',
        button: false,
        timer: 5000,
      });
      return;
    }

    if (numberBa.length < 6 || numberBa.length >= 30) {
      swal({
        text:
          'La cuenta bancaria debe tener más de  6 caracteres y menos de 30.',
        button: false,
        timer: 5000,
      });
      return;
    }

    if ((cciBa.length < 6 && bankNameBa != 'other') || numberBa.length >= 30) {
      swal({
        text:
          'La cuenta interbancaria debe tener más de  6 caracteres y menos de 30.',
        button: false,
        timer: 5000,
      });
      return;
    }

    if (
      (currencyBa === 'PEN' && isInternationalBankPEN) ||
      (currencyBa === 'USD' && isInternationalBankUSD) ||
      (currencyBa === 'HNL' && isInternationalBankHNL)
    ) {
      if (bankNameBa == '') {
        swal({
          text: 'Debes ingresar el nombre del banco',
          button: false,
          timer: 5000,
        });
        return;
      }
      if (addressBa == '') {
        swal({
          text: 'Debes ingresar la dirección del banco',
          button: false,
          timer: 5000,
        });
        return;
      }
      if (swiftBa == '') {
        swal({
          text:
            'Debes ingresar el código SWIFT de tu cuenta bancaria. Si no la tienes, solicitarla a tu banco.',
          button: false,
          timer: 5000,
        });
        return;
      }
    }

    // Enviar

    const jsonBody = JSON.stringify({
      currency: currencyBa,
      accountNumber: numberBa,
      name: bankNameBa,
      cci: cciBa,
      address: addressBa,
      swift: swiftBa,
    });

    let data = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: jsonBody,
    };
    let endpoint = `/client/user/bank`;
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          if (currencyBa === 'PEN') {
            ReactGA.event({
              category: 'Banco agregado',
              action: 'Banco agregado en PEN',
            });
          } else if( currencyBa === 'USD' ) {
            ReactGA.event({
              category: 'Banco agregado',
              action: 'Banco agregado en USD',
            });
          } else {
            ReactGA.event({
              category: 'Banco agregado',
              action: 'Banco agregado en HNL',
            });
          }
          swal({
            text: '¡Se añadió correctamente tu cuenta de banco! :)',
            button: true,
            timer: 5000,
          });
          return response.json();
        } else {
          swal({
            text: '¡Completar todos los campos faltantes antes de guardar!',
            button: true,
            timer: 5000,
          });
          //return response.json();
        }
      })
      .then(responseJson => {
        /*if(responseJson.type == 'missing-fields') {
          swal({text: '¡Completar todos los campos faltantes antes de guardar!', button: true, timer: 5000});
        }*/

        getBankAccounts();
      })
      .catch(error => {
        swal({
          text:
            'Ocurrió un error al añadir tu cuenta de banco. Intenta más tarde.',
          button: true,
          timer: 5000,
        });
      });
  };

  const deactivateBankAccount = (bankId, showMessage, isPEN, deactivateCurrency) => {
    let data = {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/user/bank/${bankId}`;
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          getBankAccounts();
          return response.json();
        }
      })
      .then(responseJson => {
        getBankAccounts();
        if (showMessage) {
          // swal({
          //   text: 'Cuenta bancaria eliminada correctamente :)',
          //   button: true,
          //   timer: 5000,
          // });
        }

        switch(deactivateCurrency){
          case 'PEN':
            resetPEN();
            break;
          case 'USD':
            resetUSD();
            break;
          case 'HNL':
            resetHNL();
            break;
        }
        //window.location.reload(false);
      });
  };

  const resetPEN = () => {
    setBancoPEN('');
    setCCIPEN('');
    setNumeroDeCuentaPEN('');
    setPENBankID('');
  };

  const resetUSD = () => {
    setBancoUSD('');
    setCCIUSD('');
    setNumeroDeCuentaUSD('');
    setUSDBankID('');
  };

  const resetHNL = () => {
    setBancoHNL('');
    setCCIHNL('');
    setNumeroDeCuentaHNL('');
    setHNLBankID('');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        bg={color}
        boxShadow={'2xl'}
        rounded={'md'}
        pb={5}
        mb={10}
        paddingInline={10}
        w={'100%'}
        align={{ base: 'center', md: 'center' }}
      >
        <Box
          style={{
            borderBottomWidth: 1,
            borderBottomColor: 'lightgrey',
            paddingBottom: 2,
          }}
        >
          <Heading fontSize={'24'} fontWeight={'bold'} color="#1A4BEA" p={10}>
            Cuentas Bancarias
          </Heading>
        </Box>
        <Text color="gray" fontSize={15} width={'80%'} ml={15} mt={5}>
          Estas son tus cuentas a las que AgenteBTC depositará los retiros de
          dinero que hagas. Solo se deben agregar cuentas bancarias asociadas a
          tu identidad registrada en tu cuenta (Persona Natural o Empresa)
        </Text>
        <Stack
          align={'center'}
          justify={'center'}
          justifyContent="space-around"
          mb={5}
          mt={5}
          style={{ alignItems: 'baseline' }}
          sx={{ flexDirection: isSmallerThan640 ? 'column' : 'row' }}
        >
          <Box
            flexDirection="column"
            style={{ width: isSmallerThan640 ? '100%' : '40%' }}
            px={3}
            align="center"
          >
            <Text
              backgroundColor="#1A4BEA"
              p={5}
              w={'30%'}
              style={{
                borderRadius: 10,
                color: '#fff',
                fontWeight: 'bold',
              }}
            >
              Soles
            </Text>
            <Box
              backgroundColor="#eeeeeeaa"
              style={{ borderRadius: 8 }}
              mt={15}
              pl={30}
              pr={15}
              pt={15}
              pb={20}
            >
              <Stack
                justifyContent="space-between"
                flexDirection="row"
                pb={15}
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: 'lightgrey',
                }}
              >
                <Box alignSelf="center" justifyContent="center">
                  <Text> Añadir cuenta Bancaria</Text>
                </Box>
                <Box
                  flexDirection="row"
                  alignSelf="center"
                  justifyContent="center"
                  style={{ marginTop: 0 }}
                >
                  <Button onClick={() => setEditPEN(true)}>
                    <EditIcon w={24} h={24} mr={6} />
                  </Button>
                  <Button
                    onClick={() => {
                      deactivateBankAccount(PENBankID, true, true, 'PEN');
                    }}
                  >
                    <DeleteIcon w={24} h={24} />
                  </Button>
                </Box>
              </Stack>
              <Text align="left" fontWeight="bold" pt={20} pb={5}>
                Banco
              </Text>
              <Selector
                required
                placeholder="Banco"
                options={BancoPENOptions}
                value={BancoPENOptionsObject[bancoPEN]}
                onChange={handleSelectBancoPEN}
                isDisabled={havePenAccount && !editPEN ? true : false}
              />
              <Text mb="2px" mt="8px" align="start">
                Moneda
              </Text>
              <Input
                placeholder="Moneda"
                size="xs"
                style={{
                  borderColor: 'lightgrey',
                  borderWidth: 1,
                  borderRadius: 5,
                  padding: 8,
                  width: '100%',
                }}
                value={'Soles'}
                disabled
              />
              <Text mb="2px" mt="8px" align="start">
                Número de cuenta
              </Text>
              <Input
                placeholder="Número de cuenta"
                size="xs"
                style={{
                  borderColor: 'lightgrey',
                  borderWidth: 1,
                  borderRadius: 5,
                  padding: 8,
                  width: '100%',
                }}
                value={numeroDeCuentaPEN}
                onChange={event => {
                  setNumeroDeCuentaPEN(event.target.value);
                }}
                disabled={havePenAccount && !editPEN ? true : false}
              />
              {!isInternationalBankPEN ? (
                <>
                  <Text mb="2px" mt="8px" align="start">
                    CCI
                  </Text>
                  <Input
                    placeholder="CCI"
                    size="xs"
                    style={{
                      borderColor: 'lightgrey',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      width: '100%',
                    }}
                    value={CCIPEN}
                    onChange={event => {
                      setCCIPEN(event.target.value);
                    }}
                    disabled={havePenAccount && !editPEN ? true : false}
                  />
                </>
              ) : (
                <>
                  <Text mb="2px" mt="8px" align="start">
                    Nombre del Banco
                  </Text>
                  <Input
                    placeholder="Banco de Chile"
                    size="xs"
                    style={{
                      borderColor: 'lightgrey',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      width: '100%',
                    }}
                    onChange={e => setBankNameBaPEN(e.target.value)}
                    value={bankNameBaPEN}
                    disabled={havePenAccount && !editPEN ? true : false}
                  />
                  <Text mb="2px" mt="8px" align="start">
                    Dirección del banco
                  </Text>
                  <Input
                    placeholder="Ejem: Santiago, Chile"
                    size="xs"
                    style={{
                      borderColor: 'lightgrey',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      width: '100%',
                    }}
                    onChange={e => setAddressBaPEN(e.target.value)}
                    value={addressBaPEN}
                    disabled={havePenAccount && !editPEN ? true : false}
                  />
                  <Text mb="2px" mt="8px" align="start">
                    Código SWIFT
                  </Text>
                  <Input
                    placeholder="Ingresa código SWIFT"
                    size="xs"
                    style={{
                      borderColor: 'lightgrey',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      width: '100%',
                    }}
                    onChange={e => setSwiftBaPEN(e.target.value)}
                    value={swiftBaPEN}
                    disabled={havePenAccount && !editPEN ? true : false}
                  />
                </>
              )}
              <Box mt={20} w={'98%'} backgroundColor="lightgrey" h={1} />
              <Stack
                align={'center'}
                justify={'center'}
                mb={10}
                mt={20}
                flexDirection={'row'}
                style={{ alignItems: 'baseline' }}
              >
                {(editPEN || !havePenAccount) && (
                  <>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: 'lightgray', marginRight: 8 }}
                      size="small"
                      onClick={() => setEditPEN(false)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setEditPEN(false);
                        havePenAccount &&
                          deactivateBankAccount(PENBankID, true, true, 'PEN');
                        createBankAccount(
                          'PEN',
                          numeroDeCuentaPEN,
                          CCIPEN,
                          bancoPEN,
                          addressBaPEN,
                          swiftBaPEN
                        );
                      }}
                    >
                      Guardar
                    </Button>
                  </>
                )}
              </Stack>
            </Box>
          </Box>



          {/* USD */}
          <Box
            flexDirection="column"
            style={{ width: isSmallerThan640 ? '100%' : '40%' }}
            px={3}
            align="center"
          >
            <Text
              backgroundColor="#1A4BEA"
              p={5}
              w={'30%'}
              style={{
                borderRadius: 10,
                color: '#fff',
                fontWeight: 'bold',
              }}
            >
              Dólares
            </Text>
            <Box
              backgroundColor="#eeeeeeaa"
              style={{ borderRadius: 8 }}
              mt={15}
              pl={30}
              pr={15}
              pt={15}
              pb={20}
            >
              <Stack
                justifyContent="space-between"
                flexDirection="row"
                pb={15}
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: 'lightgrey',
                }}
              >
                <Box alignSelf="center" justifyContent="center">
                  <Text> Añadir cuenta Bancaria</Text>
                </Box>
                <Box
                  flexDirection="row"
                  alignSelf="center"
                  justifyContent="center"
                  style={{ marginTop: 0 }}
                >
                  <Button onClick={() => setEditUSD(true)}>
                    <EditIcon w={24} h={24} mr={6} />
                  </Button>
                  <Button
                    onClick={() => {
                      deactivateBankAccount(USDBankID, true, false, 'USD');
                    }}
                  >
                    <DeleteIcon w={24} h={24} />
                  </Button>
                </Box>
              </Stack>
              <Text align="left" fontWeight="bold" pt={20} pb={5}>
                Banco
              </Text>
              <Selector
                required
                placeholder="Banco"
                options={BancoUSDOptions}
                value={BancoUSDOptionsObject[bancoUSD]}
                onChange={handleSelectBancoUSD}
                isDisabled={haveUsdAccount && !editUSD ? true : false}
              />
              <Text mb="2px" mt="8px" align="start">
                Moneda
              </Text>
              <Input
                placeholder="Moneda"
                size="xs"
                style={{
                  borderColor: 'lightgrey',
                  borderWidth: 1,
                  borderRadius: 5,
                  padding: 8,
                  width: '100%',
                }}
                value={'Dólares'}
                disabled
              />
              <Text mb="2px" mt="8px" align="start">
                Número de cuenta
              </Text>
              <Input
                placeholder="Número de cuenta"
                size="xs"
                style={{
                  borderColor: 'lightgrey',
                  borderWidth: 1,
                  borderRadius: 5,
                  padding: 8,
                  width: '100%',
                }}
                value={numeroDeCuentaUSD}
                onChange={event => {
                  setNumeroDeCuentaUSD(event.target.value);
                }}
                disabled={haveUsdAccount && !editUSD ? true : false}
              />
              {!isInternationalBankUSD ? (
                <>
                  <Text mb="2px" mt="8px" align="start">
                    CCI
                  </Text>
                  <Input
                    placeholder="CCI"
                    size="xs"
                    style={{
                      borderColor: 'lightgrey',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      width: '100%',
                    }}
                    value={CCIUSD}
                    onChange={event => {
                      setCCIUSD(event.target.value);
                    }}
                    disabled={haveUsdAccount && !editUSD ? true : false}
                  />
                </>
              ) : (
                <>
                  <Text mb="2px" mt="8px" align="start">
                    Nombre del Banco
                  </Text>
                  <Input
                    placeholder="Banco de Chile"
                    size="xs"
                    style={{
                      borderColor: 'lightgrey',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      width: '100%',
                    }}
                    onChange={e => setBankNameBaUSD(e.target.value)}
                    type="text"
                    value={bankNameBaUSD}
                    disabled={haveUsdAccount && !editUSD ? true : false}
                  />
                  <Text mb="2px" mt="8px" align="start">
                    Dirección del banco
                  </Text>
                  <Input
                    placeholder="Ejem: Santiago, Chile"
                    size="xs"
                    style={{
                      borderColor: 'lightgrey',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      width: '100%',
                    }}
                    onChange={e => setAddressBaUSD(e.target.value)}
                    type="text"
                    value={addressBaUSD}
                    disabled={haveUsdAccount && !editUSD ? true : false}
                  />
                  <Text mb="2px" mt="8px" align="start">
                    Código SWIFT
                  </Text>
                  <Input
                    placeholder="Ingresa código SWIFT"
                    size="xs"
                    style={{
                      borderColor: 'lightgrey',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      width: '100%',
                    }}
                    onChange={e => setSwiftBaUSD(e.target.value)}
                    type="text"
                    value={swiftBaUSD}
                    disabled={haveUsdAccount && !editUSD ? true : false}
                  />
                </>
              )}
              <Box mt={20} w={'98%'} backgroundColor="lightgrey" h={1} />
              <Stack
                align={'center'}
                justify={'center'}
                mb={10}
                mt={20}
                flexDirection={'row'}
                style={{ alignItems: 'baseline' }}
              >
                {(editUSD || !haveUsdAccount) && (
                  <>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: 'lightgray', marginRight: 8 }}
                      size="small"
                      onClick={() => setEditUSD(false)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setEditUSD(false);
                        haveUsdAccount &&
                          deactivateBankAccount(USDBankID, true, false, 'USD');
                        createBankAccount(
                          'USD',
                          numeroDeCuentaUSD,
                          CCIUSD,
                          bancoUSD,
                          addressBaUSD,
                          swiftBaUSD
                        );
                      }}
                    >
                      Guardar
                    </Button>
                  </>
                )}
              </Stack>
            </Box>
          </Box>
        </Stack>








        {/* HNL */}

        <Stack
          align={'center'}
          justify={'center'}
          justifyContent="space-around"
          mb={5}
          mt={6}
          style={{ alignItems: 'baseline' }}
          sx={{ flexDirection: isSmallerThan640 ? 'column' : 'row'}}
        >
          {/* HNL */}
          <Box
            flexDirection="column"
            style={{ width: isSmallerThan640 ? '100%' : '40%' }}
            px={3}
            align="center"
          >
            <Text
              backgroundColor="#1A4BEA"
              p={5}
              w={'30%'}
              style={{
                borderRadius: 10,
                color: '#fff',
                fontWeight: 'bold',
                marginTop: 15,
              }}
            >
              Lempiras HNL
            </Text>
            <Box
              backgroundColor="#eeeeeeaa"
              style={{ borderRadius: 8 }}
              mt={15}
              pl={30}
              pr={15}
              pt={15}
              pb={20}
            >
              <Stack
                justifyContent="space-between"
                flexDirection="row"
                pb={15}
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: 'lightgrey',
                }}
              >
                <Box alignSelf="center" justifyContent="center">
                  <Text> Añadir cuenta Bancaria</Text>
                </Box>
                <Box
                  flexDirection="row"
                  alignSelf="center"
                  justifyContent="center"
                  style={{ marginTop: 0 }}
                >
                  <Button onClick={() => setEditHNL(true)}>
                    <EditIcon w={24} h={24} mr={6} />
                  </Button>
                  <Button
                    onClick={() => {
                      deactivateBankAccount(HNLBankID, true, false, 'HNL');
                    }}
                  >
                    <DeleteIcon w={24} h={24} />
                  </Button>
                </Box>
              </Stack>
              <Text align="left" fontWeight="bold" pt={20} pb={5}>
                Banco
              </Text>
              <Selector
                required
                placeholder="Banco"
                options={BancoHNLOptions}
                value={BancoHNLOptionsObject[bancoHNL]}
                onChange={handleSelectBancoHNL}
                isDisabled={haveHnlAccount && !editHNL ? true : false}
              />
              <Text mb="2px" mt="8px" align="start">
                Moneda
              </Text>
              <Input
                placeholder="Moneda"
                size="xs"
                style={{
                  borderColor: 'lightgrey',
                  borderWidth: 1,
                  borderRadius: 5,
                  padding: 8,
                  width: '100%',
                }}
                value={'Lempiras'}
                disabled
              />
              <Text mb="2px" mt="8px" align="start">
                Número de cuenta
              </Text>
              <Input
                placeholder="Número de cuenta"
                size="xs"
                style={{
                  borderColor: 'lightgrey',
                  borderWidth: 1,
                  borderRadius: 5,
                  padding: 8,
                  width: '100%',
                }}
                value={numeroDeCuentaHNL}
                onChange={event => {
                  setNumeroDeCuentaHNL(event.target.value);
                }}
                disabled={haveHnlAccount && !editHNL ? true : false}
              />
              {!isInternationalBankHNL ? (
                <>
                  <Text mb="2px" mt="8px" align="start">
                    CCI
                  </Text>
                  <Input
                    placeholder="CCI"
                    size="xs"
                    style={{
                      borderColor: 'lightgrey',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      width: '100%',
                    }}
                    value={CCIHNL}
                    onChange={event => {
                      setCCIHNL(event.target.value);
                    }}
                    disabled={haveHnlAccount && !editHNL ? true : false}
                  />
                </>
              ) : (
                <>
                  <Text mb="2px" mt="8px" align="start">
                    Nombre del Banco
                  </Text>
                  <Input
                    placeholder="Banco de Chile"
                    size="xs"
                    style={{
                      borderColor: 'lightgrey',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      width: '100%',
                    }}
                    onChange={e => setBankNameBaHNL(e.target.value)}
                    type="text"
                    value={bankNameBaHNL}
                    disabled={haveHnlAccount && !editHNL ? true : false}
                  />
                  <Text mb="2px" mt="8px" align="start">
                    Dirección del banco
                  </Text>
                  <Input
                    placeholder="Ejem: Santiago, Chile"
                    size="xs"
                    style={{
                      borderColor: 'lightgrey',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      width: '100%',
                    }}
                    onChange={e => setAddressBaHNL(e.target.value)}
                    type="text"
                    value={addressBaHNL}
                    disabled={haveHnlAccount && !editHNL ? true : false}
                  />
                  <Text mb="2px" mt="8px" align="start">
                    Código SWIFT
                  </Text>
                  <Input
                    placeholder="Ingresa código SWIFT"
                    size="xs"
                    style={{
                      borderColor: 'lightgrey',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      width: '100%',
                    }}
                    onChange={e => setSwiftBaHNL(e.target.value)}
                    type="text"
                    value={swiftBaHNL}
                    disabled={haveHnlAccount && !editHNL ? true : false}
                  />
                </>
              )}
              <Box mt={20} w={'98%'} backgroundColor="lightgrey" h={1} />
              <Stack
                align={'center'}
                justify={'center'}
                mb={10}
                mt={20}
                flexDirection={'row'}
                style={{ alignItems: 'baseline' }}
              >
                {(editHNL || !haveHnlAccount) && (
                  <>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: 'lightgray', marginRight: 8 }}
                      size="small"
                      onClick={() => setEditHNL(false)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setEditHNL(false);
                        haveHnlAccount &&
                          deactivateBankAccount(HNLBankID, true, false, 'HNL');
                        createBankAccount(
                          'HNL',
                          numeroDeCuentaHNL,
                          CCIHNL,
                          bancoHNL,
                          addressBaHNL,
                          swiftBaHNL
                        );
                      }}
                    >
                      Guardar HNL
                    </Button>
                  </>
                )}
              </Stack>
            </Box>
          </Box>
        </Stack>

      </Box>
    </ThemeProvider>
  );
};

export default BankAcc;
