import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './Auth';
import swal from 'sweetalert';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentToken, expiration, clearAuthentication } = useContext(
    AuthContext
  );
  if (!currentToken) {
    swal({
      title: 'Inicio de Sesión Requerido',
      text: 'Porfavor, iniciar sesión para acceder a esta sección',
      button: false,
    });
  } else if (parseInt(expiration) - Date.now() < 1000 || !expiration) {
    swal({
      title: 'Tiempo de Sesión Expirado',
      text: 'El tiempo de su sesión ha expirado. Porfavor vuelve a ingresar.',
      button: false,
    });
  }
  const isAuthenticated =
    currentToken && expiration && parseInt(expiration) - Date.now() > 1000;
  !isAuthenticated && clearAuthentication();
  return (
    <Route
      {...rest}
      render={routeProps =>
        isAuthenticated ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
