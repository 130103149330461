import React, { useState, createContext, useEffect } from 'react';

export const DateContext = createContext();

export const DateProvider = ({ children }) => {
  const [date, setDate] = useState({});

  const handleDate = () => {
    const d = new Date();
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const day = d.getDay();
    const fullDate = {
      hours,
      minutes,
      day,
    };
    setDate(fullDate);
  };

  useEffect(() => {
    handleDate();
  }, []);

  return <DateContext.Provider value={date}>{children}</DateContext.Provider>;
};
