import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from './Login/index.js';
import Register from './Register/index.js';
import WelcomePage from './WelcomePage/index.js';
import Recover from './Recover/index.js';
import ChangePassword from './ChangePassword/index.js';
import NotFound from 'pages/NotFound';
import Styled from './styles';
import Header from './Header/index';

const Auth = () => {
  return (
    <Styled.AuthContainer>
      <Header />
      <Styled.AuthBox>
        <Switch>
          <Route path="*/login" component={Login} />
          <Route path="*/register" component={Register} />
          <Route path="*/welcome" component={WelcomePage} />
          <Route path="*/change_password/:token" component={ChangePassword} />
          <Route path="*/recover" component={Recover} />
          <Route path="*/" component={Auth}>
            <Redirect to="/auth/login" />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Styled.AuthBox>
    </Styled.AuthContainer>
  );
};

export default Auth;
