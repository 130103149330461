import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import agentebtcapi from 'agentebtcapi.js';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import PasswordChecklist from 'react-password-checklist';

import Select from 'react-select';
import { Header1 } from 'styles/common/index';
import StyledAuth from '../styles';
import ReactGA from 'react-ga';

const colourOptions = [
  { value: 1, label: 'Recomendación ( familiar, amigo, etc)' },
  { value: 2, label: 'Búsqueda en Google, Chrome, etc' },
  { value: 3, label: 'Redes Sociales (Facebook, Youtube, etc)' },
  { value: 4, label: 'Medios de comunicación ( Tv, radio, etc)' },
  { value: 5, label: 'Publicidad (Internet)' },
  { value: 6, label: 'Otro' },
];

const Register = ({ history }) => {
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [passwordTypeOne, setPasswordTypeOne] = useState(false);
  const tooglePasswordOne = () => setPasswordTypeOne(!passwordTypeOne);
  const [passwordTypeTwo, setPasswordTypeTwo] = useState(false);
  const tooglePasswordTwo = () => setPasswordTypeTwo(!passwordTypeTwo);
  const [mktOrigin, setMktOrigin] = useState('');

  const isSmallerThan760 = window.innerWidth <= 760;
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [dni, setDni] = useState('');

  const handleRecaptcha = value => {
    console.log('Captcha value:', value);
  };

  const handleSignUp = e => {
    e.preventDefault();
    setButtonEnabled(true);
    swal({ text: 'Registrando usuario...', button: false, timer: 1000 });

    const { name, email, dni, password } = e.target.elements;
    const checker = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (!password.value.match(checker)) {
      swal({
        text:
          'Recuerda que tu contraseña debe tener entre 8 y 15 caracteres, 1 mayúcula , 1 minúscula, 1 número y 1 caracter especial',
        button: false,
        timer: 7000,
      });

      setTimeout(() => {
        setButtonEnabled(false);
      }, 3000);
    } else {
      let body = {
        username: email.value,
        name: name.value,
        dni: dni.value,
        email: email.value,
        password: password.value,
        mktOrigin: mktOrigin.value,
      };
      let data = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body), // body data type must match "Content-Type" header
      };
      let endpoint = '/auth/register';
      let url = `${agentebtcapi}${endpoint}`;
      fetch(url, data)
        .then(response => {
          response.json().then(json => {
            console.log(response);
            console.log(json);

            if (response.ok) {
              ReactGA.event({
                category: 'Registrarse',
                action: 'Usuario registrado',
              });
              swal({
                text:
                  '¡Listo! Por favor, revisa tu correo electrónico para confirmar tu cuenta. Puede que llegue en la carpeta de Spam, revisa ahi también.',
                button: false,
                timer: 9000,
              });
              history.push('/auth/welcome');

              ReactGA.event({
                category: 'Usuario',
                action: 'Creación de cuenta',
              });
            } else if (response.status == 400)
              swal({ text: 'Correo ya existente', button: false, timer: 2000 });
            else {
              swal({ text: json.msg, button: false, timer: 2000 });
            }

            setTimeout(() => {
              setButtonEnabled(false);
            }, 3000);
          });
        })
        .catch(error => {
          setTimeout(() => {
            setButtonEnabled(false);
          }, 3000);

          swal({
            text: 'Lo sentimos, no se pudo realizar el registro',
            button: false,
            timer: 2000,
          });
        });
    }
  };

  return (
    <>
      <StyledAuth.SingleCardRegister>
        <Header1>Registro</Header1>
        <StyledAuth.AuthForm onSubmit={handleSignUp}>
          <div style={{ width: '100%' }}>
            <StyledAuth.Label>Nombre completo</StyledAuth.Label>
          </div>
          <StyledAuth.AuthInput name="name" type="text" required />
          <div style={{ width: '100%' }}>
            <StyledAuth.Label>Correo electrónico</StyledAuth.Label>
          </div>
          <StyledAuth.AuthInput name="email" type="email" required />
          <div style={{ width: '100%' }}>
            <StyledAuth.Label>DNI (Opcional)</StyledAuth.Label>
            <StyledAuth.Label>Requerido para hacer depósitos en centros KasNet</StyledAuth.Label>
          </div>
          <StyledAuth.AuthInput name="dni" type="text" />
          <div style={{ width: '100%' }}>
            <StyledAuth.Label>Crear contraseña</StyledAuth.Label>
          </div>
          <StyledAuth.PassWrapper>
            <StyledAuth.AuthInputPass
              name="password"
              type={passwordTypeOne ? 'text' : 'password'}
              required
              onChange={e => setPassword(e.target.value)}
            ></StyledAuth.AuthInputPass>
            <StyledAuth.AuthIcon onClick={tooglePasswordOne}>
              <FontAwesomeIcon icon={passwordTypeOne ? faEye : faEyeSlash} />
            </StyledAuth.AuthIcon>
          </StyledAuth.PassWrapper>
          <StyledAuth.ValidBox>
            <PasswordChecklist
              style={{
                color: 'black',
                fontSize: 10,
                display: 'flex',
                textAlign: 'center',
                width: '100%',
                justifyContent: 'space-evenly',
                display: 'block',
              }}
              rules={['minLength', 'capital', 'lowercase']}
              messages={{
                minLength: 'Mínimo 8 caracteres',
                capital: 'Una letra mayúscula',
                lowercase: 'Una letra minúscula',
              }}
              iconSize={10}
              minLength={8}
              maxLength={15}
              value={password}
              valueAgain={passwordAgain}
              onChange={isValid => {}}
            />
            <PasswordChecklist
              style={{
                color: 'black',
                fontSize: 10,
                display: 'flex',
                textAlign: 'center',
                width: '100%',
                justifyContent: 'space-evenly',
                display: 'block',
              }}
              rules={['number', 'specialChar']}
              messages={{
                number: 'Un número',
                specialChar: 'Un simbolo (ej.$@#!&*)',
              }}
              iconSize={10}
              minLength={8}
              maxLength={15}
              value={password}
              valueAgain={passwordAgain}
              onChange={isValid => {}}
            />
          </StyledAuth.ValidBox>
          <div style={{ width: '100%' }}>
            <StyledAuth.Label>Confirmar contraseña</StyledAuth.Label>
          </div>
          <StyledAuth.PassWrapper>
            <StyledAuth.AuthInputPass
              type={passwordTypeTwo ? 'text' : 'password'}
              onChange={e => setPasswordAgain(e.target.value)}
              required
            />
            <StyledAuth.AuthIcon onClick={tooglePasswordTwo}>
              <FontAwesomeIcon icon={passwordTypeTwo ? faEye : faEyeSlash} />
            </StyledAuth.AuthIcon>
          </StyledAuth.PassWrapper>
          <div style={{ width: '100%' }}>
            <Select
              onChange={setMktOrigin}
              styles={{
                singleValue: provided => ({
                  ...provided,
                  textAlign: 'center',
                  color: 'black',
                }),
                valueContainer: provided => ({
                  ...provided,
                  justifyContent: 'center',
                }),
                control: provided => ({
                  ...provided,
                  borderColor: '#3E5158',
                  opacity: 0.5,
                  borderRadius: 15,
                  marginTop: 15,
                  height: 40,
                }),
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  paddingRight: 10,
                  color: 'gray',
                }),
                option: (provided, state) => ({
                  ...provided,
                  textAlign: 'center',
                  fontSize: 12,
                  color: '#3E5158',
                }),
                placeholder: (provided, state) => ({
                  ...provided,
                  color: 'black',
                  fontSize: isSmallerThan760 ? 10 : 16,
                  whiteSpace: 'nowrap',
                }),
              }}
              className="basic-single"
              classNamePrefix="select"
              isClearable={false}
              isSearchable={false}
              options={colourOptions}
              placeholder="¿Cómo te enteraste de AgenteBTC?"
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div
            className="captcha"
            style={{
              transform: 'scale(0.85)',
              alignSelf: 'center',
            }}
          >
            <ReCAPTCHA
              sitekey="6LceUcsUAAAAAKJ3xKxdp19Ywpwl3JT2ZGmxsJ38"
              onChange={handleRecaptcha}
              className="recaptcha"
            />
          </div>
          <StyledAuth.TermsConditionsCheckerContainer>
            <input type="checkbox" required />
            &nbsp;
            <p style={{ whiteSpace: 'nowrap' }}>Acepto los </p> &nbsp;
            <StyledAuth.AuthHref
              style={{ whiteSpace: 'nowrap' }}
              href="https://www.agentebtc.com/terminos-y-condiciones"
            >
              Términos y Condiciones
            </StyledAuth.AuthHref>
          </StyledAuth.TermsConditionsCheckerContainer>
          <StyledAuth.AuthButton disabled={buttonEnabled} type="submit">
            Crear mi cuenta gratuita
          </StyledAuth.AuthButton>
        </StyledAuth.AuthForm>
        <StyledAuth.AuthText2 style={{ borderWidth: 0, marginTop: 10 }}>
          ¿Ya tienes una cuenta?{' '}
          <StyledAuth.AuthLink style={{ color: '#1A4BEA' }} to="/auth/login">
            Inicia sesión
          </StyledAuth.AuthLink>
        </StyledAuth.AuthText2>
      </StyledAuth.SingleCardRegister>
    </>
  );
};

export default withRouter(Register);
