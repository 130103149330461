import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { delay } from '../helpers';
import '../styles.css';

// Transaction "Comprar" and "Vender" menu
const Timer = ({isLoading, fetch, countdown, setCountdown, PRICE_INTERVAL = 10, isSending = false}) => {
    
    const handleFetch = useCallback(() => {
        fetch();
    }, [fetch]);


    // Countdown interval
    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                    handleFetch();
                }
                return prevCountdown === 1 ? PRICE_INTERVAL : prevCountdown - 1;
            });
        }, 1000);
        
        return () => clearInterval(countdownInterval);
    }, [countdown]); 

    if( isSending ){
        return (<></>);
    }

    if( !isLoading ){
        return (
            <div className='c-countdown-container'>
                Nuevo precio en {countdown}
            </div>
        )
    }else{
        return (
            <div className='c-countdown-container-loading'>
                <FontAwesomeIcon icon={faSpinner} spin 
                    className='c-countdown-container-loading-icon'
                />
                Obteniendo precios
            </div>
        )
    }
}

export default Timer;