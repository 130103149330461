import { getQuote } from './helper';

class ObjectCalculator {
  constructor(calculatorObserver) {
    this.ammounts = [0, 0];
    this.types = ['IN', 'OUT'];
    this.currencies = ['BTC', 'USD'];
    this.transactionType = 'BUY';
    this.calculatorObserver = calculatorObserver;
  }

  setAmmountA(ammount) {
    let newAmmount = parseFloat(ammount);
    if ((newAmmount >= 0) & (newAmmount != undefined)) {
      this.ammounts[0] = parseFloat(newAmmount);
      this.types = ['IN', 'OUT'];
      // this.convert();
    } else {
      this.ammounts[0] = 0;
      this.types = ['IN', 'OUT'];
      // this.convert();
    }
  }

  setAmmountB(ammount) {
    let newAmmount = parseFloat(ammount);
    if (newAmmount >= 0 && newAmmount != undefined) {
      this.ammounts[1] = parseFloat(newAmmount);
      this.types = ['OUT', 'IN'];
      // this.convert();
    } else {
      this.ammounts[1] = 0;
      this.types = ['OUT', 'IN'];
      // this.convert();
    }
  }

  setUSD() {
    this.currencies = ['BTC', 'USD'];
    // this.getPrice();
    // this.convert();
  }

  setPEN() {
    this.currencies = ['BTC', 'PEN'];
    // this.getPrice();
    // this.convert();
  }

  setBUY() {
    this.transactionType = 'BUY';
    // this.getPrice();
    // this.convert();
  }

  setSELL() {
    this.transactionType = 'SELL';
    // this.getPrice();
  }

  setPENBTC() {
    this.currencies = ['BTC', 'PEN'];
    // this.getPrice();
    // this.convert();
  }

  setUSDOTHER(other, fiat) {
    this.currencies = [other, fiat];
    //this.getPrice();
    // this.convert();
  }


  setOTHERUSD(other, fiat) {
    this.currencies = [fiat, other];
    //this.getPrice();
    // this.convert();
  }

  // setPENTRONUSDT() {
  //   this.currencies = ['tronUSDT', 'PEN'];
  //   this.convert();
  // }

  getPrice() {
    (async () => {
      await getQuote(
        1,
        this.types[0] == 'IN' ? this.currencies[0] : this.currencies[1],
        this.types[0] == 'IN' ? this.currencies[1] : this.currencies[0],
        this.transactionType
      ).then(r => {
        this.calculatorObserver(r);
      });
    })();
  }

  convert() {
    (async () => {
      const currencyPair =
        this.types[0] == 'IN'
          ? `${this.currencies[0]}${this.currencies[1]}`
          : `${this.currencies[1]}${this.currencies[0]}`;
      const ammount =
        this.types[0] == 'IN' ? this.ammounts[0] : this.ammounts[1];
      await getQuote(
        ammount,
        this.types[0] == 'IN' ? this.currencies[0] : this.currencies[1],
        this.types[0] == 'IN' ? this.currencies[1] : this.currencies[0],
        this.transactionType
      ).then(r => {
        this.calculatorObserver(r);
      });
      //   if (this.currencies[0] === 'USDT' && this.currencies[1] === 'PEN') {
      //     quote = await getQuote(
      //       ammount,
      //       'PEN',
      //       'USDT',
      //       this.transactionType
      //     ).then(r => {
      //       this.calculatorObserver(r);
      //     });
      //   } else if (
      //     this.currencies[0] === 'tronUSDT' &&
      //     this.currencies[1] === 'PEN'
      //   ) {
      //     quote = await getQuote(
      //       ammount,
      //       'PEN',
      //       'tronUSDT',
      //       this.transactionType
      //     ).then(r => {
      //       this.calculatorObserver(r);
      //     });
      //   } else {
      //     quote = await getQuote(
      //       ammount,
      //       currencyPair.slice(0, 3),
      //       currencyPair.slice(3),
      //       this.transactionType
      //     ).then(r => {
      //       this.calculatorObserver(r);
      //     });
      //   }
    })();
  }
}

export default ObjectCalculator;
