import styled from "styled-components";

export const NewOperationWrapper = styled.div`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 15vh;
  background: linear-gradient(180deg, rgba(236,243,248,1) 0%, rgba(236,243,248,1) 50%, rgba(255,255,255,0) 100%);
  margin-bottom: 100px;
`;


export default {
  NewOperationWrapper
}
