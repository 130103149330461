import fetch from 'node-fetch';

const backendURL = process.env.REACT_APP_AGENTEBTC_API;
const priceServiceURL = process.env.REACT_APP_AGENTEBTC_PRICE_SERVICE;

// Get cryptos
export const RESPONSE_GET_ASSETS = async () => {
    try{
        let api = `${backendURL}/public/cryptos`;

        let options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        const response = await fetch(api, options);
        const data = await response.json();
        return data;
    }catch(error){
        return error;
    }
}


// Get price
export const RESPONSE_GET_PRICE = async ( from, to, transactionType ) => {
    try{
        let api = `${priceServiceURL}/calculator/convert`;

        let options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                from, 
                to, 
                transactionType,
                fromAmount: 1 
            })
        }

        const response = await fetch(api, options);
        const data = await response.json();
        return data;
    }catch(error){
        return error;
    }
}

// Get User Data
export const RESPONSE_GET_USER_DATA = async ( currentToken ) => {
    try{
        let api = `${backendURL}/client/user`;

        let options = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${currentToken}`, 
            }
        }

        const response = await fetch(api, options);
        const data = await response.json();
        return data;
    }catch(error){
        return error;
    }
}


// Convert transaction
export const RESPONSE_CONVERT = async ( currentToken, body ) => {
    try{
        let api = `${backendURL}/client/wallet/convert/transaction`;

        let options = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${currentToken}`, 
            },
            body: JSON.stringify(body)
        }

        const response = await fetch(api, options);
        let data = {};
        if( response.status !== 200 ){
            data = await response.json();
            throw new Error(data.msg ?? 'Error al realizar la conversión. Intente en unos minutos.');
        }
        data = await response.json();
        return data;
    }catch(error){
        return error;
    }
}