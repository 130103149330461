import React, { useContext, useState, useEffect } from 'react';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsProfil from './Pages/Profil';
import BankAcc from './Pages/BankAcc';
import Security from './Pages/Security';
import CryptoWallets from './Pages/CryptoWallets';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { css } from 'glamor';

import { AuthContext } from 'Auth';
import ReactGA from 'react-ga';
import analyticsCode from 'analyticsCode.js';

let theme = createTheme({
  palette: {
    primary: {
      main: '#1A4BEA',
      dark: '#1A4BEA',
      light: '01fff0',
    },
  },
});

const Settings = () => {
  const [user, setDataUser] = useState({});
  let { currentToken } = useContext(AuthContext);

  ReactGA.initialize(analyticsCode);

  const getUser = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setDataUser(responseJson);
        /*if(responseJson.hasRegisterCoupon){
                getCoupon(responseJson.registerCouponId);
              }*/
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    getUser();
    getBankAccounts();
    // eslint-disable-next-line
  }, [currentToken]);

  const getBankAccounts = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/user/bank`;
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(responseJson => {})
      .catch(error => {
        console.log(error);
      });
  };

  const getBankAccount = bankId => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/user/bank/${bankId}`;
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(responseJson => {
        let bankData = responseJson.bank;
        if (bankData) {
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="fullWidth"
              >
                <Tab icon={<PersonPinIcon />} label="Perfil" value="1" />
                <Tab
                  icon={<CreditCardIcon />}
                  label="Cuentas Bancarias"
                  value="2"
                />
                {/* <Tab
                  icon={<AccountBalanceWalletIcon />}
                  label="Crypto Wallets"
                  value="3"
                /> */}
                <Tab icon={<SecurityIcon />} label="Seguridad" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <SettingsProfil />
            </TabPanel>
            <TabPanel value="2">
              <BankAcc />
            </TabPanel>
            {/* <TabPanel value="3">
              <CryptoWallets />
            </TabPanel> */}
            <TabPanel value="3">
              <Security />
            </TabPanel>
          </TabContext>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Settings;
