import './styles.css';
import React, { useContext, useEffect, useState } from 'react';
import swal from 'sweetalert';
import StopInvesting from 'components/stop-investing/StopInvesting';
import { AuthContext } from 'Auth';
import agentebtcapi from 'agentebtcapi.js';
const Inversiones = props => {
  const [modalCanjear, setCanjear] = useState(false);
  function toggleCanjear() {
    setCanjear(!modalCanjear);
  }

  // function canjearAhora() {
  //   setCanjear(!modalCanjear);
  //   swal({
  //     title: 'Has dejado de invertir',
  //     position: 'top-end',
  //     icon: 'success',
  //     showConfirmButton: false,
  //     timer: 2000,
  //   });
  // }

  const [remainingDays, setRemainingDays] = useState();
  function handleRemainingDays(date1, date2) {
    let result = date2 - date1
    setRemainingDays(result);
  }

  let { currentToken } = useContext(AuthContext);

  //Get user transactions
  const [userStakingTransactions, setUserStakingTransactions] = useState([]);
  const getUserStakingTransactions = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/staking/';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setUserStakingTransactions(responseJson.transactions);
        console.log('Transactions');
        console.log(responseJson.transactions);
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    getUserStakingTransactions();
  }, []);

  return (
    <div className="inversiones-wrapper">
      <div className="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th className="th logo-header"> </th>
              <th class="th moneda-header">Moneda</th>
              <th class="th invest-amount-header">Cantidad Invertida</th>
              <th className="th apy-header">APY</th>
              <th className="th period-header">Periodo de bloqueo</th>
              <th className="th remaining-time-header">Tiempo restante</th>
              {/* <th className="th action-header">Acción</th> */}
            </tr>
          </thead>

          {userStakingTransactions.length === 0 ? (
            <tbody className="tbody">
              <tr>
                <td colSpan={7} rowSpan={6}>
                  <div className="empty-table-container">
                    <div className="empty-table-text">
                      ¡Aún no tienes criptomendas invertidas!
                    </div>
                    <div className="empty-table-btn">Comienza a invertir</div>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className="tbody">
              {userStakingTransactions.map((item, index) => {
                
                let d1 = new Date().getTime();
                let d2 = new Date(item.finalDate).getTime();
                let provisional = d2- d1;
                // Delta time
                let result = Math.round(provisional/ (1000 * 3600 * 24))

                // Ignore delivered and canceled transactions
                if( item.status === 'DELIVERED' || item.status === 'CANCELED' )
                  return ( <></> );

                if( result > 0 )

                  // Backlog: Change this so it comes from the API instead of hardcoded
                  if (item.currency === 'USD') {
                    item.image = "https://i.imgur.com/z3HA1co.png"
                  }

                return (
                  <tr className="tr" key={index}>
                    <td className="td logo">
                      <img className="cripto-img" src={item.image}></img>
                    </td>
                    <td className="column-name td">
                      {item.currencyName}{' '}
                      <span className="abb">{item.currency}</span>
                    </td>
                    <td className="td"> {item.startAmount} </td>
                    <td className="td">
                      {item.minApy} - {item.maxApy} %
                    </td>
                    <td className="td">{item.timeInSpanish}</td>
                    <td className="td">
                      {result} días
                    </td>
                    {/* <td className="td">
                    <span
                      className="retirar-btn"
                      onClick={() => toggleCanjear()}
                    >
                      Canjear ahora
                    </span>
                  </td> */}
                  </tr>
                );
              })}{' '}
            </tbody>
          )}
        </table>
        {modalCanjear && <StopInvesting toggleCanjear={toggleCanjear} />}
      </div>
    </div>
  );
};

export default Inversiones;
