import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import agentebtcapi from 'agentebtcapi.js';
import swal from 'sweetalert';
import { Header1 } from 'styles/common/index';
import StyledAuth from '../styles';

const Recover = ({ history }) => {
  const handleRecover = e => {
    swal({
      text: 'Solicitando recuperación de cuenta...',
      button: false,
      timer: 1000,
    });

    e.preventDefault();
    const { email } = e.target.elements;
    let body = { email: email.value };
    let data = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body), // body data type must match "Content-Type" header
    };
    let endpoint = '/auth/recover';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          swal({
            text:
              'Por favor, revisa tu correo electrónico para continuar el reestablecimiento de tu contraseña. Puede que el correo llegue en la carpeta de Spam, revisa ahi también.',
            button: false,
            timer: 3000,
          });
          history.push('/auth/login');
        } else {
          swal({
            text:
              'Lo sentimos, no se encontrar el correo relacionado. Si no tiene una cuenta, favor de crearse una cuenta.',
            button: false,
            timer: 2000,
          });
        }
      })
      .catch(error => {
        swal({
          text: 'Lo sentimos, no se encontrar el correo relacionado',
          button: false,
          timer: 2000,
        });
      });
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <>
      <StyledAuth.SingleCardRegister>
        <Header1>Recuperar Cuenta</Header1>
        <StyledAuth.AuthText>
          Ingresa tu correo electrónico. Te enviaremos un correo en donde
          encontrarás las instrucciones para recuperar tu cuenta:{' '}
        </StyledAuth.AuthText>
        <StyledAuth.AuthForm onSubmit={handleRecover}>
          <StyledAuth.AuthInput
            name="email"
            type="email"
            placeholder="Tu correo electrónico"
          />
          <StyledAuth.AuthButton type="submit">
            Recuperar contraseña
          </StyledAuth.AuthButton>
        </StyledAuth.AuthForm>
        <StyledAuth.AuthText2>
          ¿Ya tienes una cuenta?{' '}
          <StyledAuth.AuthLink style={{ color: '#1A4BEA' }} to="/auth/login">
            Ingresar
          </StyledAuth.AuthLink>
        </StyledAuth.AuthText2>
        <StyledAuth.AuthText2 style={{ borderWidth: 0 }}>
          ¿Aún no tienes una cuenta?{' '}
          <StyledAuth.AuthLink style={{ color: '#1A4BEA' }} to="/auth/register">
            Registro
          </StyledAuth.AuthLink>
        </StyledAuth.AuthText2>
      </StyledAuth.SingleCardRegister>
    </>
  );
};

export default withRouter(Recover);
