import React, { useState, useEffect } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import agentebtcapi from 'agentebtcapi.js';
import moment from 'moment';

import ChartSelect from './Select/select';

import { Heading, useMediaQuery } from '@chakra-ui/react';

const CryptoChart = () => {
  const [data, setData] = useState({
    data: [
      {
        d: '',
        p: '',
        y: '',
      },
    ],
  });

  const crypto = [
    { value: 'BTC', label: 'BTC' },
    { value: 'ETH', label: 'ETH' },
    { value: 'ADA', label: 'ADA' },
    //{ value: 'SHIB', label: 'SHIB' },
    { value: 'DOGE', label: 'DOGE' },
    { value: 'USDT', label: 'USDT' },
    { value: 'MATIC', label: 'MATIC' },
    { value: 'RBTC', label: 'RBTC' },
    { value: 'USDC', label: 'USDC' },
    { value: 'SOL', label: 'SOL' },

  ];

  const time = [
    { value: '8 h', label: '8 h' },
    { value: '24 h', label: '24 h' },
    { value: '7 d', label: '7 d' },
    { value: '30 d', label: '30 d' },
  ];

  const [selectedCurrency, setSelectedCurrency] = useState(crypto[0].label);

  const [selectedTime, setSelectedTime] = useState(time[0].value);

  const SelectCurrency = option => {
    setSelectedCurrency(option.value);
  };

  const SelectTime = option => {
    setSelectedTime(option.value);
  };

  const getChart = async () => {
    const selectedTimeNew = selectedTime.split(' ', 1);
    const token = localStorage.getItem('token-agente-btc');
    const endpoint =
      selectedTimeNew[0] === '24' || selectedTimeNew[0] === '8'
        ? '/public/quote/histohour'
        : '/public/quote/histoday';
    const url = `${agentebtcapi}${endpoint}`;
    const body = { limit: selectedTimeNew, currency: selectedCurrency };
    const data = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };
    fetch(url, data)
      .then(r => r.json())
      .then(cryptoprice => {
        const sortedData = [];

        for (let date in cryptoprice.Data.Data) {
          sortedData.push({
            d:
              selectedTimeNew[0] === '24' || selectedTimeNew[0] === '8'
                ? moment(cryptoprice.Data.Data[date].time * 1000).format(
                    'HH.mm'
                  )
                : moment(cryptoprice.Data.Data[date].time * 1000).format(
                    'DD.MM'
                  ),
            price: cryptoprice.Data.Data[date].close.toLocaleString('us-EN', {
              style: 'currency',
              currency: 'USD',
            }),
            y: (cryptoprice.Data.Data[date].close / 1000).toFixed(8),
          });
        }
        setData({
          data: sortedData,
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  const [isSmallerThan1020] = useMediaQuery('(max-width: 1020px)');
  const [isSmallerThan768px] = useMediaQuery('(max-width: 768px)');
  const [isSmallerThan640] = useMediaQuery('(max-width: 640px)');

  useEffect(() => {
    getChart();
  }, [selectedCurrency, selectedTime]);

  const { innerWidth: width, innerHeight: height } = window;

  return (
    <div>
      <div style={{ flexDirection: 'row', paddingBottom: 10 }}>
        <div style={{ width: '46%', display: 'inline-block' }}>
          <Heading
            fontSize={isSmallerThan640 ? '12' : '18'}
            fontWeight={500}
            pl={10}
          >
            Precio de criptomonedas
          </Heading>
        </div>
        <div style={{ width: '25%', display: 'inline-block' }}>
          <ChartSelect
            selectedCurrency={selectedTime}
            selectCurrency={SelectTime}
            options={time}
            style={{ fontSize: isSmallerThan640 ? '12' : '13' }}
            icon="time"
          />
        </div>
        <div style={{ width: '4%', display: 'inline-block' }} />
        <div style={{ width: '25%', display: 'inline-block' }}>
          <ChartSelect
            selectedCurrency={selectedCurrency}
            selectCurrency={SelectCurrency}
            options={crypto}
            icon={selectedCurrency}
            style={{ fontSize: isSmallerThan640 ? '12' : '13' }}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" aspect={4.2 / 2.7}>
        <AreaChart height={270} style={{ marginLeft: 20 }} data={data.data}>
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FFA500" stopOpacity={0.2} />
              <stop offset="95%" stopColor="#FFA500" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="d" />
          <YAxis width={65} dataKey="y" unit="k$" domain={['auto', 'auto']} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area
            type="linear"
            dataKey="price"
            stroke="#00D100"
            strokeLinejoin="miter"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
          <Area
            type="linear"
            dataKey="y"
            stroke="#FFA500"
            strokeWidth={5}
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
export { CryptoChart };
