import axios from 'axios';

import agentebtcapi from 'agentebtcapi.js';

export default axios.create({
  baseURL: `${agentebtcapi}`,
  headers: {
    'Content-type': 'application/json',
  },
});
