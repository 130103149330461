import './styles.css';
import { AuthContext } from 'Auth';
import React, { useContext, useEffect, useState } from 'react';
import { IoIosClose, IoIosAddCircle } from 'react-icons/io';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';

const StakingModal = props => {
  const [currentPeriod, setCurrentPeriod] = useState('');
  function activatePeriod(item) {
    if (item === currentPeriod) {
      setCurrentPeriod();
      return;
    }
    setCurrentPeriod(item);
  }

  const selectedCurrency = props.selectedCurrency;
  const [selectedPeriod, setSelectedPeriod] = useState('');
  function handlePeriod(value) {
    console.log('value');
    console.log(value);
    setSelectedPeriod(value);
  }

  const [currentBalance, setCurrentBalance] = useState(0);
  const handleUserBalanceChange = () => {
    props.userBalance(currentBalance);
  };

  const getUserBalance = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/wallet/balance?currency=';
    let url = `${agentebtcapi}${endpoint}${props.selectedCurrency}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        //setRatesComplete(responseJson.data);
        setCurrentBalance(responseJson.balance);
        handleUserBalanceChange(responseJson.balance);
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    getUserBalance();
  }, []);

  const [amount, setAmount] = useState(currentBalance * 0.25);
  function newAmount(value) {
    if (value === '') {
      setAmount('');
      return;
    }
    if (!isNumeric(value)) return;
    setAmount(value);
  }

  const isNumeric = n => {
    const regex = /^[1-9]\d*(\.\d+)?$/;
    console.log(n);
    return (!isNaN(parseFloat(n)) && isFinite(n)) || regex.test(n);
  };

  let { currentToken } = useContext(AuthContext);

  //Get Rates By Currency
  const [ratesComplete, setRatesComplete] = useState([]);
  const [ratesOnlyRates, setRatesOnlyRates] = useState([]);

  const getStakingRates = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/public/staking/rate/';
    let url = `${agentebtcapi}${endpoint}${props.selectedCurrency}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setRatesComplete(responseJson.data);
        setRatesOnlyRates(responseJson.data.rates);
        console.log('Only rates');
        console.log(responseJson.data.rates);
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    getStakingRates();
  }, []);

  // Se usaba para calcular el número de días

  // const [convertedPeriods, setConvertedPeriods] = useState([]);
  // function convertPeriods(data) {
  //   let transformedArray = data.map(item =>
  //     item.timeUnit === 'year'
  //       ? item.timeNumber * 365
  //       : item.timeUnit === 'month'
  //       ? item.timeNumber * 30
  //       : item.timeNumber
  //   );
  //   setConvertedPeriods(transformedArray);
  // }

  // useEffect(() => {
  //   convertPeriods(ratesOnlyRates);
  // }, [ratesOnlyRates]);

  function translateError(type) {
    var textEnglish;
    var textSpanish;

    console.log(type);

    switch (type) {
      case 'invalid-user':
        textEnglish = 'User not found.';
        textSpanish = 'Usuario no encontrado.';
        break;
      case 'missing-fields':
        textEnglish = 'Please fill in all the fields.';
        textSpanish = 'Por favor llene todos los campos.';
        break;
      case 'invalid-body-format':
        textEnglish = 'Invalid body format.';
        textSpanish = 'Formato de body no válido.';
        break;
      case 'invalid-time-format':
        textEnglish = 'Invalid time format.';
        textSpanish = 'Formato de tiempo no válido.';
        break;
      case 'staking-rate-not-found':
        textEnglish = 'Staking rate not found.';
        textSpanish = 'Tasa de staking no encontrada.';
        break;
      case 'max-amount-error':
        textEnglish = 'The amount exceeds the maximum allowed.';
        textSpanish = 'La cantidad excede el máximo permitido.';
        break;
      case 'min-amount-error':
        textEnglish = 'The amount does not meet the minimum required.';
        textSpanish = 'La cantidad no cumple con el mínimo requerido.';
        break;
      case 'insufficient-balance':
        textEnglish = 'Insufficient balance.';
        textSpanish = 'Balance insuficiente.';
        break;
      case 'internal-error':
        textEnglish = 'Internal error.';
        textSpanish = 'Error interno.';
        break;
      case 'bad-request':
        textEnglish = 'Cannot save staking operation.';
        textSpanish = 'No se ha podido completar la operación.';
        break;
      case 'withdraw-error':
        textEnglish =
          'Cannot verify staking operation. Please contact support.';
        textSpanish =
          'No se ha podido verificar la operación. Por favor contacte a soporte.';
        break;
      default:
        textEnglish = 'An unknown error happened.';
        textSpanish = 'Un error desconocido ha ocurrido.';
        break;
    }
    return textSpanish;
  }

//Por ahora está en false para poder saltarse los términos y condiciones
//Regresar a true cuando esté listo el apartado legal
  const [checkbox, setCheckbox] = useState(false);
  function handleCheckbox() {
    setCheckbox(!checkbox);
  }

  const createStakingDeposit = (currency, amount, time) => {
    const jsonBody = JSON.stringify({
      currency: currency,
      time: time,
      amount: amount,
    });

    let data = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: jsonBody,
    };
    let endpoint = '/client/staking/';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(async response => {

        if (response.ok) {
          let responseJson = await response.json();

          props.activateFunds( responseJson.type === 'success' ? true : false );

          return response;
        } else {
          let responseJson = await response.json();
          let errorResponse = translateError(responseJson.type);
          swal({
            text: errorResponse,
            button: false,
            timer: 5000,
          });
        }
      })
      .catch(error => {
        swal({
          text: 'Error al enviar su solicitud de staking.',
          button: false,
          timer: 5000,
        });
      });
  };

  return (
    <div className="modal-staking">
      <div
        onClick={() => {
          props.showStakingModal();
        }}
        className="overlay"
      ></div>
      <div className="modal-content-staking">
        <div
          className="modal-exit"
          onClick={() => {
            props.showStakingModal();
          }}
        >
          <IoIosClose></IoIosClose>
        </div>
        <div className="modal-staking-header">
          <div className="staking-header-cripto-logo">
            <img className="header-cripto-img" src={ratesComplete.image}></img>
          </div>
          <div>
            <div className="staking-header-title">
              Invierte y genera más ingresos
            </div>

            {ratesOnlyRates.length > 0 && (
              <div className="staking-header-subtitle">
                Totalmente gratuito y gana hasta un{' '}
                {ratesOnlyRates[ratesOnlyRates.length - 1].maxApy}% anual de
                interés
              </div>
            )}
          </div>
        </div>

        <hr className="text-divider" />
        <div className="modal-staking-body">
          <div className="staking-body-time">
            <div className="staking-body-title">
              ¿Cuánto tiempo deseas invertirlos?
            </div>
            <div className="staking-time-periods">
              {ratesOnlyRates.map((item, index) => (
                <div
                  className={
                    currentPeriod === item.timeInSpanish
                      ? 'staking-time-periods-option active-period '
                      : 'staking-time-periods-option'
                  }
                  key={index}
                  onClick={() => {
                    activatePeriod(item.timeInSpanish);
                    handlePeriod(item.time);
                  }}
                >
                  {item.timeInSpanish}
                </div>
              ))}
            </div>
          </div>

          <div className="staking-body-amount">
            <div className="staking-body-title-container">
              <div className="staking-body-title">
                ¿Cuánto {props.selectedCurrency.abbreviation} quieres invertir?
              </div>
              <a
                className="staking-body-amount-buy"
                href="/app/profile/comprar_vender"
              >
                <IoIosAddCircle className="add-circle-icon"></IoIosAddCircle>
                Comprar {props.selectedCurrency.abbreviation}
              </a>
            </div>
            <div className="staking-body-amount-input">
              <input
                className="staking-amount-input"
                value={amount}
                onChange={e => {
                  newAmount(e.target.value);
                }}
              />
              <span className="floating-abbreviation">
                {props.selectedCurrency.abbreviation}
              </span>
            </div>

            <div className="staking-inline-block">
              <div className="staking-body-amount-slider">
                <Box width={330}>
                  <Slider
                    size="small"
                    defaultValue={currentBalance * 0.25}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    min={0}
                    max={currentBalance}
                    onChange={e => {
                      newAmount(e.target.value);
                    }}
                    value={amount}
                  />
                </Box>
                <div className="filler"> .&nbsp; </div>
              </div>
              <div className="percentage-container">
                <div className="percentage-small" onClick={()=> { newAmount( currentBalance * 0.25 ) }}>25%</div>
                <div className="percentage-small" onClick={()=> { newAmount( currentBalance * 0.5 ) }}>50%</div>
                <div className="percentage-small" onClick={()=> { newAmount( currentBalance * 0.75 ) }}>75%</div>
                <div className="percentage-small" onClick={()=> { newAmount( currentBalance * 1 ) }}>100%</div>
              </div>
            </div>

            <div className="staking-body-amount-balance">
              Balance: {currentBalance.toFixed(5)}
            </div>
          </div>
        </div>

        <hr className="text-divider" />
        <div className="modal-staking-end">
          <div className="staking-end-text">
            Ten en cuenta que todas las monedas pueden presentar volatilidad y
            fluctuaciones en su cotización según los cambios del mercado.
          </div>
          {/* <div className="staking-end-checkbox">
            <input
              type="checkbox"
              className="staking-checkbox"
              onChange={handleCheckbox}
            />
            &nbsp; He leído y acepto los
            <a className="terms-conditions">
              &nbsp;Términos y condiciones
            </a>
          </div> */}
          <button
            className="start-investing-btn"
            type="submit"
            onClick={() => {
              createStakingDeposit(selectedCurrency, amount, selectedPeriod);
            }}
            disabled={
              checkbox ||
              amount === '' ||
              amount === 0 ||
              currentPeriod === '' ||
              selectedPeriod === ''
            }
          >
            Comenzar a invertir
          </button>
        </div>
      </div>
    </div>
  );
};

export default StakingModal;
