import React, { useContext, useState, useEffect } from 'react';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import Button from '@mui/material/Button';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Heading,
  Text,
  Stack,
  Box,
  useColorModeValue,
  useMediaQuery,
  SimpleGrid,
  Input,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthContext } from 'Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { StyledSelect } from 'styles/Styled';
import Select from 'react-select';
import {
  Visor,
  EtiquetaPrecioFiat,
  EtiquetaNombre,
  CryptoIcon,
} from '../../../../../components/Calculator/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#1A4BEA',
      dark: '#1A4BEA',
      light: '01fff0',
    },
  },
});

const Cryptos = [
  {
    index: 0,
    name: 'BTC',
    icon:
      'https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Bitcoin-BTC-icon.png',
    network: [
      {
        value: 'ERC20',
        label: 'ERC20',
        cryptoAddress: 'mzfouedGWKFXPPa4epAMBS7H9DfWjc2DAE',
        isEditing: false,
      },
      {
        value: 'BSC',
        label: 'BSC',
        cryptoAddress: 'mzfouedGWKFXPPa4epAMBS7H9DfWjc2DAE',
        isEditing: false,
      },
      {
        value: 'CRONOS',
        label: 'CRONOS',
        cryptoAddress: '',
        isEditing: false,
      },
    ],
  },
  {
    index: 1,
    name: 'ETH',
    icon:
      'https://banner2.cleanpng.com/20190418/rgf/kisspng-ethereum-portable-network-graphics-computer-icons-developers-icon-request-icon-ethereum-5cb941c1cb12b1.1213852915556448658318.jpg',
    network: [
      {
        value: 'ERC20',
        label: 'ERC20',
        cryptoAddress: '',
        isEditing: false,
      },
      {
        value: 'BSC',
        label: 'BSC',
        cryptoAddress: 'mzfouedGWKFXPPa4epAMBS7H9DfWjc2DAE',
        isEditing: false,
      },
    ],
  },
];

const CryptoWallets = () => {
  //   let { currentToken } = useContext(AuthContext);
  const [Cryptos, setCryptos] = useState([
    {
      index: 0,
      name: 'BTC',
      icon:
        'https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Bitcoin-BTC-icon.png',
      network: [
        {
          value: 'ERC20',
          label: 'ERC20',
          cryptoAddress: 'mzfouedGWKFXPPa4epAMBS7H9DfWjc2DAE',
          isEditing: false,
        },
        {
          value: 'BSC',
          label: 'BSC',
          cryptoAddress: 'mzfouedGWKFXPPa4epAMBS7H9DfWjc2DAE',
          isEditing: false,
        },
        {
          value: 'CRONOS',
          label: 'CRONOS',
          cryptoAddress: '',
          isEditing: false,
        },
      ],
    },
    {
      index: 1,
      name: 'ETH',
      icon:
        'https://banner2.cleanpng.com/20190418/rgf/kisspng-ethereum-portable-network-graphics-computer-icons-developers-icon-request-icon-ethereum-5cb941c1cb12b1.1213852915556448658318.jpg',
      network: [
        {
          value: 'ERC20',
          label: 'ERC20',
          cryptoAddress: '',
          isEditing: false,
        },
        {
          value: 'BSC',
          label: 'BSC',
          cryptoAddress: 'mzfouedGWKFXPPa4epAMBS7H9DfWjc2DAE',
          isEditing: false,
        },
      ],
    },
  ]);
  const [selectedOption, setSelectedOption] = useState(Cryptos[0]);
  const [menuFiat, setMenuFiat] = useState(false);
  const [network, setNetwork] = useState('BSC');
  const color = useColorModeValue('white', 'gray.800');
  const [isSmallerThan640] = useMediaQuery('(max-width: 640px)');

  const toggleMenuFiat = option => {
    setMenuFiat(!menuFiat);
  };

  const handleClick = id => {
    console.log(id);
    const crypto = selectedOption;
    crypto.network[id].isEditing = !selectedOption.network[id].isEditing;
    console.log(crypto.network[id].isEditing);
    setSelectedOption(crypto);
  };

  //   useEffect(() => {
  //     getUser();
  //   }, [currentToken]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        bg={color}
        boxShadow={'2xl'}
        rounded={'md'}
        pb={5}
        mb={10}
        paddingInline={10}
        w={'100%'}
        align={{ base: 'center', md: 'center' }}
      >
        <Box
          style={{
            borderBottomWidth: 1,
            borderBottomColor: 'lightgrey',
            paddingBottom: 2,
          }}
        >
          <Heading fontSize={'24'} fontWeight={'bold'} color="#1A4BEA" p={10}>
            Add personal wallet address
          </Heading>
        </Box>
        <Stack
          align={'center'}
          justify={'center'}
          justifyContent="space-around"
          mb={5}
          mt={10}
          flexDirection="column"
          style={{ alignItems: 'center' }}
        >
          <Visor style={{ width: '40%' }}>
            <EtiquetaPrecioFiat onClick={toggleMenuFiat}>
              <div
                style={{
                  paddingLeft: 20,
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                  }}
                >
                  <CryptoIcon src={selectedOption.icon} />
                </div>
                <i
                  style={{
                    display: 'inline',
                    paddingLeft: 40,
                    color: 'black',
                    fontSize: 18,
                  }}
                >
                  {selectedOption.name}
                </i>
              </div>
              {menuFiat ? (
                <FontAwesomeIcon
                  icon={faAngleUp}
                  style={{
                    position: 'absolute',
                    right: 16,
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  style={{
                    position: 'absolute',
                    right: 16,
                  }}
                />
              )}

              <StyledSelect
                value={{
                  value: selectedOption,
                  label: selectedOption.name,
                }}
                onChange={e => setSelectedOption(Cryptos[e.value])}
                styles={{
                  control: styles => ({
                    ...styles,
                    display: 'none',
                  }),
                }}
                menuIsOpen={menuFiat}
                options={Cryptos.flatMap((crypto, index) => [
                  {
                    value: index,
                    label: (
                      <>
                        <div
                          style={{
                            paddingLeft: 20,
                            flexDirection: 'row',
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                            }}
                          >
                            <CryptoIcon src={crypto.icon} />
                          </div>
                          <i
                            style={{
                              display: 'inline',
                              paddingLeft: 40,
                              color: 'black',
                              fontSize: 18,
                            }}
                          >
                            {crypto.name}
                          </i>
                        </div>
                      </>
                    ),
                  },
                ])}
              />
            </EtiquetaPrecioFiat>
          </Visor>
        </Stack>
        <Stack
          align={'center'}
          justify={'center'}
          justifyContent="space-around"
          mb={5}
          mt={10}
          flexDirection="column"
          style={{ alignItems: 'center' }}
        >
          <div style={{ marginBottom: 10, width: '40%', alignItems: 'center' }}>
            <div style={{ textAlign: 'left', marginTop: 0 }}>
              <span>Select network to {selectedOption.name}</span>
            </div>
            <Select
              value={{
                value: network,
                label: network,
              }}
              onChange={e => setNetwork(e.value)}
              components={{
                IndicatorSeparator: () => null,
              }}
              textFieldProps={{
                label: 'Label',
                InputLabelProps: {
                  shrink: true,
                },
              }}
              className="basic-single"
              classNamePrefix="select"
              defaultValue={Cryptos[0].network[0]}
              isSearchable={false}
              name="color"
              options={selectedOption.network}
            />
          </div>
        </Stack>
        <Stack
          align={'center'}
          justify={'center'}
          justifyContent="space-around"
          mb={5}
          mt={5}
          style={{ alignItems: 'baseline' }}
          width={'100%'}
          sx={{ flexDirection: isSmallerThan640 ? 'column' : 'row' }}
        >
          <SimpleGrid minChildWidth="350px" spacing="40px">
            {selectedOption.network.map((mapedNetwork, index) => {
              return (
                <Box
                  backgroundColor="#eeeeeeaa"
                  style={{ borderRadius: 8 }}
                  pl={30}
                  pr={15}
                  pt={15}
                  pb={20}
                  paddingInline={10}
                  key={index}
                >
                  <Box style={{ marginTop: 0, alignSelf: 'center' }}>
                    <Box flexDirection="column" px={3} align="center">
                      <p>Network: {mapedNetwork.value}</p>
                    </Box>
                    <Text mb="2px" mt="8px" align="start" fontWeight="bold">
                      Wallet address
                    </Text>
                    <Input
                      placeholder="Add crypto wallet address"
                      size="xs"
                      style={{
                        borderColor: 'lightgrey',
                        borderWidth: 1,
                        borderRadius: 5,
                        padding: 8,
                        width: '100%',
                        fontSize: 14,
                      }}
                      value={mapedNetwork.cryptoAddress ?? ''}
                      //   onChange={event => {
                      //     setNumeroDeCuentaPEN(event.target.value);
                      //   }}
                      //   disabled={havePenAccount && !editPEN ? true : false}
                    />
                    <Stack
                      align={'center'}
                      justify={'center'}
                      justifyContent="space-around"
                      mb={5}
                      mt={10}
                      flexDirection="column"
                      style={{ alignItems: 'center' }}
                    >
                      <Button
                        style={{ marginTop: 10, width: '40%' }}
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              );
            })}
          </SimpleGrid>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

export default CryptoWallets;
