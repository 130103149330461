import './styles.css';

import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'Auth';
import agentebtcapi from 'agentebtcapi.js';
import question_mark from 'media/question-mark@2x.png';
import swal from 'sweetalert';

const initialConvertedLimits = {
  BTC: {
    sell: [0, 1],
    buy: [0, 1],
  },
  USD: {
    sell: [5, 100000],
    buy: [5, 100000],
  },
  PEN: {
    sell: [20, 374000],
    buy: [20, 374000],
  },
  HNL: {
    sell: [100, 374000],
    buy: [100, 374000],
  },
};

const NewCalc = () => {
  /*----------------------*/
  /* States               */
  /*----------------------*/

  // Auth
  const { currentToken } = useContext(AuthContext);

  // User
  const [user, setDataUser] = useState({});

  // Balances
  const [balancePEN, setBalancePEN] = useState(0);
  const [balanceUSD, setBalanceUSD] = useState(0);
  const [balanceHNL, setBalanceHNL] = useState(0);
  const [cryptos, setCryptos] = useState([]);
  const [limits, setLimits] = useState(initialConvertedLimits);

  // Inputs
  const [buyFiatInput, setBuyFiatInput] = useState('');
  const [buyCryptoInput, setBuyCryptoInput] = useState('');
  const [sellFiatInput, setSellFiatInput] = useState('');
  const [sellCryptoInput, setSellCryptoInput] = useState('');

  // Options
  const [activeBuyFiat, setActiveBuyFiat] = useState(fiatOptions[0]);
  const [activeSellFiat, setActiveSellFiat] = useState(fiatOptions[0]);
  const [activeBuyCrypto, setActiveBuyCrypto] = useState(cryptos[0]);
  const [activeSellCrypto, setActiveSellCrypto] = useState(cryptos[0]);

  const [buyCryptoListExpanded, setBuyCryptoListExpanded] = useState(false);
  const [buyFiatListExpanded, setBuyFiatListExpanded] = useState(false);
  const [sellCryptoListExpanded, setSellCryptoListExpanded] = useState(false);
  const [sellFiatListExpanded, setSellFiatListExpanded] = useState(false);

  // Everything else
  const [selectedOption, setSelectedOption] = useState('buy');
  const [buttonColorBuy, setButtonColorBuy] = useState('grey');
  const [buttonColorSell, setButtonColorSell] = useState('grey');
  const [exchangeRate, setExchangeRate] = useState(cryptoMock.buy.rate);
  const [disableBuyButton, setDisableBuyButton] = useState(true);
  const [disableSellButton, setDisableSellButton] = useState(true);

  /*----------------------*/
  /* Getters              */
  /*----------------------*/
  const getUser = async () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setDataUser(responseJson);
      })
      .catch(error => {
        console.log('Ha habido un error en nuestro sistema');
      });
  };

  const getCryptos = async () => {
    const token = localStorage.getItem('token-agente-btc');
    const endpoint = '/public/cryptos';
    const url = `${agentebtcapi}${endpoint}`;
    const data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setCryptos(responseJson);
        console.log('Respuesta de cryptos: ', responseJson);
        setActiveBuyCrypto(responseJson[0]);
        setActiveSellCrypto(responseJson[0]);
        /*if(responseJson.hasRegisterCoupon){
              getCoupon(responseJson.registerCouponId);
            }*/
      })
      .catch(error => {
        console.log('Ha habido un error en nuestro sistema');
      });
  };

  /*----------------------*/
  /* Arrow Functions      */
  /*----------------------*/
  const showInfoAboutPrice = () => {
    swal({
      text:
        'Los precios son referenciales y son actualizados cada 10 segundos.',
      button: false,
      timer: 3000,
    });
  };

  /*----Active currencies----*/
  /*-------------------------*/

  const handleSellFiatOption = index => {
    setActiveSellFiat(fiatOptions[index]);
    setSellFiatListExpanded(false);
  };

  const handleBuyFiatOption = index => {
    setActiveBuyFiat(fiatOptions[index]);
    setBuyFiatListExpanded(false);
  };

  const handleBuyCryptoOption = index => {
    setActiveBuyCrypto(cryptos[index]);
    setBuyCryptoListExpanded(false);
  };

  const handleSellCryptoOption = index => {
    setActiveSellCrypto(cryptos[index]);
    setSellCryptoListExpanded(false);
  };

  // Change between buy and sell
  const handleOptionChange = option => {
    if (selectedOption === 'buy') {
      setSellFiatInput('');
      setSellCryptoInput('');
      setActiveSellFiat(fiatOptions[0]);
      setActiveSellCrypto(cryptos[0]);
      setSelectedOption(option);
    } else {
      setBuyFiatInput('');
      setBuyCryptoInput('');
      setActiveBuyFiat(fiatOptions[0]);
      setActiveBuyCrypto(cryptos[0]);
      setSelectedOption(option);
    }
  };

  /*---Input handlers---*/
  /*--------------------*/
  const handleBuyFiatInput = e => {
    const value = e.target.value.trim();

    if (value === '') {
      setBuyFiatInput('');
      setBuyCryptoInput('');
      setButtonColorBuy('grey');
    } else {
      setBuyFiatInput(value);

      // Calculate equivalent crypto value
      const cryptoValue = parseFloat(value) * exchangeRate; // Parse to float
      setBuyCryptoInput(cryptoValue.toFixed(8));

      // Update buy button color
      updateButtonColorBuy(value, buyCryptoInput);
      setDisableBuyButton(false);
    }
  };

  const handleBuyCryptoInput = e => {
    const value = e.target.value.trim();
    if (value === '') {
      setBuyFiatInput('');
      setBuyCryptoInput('');
      setButtonColorBuy('grey');
      setDisableBuyButton(true);
    } else {
      setBuyCryptoInput(value);

      // Calculate equivalent fiat value
      const fiatValue = parseFloat(value) / exchangeRate; // Parse to float
      setBuyFiatInput(fiatValue.toFixed(2));

      // Update buy button color
      updateButtonColorBuy(buyFiatInput, value);
      setDisableBuyButton(false);
    }
  };

  const handleSellFiatInput = e => {
    const value = e.target.value.trim();

    if (value === '') {
      setSellFiatInput('');
      setSellCryptoInput('');
      setButtonColorSell('grey');
      setDisableSellButton(true);
    } else {
      setSellFiatInput(value);

      // Calculate equivalent crypto value
      const cryptoValue = parseFloat(value) * exchangeRate;
      setSellCryptoInput(cryptoValue.toFixed(8));

      // Update sell button color
      setDisableSellButton(false);
      updateButtonColorSell(value, sellCryptoInput);
    }
  };

  const handleSellCryptoInput = e => {
    const value = e.target.value.trim();
    if (value === '') {
      setSellFiatInput('');
      setSellCryptoInput('');
      setButtonColorSell('grey');
      setDisableSellButton(true);
    } else {
      setSellCryptoInput(value);

      // Calculate equivalent fiat value
      const fiatValue = parseFloat(value) / exchangeRate;
      setSellFiatInput(fiatValue.toFixed(2));

      // Update sell button color
      setDisableSellButton(false);
      updateButtonColorSell(sellFiatInput, value);
    }
  };

  const handleBuyButton = () => {
    if (buyFiatInput.trim() === '' && buyCryptoInput.trim() === '') {
      swal({
        text: 'Ingresa un valor válido',
        button: false,
        timer: 3000,
      });
      return;
    }
    // Rest of logic for buy button....
  };

  const updateButtonColorBuy = (value1, value2) => {
    if (value1 === '' || value2 === '') {
      setButtonColorBuy('grey');
    } else {
      setButtonColorBuy('#1a4bea');
    }
  };

  const handleSellButton = () => {
    if (sellFiatInput.trim() === '' && sellCryptoInput.trim() === '') {
      swal({
        text: 'Ingresa un valor válido',
        button: false,
        timer: 3000,
      });
      return;
    }
    // Rest of logic for sell button...
  };

  const updateButtonColorSell = (value1, value2) => {
    if (value1.trim() === '' || value2.trim() === '') {
      setButtonColorSell('grey');
    } else {
      setButtonColorSell('#1a4bea');
    }
  };

  /*---Fiat & Crypto Lists---*/
  /*-------------------------*/
  const handleBuyCryptoList = () => {
    setBuyCryptoListExpanded(!buyCryptoListExpanded);
  };

  const handleBuyFiatList = () => {
    setBuyFiatListExpanded(!buyFiatListExpanded);
  };

  const handleSellCryptoList = () => {
    setSellCryptoListExpanded(!sellCryptoListExpanded);
  };

  const handleSellFiatList = () => {
    setSellFiatListExpanded(!sellFiatListExpanded);
  };

  const handleCloseLists = () => {
    setBuyCryptoListExpanded(false);
    setBuyFiatListExpanded(false);
    setSellCryptoListExpanded(false);
    setSellFiatListExpanded(false);
  };

  /*----------------------*/
  /* Use Effect           */
  /*----------------------*/
  useEffect(() => {
    getUser();

    //getWallet();
    getCryptos();
  }, []);

  useEffect(() => {
    console.log('Active sell crypto', activeSellCrypto);
    console.log('Active sell fiat', activeSellFiat);
    console.log('Active buy crypto', activeBuyCrypto);
    console.log('Active buy fiat', activeBuyFiat);
  }, [activeSellCrypto, activeSellFiat, activeBuyCrypto, activeBuyFiat]);

  /*----------------------*/
  /* Event listener       */
  /*----------------------*/

  return (
    <div>
      {cryptos && activeSellCrypto && activeBuyCrypto ? (
        <div className="NewCalcContainer">
          {/* Upper part (Buy | Sell) */}
          <div className="optionsContainer">
            <button
              className={`optionButton ${
                selectedOption === 'buy' ? 'selectedChoice' : ''
              }`}
              onClick={() => handleOptionChange('buy')}
            >
              Comprar
            </button>
            <button
              className={`optionButton ${
                selectedOption === 'sell' ? 'selectedChoice' : ''
              }`}
              onClick={() => handleOptionChange('sell')}
            >
              Vender
            </button>
          </div>
          

          <div className="operationSection">
            {/* Buy Section */}
            <div
              className="calc-body-container"
              style={{ display: selectedOption === 'buy' ? '' : 'none' }}
            >
              {/* Select currency */}
              <div className="calc-select-currency" onClick={handleBuyFiatList}>
                <div className="calc-select-currency-wrapper unselectable">
                  <span className="calc-select-currency-label">
                    {activeBuyFiat.currency}
                  </span>
                  <div className="calc-currency-info">
                    <div style={{ width: '85%' }}>
                      <img
                        src={activeBuyFiat.src}
                        className="calc-currency-logo"
                      />
                      <i
                        style={{
                          display: 'inline',
                          color: 'black',
                          fontSize: '18px',
                          marginTop: '-10px',
                        }}
                      >
                        {activeBuyFiat.name}
                      </i>
                      <i
                        style={{
                          display: 'inline',
                          paddingLeft: '10px',
                          fontSize: '18px',
                          marginTop: '-10px',
                        }}
                      >
                        {activeBuyFiat.sign}
                      </i>
                    </div>
                    <svg
                      height="20"
                      width="20"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      focusable="false"
                      class={`calc-arrow-svg calc-arrow-svg-2 ${
                        buyFiatListExpanded === true ? 'open-menu' : ''
                      } `}
                    >
                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                    </svg>
                  </div>
                </div>

                {/* Open List of Fiat */}
                {buyFiatListExpanded && (
                  <div className="calc-list-wrapper-large">
                    <div className="calc-select-currency-list-large">
                      {fiatOptions.map((option, index) => (
                        <div
                          className={`calc-select-currency-wrapper ${
                            option.currency === activeBuyFiat.currency
                              ? 'calc-selected-option'
                              : 'calc-hover'
                          } `}
                          key={index}
                          style={{ display: 'block' }}
                          onClick={() => handleBuyFiatOption(index)}
                        >
                          <span className="calc-select-currency-label">
                            {option.currency}
                          </span>
                          <div className="calc-currency-info">
                            <div style={{ width: '85%' }}>
                              <img
                                src={option.src}
                                className="calc-currency-logo"
                              />
                              <i
                                style={{
                                  display: 'inline',
                                  color: 'black',
                                  fontSize: '18px',
                                  marginTop: '-10px',
                                }}
                              >
                                {option.name}
                              </i>
                              <i
                                style={{
                                  display: 'inline',
                                  paddingLeft: '10px',
                                  fontSize: '18px',
                                  marginTop: '-10px',
                                }}
                              >
                                {option.sign}
                              </i>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {/* Exhange rate */}
              <div className="calc-exchange-rate">
                <img
                  className="calc-exchange-rate-img"
                  src={`${question_mark}`}
                  onClick={showInfoAboutPrice}
                  style={{ cursor: 'pointer' }}
                />
                <p>
                  1 {activeBuyCrypto.symbol} = 43990.89 {activeBuyFiat.currency}
                </p>
              </div>

              {/* Input 1 */}
              <div className="calc-input-container">
                <div className="calc-input-left">
                  <label className="calc-input-left-label">
                    Monto ({activeBuyFiat.currency})
                  </label>
                  <input
                    placeholder="0"
                    type="number"
                    className="calc-input"
                    value={buyFiatInput}
                    onChange={handleBuyFiatInput}
                  ></input>
                </div>
                <div className="calc-input-right-first">
                  <p className="calc-input-currency">
                    {activeBuyFiat.currency}
                  </p>
                </div>
              </div>

              {/* Min legend (only displays when the input is less than the min) */}
              <div className="calc-input-minimum-label"
              style={{
                display:
                  buyFiatInput === '' ||
                  (parseInt(buyFiatInput) <
                    initialConvertedLimits[activeBuyFiat.currency].sell[0] &&
                    buyFiatInput !== '')
                    ? 'block'
                    : 'none'
              }}
              >
                {' '}
                El mínimo es {activeBuyFiat.buy.min}{' '}
              </div>

              {/* Input 2 */}
              <div className="calc-input-container">
                <div className="calc-input-left">
                  <label className="calc-input-left-label">
                    Monto ({activeBuyCrypto.symbol})
                  </label>
                  <input
                    placeholder="0"
                    type="number"
                    className="calc-input"
                    value={buyCryptoInput}
                    onChange={handleBuyCryptoInput}
                  ></input>
                </div>
                <div
                  className="calc-input-right-second unselectable"
                  onClick={handleBuyCryptoList}
                >
                  <div className="calc-input-right-second-wrapper">
                    <img
                      src={activeBuyCrypto.image}
                      className="calc-input-right-img"
                    />
                    <p className="calc-input-currency">
                      {activeBuyCrypto.symbol}
                    </p>
                    <div
                      style={{
                        color: 'orange',
                        display: 'flex',
                        margin: '0px 5px 0px -5px',
                      }}
                    >
                      <svg
                        height="20"
                        width="20"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        focusable="false"
                        class={`calc-arrow-svg ${
                          buyCryptoListExpanded === true ? 'open-menu' : ''
                        } `}
                      >
                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              {/* Open List of Crypto */}
              {buyCryptoListExpanded && (
                <div className="calc-list-wrapper-small">
                  <div className="calc-select-currency-list-small">
                    {cryptos.map((option, index) => (
                      <div
                        className={`calc-select-currency-wrapper-small ${
                          option.symbol === activeBuyCrypto.symbol
                            ? 'calc-selected-option color-white'
                            : 'calc-hover'
                        }`}
                        key={index}
                        onClick={() => handleBuyCryptoOption(index)}
                      >
                        <img
                          className="calc-input-right-img"
                          src={option.image}
                          style={{ margin: '0px 15px 0px 10px' }}
                        />
                        <span className="calc-select-currency-small-text">
                          {option.symbol}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Buy button */}
              <button
                className="calc-confirm-btn"
                style={{ backgroundColor: buttonColorBuy }}
                onClick={handleBuyButton}
                disabled={!buyFiatInput.trim() || !buyCryptoInput.trim()}
              >
                {buttonColorBuy === 'grey' ? 'Ingresa un valor' : 'Comprar'}
              </button>

              {/* Legends */}
              <div className="calc-legend-container">
                <p>
                  Min: {initialConvertedLimits[activeBuyFiat.currency].buy[0]}{' '}
                  {activeBuyFiat.currency}
                </p>
                <p>
                  Max: {initialConvertedLimits[activeBuyFiat.currency].buy[1]}{' '}
                  {activeBuyFiat.currency}
                </p>
              </div>
            </div>

            {/* Sell section */}
            <div
              className="calc-body-container"
              style={{ display: selectedOption === 'sell' ? '' : 'none' }}
            >
              {/* Select currency */}
              <div className="calc-select-currency">
                <div
                  className="calc-select-currency-wrapper unselectable"
                  onClick={handleSellCryptoList}
                >
                  <span className="calc-select-currency-label">
                    {activeSellCrypto.symbol}
                  </span>
                  <div className="calc-currency-info">
                    <div style={{ width: '85%' }}>
                      <img
                        src={activeSellCrypto.image}
                        className="calc-currency-logo"
                      />
                      <i
                        style={{
                          display: 'inline',
                          color: 'black',
                          fontSize: '18px',
                          marginTop: '-10px',
                        }}
                      >
                        {activeSellCrypto.name}
                      </i>
                      <i
                        style={{
                          display: 'inline',
                          paddingLeft: '10px',
                          fontSize: '18px',
                          marginTop: '-10px',
                        }}
                      >
                        {activeSellCrypto.symbol}
                      </i>
                    </div>
                    <svg
                      height="20"
                      width="20"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      focusable="false"
                      class="calc-arrow-svg calc-arrow-svg-2"
                    >
                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                    </svg>
                  </div>
                </div>

                {/* Open List of Crypto */}
                {sellCryptoListExpanded && (
                  <div className="calc-list-wrapper-large">
                    <div className="calc-select-currency-list-large">
                      {cryptos.map((option, index) => (
                        <div
                          className={`calc-select-currency-wrapper ${
                            option.symbol === activeSellCrypto.symbol
                              ? 'calc-selected-option'
                              : 'calc-hover'
                          } `}
                          style={{ display: 'block' }}
                          key={index}
                          onClick={() => handleSellCryptoOption(index)}
                        >
                          <span className="calc-select-currency-label">
                            {option.name}
                          </span>
                          <div className="calc-currency-info">
                            <div style={{ width: '85%' }}>
                              <img
                                src={option.image}
                                className="calc-currency-logo"
                              />
                              <i
                                style={{
                                  display: 'inline',
                                  color: 'black',
                                  fontSize: '17px',
                                  marginTop: '-10px',
                                }}
                              >
                                {option.name}
                              </i>
                              <i
                                style={{
                                  display: 'inline',
                                  paddingLeft: '10px',
                                  fontSize: '18px',
                                  marginTop: '-10px',
                                }}
                              >
                                {option.symbol}
                              </i>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {/* Exhange rate */}
              <div className="calc-exchange-rate">
                <img
                  className="calc-exchange-rate-img"
                  src={`${question_mark}`}
                  onClick={showInfoAboutPrice}
                  style={{ cursor: 'pointer' }}
                />
                <p>
                  1 {activeSellCrypto.symbol} = 43990.89{' '}
                  {activeSellFiat.currency}
                </p>
              </div>

              {/* Input 1 */}
              <div className="calc-input-container">
                <div className="calc-input-left">
                  <label className="calc-input-left-label">
                    Monto ({activeSellCrypto.symbol})
                  </label>
                  <input
                    placeholder="0"
                    type="number"
                    className="calc-input"
                    value={sellCryptoInput}
                    onChange={handleSellCryptoInput}
                  ></input>
                </div>
                <div className="calc-input-right-first unselectable">
                  <p className="calc-input-currency">
                    {activeSellCrypto.symbol}
                  </p>
                </div>
              </div>

              {/* Input 2 */}
              <div className="calc-input-container">
                <div className="calc-input-left">
                  <label className="calc-input-left-label">
                    Monto ({activeSellFiat.currency})
                  </label>
                  <input
                    placeholder="0"
                    type="number"
                    className="calc-input"
                    value={sellFiatInput}
                    onChange={handleSellFiatInput}
                  ></input>
                </div>
                <div
                  className="calc-input-right-second unselectable"
                  onClick={handleSellFiatList}
                >
                  <div className="calc-input-right-second-wrapper">
                    <p className="calc-input-currency">
                      {activeSellFiat.currency}
                    </p>
                    <div
                      style={{
                        color: 'orange',
                        display: 'flex',
                        margin: '0px 5px 0px -5px',
                      }}
                    >
                      <svg
                        height="20"
                        width="20"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        focusable="false"
                        class="calc-arrow-svg"
                      >
                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              {/* Open List of Crypto */}
              {sellFiatListExpanded && (
                <div className="calc-list-wrapper-small">
                  <div className="calc-select-currency-list-small">
                    {fiatOptions.map((option, index) => (
                      <div
                        className={`calc-select-currency-wrapper-small ${
                          option.currency === activeSellFiat.currency
                            ? 'calc-selected-option color-white'
                            : 'calc-hover'
                        }`}
                        key={index}
                        onClick={() => handleSellFiatOption(index)}
                      >
                        <img
                          className="calc-input-right-img"
                          src={option.src}
                          style={{ margin: '0px 15px 0px 10px' }}
                        />
                        <span className="calc-select-currency-small-text">
                          {option.name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Min legend (only displays when the input is less than the min) */}
              <div
                className="calc-input-minimum-label"
                style={{
                  display:
                    sellFiatInput === '' ||
                    (parseInt(sellFiatInput) <
                      initialConvertedLimits[activeSellFiat.currency].sell[0] &&
                      sellFiatInput !== '')
                      ? 'block'
                      : 'none'
                }}
              >
                El mínimo en {activeSellFiat.buy.min}
              </div>

              {/* Sell button */}
              <button
                className="calc-confirm-btn"
                style={{ backgroundColor: buttonColorSell }}
                onClick={handleSellButton}
                disabled={disableSellButton}
              >
                {buttonColorSell === 'grey' ? 'Ingresa un valor' : 'Vender'}
              </button>

              {/* Legends */}
              <div className="calc-legend-container">
                <p>
                  Min: {initialConvertedLimits[activeSellFiat.currency].sell[0]}{' '}
                  {activeSellFiat.currency}
                </p>
                <p>
                  Max: {initialConvertedLimits[activeSellFiat.currency].sell[1]}{' '}
                  {activeSellFiat.currency}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        /* Spinner */
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );
};

export default NewCalc;

const cryptoMock = {
  currency: 'BTC',
  name: 'Bitcoin',
  buy: {
    min: 0.0001,
    max: 2,
    rate: 0.000023,
  },
  src: 'https://cdn-icons-png.flaticon.com/512/825/825540.png',
};

const fiatOptions = [
  {
    currency: 'USD',
    name: 'Dólares',
    sign: '$',
    buy: {
      min: 5,
      max: 100000,
      rate: 43990.89,
    },
    src:
      'https://icon-library.com/images/dollar-png-icon/dollar-png-icon-28.jpg',
  },
  {
    currency: 'PEN',
    name: 'Soles',
    sign: '/S',
    buy: {
      min: 20,
      max: 374000,
      rate: 3.94,
    },
    src:
      'https://cdn3.iconfinder.com/data/icons/major-currencies-24px-glyphs/48/sol_peruvian_currency_PEN-512.png',
  },
  {
    currency: 'HNL',
    name: 'Lempiras',
    sign: 'L.',
    buy: {
      min: 100,
      max: 374000,
      rate: 1.78,
    },
    src: 'https://i.imgur.com/5cRwRUe.png',
  },
];
