import React, { useContext, useState, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from 'PrivateRoute';
import Profile from './Profile';
import Staking from './Profile/Staking';
import Validation from './Profile/UsersValidation';
import ValidationLevelOne from './Profile/UsersValidation/Level1';
import ValidationLevelTwo from './Profile/UsersValidation/Level2';
import CalculatorPage from './Profile/CalculatorPage';
import Operaciones from './Profile/Operations';
import Withdraw from './Profile/Withdraw';
import Deposit from './Profile/Deposit';
import Settings from './Profile/Settings';
import Bodega from './Profile/KasnetPage';
import { ChakraProvider } from '@chakra-ui/react';
import logo from '../../images/LOGOTIPO-HORIZONTAL.png';
import ObjectCalculator from '../../components/object-calculator/ObjectCalculator';
import { Stepper, Step } from 'react-form-stepper';
import Operation from './Operation/index.js';

import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import Wallet from './Profile/Wallet';

import {
  Center,
  Image,
  Button,
  Spinner,
  useMediaQuery,
} from '@chakra-ui/react';

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import {
  FiClock,
  FiSettings,
  FiMenu,
  FiClipboard,
  FiChevronDown,
  FiCornerUpLeft,
  FiCornerUpRight,
} from 'react-icons/fi';
import { IoMdWallet, IoMdHome, IoIosSwap, IoIosStats } from 'react-icons/io';
import { FaCoins } from 'react-icons/fa';
import { AuthContext } from 'Auth';
import ReactGA from 'react-ga';
import TestCalcPage from './Profile/TestCalcPage';

const LinkItems = [
  { name: 'Inicio', icon: IoMdHome, href: '' },
  { name: 'Comprar y vender', icon: IoIosSwap, href: 'comprar_vender' },
  { name: 'Mis Billeteras', icon: IoMdWallet, href: 'billeteras' },
  { name: 'Depositar', icon: FiCornerUpRight, href: 'deposit' },
  { name: 'Retirar', icon: FiCornerUpLeft, href: 'withdraw' },
  { name: 'Operaciones', icon: FiClock, href: 'operaciones' },
  { name: 'Inversiones', icon: IoIosStats, href: 'staking' },
  { name: 'Validación', icon: FiClipboard, href: 'validacion' },
  { name: 'Pagar Servicios', icon: FaCoins, href: '' },
];

export default function App({ history, location }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { clearAuthentication, currentToken } = useContext(AuthContext);

  const [data, setData] = useState({});

  // For statuses
  const [isValidated, setIsValidated] = useState(false);
  const [hasBankAccount, setHasBankAccount] = useState(false);

  const [BTCBalance, setBTCBalance] = useState('0.0000');
  const [PENBalance, setPENBalance] = useState('0.00');
  const [USDBalance, setUSDBalance] = useState('0.00');
  const [USDTBalance, setUSDTBalance] = useState('0.00');
  const [btcValueinPEN, setBTCValue] = useState(0);
  const [firstLevelApproved, setFirstLevelApproved] = useState('');
  const [secondLevelApproved, setSecondLevelApproved] = useState('');

  const calculatorObserver = data => {
    setBTCValue(data.rates.BTCPENSell);
  };

  const getUser = () => {
    let data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    currentToken &&
      fetch(url, data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw { type: 'non-user' };
          }
        })
        .then(responseJson => {
          setFirstLevelApproved(responseJson.firstLevelApproved);
          setSecondLevelApproved(responseJson.secondLevelApproved);
          setIsValidated(responseJson.firstLevelApproved);
          setData(responseJson);
        })
        .catch(error => {
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });
        });
  };

  const getBankAccounts = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/user/bank`;
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(responseJson => {
        if (responseJson.pen != 0) setHasBankAccount(true);
        if (responseJson.usd != 0) setHasBankAccount(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getWallet = async () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/wallet';
    let url = `${agentebtcapi}${endpoint}`;
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        responseJson.forEach(function(item) {
          if (item.currency == 'BTC') {
            //console.log('Wallet BTC detectada');
            if (item.balance) {
              setBTCBalance(item.balance.toFixed(8));
            }
          }

          if (item.currency == 'PEN') {
            if (item.balance) {
              setPENBalance(item.balance.toFixed(2));
            }
          }

          if (item.currency == 'USD') {
            if (item.balance) {
              setUSDBalance(item.balance.toFixed(2));
            }
          }

          if (item.currency == 'USDT') {
            if (item.balance) {
              setUSDTBalance(item.balance.toFixed(2));
            }
          }
        });
      })
      .catch(error => {
        //createWallet();
        //swal({text: "Estamos creando tu billetera de Bitcoins, en unos segundos la verás... :)", button: false, timer: 3000});
      });
  };

  const handleLogOut = () => {
    swal({
      text: 'Se ha cerrado sesión. Muchas gracias',
      button: false,
      timer: 3000,
    });
    clearAuthentication();
    history.push('/');

    //setTimeout(function(){window.location.href = "https://www.agentebtc.com";}, 3000)
  };

  useEffect(() => {
    getUser();
    getWallet();
    getBankAccounts();
    const calculatorObjTmp = new ObjectCalculator(calculatorObserver);
    calculatorObjTmp.convert();
    const interval = setInterval(() => {
      calculatorObjTmp.convert();
    }, 15000);
    return () => {
      clearTimeout(interval);
    };
  }, [currentToken]);

  return (
    <>
      <ChakraProvider>
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
          <SidebarContent
            onClose={() => onClose}
            display={{ base: 'none', md: 'block' }}
          />
          <Drawer
            autoFocus={false}
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="full"
          >
            <DrawerContent>
              <SidebarContent onClose={onClose} location={location} />
            </DrawerContent>
          </Drawer>
          {/* mobilenav */}
          <MobileNav
            onOpen={onOpen}
            handleLogOut={handleLogOut}
            name={data.name}
            email={data.email}
            history={history}
            isValidated={isValidated}
            hasBankAccount={hasBankAccount}
            PENBalance={PENBalance}
            USDBalance={USDBalance}
            USDTBalance={USDTBalance}
            BTCBalance={BTCBalance}
          />
          <Box ml={{ base: 0, md: 60 }} p={{ xl: '4', base: '0' }}>
            <Switch>
              <PrivateRoute path="*/profile/bodega" component={Bodega} />
              <PrivateRoute path="*/operation" component={Operation} />
              <PrivateRoute
                path="*/profile/comprar_vender"
                component={CalculatorPage}
              />
              <PrivateRoute
                path="*/profile/operaciones"
                component={Operaciones}
              />
              <PrivateRoute
                path="*/profile/staking"
                component={Staking}
              />
              <PrivateRoute path="*/profile/billeteras" component={Wallet} />
              {!firstLevelApproved && (
                <PrivateRoute
                  path="*/profile/validacion/level1"
                  component={ValidationLevelOne}
                />
              )}
              {!secondLevelApproved && (
                <PrivateRoute
                  path="*/profile/validacion/level2"
                  component={ValidationLevelTwo}
                />
              )}
              <PrivateRoute path="*/profile/deposit" component={Deposit} />
              <PrivateRoute path="*/profile/withdraw" component={Withdraw} />
              <PrivateRoute
                path="*/profile/validacion"
                component={Validation}
              />
              <PrivateRoute
                path="*/profile/configuration"
                component={Settings}
              />
              <PrivateRoute
                path="*/profile/nueva-calc"
                component={TestCalcPage}
              />
              <PrivateRoute path="*/profile" component={Profile} />
              <Redirect from="/" to="app/profile" />
            </Switch>
          </Box>
        </Box>
      </ChakraProvider>
    </>
  );
}

const SidebarContent = ({ onClose, history, location, ...rest }) => {
  const [isSmallerThan768] = useMediaQuery('(max-width: 767px)');
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      style={{ overflowY: 'scroll', height: '100%' }}
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Link href={`/app/profile/`}>
          <Image src={logo} alt="Agente BTC" />
        </Link>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      {!isSmallerThan768 && (
        <Center mb="5">
          <Flex>
            <Link
              href={`/app/profile/bodega`}
              style={{ textDecoration: 'none' }}
            >
              <Button
                style={{ backgroundColor: '#1A4BEA', color: '#fff' }}
                size="md"
              >
                <img
                  src={require('../../media/icons/bodega_icon.png')}
                  width={35}
                  style={{ paddingRight: 10 }}
                />
                Deposita con <br/>efectivo
              </Button>
            </Link>
          </Flex>
        </Center>
      )}

      {LinkItems.map(link => (
        <NavItem
          key={link.name}
          icon={link.icon}
          href={link.href}
          onClick={() => {
            link.name === 'Validación' &&
              ReactGA.event({
                category: 'Validación',
                action: 'Clic en validación',
              });
          }}
          className={link.name === 'Pagar Servicios' ? 'disabled-link' : ''}
          disabled={link.name === 'Pagar Servicios' ? true : false}
          location
        >
          {link.name === 'Pagar Servicios' ? (
            <div style={{ display: 'block', textAlign: 'center' }}>
              <a>{link.name}</a>
              <a
                style={{
                  color: 'red',
                  position: 'absolute',
                  fontSize: 10,
                  paddingLeft: 5,
                  textAlign: 'center',
                }}
              >
                En proceso
              </a>
            </div>
          ) : (
            link.name
          )}
        </NavItem>
      ))}
      <Text color={'lightgrey'} mt={30} fontSize={14} pl={5}>
        SOPORTE
      </Text>
      <NavItem icon={FiSettings} href={'configuration'} location>
        Configuración
      </NavItem>
    </Box>
  );
};

const NavItem = ({ icon, children, href, location, disabled, ...rest }) => {
  return disabled ? (
    <Link
      style={{
        textDecoration: 'none',
        color:
          location.pathname === `/app/profile/${href}` ? '#1A4BEA' : '#000',
      }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'blue',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  ) : (
    <Link
      href={`/app/profile/${href}`}
      style={{
        textDecoration: 'none',
        color:
          location.pathname === `/app/profile/${href}` ? '#1A4BEA' : '#000',
      }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'blue',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({
  onOpen,
  handleLogOut,
  history,
  name,
  email,
  isValidated,
  hasBankAccount,
  PENBalance,
  USDBalance,
  USDTBalance,
  BTCBalance,
  ...rest
}) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      h="100%"
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Link href={`/app/profile/`}>
        <Image
          display={{ base: 'flex', md: 'none' }}
          src={logo}
          alt="Agente BTC"
          style={{ width: '40%' }}
        />
      </Link>
      <VStack
        display={{ base: 'none', md: 'block' }}
        style={{ width: '80%', justifyContent: 'center', alignItems: 'center' }}
      >
        <Stepper
          styleConfig={{
            labelFontSize: 14,
            size: 24,
            completedBgColor: '#1A4BEA',
            activeBgColor: '#3C4A5B',
          }}
          connectorStyleConfig={{ stepSize: '4em' }}
        >
          <Step
            key={'Valida tu cuenta'}
            label="Valida tu cuenta"
            style={{ backgroundColor: isValidated ? '#1A4BEA' : 'lightgrey' }}
            onClick={() => history.push('/app/profile/validacion')}
            active
          />
          <Step
            key={'Agrega cuenta bancaria'}
            label="Agrega cuenta bancaria"
            style={{
              backgroundColor: hasBankAccount ? '#1A4BEA' : 'lightgrey',
            }}
            onClick={() => history.push('/app/profile/configuration')}
            active
          />
          <Step
            key={'Abona soles o dólares'}
            label="Abona soles o dólares"
            style={{
              backgroundColor:
                (PENBalance > 0 || USDBalance > 0) && hasBankAccount
                  ? '#1A4BEA'
                  : 'lightgrey',
            }}
            onClick={() => history.push('/app/profile/deposit')}
            active
          />
          <Step
            key={'¡Comprar Bitcoin ahora!'}
            label="¡Comprar Bitcoin ahora!"
            style={{
              backgroundColor:
                BTCBalance > 0 || USDTBalance > 0 ? '#1A4BEA' : 'lightgrey',
            }}
            active
            onClick={() => history.push('/app/profile/comprar_vender')}
          />
        </Stepper>
      </VStack>
      <HStack spacing={{ base: '0', md: '6' }} style={{ flex: 1 }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar
                  size={'sm'}
                  src={'https://static.thenounproject.com/png/363639-200.png'}
                />
                {name ? (
                  <>
                    <VStack
                      display={{ base: 'none', md: 'flex' }}
                      alignItems="flex-start"
                      spacing="1px"
                      ml="2"
                    >
                      <>
                        <Text fontSize="sm">{name}</Text>
                        <Text fontSize="xs" color="gray.600">
                          {email}
                        </Text>
                      </>
                    </VStack>
                    <Box display={{ base: 'none', md: 'flex' }}>
                      <FiChevronDown />
                    </Box>
                  </>
                ) : (
                  <Spinner size="lg" />
                )}
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={() => history.push('/app/profile/')}>
                Inicio
              </MenuItem>
              <MenuItem
                onClick={() => history.push('/app/profile/configuration')}
              >
                Ir a Perfil
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={handleLogOut}>Cerrar Sesión</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
