import React from 'react';
import { Box } from '@chakra-ui/react';
import NewCalc from 'components/NewCalc/NewCalc';

const TestCalcPage = () => {
  return (
    <Box align={'center'} w="100%" pt={10}>
      <NewCalc />
    </Box>
  );
};

export default TestCalcPage;
