import React, { useContext, useState, useEffect } from 'react';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import DecimalsNum from 'helpers/decimals';

import { SimpleGrid } from '@chakra-ui/react';

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { Heading, Image, Stack, IconButton } from '@chakra-ui/react';

import { Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react';

import { Box, useColorModeValue } from '@chakra-ui/react';

import { AuthContext } from 'Auth';

const collectionsPerPage = 5;

export default function Operacions() {
  const { currentToken } = useContext(AuthContext);

  const [transacciones, setTransacciones] = useState([]);
  const [beforeEnabled, setActivateBefore] = useState(false);
  const [nextEnabled, setActivateNext] = useState(false);
  const [page, setPage] = useState(0);

  const getTransactions = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/transaction?skip=${collectionsPerPage *
      page}&limit=${collectionsPerPage}`;
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-available' };
        }
      })
      .then(responseJson => {
        if (responseJson.length !== 0) {
          checkBefore();
          checkNext();
        }
        setTransacciones(responseJson);
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  const deactivateBefore = () => {
    setActivateBefore(false);
  };

  const activateBefore = () => {
    setActivateBefore(true);
  };

  const deactivateNext = () => {
    setActivateNext(false);
  };

  const activateNext = () => {
    setActivateNext(true);
  };

  const checkBefore = val => {
    if (page == 0) {
      deactivateBefore();
    } else {
      activateBefore();
    }
  };

  const checkNext = val => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/transaction?skip=${collectionsPerPage *
      (page + 1)}&limit=${collectionsPerPage}`;
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-available' };
        }
      })
      .then(responseJson => {
        if (responseJson.length == 0) {
          deactivateNext();
        } else {
          activateNext();
        }
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  const before = () => {
    setPage(page - 1);
  };

  const next = async () => {
    setPage(page + 1);
  };

  useEffect(() => {
    getTransactions();
  }, [page]);

  return (
    <>
      <SimpleGrid spacing="15">
        <Stack
          align={'end'}
          flexDirection="row"
          justifyContent="end"
          alignItems="center"
          mb={3}
          mr={3}
        >
          <IconButton
            variant="outline"
            onClick={() => {
              beforeEnabled && before();
            }}
            aria-label="open menu"
            icon={<FiChevronLeft />}
          />
          s
          <Text style={{ marginTop: 0, paddingInline: 15 }}>
            Página: {page + 1}
          </Text>
          <IconButton
            variant="outline"
            onClick={() => {
              nextEnabled && next();
            }}
            aria-label="open menu"
            style={{ marginTop: 0 }}
            icon={<FiChevronRight />}
          />
        </Stack>
        <Box
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'2xl'}
          rounded={'md'}
          overflow={'hidden'}
          w={'100%'}
          style={{ overflowX: 'scroll' }}
          mr={15}
        >
          <Stack spacing="24px" align={'left'} ml={5} mb={5} mt={5}>
            <Heading fontSize={'16'} fontWeight={500} fontFamily={'body'}>
              Operaciones
            </Heading>
          </Stack>

          <Table variant="simple" style={{ overflowX: 'scroll' }}>
            <Thead>
              <Tr bg={useColorModeValue('gray.100', 'gray.900')}>
                <Th>Actividad</Th>
                <Th>Monto</Th>
                <Th>Comisión</Th>
                <Th>Mercado</Th>
                <Th width={'8%'}>Estado</Th>
                <Th>Fecha</Th>
              </Tr>
            </Thead>
            <Tbody>
              {transacciones.map(transaccion => {
                const transactionObj = { ...transaccion };
                const date = new Date(transactionObj.createdAt);
                let labelColor;
                let labelText;
                if (transaccion.state === 'DELIVERED') {
                  labelColor = '#00983A';
                  labelText = 'Entregado';
                } else if (transaccion.state === 'CREATED') {
                  labelColor = '#F39200';
                  labelText = 'Creado';
                } else if (transaccion.state === 'REQUESTED') {
                  labelColor = '#F39200';
                  labelText = 'Pendiente';
                } else if (transaccion.state === 'PENDING') {
                  labelColor = '#F39200';
                  labelText = 'Pendiente';
                } else {
                  labelColor = '#E30613';
                  labelText = 'Cancelado';
                }
                let transactionText;
                if (transaccion.category === 'deposit') {
                  transactionText = 'Depósito';
                } else if (transaccion.category === 'withdraw') {
                  transactionText = 'Retiro';
                } else if (transaccion.category === 'CONVERT') {
                  transactionText = 'Conversión';
                } else if (transaccion.category === 'Compra') {
                  transactionText = 'Compra';
                } else if (transaccion.category === 'send') {
                  transactionText = 'Envío';
                } else if (transaccion.category === 'receive') {
                  transactionText = 'Recibo';
                }
                return (
                  <Tr>
                    <Td>{transactionText}</Td>
                    <Td>
                      {transaccion.ammounts.length === 2
                        ? `${+parseFloat(transaccion.ammounts[0]).toFixed(DecimalsNum(transaccion.currencies[0])) +
                            ' ' +
                            transaccion.currencies[0] +
                            ' - ' +
                            +parseFloat(transaccion.ammounts[1]).toFixed(DecimalsNum(transaccion.currencies[1])) +
                            ' ' +
                            transaccion.currencies[1]}`
                        : +parseFloat(transaccion.amount).toFixed(DecimalsNum(transaccion.currency)) + ' ' + transaccion.currency}
                    </Td>
                    {/* <Td>
                      {transaccion.fee > 0 
                        ? transaccion.fee
                        : ''}
                    </Td> */}
                    <Td>
                      {transaccion.agenteBTCFee > 0 
                        ? transaccion.agenteBTCFee
                        : transaccion.fee > 0 ? transaccion.fee : ''}
                    </Td>
                    <Td>
                      {transaccion.currencies.length === 2
                        ? transaccion.currencies[0] +
                          ' - ' +
                          transaccion.currencies[1]
                        : transaccion.currency}
                    </Td>
                    <Td>
                      <Text
                        backgroundColor={labelColor}
                        px={5}
                        color={'#ffffff'}
                        borderRadius={5}
                        textAlign="center"
                        whiteSpace="nowrap"
                      >
                        {labelText}
                      </Text>
                    </Td>
                    <Td>
                      {date.getDate()}/{('0' + (date.getMonth() + 1)).slice(-2)}
                      /{1900 + date.getYear()} -{' '}
                      {('0' + date.getHours()).slice(-2)}:
                      {('0' + date.getMinutes()).slice(-2)}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </SimpleGrid>
    </>
  );
}
