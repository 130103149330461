import React, { useContext, useState, useEffect } from 'react';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import Button from '@mui/material/Button';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Heading, Text, Stack, Box, useColorModeValue } from '@chakra-ui/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthContext } from 'Auth';

let theme = createTheme({
  palette: {
    primary: {
      main: '#1A4BEA',
      dark: '#1A4BEA',
      light: '01fff0',
    },
  },
});

const Security = () => {
  let { currentToken } = useContext(AuthContext);
  const color = useColorModeValue('white', 'gray.800');
  const [user, setDataUser] = useState('');

  const getUser = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setDataUser(responseJson);
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  const recoverPassword = () => {
    const jsonBody = JSON.stringify({ email: user.email });

    let data = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonBody,
    };
    let endpoint = `/auth/recover`;
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          swal({
            text:
              '¡Correo enviado correctamente! Revisa tu bandeja de entrada y sigue los pasos para cambiar tu contraseña',
            button: true,
            timer: 3000,
          });
          return response.json();
        }
      })
      .catch(error => {
        swal({
          text:
            'Hubo un error al enviar el correo. Si el error persiste escríbenos a Soporte.',
          button: false,
          timer: 5000,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getUser();
  }, [currentToken]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        bg={color}
        boxShadow={'2xl'}
        rounded={'md'}
        pb={5}
        mb={10}
        paddingInline={10}
        w={'100%'}
        align={{ base: 'center', md: 'center' }}
      >
        <Box
          style={{
            borderBottomWidth: 1,
            borderBottomColor: 'lightgrey',
            paddingBottom: 2,
          }}
        >
          <Heading fontSize={'24'} fontWeight={'bold'} color="#1A4BEA" p={10}>
            Cambiar Contraseña
          </Heading>
        </Box>
        <Text color="gray" fontSize={15} width={'80%'} ml={15} mt={5}>
          Tu cuenta es tan segura como lo es tu contraseña. No compartas con
          nadie tu contraseña ni utilices la misma en otro sitio web.
        </Text>
        <Stack
          align={'center'}
          justify={'center'}
          justifyContent="space-around"
          mb={5}
          mt={10}
          flexDirection="column"
          style={{ alignItems: 'center' }}
        >
          <Button
            variant="contained"
            size="small"
            style={{ marginBottom: 20 }}
            onClick={() => recoverPassword()}
          >
            Cambiar contraseña
          </Button>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

export default Security;
