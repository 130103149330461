import React, { useContext, useState, useEffect, useRef } from 'react';
import './styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Heading, Stack } from '@chakra-ui/react';
import StakingCalculator from 'components/staking-calculator/StakingCalculator';
import {
  IoIosInformationCircleOutline,
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosClose,
  IoIosCalculator,
} from 'react-icons/io';
import { TbPig } from "react-icons/tb";
import { GrLineChart } from "react-icons/gr";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthContext } from 'Auth';
import ActivosDisponibles from 'components/activos-disponibles/ActivosDisponibles';
import Inversiones from 'components/inversiones/Inversiones';
import Desembolsos from 'components/desembolsos/Desembolsos';

const Staking = ({ history }) => {
  const { currentToken } = useContext(AuthContext);

  let theme = createTheme({
    palette: {
      primary: {
        main: '#1A4BEA',
        dark: '#1A4BEA',
        light: '01fff0',
      },
    },
  });

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [accordion, SetActiveAccordion] = useState(-1);
  function toggleAccordion(index) {
    if (index === accordion) {
      SetActiveAccordion(-1);
      return;
    }
    SetActiveAccordion(index);
  }

  const [modalGanancias, setModalGanancias] = useState(false);
  const toggleModalGanancias = () => {
    setModalGanancias(!modalGanancias);
  };

  const [option, setActiveOption] = useState('activos');
  function toggleOptionSelected(currentSelection) {
    setActiveOption(currentSelection);
  }

  function viewInvestment() {
    setActiveOption('inversiones')
  }

  return (
    <Stack align={'center'} mb={5} w={'100%'}>
      <ThemeProvider theme={theme}>
        <div className="inversiones-container">
          <div className="header-text">
            <div className="header-text-left">
              <img
                className="header-text-img"
                src="https://i.imgur.com/gtvUtk8.png"
              />
              Inversiones
            </div>

            <div className="header-text-right" onClick={toggleModal}>
              <span className="header-text-right-img">
                <IoIosInformationCircleOutline />
              </span>
              <div className='header-text-right-title'>¿Cómo funciona mis inversiones?</div>
            </div>
          </div>

          {/* Modal */}
          {modal && (
            <div className="modal">
              <div onClick={toggleModal} className="overlay"></div>
              <div className="modal-content">
                <div className="modal-exit" onClick={toggleModal}>
                  <IoIosClose></IoIosClose>
                </div>
                <div className="modal-header">
                  <div className="modal-header-text">
                    Información sobre rendimientos
                  </div>
                </div>

                <div className="modal-accordion-container">
                  <div className="modal-accordion">
                    {preguntas.map((item, index) => (
                      <div key={index} onClick={() => toggleAccordion(index)}>
                        <div className="modal-accordion-upper-container">
                          <div className="modal-accordion-heading">
                            <div
                              className={accordion === index ? 'active' : ''}
                            >
                              {item.question}
                            </div>
                          </div>
                          <div className="modal-accordion-arrow-container">
                            {accordion === index ? (
                              <>
                                <span className="modal-accordion-arrow">
                                  <IoIosArrowUp></IoIosArrowUp>
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="modal-accordion-arrow">
                                  <IoIosArrowDown></IoIosArrowDown>
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="container-p">
                          <hr className="text-divider" />
                          <p
                            className={
                              accordion === index ? 'active' : 'inactive'
                            }
                          >
                            {item.answer}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="info-container">
            <div className="info-left">
              <div className="info-title">Haz rendir tu dinero</div>
              <div className="info-text">
                Genera ingresos pasivos con tus criptomonedas, tomamos medidas
                para mitigar los riesgos. Retira al finalizar el plazo de tu inversión.
              </div>
              <div className="info-ganancias" onClick={toggleModalGanancias}>
                <IoIosCalculator></IoIosCalculator> Calcular ganancias
              </div>
            </div>
            <div className="info-right">
              <img
                className="info-image"
                src="https://i.imgur.com/OXNyvjD.png"
              />
            </div>
          </div>

          {/* Modal calculadora */}
          {modalGanancias && (
            <StakingCalculator toggleFunction={toggleModalGanancias} />
          )}

          <div className="section-select">
            <div
              className="section-activos"
              onClick={() => {
                toggleOptionSelected('activos');
              }}
            >
              <div className="title-selection">
                <span className='title-selection-icon'> <GrLineChart/> </span> Activos disponibles
              </div>
              <div
                className={
                  option === 'activos'
                    ? 'option-filler active-option'
                    : 'option-filler'
                }
              >
              </div>
            </div>

            <div
              className="section-inversiones"
              onClick={() => {
                toggleOptionSelected('inversiones');
              }}
            >
              <div className='title-selection'>
                <span className='title-selection-icon'> <TbPig/> </span> Mis inversiones
              </div>
              
              <div
                className={
                  option === 'inversiones'
                    ? 'option-filler active-option'
                    : 'option-filler'
                }
              >
              </div>
            </div>
          </div>

          {option === 'inversiones' ? (
           [ <Inversiones/> , 
           <Desembolsos/> ]
          ) : (
            <ActivosDisponibles  viewInvestment={viewInvestment}/>
          )}
        </div>
      </ThemeProvider>
    </Stack>
  );
};

export default Staking;

const preguntas = [
  {
    question: '¿AgenteBTC me cobra algo por invertir?',
    answer:
      'No te cobramos nada por invertir. La comisión que se lleva AgenteBTC por facilitar el acceso a los protocolos de inversión ya está contemplada en el interés anual estimado de cada moneda.',
  },
  {
    question: '¿Cómo empiezo a obtener ganancias?',
    answer: 'Solo tienes que depositar la cantidad de criptomonedas que desees en un producto. Por norma general, tus ganancias se calculan a partir de las 00:00 h (UTC) del día siguiente.  El periodo mínimo de cálculo de ganancias es de un día; las ganancias de un periodo inferior a un día no se incluirán en el reparto de ganancias.',
  },
  {
    question: '¿Cada cuánto tiempo se actualizan mis rendimientos?',
    answer: 'Tus rendimientos se actualizan todos los días.',
  },
  {
    question: '¿Puedo retirar parte o toda mi inversión antes del plazo?',
    answer: 'Lamentablemente, no se podrá retirar parte de tu inversión o el total, hasta que finalice el plazo elegido. Debido a que los canjes anticipados deducirán todos los ingresos de los intereses. Esto se debe a que los activos comprometidos para participar se bloquean en el contrato inteligente en la cadena e incluyen ciertas comisiones de transferencia de la blockchain. Elige el periodo de inversión adecuado cuando te suscribas a Invertir. Al finalizar el plazo se te devolverá el monto que hayas depositado a Invertir junto con los rendimientos generados.',
  },
];