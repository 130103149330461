import './styles.css';
import React, { useContext, useEffect, useState } from 'react';
import { GrNotes } from 'react-icons/gr';
import swal from 'sweetalert';
import { AuthContext } from 'Auth';
import agentebtcapi from 'agentebtcapi.js';

const Desembolsos = props => {
  let { currentToken } = useContext(AuthContext);

  //Get user transactions
  const [userStakingTransactions, setUserStakingTransactions] = useState([]);
  const getUserStakingTransactions = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/staking/';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setUserStakingTransactions(responseJson.transactions);
        console.log('Transactions');
        console.log(responseJson.transactions);
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    getUserStakingTransactions();
  }, []);

  return (
    <div className="desembolsos-wrapper">
      <div className="table-wrapper">
        <div className="desembolso-title-container">
          <div>
            {' '}
            <GrNotes></GrNotes>{' '}
          </div>
          <div className="desembolsos-title">Historial</div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th className="th-white ">Fecha de suscripción</th>
              <th class="th-white moneda-inv-header">Moneda invertida</th>
              <th class="th-white invest-header">Inversión</th>
              <th className="th-white apy-header">Monto Final</th>
              <th className="th-white period-header">Periodo bloqueado</th>
              <th className="th-white action-header">Estado</th>
            </tr>
          </thead>
          <tbody className="tbody">
            {userStakingTransactions.map((item, index) => {
              
              // Convert date to human readable date
              let date = new Date(item.startDate);
              let year = date.getFullYear();
              let month = date.getMonth()+1;
              let dt = date.getDate();
              
              if (dt < 10) {
                dt = '0' + dt;
              }
              if (month < 10) {
                month = '0' + month;
              }
              let subscriptionDate = year+'-' + month + '-'+dt;
              
              //Get Status and give style accordingly
              let status;
              let statusSpanish;
              // switch (item.status) {
              //   case 'REQUESTED':
              //     status = 'requested';
              //     statusSpanish = 'Solicitado';
              //     break;
              //   case 'APPROVED':
              //     status = 'approved';
              //     statusSpanish = 'Aprobado';
              //     break;
              //   default:
              //     status = 'canceled';
              //     statusSpanish = 'No aprobado';
              // }

              switch (item.status) {
                  case 'REQUESTED':
                    status = 'requested';
                    statusSpanish = 'Solicitado';
                    break;
                  case 'APPROVED':
                    status = 'approved';
                    statusSpanish = 'Aprobado';
                    break;

                  case 'IN-PROGRESS':
                    status = 'in-progress';
                    statusSpanish = 'Activo';
                    break;

                  case 'DELIVERED':
                    status = 'delivered';
                    statusSpanish = 'Entregado';
                    break;

                  case 'CANCELED':
                    status = 'canceled';
                    statusSpanish = 'Cancelado';
                    break;
                  default:
                    status = 'canceled';
                    statusSpanish = 'No aprobado';
                }

              return (
                <tr className="tr" key={index}>
                  <td className="td-desembolsos"> {subscriptionDate} </td>
                  <td className="td-desembolsos"> {item.currencyName} </td>
                  <td className="td-desembolsos"> {item.startAmount} </td>
                  <td className="td-desembolsos"> {item.finalAmount} </td>
                  <td className="td-desembolsos"> {item.timeInSpanish} </td>
                  <td className="td-desembolsos">
                    <span
                      className={`${status}-btn`}
                    >
                      {statusSpanish}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Desembolsos;