import React, { useState, useEffect} from 'react';
import '../styles.css';

// Transaction "Comprar" and "Vender" menu
const MinMaxAmounts = ({ 
    assets = [], 
    selectedAsset, 
    transactionType, 
    level,
    setMinAmount,
    setMaxAmount 
}) => {


    const [_minAmount, _setMinAmount] = useState(0);
    const [_maxAmount, _setMaxAmount] = useState(0);

    // Initial load
    useEffect(() => {
        try{
            // Load min and max amounts
            if( assets.length > 0 ){
                loadMinMaxAmounts();
            }
        }catch(error){
            console.log("Error loading min and max amounts: ",error);
        }
    }, [selectedAsset, transactionType, level, assets])

    // Load min and max amounts
    const loadMinMaxAmounts = () => {
        try{
            // Get asset object
            let asset = assets.find(asset => asset.symbol === selectedAsset);
            if( asset === undefined ){
                throw new Error("Asset not found");
            }

            let _min = 0;
            let _max = 0;
            // Load min and max amounts
            if( level === 2 || level === '2' ){
                _min = asset.limitL2[0] ?? 0;
                _max = asset.limitL2[1] ?? 0;
            }else{
                _min = asset.limitL1[0] ?? 0;
                _max = asset.limitL1[1] ?? 0;
            }

            // Load min and max amounts
            let minString = new Intl.NumberFormat('en-US').format(_min);
            let maxString = new Intl.NumberFormat('en-US').format(_max);
            _setMinAmount(minString);
            _setMaxAmount(maxString);
            setMinAmount(_min);
            setMaxAmount(_max);
        }catch(error){
            console.log("Error loading min and max amounts: ",error);
            _setMinAmount(0);
            _setMaxAmount(0);
            setMinAmount(0);
            setMaxAmount(0);
        }
    }


    // Return empty if no selected asset, _minAmount and _maxAmount are 0 or undefined
    if( !selectedAsset || !_minAmount || !_maxAmount ){
        return (<></>);
    }

    return (
        <>
            <div className='c-min-max-container'>
                <div className='c-min-max-col'>
                    Min: {_minAmount} {selectedAsset}
                </div>
                <div className='c-min-max-col'>
                    Max: {_maxAmount} {selectedAsset}
                </div>
            </div>
        </>
    )
}

export default MinMaxAmounts;