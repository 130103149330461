import React from 'react';
import '../styles.css';

// Transaction "Comprar" and "Vender" menu
const TransactionMenu = ({transactionType, onTransactionTypeChange}) => {
    let activeClass = 'c-transaction-menu-tab-active';
    return (
        <>
            <div className='c-transaction-menu-container'>
                <div className={`c-transaction-menu-tab c-col-left ${transactionType==='BUY'?activeClass:''}`}
                    onClick={()=>onTransactionTypeChange('BUY')}
                >
                    Comprar
                </div>
                <div className={`c-transaction-menu-tab c-col-right ${transactionType==='SELL'?activeClass:''}`}
                    onClick={()=>onTransactionTypeChange('SELL')}
                >
                    Vender
                </div>
            </div>
        </>
    )
}

export default TransactionMenu;