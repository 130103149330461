import React, { useEffect, useState, useContext } from "react";
// Get Link component
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
import { Ellipsis } from "react-awesome-spinners";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Get FontAwesome Icons
import { faCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import agentebtcapi from 'agentebtcapi.js';
import swal from 'sweetalert';
// Styled components
import {
  Wrapper,
  LoaderWrapper,
  HeadingPages,
  Space,
  TextSaldo,
  AmountCurrency,
  TrackBox,
  TrackStep,
  TrackTitle,
  TrackContent
} from "styles/Styled";

import Styled from './styles';

import { Header1, Header2, Header5, WarningBox, Paragraph } from  "styles/common/index.js";
import { AuthContext } from "Auth";
// Print FontAwesome Icons
library.add(faCircle, faCheckCircle);

const Tracking = ({ history, match }) => {
  const { operationId } = match.params;
  const [loading, setLoading] = useState(true);
  const [operation, setOperation] = useState({});
  const [type, setType] = useState('SELL');

  // Get user auth
  const { currentToken } = useContext(AuthContext);

  // Loading page
  const handleLoading = () => {
    setLoading(false);
  };

  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  // Get the operation data
  const getOperation = async () => {
    let options =  {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        }
    }
    let endpoint = `/client/transaction/${operationId}`;
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, options)
      .then((response) => {
        if(response.ok){
          return response.json();
        }else{
          throw {'type': 'non-available'}
        }
      })
      .then((responseJson)=>{
          setType(responseJson.transactionType);
          setOperation(responseJson);
          handleLoading();
      })
      .catch((error)=>{
        swal({text: "Ha habido un error en nuestro sistema", button: false, timer: 1000});
      })
  };


  useEffect(() => {
    getOperation();
    scrollTop();
    // eslint-disable-next-line
  }, [currentToken]);

  const montoBtc = operation.montoBitcoins;
  const montoBtcVenta = operation.montoBitcoinsVenta;
  const transaction = operation.operacion;

  return loading ? (
    <Styled.TrackingWrapper>
      <Ellipsis color={"#3C4A5B"}/>
    </Styled.TrackingWrapper>
  ) : (
    <Styled.TrackingWrapper>
      <Header1>3. Estado de tu Operación</Header1>
        <WarningBox>
          <Paragraph>
            Tu operación con ID:{" "}
            <AmountCurrency>{operationId}</AmountCurrency> ha iniciado. Te
            mantendremos informado de cuando tendrás tus{" "}
            {type === "BUY" ? (
              <AmountCurrency>{(operation.ammounts[0]).toFixed(8)} BTC</AmountCurrency>
            ):(
              <AmountCurrency>{operation.ammounts[1]} {operation.currencies[1]}</AmountCurrency>
            )}{" "}
            {type === "BUY" ? (
              'en tu wallet.'
            ):(
              'en tu cuenta.'
            )}
          </Paragraph>
        </WarningBox>
      <TrackBox>
        <TrackStep>
          <TrackTitle>
            <FontAwesomeIcon
              icon="check-circle"
              className="iconTracking verified"
            />
          <Header5> Registro de operación </Header5>
          </TrackTitle>
          <TrackContent><Paragraph>Tu operación fue registrada con éxito</Paragraph></TrackContent>
        </TrackStep>
        <TrackStep>
          <TrackTitle>
            <FontAwesomeIcon
              icon="circle"
              className="iconTracking unverified"
            />
          <Header5>Verificando transferencia</Header5>
          </TrackTitle>
          <TrackContent>
            <Paragraph>
            {type === "BUY" ? (
              `Nos tomará poco tiempo, estamos verificando la transferencia y tu
              constancia de transferencia.`
            ):(
              'Nos tomará poco tiempo, estamos verificando el recibo de los bitcoins en nuestra wallet.'
            )}
            </Paragraph>
          </TrackContent>
        </TrackStep>
        <TrackStep>
          <TrackTitle>
            <FontAwesomeIcon
              icon="circle"
              className="iconTracking unverified"
            />
          <Header5>
          {type === "BUY" ? (
            'Entrega de bitcoins'
          ):(
            'Transferencia de dinero'
          )}
          </Header5>
          </TrackTitle>
          <TrackContent>
            <Paragraph>
            {type === "BUY" ? (
              `
              En un lapso no mayor a 30 minutos recibirás
              tus bitcoins en tu wallet.
              `
            ):(
              `
              En un lapso no mayor a 30 minutos recibirás
              el dinero en tu cuenta bancaria.
              `
            )}
            </Paragraph>
          </TrackContent>
        </TrackStep>
        <Space />
        <Styled.ButtonLink to="/app/profile">
          Regresar a mi perfil
        </Styled.ButtonLink>
        <Paragraph>* IMPORTANTE: No es necesario que te quedes esperando en esta vista.</Paragraph>
      </TrackBox>
      <Space />
    </Styled.TrackingWrapper  >
  );
};

export default withRouter(Tracking);
