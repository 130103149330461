import React, { useState } from 'react';
import Select from 'react-select';
import ImageTime from 'images/time.png';

import { useMediaQuery } from '@chakra-ui/react';

const ChartSelect = ({ selectCurrency, selectedCurrency, options, icon }) => {
  const [isSmallerThan640] = useMediaQuery('(max-width: 640px)');
  function renderIcon() {
    if (icon === 'time') {
      return ImageTime;
    } else if (icon === 'BTC') {
      return 'https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Bitcoin-BTC-icon.png';
    }

    switch( icon ) {
      case 'BTC':
        return 'https://i.imgur.com/1X3UfOB.png';
      case 'ETH':
        return 'https://i.imgur.com/raoS1oe.png';
      case 'ADA':
        return 'https://i.imgur.com/YbHTGxa.png';
      case 'SHIB':
        return 'https://i.imgur.com/7wVP3v4.png';
      case 'DOGE':
        return 'https://i.imgur.com/EgVHQd9.png';
      case 'USDT':
        return 'https://i.imgur.com/cxIdakO.png';
      case 'MATIC':
        return 'https://i.imgur.com/PeaTBWR.png';
      case 'RBTC':
        return 'https://i.imgur.com/SLU2xow.png';
      case 'USDC':
        return 'https://i.imgur.com/Q4T72Hk.png';
      case 'SOL':
        return 'https://i.imgur.com/VZjTC09.png';
    }

    return 'https://cdn.iconscout.com/icon/free/png-256/ethereum-2296075-1912034.png';
  }
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        borderColor: '#f0f0f0',
        borderRadius: 6,
        borderWidth: 1,
        borderStyle: 'solid',
        shadowColor: '#000',
        backgroundColor: '#f0f0f0',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <div
          style={{
            width: '20%',
            height: '100%',
            textAlign: 'left',
            display: 'block',
            marginLeft: 8,
          }}
        >
          <img
            style={{
              width: isSmallerThan640 ? 17 : 20,
              height: isSmallerThan640 ? 17 : 20,
            }}
            src={renderIcon()}
          />
        </div>
        <div
          style={{
            width: '80%',
            color: 'black',
            fontWeight: 'bold',
            display: 'inline',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <Select
            value={{
              value: selectedCurrency,
              label: selectedCurrency,
            }}
            onChange={selectCurrency}
            styles={{
              control: (provided, state) => ({
                ...provided,
                boxShadow: 'none',
                backgroundColor: '#f0f0f0',
                border: 'none',
                width: '100%',
                fontSize: isSmallerThan640 ? '7px' : '10px',
                minHeight: 30,
              }),
              dropdownIndicator: (provided, state) => ({
                ...provided,
                padding: 0,
                paddingRight: isSmallerThan640 ? 8 : 16,
                color: 'gray',
                '&:hover': {
                  color: state.isFocused ? 'black' : 'black',
                },
              }),
              singleValue: provided => ({
                ...provided,
                color: 'black',
              }),
              input: provided => ({
                ...provided,
                fontSize: 10,
              }),
              valueContainer: provided => ({
                ...provided,
                padding: 0,
              }),
              singleValue: provided => ({
                ...provided,
                fontSize: isSmallerThan640 ? 12 : 14,
              }),
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
            className="basic-single"
            classNamePrefix="select"
            defaultValue={options[0]}
            isSearchable={false}
            name="crypto"
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartSelect;
