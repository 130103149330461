import './styles.css';
import React, { useContext, useState, useEffect } from 'react';
import StakingModal from 'components/staking-modal/StakingModal';
import agentebtcapi from 'agentebtcapi.js';
import { AuthContext } from 'Auth';
import swal from 'sweetalert';

const ActivosDisponibles = props => {
  const [stakingShows, setStakingShows] = useState(false);
  function showStakingModal() {
    setStakingShows(!stakingShows);
  }

  const [selectedCurrency, setSelectedCurrency] = useState('BTC');
  function handleCurrency(name) {
    setSelectedCurrency(name);
  }

  const [showAddFunds, setShowAddFunds] = useState(false);
  function activateFunds(amount) {
    if (userBalance < amount) {
      setStakingShows(false);
      setShowAddFunds(!showAddFunds);
    } else {
      activateEnd();
    }
  }

  function activateFundsV2(success) {
    // Success response
    if (success) {
      setShowAddFunds(false);
      activateEnd();
      return;
    }

    // Error response
    setStakingShows(false);
    setShowAddFunds(true);
  }

  const [showEnd, setShowEnd] = useState(false);
  function activateEnd() {
    setShowEnd(true);
  }

  const [userBalance, setUserBalance] = useState(0);
  function handleUserBalance(amount) {
    setUserBalance(amount);
  }

  let { currentToken } = useContext(AuthContext);
  const [dataReturn, setDataReturn] = useState([]);

  const getStakingRatesTable = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/public/staking/rates/table';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setDataReturn(responseJson.data);
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    getStakingRatesTable();
  }, [currentToken]);

  return (
    <div className="activos-wrapper">
      <div className="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th className="th logo-header"> </th>
              <th class="th moneda-header">Moneda</th>
              <th className="th apy-header">APY estimado</th>
              <th className="th action-header">Acción</th>
            </tr>
          </thead>
          <tbody className="tbody">
            {dataReturn.map((item, index) => {
              
              // Backlog: Change this so it comes from the API instead of hardcoded
              if (item.currency === 'USD') {
                item.image = "https://i.imgur.com/z3HA1co.png"
              }

              return (
                <tr className="tr" key={index}>
                  <td className="td logo">
                    <img className="cripto-img" src={item.image}></img>
                  </td>
                  <td className="column-name td">
                    {item.currencyName}{' '}
                    <span className="abb">{item.currency}</span>
                  </td>
                  <td className="td">
                    {item.rate.minApy} - {item.rate.maxApy} %
                  </td>
                  <td className="td">
                    <span
                      className="invertir-btn"
                      onClick={() => {
                        handleCurrency(item.currency);
                        showStakingModal();
                      }}
                    >
                      Invertir
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {stakingShows && (
        <StakingModal
          showStakingModal={showStakingModal}
          selectedCurrency={selectedCurrency}
          userBalance={handleUserBalance}
          activateFunds={activateFundsV2}
        />
      )}

      {showAddFunds && (
        <div className="modal-add-funds">
          <div
            className="overlay"
            onClick={() => {
              setShowAddFunds(false);
            }}
          ></div>
          <div className="modal-content-funds">
            <div className="modal-funds-body">
              <div className="modal-funds-title-principal">
                Parece que no tienes saldo suficiente
              </div>

              <div className="modal-funds-body-text">
                Recuerda que para generar dinero con tus criptomonedas, primero
                debes tener saldo en tu billetera.
              </div>

              <a
                className="modal-funds-btn-continue"
                href="/app/profile/comprar_vender"
              >
                + Agregar fondos
              </a>
            </div>
          </div>
        </div>
      )}

      {showEnd && (
        <div className="modal-end">
          <div className="overlay"></div>
          <div className="modal-content-end">
            <div className="modal-end-body">
              <div className="modal-end-title-principal">
                Haz comenzado a invertir
              </div>

              <div className="modal-end-body-text">
                Tus rendimientos se verán reflejados automaticamente en tu
                billetera al acabar el periodo de bloqueo seleccionado.
              </div>

              <div className="modal-end-buttons">
                <a className="modal-end-btn-start" href='href="/app/profile"'>
                  Ir al inicio
                </a>
                <a
                  className="modal-funds-btn-continue"
                  onClick={props.viewInvestment}
                >
                  Ver inversión
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivosDisponibles;
