import React, { useContext, useState, useEffect } from 'react';
import { CryptoChart } from '../../../components/cryptoChart';
import ObjectCalculator from '../../../components/object-calculator/ObjectCalculator';
import KasnetModal from '../../../components/modals/KasnetModal';
import DecimalsNum from 'helpers/decimals';
import imgBlog from 'images/Image-Blog.jpg';

import Calculator from '../../../components/Calculator';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';

import { SocialMedia } from './styles';

import { SimpleGrid } from '@chakra-ui/react';

import { Heading, Image, Stack, Button } from '@chakra-ui/react';

import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

import {
  Box,
  useColorModeValue,
  useMediaQuery,
  Flex,
  Link,
} from '@chakra-ui/react';

import { Ring } from 'react-awesome-spinners';

import { AuthContext } from 'Auth';
import NumToFixed from 'helpers/numToFixed';
import CalculatorV2 from 'components/CalculatorV2/CalculatorV2';

export default function SidebarWithHeader({ history }) {
  const { currentToken } = useContext(AuthContext);

  const [cryptos, setCryptos] = useState([]);

  const [isSmallerThan1020] = useMediaQuery('(max-width: 1020px)');

  const [cryptosBalance, setCryptosBalance] = useState({
    BTC: '0.0000',
    USDT: '0.0000',
    ALIC: '0.0000',
    ADA: '0.0000',
    DOGE: '0.0000',
    MATIC: '0.0000',
    ETH: '0.0000',
    SHIB: '0.0000',
    SOL: '0.0000',
    RBTC: '0.0000',
    CKBTC: '0.0000',
    USDC: '0.0000',
  });
  const [PENBalance, setPENBalance] = useState('0.00');
  const [HNLBalance, setHNLBalance] = useState('0.00');
  const [USDBalance, setUSDBalance] = useState('0.00');
  const [cryptoPricesArray, setCryptoPricesArray] = useState(0);

  const calculatorObserver = data => {
    setCryptoPricesArray(data.cryptoPrices);
  };

  const getUser = () => {
    let data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    currentToken &&
      fetch(url, data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw { type: 'non-user' };
          }
        })
        .then(responseJson => {})
        .catch(error => {
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });
        });
  };

  const createWallet = createCurrency => {
    const jsonBody = JSON.stringify({
      currency: createCurrency,
    });
    console.log(`Create Currency: ${createCurrency}`);
    let data = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: jsonBody,
    };
    let endpoint = '/client/wallet';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          /*
          swal({
            text: 'Hubo un error al crear wallet. Contacta a Soporte',
            button: false,
            timer: 5000,
          });
          */
        }
      })
      .then(responseJson => {
        console.log(`CREATE ${createCurrency} WALLET`);

        if (!responseJson.address) return;
      })
      .catch(error => {});
  };

  const getWallet = async () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/wallet';
    let url = `${agentebtcapi}${endpoint}`;
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        const hasBTC = responseJson.find(value => value.currency === 'BTC');
        const hasUSDT = responseJson.find(value => value.currency === 'USDT');
        // const hastronUSDT = responseJson.find(
        //   value => value.currency === 'tronUSDT'
        // );
        const hasADA = responseJson.find(value => value.currency === 'ADA');
        const hasSOL = responseJson.find(value => value.currency === 'SOL');
        const hasSHIB = responseJson.find(value => value.currency === 'SHIB');
        const hasMATIC = responseJson.find(value => value.currency === 'MATIC');
        const hasETH = responseJson.find(value => value.currency === 'ETH');
        const hasDOGE = responseJson.find(value => value.currency === 'DOGE');
        const hasALIC = responseJson.find(value => value.currency === 'ALIC');
        const hasHNL = responseJson.find(value => value.currency === 'HNL');
        const hasPEN = responseJson.find(value => value.currency === 'PEN');
        const hasUSD = responseJson.find(value => value.currency === 'USD');
        const hasRBTC = responseJson.find(value => value.currency === 'RBTC');
        const hasUSDC = responseJson.find(value => value.currency === 'USDC');
        const hasCKBTC = responseJson.find(value => value.currency === 'CKBTC');
        !hasBTC && createWallet('BTC');
        !hasHNL && createWallet('HNL');
        !hasPEN && createWallet('PEN');
        !hasUSD && createWallet('USD');
        !hasUSDT && createWallet('USDT');
        !hasALIC && createWallet('ALIC');
        // !hastronUSDT && createWallet('tronUSDT');
        !hasADA && createWallet('ADA');
        !hasSOL && createWallet('SOL');
        !hasSHIB && createWallet('SHIB');
        !hasMATIC && createWallet('MATIC');
        !hasETH && createWallet('ETH');
        !hasDOGE && createWallet('DOGE');
        !hasRBTC && createWallet('RBTC');
        !hasUSDC && createWallet('USDC');
        !hasCKBTC && createWallet('CKBTC');

        responseJson.forEach(function(item) {
          if (item.currency == 'BTC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { BTC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'PEN') {
            if (item.balance) {
              setPENBalance(item.balance.toFixed(2));
            }
          }
          
          if (item.currency == 'HNL') {
            if (item.balance) {
              setHNLBalance(item.balance.toFixed(2));
            }
          }

          if (item.currency == 'USD') {
            if (item.balance) {
              setUSDBalance(item.balance.toFixed(2));
            }
          }

          if (item.currency == 'ADA') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { ADA: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'DOGE') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { DOGE: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'ALIC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { ALIC: item.balance.toFixed(4) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'ETH') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { ETH: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'MATIC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { MATIC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'SHIB') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { SHIB: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'SOL') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { SOL: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'USDT') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { USDT: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'RBTC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { RBTC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'USDC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { USDC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'CKBTC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { CKBTC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          // if (item.currency == 'tronUSDT') {
          //   console.log('Wallet tron USDT detectada');
          //   if (item.balance) {
          //     setTronUSDTBalance(item.balance.toFixed(2));
          //   }
          // }
        });
      })
      .catch(error => {
        //createWallet();
        //swal({text: "Estamos creando tu billetera de Bitcoins, en unos segundos la verás... :)", button: false, timer: 3000});
      });
  };

  const getCryptos = async () => {
    const token = localStorage.getItem('token-agente-btc');
    const endpoint = '/public/cryptos';
    const url = `${agentebtcapi}${endpoint}`;
    const data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setCryptos(responseJson);
      })
      .catch(error => {
        console.log('Ha habido un error en nuestro sistema');
      });
  };

  useEffect(() => {
    getUser();
    getWallet();
    getCryptos();
    const calculatorObjTmp = new ObjectCalculator(calculatorObserver);
    calculatorObjTmp.convert();
    const interval = setInterval(() => {
      calculatorObjTmp.convert();
    }, 15000);
    return () => {
      clearTimeout(interval);
    };
  }, [currentToken]);
  const [isSmallerThan768] = useMediaQuery('(max-width: 767px)');

  return (
    <>
      <SimpleGrid minChildWidth="320px" spacing="40px">
        {isSmallerThan768 && (
          <Flex style={{ width: '100%', marginBlock: 10 }}>
            <Link
              href={`/app/profile/bodega`}
              style={{ textDecoration: 'none', width: '100%' }}
            >
              <Button
                style={{
                  backgroundColor: '#1A4BEA',
                  color: '#fff',
                  width: '100%',
                }}
                size="md"
              >
                <img
                  src={require('../../../media/icons/bodega_icon.png')}
                  width={35}
                  style={{ paddingRight: 10 }}
                />
                Deposita con efectivo
              </Button>
            </Link>
          </Flex>
        )}
      </SimpleGrid>
      <SimpleGrid minChildWidth="320px" spacing="40px">
        <Box
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'2xl'}
          maxHeight={530}
          rounded={'md'}
          overflow={'hidden'}
          w={{ xl: '125%', md: '100%' }}
          style={{ overflowX: 'scroll', overflowY: 'scroll' }}
          mt={{ base: 10, xl: 0 }}
        >
          <Stack spacing="24px" align={'left'} ml={5} mb={5} mt={5}>
            <Heading fontSize={'16'} fontWeight={500} fontFamily={'body'}>
              Mis activos (11)
            </Heading>
          </Stack>

          <Table style={{ overflowX: 'scroll' }}>
            <Thead>
              <Tr bg={useColorModeValue('gray.100', 'gray.900')}>
                <Th>Moneda</Th>
                <Th>Balance</Th>
                <Th isNumeric>Cantidad</Th>
                <Th>Acción</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td fontWeight={500} style={{ whiteSpace: 'nowrap' }}>
                  {' '}
                  <Image
                    src={require('../../../images/peru.png')}
                    alt="Soles"
                    style={{
                      width: 30,
                      display: 'inline-block',
                      marginRight: 3,
                    }}
                  />{' '}
                  <span style={{ verticalAlign: 'super' }}>Soles Peruanos</span>
                </Td>
                <Td style={{ whiteSpace: 'nowrap' }}>
                  S/ {Number.parseFloat(PENBalance).toFixed(2)}
                </Td>
                <Td isNumeric style={{ whiteSpace: 'nowrap' }}>
                  {Number.parseFloat(PENBalance).toFixed(2)}
                </Td>
                <Td style={{ whiteSpace: 'nowrap' }}>
                  <Button
                    variant="outline"
                    colorScheme="blue"
                    size="xs"
                    onClick={() => history.push('/app/profile/deposit')}
                  >
                    Depositar
                  </Button>{' '}
                  <Button
                    variant="outline"
                    colorScheme="yellow"
                    size="xs"
                    onClick={() => history.push('/app/profile/withdraw')}
                  >
                    Retirar
                  </Button>{' '}
                </Td>
              </Tr>
              
              {/* HNL */}
              <Tr>
                <Td fontWeight={500} style={{ whiteSpace: 'nowrap' }}>
                  {' '}
                  <Image
                    src='https://i.imgur.com/cK9E867.png'
                    alt="Lempiras"
                    style={{
                      width: 30,
                      display: 'inline-block',
                      marginRight: 3,
                    }}
                  />{' '}
                  <span style={{ verticalAlign: 'super' }}>Lempiras Hondureñas</span>
                </Td>
                <Td style={{ whiteSpace: 'nowrap' }}>
                  L/ {Number.parseFloat(HNLBalance).toFixed(2)}
                </Td>
                <Td isNumeric style={{ whiteSpace: 'nowrap' }}>
                  {Number.parseFloat(HNLBalance).toFixed(2)}
                </Td>
                <Td style={{ whiteSpace: 'nowrap' }}>
                  <Button
                    variant="outline"
                    colorScheme="blue"
                    size="xs"
                    onClick={() => history.push('/app/profile/deposit')}
                  >
                    Depositar
                  </Button>{' '}
                  <Button
                    variant="outline"
                    colorScheme="yellow"
                    size="xs"
                    onClick={() => history.push('/app/profile/withdraw')}
                  >
                    Retirar
                  </Button>{' '}
                </Td>
              </Tr>

              <Tr>
                <Td fontWeight={500} style={{ whiteSpace: 'nowrap' }}>
                  {' '}
                  <Image
                    src={require('../../../images/unitedStates.png')}
                    alt="Dólares Americanos"
                    style={{
                      width: 30,
                      display: 'inline-block',
                      marginRight: 3,
                    }}
                  />{' '}
                  <span style={{ verticalAlign: 'super' }}>
                    Dólares Americanos
                  </span>
                </Td>
                <Td style={{ whiteSpace: 'nowrap' }}>
                  $ {Number.parseFloat(USDBalance).toFixed(2)}
                </Td>
                <Td isNumeric style={{ whiteSpace: 'nowrap' }}>
                  {Number.parseFloat(USDBalance).toFixed(2)}
                </Td>
                <Td style={{ whiteSpace: 'nowrap' }}>
                  <Button
                    variant="outline"
                    colorScheme="blue"
                    size="xs"
                    onClick={() => history.push('/app/profile/deposit')}
                  >
                    Depositar
                  </Button>{' '}
                  <Button
                    variant="outline"
                    colorScheme="yellow"
                    size="xs"
                    onClick={() => history.push('/app/profile/withdraw')}
                  >
                    Retirar
                  </Button>{' '}
                </Td>
              </Tr>
              {cryptoPricesArray ? (
                cryptos.map(crypto => {
                  return (
                    <Tr>
                      <Td>
                        <Image
                          src={crypto.image}
                          alt={crypto.name}
                          style={{
                            width: 30,
                            display: 'inline-block',
                            marginRight: 3,
                          }}
                        />{' '}
                        <span style={{ verticalAlign: 'super' }}>
                          {crypto.name}
                        </span>
                      </Td>
                      <Td>
                        ${' '}
                        {Number.parseFloat(
                          (
                            cryptosBalance[crypto.symbol] *
                            cryptoPricesArray[crypto.symbol]?.usdSell
                          ).toFixed(2)
                        )}
                      </Td>
                      {/* <Td isNumeric>
                        {Number.parseFloat(
                          Number.parseFloat(
                            cryptosBalance[crypto.symbol]
                          ).toFixed(DecimalsNum(crypto.symbol))
                        )}
                      </Td> */}
                      <Td isNumeric>
                        { NumToFixed( parseFloat(cryptosBalance[crypto.symbol]), DecimalsNum(crypto.symbol) ) }
                      </Td>
                      <Td>
                        { crypto.allowDeposit ?
                          <Button
                            variant="outline"
                            colorScheme="blue"
                            size="xs"
                            onClick={() => history.push('/app/profile/deposit')}
                          >
                            Depositar
                          </Button>
                        : 
                          <Button
                            variant="outline"
                            colorScheme="dark"
                            size="xs"
                          >
                            Depositar
                          </Button>
                        }
                        { crypto.allowWithdraw ?
                          <Button
                            variant="outline"
                            colorScheme="yellow"
                            size="xs"
                            onClick={() => history.push('/app/profile/withdraw')}
                          >
                            Retirar
                          </Button>
                        : 
                          <Button
                            variant="outline"
                            colorScheme="dark"
                            size="xs"
                          >
                            Retirar
                          </Button>
                        }
                      </Td>
                    </Tr>
                  );
                })
              ) : (
                <div
                  style={{
                    width: '300%',
                    textAlign: 'center',
                  }}
                >
                  <Ring size={45} />
                </div>
              )}

              {/* <Tr>
                <Td fontWeight={500}>
                  {' '}
                  <Image
                    src="https://logowik.com/content/uploads/images/tether-usdt1484.jpg"
                    alt="Tether"
                    style={{
                      width: 30,
                      display: 'inline-block',
                      marginRight: 3,
                    }}
                  />{' '}
                  <span style={{ verticalAlign: 'super' }}>Tether</span>
                </Td>
                <Td>
                  S/{' '}
                  {Number.parseFloat(USDTBalance * USDTValueinPEN).toFixed(2)}
                </Td>
                <Td isNumeric>{Number.parseFloat(USDTBalance).toFixed(2)}</Td>
                <Td>
                  {' '}
                  <Button
                    variant="outline"
                    colorScheme="blue"
                    size="xs"
                    onClick={() => history.push('/app/profile/deposit')}
                  >
                    Depositar
                  </Button>{' '}
                  <Button
                    variant="outline"
                    colorScheme="yellow"
                    size="xs"
                    onClick={() => history.push('/app/profile/withdraw')}
                  >
                    Retirar
                  </Button>{' '}
                </Td>
              </Tr>

              <Tr>
                <Td fontWeight={500}>
                  {' '}
                  <Image
                    src="https://changenow.io/images/cached/usdttrc20.png"
                    alt=">Tron USDT"
                    style={{
                      width: 30,
                      display: 'inline-block',
                      marginRight: 3,
                    }}
                  />{' '}
                  <span style={{ verticalAlign: 'super' }}>Tron USDT</span>
                </Td>
                <Td>
                  S/{' '}
                  {Number.parseFloat(tronUSDTBalance * USDTValueinPEN).toFixed(
                    2
                  )}
                </Td>
                <Td isNumeric>
                  {Number.parseFloat(tronUSDTBalance).toFixed(2)}
                </Td>
                <Td>
                  {' '}
                  <Button
                    variant="outline"
                    colorScheme="blue"
                    size="xs"
                    onClick={() => history.push('/app/profile/deposit')}
                  >
                    Depositar
                  </Button>{' '}
                  <Button
                    variant="outline"
                    colorScheme="yellow"
                    size="xs"
                    onClick={() => history.push('/app/profile/withdraw')}
                  >
                    Retirar
                  </Button>{' '}
                </Td>
              </Tr> */}
            </Tbody>
          </Table>
        </Box>
        {/* <Box
          rounded={'md'}
          overflow={'hidden'}
          style={{
            justifyContent: isSmallerThan1020 ? 'center' : 'flex-end',
            overflow: 'visible',
          }}
          mt={{ base: 10, xl: 0 }}
          display="flex"
        >
          <CalculatorV2 selected="BUY" />
        </Box> */}
        <div className='c-profile-view-wrapper'>
          <div className='c-profile-view-wrapper2'>
          {/* <Calculator selected="sell" /> */}
            <CalculatorV2 selected="BUY" />
          </div>
        </div>

      </SimpleGrid>

      <SimpleGrid minChildWidth="320px" spacing="40px" mt={30}>
        <Box
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'2xl'}
          rounded={'md'}
          overflow={'hidden'}
          b={5}
        >
          <Stack spacing="24px" align={'left'} ml={5} mb={5} mt={5}>
            <Heading fontSize={'16'} fontWeight={500} fontFamily={'body'}>
              Novedades
            </Heading>
          </Stack>
          <Box align={'center'}>
            <a href={`/app/profile/staking`}>
              <SocialMedia style={{width:"auto", margin:"10px"}}>
                {/* <Image src={imgBlog} alt="Blog Image" w={320} mr={10} /> */}
                <Image src='https://i.imgur.com/PinL14x.png' alt="Staking información"  mr={10}  />

                {/* Commented for staking section */}
                {/* <p
                  style={{
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 15,
                    fontWeight: 'bold',
                  }}
                >
                  Staking
                </p>
                <p
                  style={{
                    paddingBottom: 15,
                    paddingLeft: 15,
                    color: 'lightgrey',
                  }}
                >
                  Conoce las novedades en la sección de staking
                </p> */}
              </SocialMedia>
            </a>
          </Box>
        </Box>
        <Box
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'2xl'}
          rounded={'md'}
          overflow={'hidden'}
        >
          <Stack spacing="12px" align={'left'} mb={5} mt={5} pr={10}>
            <CryptoChart />
          </Stack>
        </Box>
        <KasnetModal />
      </SimpleGrid>
    </>
  );
}
