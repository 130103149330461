import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import analyticsCode from 'analyticsCode.js';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import es from 'javascript-time-ago/locale/es';
import ReactPixel from 'react-facebook-pixel';

import { registerLocale } from 'react-datepicker';
import esDP from 'date-fns/locale/es';
registerLocale('es', esDP);

JavascriptTimeAgo.addLocale(en);
JavascriptTimeAgo.addLocale(es);

ReactGA.initialize(analyticsCode);
ReactGA.pageview(window.location.pathname + window.location.search);

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

ReactPixel.init('638564803493448', {}, options);
ReactPixel.pageView(); // For tracking page view
ReactPixel.fbq('track', 'PageView');

toast.configure();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
