const colors = {
  blue_agente_btc : '#1A4BEA',
  yellow_agente_btc: '#FFC85C',
  ligth_yellow_agente_btc: "rgba(255, 200, 92, .4)",
  grey_agente_btc: '#FFC85C',
  dark_blue_agente_btc: '#3C4A5B',
  super_dark_blue_agente_btc: '#272C49',
  soft_grey: '#727D8A'
}

export default colors;
