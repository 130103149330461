import React, { useContext, useState, useEffect } from 'react';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { InfoIcon } from '@chakra-ui/icons';
import ImageUploader from 'react-images-upload';
import ModalImage1 from 'images/finishImage.png';
import { Line } from 'rc-progress';
import { Spinner } from '@chakra-ui/react';
import ReactGA from 'react-ga';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Heading,
  Box,
  Stack,
  Text,
  useColorModeValue,
  Spacer,
  Flex,
  Image,
  useMediaQuery,
  Input,
} from '@chakra-ui/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { AuthContext } from 'Auth';
import http from 'http-common';
import { Clear, DetailsOperation, DetailTitle } from 'styles/Styled';
import { getAllowedBanksList } from 'helpers/deposit.helpers';

const AllowedBanksDropdown = ({ option = 'PEN', onDestinationBankChange }) => {
  const options = getAllowedBanksList(option);

  const onChange = selectedOption => {
    onDestinationBankChange(selectedOption.value);
  };

  return (
    <Select
      options={options}
      isSearchable={true}
      placeholder="Selecciona"
      styles={{
        control: (provided, state) => ({
          ...provided,
          height: 45,
        }),
        placeholder: provided => ({
          ...provided,
          textAlign: 'center',
          width: '100%',
          paddingRight: '10px',
          color: '#A0AEC0',
        }),
        container: (provided, state) => ({
          ...provided,
          width: '196px',
        }),
        indicatorSeparator: (baseStyles, state) => {
          return {
            display: 'none',
          };
        },
        indicatorsContainer: () => ({
          display: 'none',
        }),
        option: (baseStyles, state) => {
          return {
            ...baseStyles,
            backgroundColor: state.isSelected ? '#1A4BEA' : 'white',
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
              backgroundColor: '#1A4BEA',
              color: 'white',
            },
          };
        },
        menu: provided => ({
          ...provided,
          maxHeight: 140,
        }),
        menuList: provided => ({
          ...provided,
          maxHeight: 140,
          overflowY: 'auto',
        }),
      }}
      onChange={onChange}
      className="basic-single"
      classNamePrefix="select"
    />
  );
};

const Deposit = () => {
  const { currentToken } = useContext(AuthContext);
  const banksArray = [
    {
      value: 'Interbank',
      name: 'Interbank',
      image: require('../../../../media/Logo-INTERBANK.png'),
    },
    {
      value: 'Banco_de_Crédito',
      name: 'Banco de Crédito BCP',
      image: require('../../../../media/Logo-BCP.png'),
    },
    // {
    //   value: 'BanBif',
    //   name: 'BanBif',
    //   image: require('../../../../media/Logo-BCP.png'),
    // },
  ];

  /*
    {
      value: 'Tron USDT',
      image: 'https://changenow.io/images/cached/usdttrc20.png',
    },
  */

  /*
    {
      value: 'Tron USDT',
      image: 'https://changenow.io/images/cached/usdttrc20.png',
    },
  */

  let theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      primary: {
        main: '#1A4BEA',
        dark: '#1A4BEA',
        gray: '#f2f2f2',
      },
    },
  });

  // Real and final bank list
  const bankDetailsList = [
    {
      currency: 'PEN',
      name: 'Interbank',
      type: 'Corriente Soles',
      number: '2003004932372',
      cci: '00320000300493237235',
      ruc: '20610602976',
      accountName: 'COMPUTE INFINITUM CORPORATION S.A.C.',
      minDeposit: 20,
    },
    {
      currency: 'PEN',
      name: 'BCP',
      type: 'Corriente Soles',
      number: '1949928430070',
      cci: '00219400992843007096',
      ruc: '20610602976',
      accountName: 'COMPUTE INFINITUM CORPORATION S.A.C.',
      minDeposit: 20,
    },
    {
      currency: 'USD',
      name: 'Interbank',
      type: 'Corriente Dólares',
      number: '2003004932380',
      cci: '00320000300493238038',
      ruc: '20610602976',
      accountName: 'COMPUTE INFINITUM CORPORATION S.A.C.',
      minDeposit: 5,
    },
    {
      currency: 'USD',
      name: 'BCP',
      type: 'Corriente Dólares',
      number: '1949944174110',
      cci: '00219400994417411097',
      ruc: '20610602976',
      accountName: 'COMPUTE INFINITUM CORPORATION S.A.C.',
      minDeposit: 5,
    },
    {
      currency: 'PEN',
      name: 'BanBif',
      type: 'Corriente Soles',
      number: '007000731184',
      cci: '03840310700073118420',
      ruc: '20606053194',
      accountName: 'COMPAÑÍA DE ACTIVOS DIGITALES',
      minDeposit: 20,
    },
    {
      currency: 'USD',
      name: 'BanBif',
      type: 'Corriente Dólares',
      number: '007000730030',
      cci: '03840320700073003025',
      ruc: '20606053194',
      accountName: 'COMPAÑÍA DE ACTIVOS DIGITALES ',
      minDeposit: 5,
    },
    {
      currency: 'HNL',
      name: 'Banco Ficohsa',
      type: 'Corriente Lempiras',
      number: '200017130292',
      cci: 'No aplica',
      ruc: '08019023531166',
      accountName: 'COMPUTE INFINITUM S.A.', // Change to real account name
      minDeposit: 100,
    },
    {
      currency: 'USD',
      name: 'Banco Ficohsa',
      type: 'Corriente Dólares',
      number: '200017130508',
      cci: 'No aplica',
      ruc: '08019023531166',
      accountName: 'COMPUTE INFINITUM S.A.', // Change to real account name
      minDeposit: 5,
    },
    {
      currency: 'HNL',
      name: 'Banco Atlántida',
      type: 'Corriente Lempiras',
      number: '2020166241',
      cci: 'No aplica',
      ruc: '08019023531166',
      accountName: 'COMPUTE INFINITUM S.A.', // Change to real account name
      minDeposit: 100,
    },
    {
      currency: 'USD',
      name: 'Banco Atlántida',
      type: 'Corriente Dólares',
      number: '2020166242',
      cci: 'No aplica',
      ruc: '08019023531166',
      accountName: 'COMPUTE INFINITUM S.A.', // Change to real account name
      minDeposit: 5,
    },
  ];
  // States
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedBank, setSelectedBank] = useState('BanBif'); // Unusable
  const [selectedBankName, setSelectedBankName] = useState('Interbank');
  const [selectedBankDetails, setSelectedBankDetails] = useState(
    bankDetailsList[0]
  );

  const [selectedOption, setSelectedOption] = useState('PEN');

  const [operationNumber, setOperationNumber] = useState();
  const [valueToDeposit, setValueToDeposit] = useState('');
  const [imageFund, setImageFund] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const [cryptos, setCryptos] = useState([]);

  const [checked, setChecked] = React.useState(false);

  const [havePenAccount, setHavePenAccount] = useState(false);
  const [haveHnlAccount, setHaveHnlAccount] = useState(false);
  const [haveUsdAccount, setHaveUsdAccount] = useState(false);
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [bankAccountId, setBankAccountId] = useState('');
  const [bankName, setBankName] = useState('');

  const [hasBankPEN, setHasBankPEN] = useState(false);
  const [hasBankHNL, setHasBankHNL] = useState(false);
  const [hasBankUSD, setHasBankUSD] = useState(false);
  const [hashID, setHashID] = useState('');

  const [loadingButton, setLoadingbutton] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fundId, setFundId] = useState('');
  const [BTCwalletAddress, setBTCwalletAddress] = useState('BTC No Disponible');
  const [USDTwalletAddress, setUSDTwalletAddress] = useState(
    'USDT No Disponible'
  );
  const [tronUSDTwalletAddress, settronUSDTwalletAddress] = useState(
    'Tron USDT No Disponible'
  );
  const [openSend, setOpenSend] = useState(false);
  const [fundCreate, setFundCreate] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState('BEP20');

  const [destinationBank, setDestinationBank] = useState('');

  // For money format based on currency
  let dollarUSLocale = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency:
      selectedOption === 'USD'
        ? 'USD'
        : selectedOption === 'PEN'
        ? 'PEN'
        : 'HNL',
  });
  const [valueToDepositLabel, setValueToDepositLabel] = useState('');
  const [ruc, setRuc] = useState('');

  const [hnlMinDeposit, setHnlMinDeposit] = useState(100);
  const [usdMinDeposit, setUsdMinDeposit] = useState(5);

  const options = [
    // ADD NEW FIAT CURRENCY HERE
    // --------------------------
    {
      value: 'PEN',
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfd8ZgbLagr2z17duiTdfy4NT1xp45vG7u8w&usqp=CAU',
    },
    {
      value: 'HNL',
      image: 'https://i.imgur.com/cK9E867.png',
    },
    {
      value: 'USD',
      image:
        'https://w7.pngwing.com/pngs/666/76/png-transparent-flag-of-the-united-states-national-flag-usa-flag-flag-logo-united-states.png',
    },
    ...cryptos.map(crypto => {
      return {
        value: crypto.symbol,
        image: crypto.image,
      };
    }),
  ];

  const mapedCryptos = {
    BTC: { id: 0 },
    //ALIC: { id: 2 },
    USDT: { id: 1 },
    RBTC: { id: 2 },
    USDC: { id: 3 },
    CKBTC: { id: 4 },
    SOL: { id: 5 },
    ADA: { id: 6 },
    SHIB: { id: 7 },
    MATIC: { id: 8 },
    ETH: { id: 9 },
    DOGE: { id: 10 },
  };

  // Verify money format
  const IsNumeric = num => /^-{0,1}\d*\.{0,1}\d+$/.test(num);

  // Updates total label with currency and limits the decimal to 2 digits
  // Limit amount to 15,000
  // * Can refactorize
  const verifyMoneyFormat = value => {
    if (IsNumeric(value) || value === '') {
      if (value > 15000) {
        swal({
          text: `Los depósitos no pueden ser mayores a ${selectedOption}15,000`,
          button: false,
          timer: 2000,
        });
        return 0;
      }
      if (value.split('.').length - 1 < 2 && value.split('.')[1] < 100) {
        setValueToDeposit(value);
        setValueToDepositLabel(dollarUSLocale.format(value));
      } else if (value.split('.').length - 1 < 1) {
        setValueToDeposit(value);
        setValueToDepositLabel(dollarUSLocale.format(value));
      }
    } else {
      if (value.slice(-1) === '.' && value.split('.').length - 1 < 2) {
        setValueToDeposit(value);
        setValueToDepositLabel(dollarUSLocale.format(value));
      } else {
        swal({
          text: 'Formato inválido',
          button: false,
          timer: 1500,
        });
      }
    }
  };

  const getCryptos = async () => {
    const token = localStorage.getItem('token-agente-btc');
    const endpoint = '/public/cryptos';
    const url = `${agentebtcapi}${endpoint}`;
    const data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setCryptos(responseJson.filter(fc => fc.allowDeposit));
      })
      .catch(error => {
        console.log('Ha habido un error en nuestro sistema');
      });
  };

  // Get user information from API using token
  //    and set RUC variable
  const getUser = () => {
    let data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    currentToken &&
      fetch(url, data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw { type: 'non-user' };
          }
        })
        .then(responseJson => {
          getWallet();
          setRuc(responseJson.ruc);
        })
        .catch(error => {
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });
        });
  };

  // Get Wallets
  const getWallet = () => {
    let data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/wallet';
    let url = `${agentebtcapi}${endpoint}`;
    currentToken &&
      fetch(url, data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw { type: 'non-wallet' };
          }
        })
        .then(responseJson => {
          const hasBTC = responseJson.find(value => value.currency === 'BTC');
          const hasPEN = responseJson.find(value => value.currency === 'PEN');
          const hasHNL = responseJson.find(value => value.currency === 'HNL');
          const hasUSDT = responseJson.find(value => value.currency === 'USDT');
          const hastronUSDT = responseJson.find(
            value => value.currency === 'tronUSDT'
          );
          const hasADA = responseJson.find(value => value.currency === 'ADA');
          const hasUSD = responseJson.find(value => value.currency === 'USD');
          hasBTC ? setBTCwalletAddress(hasBTC.address) : createWallet('BTC');
          hasUSDT
            ? setUSDTwalletAddress(hasUSDT.address)
            : createWallet('USDT');
          // hastronUSDT
          //   ? settronUSDTwalletAddress(hastronUSDT.address)
          //   : createWallet('tronUSDT');
          hasADA
            ? settronUSDTwalletAddress(hastronUSDT.address)
            : createWallet('ADA');
        })
        .catch(error => {
          /*
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });*/
        });
  };

  const getBankAccounts = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/user/bank`;
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(responseJson => {
        let bankAccountsData = responseJson.banks;

        if (responseJson.pen != 0) {
          setHavePenAccount(true);
        }
        if (responseJson.usd != 0) {
          setHaveUsdAccount(true);
        }

        if (responseJson.hnl != 0) {
          setHaveHnlAccount(true);
        }

        // PENDING AFC: Add HNL bank accounts and update states
        if (responseJson.pen != 0 && selectedOption === 'PEN') {
          setHavePenAccount(true);
          bankAccountsData.forEach(function(item) {
            if (item.currency == 'PEN') {
              setBankAccountNumber(item.accountNumber);
              setBankAccountId(item._id);
              setBankName(item.name);
              setHasBankPEN(true);
            }
          });
        }

        if (responseJson.hnl != 0 && selectedOption === 'HNL') {
          setHaveHnlAccount(true);
          bankAccountsData.forEach(function(item) {
            if (item.currency == 'HNL') {
              setBankAccountNumber(item.accountNumber);
              setBankAccountId(item._id);
              setBankName(item.name);
              setHasBankHNL(true);
            }
          });
        }
        if (responseJson.usd != 0 && selectedOption === 'USD') {
          setHaveUsdAccount(true);
          bankAccountsData.forEach(function(item) {
            if (item.currency == 'USD') {
              setBankAccountNumber(item.accountNumber);
              setBankAccountId(item._id);
              setBankName(item.name);
              setHasBankUSD(true);
            }
          });
        }
      });
  };

  const depositCrypto = async () => {
    setIsClicked(true);
    const jsonBody = JSON.stringify({
      hash: hashID,
      currency: selectedOption,
      network: selectedNetwork,
    });

    //AGREGAR VALIDACION CON FEE

    // const encodeFormData = data => {
    //   return Object.keys(data)
    //     .map(
    //       key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
    //     )
    //     .join('&');
    // };

    let data = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: jsonBody,
    };

    let endpoint = `/client/wallet/deposit/crypto-deposit`;
    let url = `${agentebtcapi}${endpoint}`;
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          setIsClicked(false);
          swal({
            text: '¡Envío Exitoso!',
            button: true,
            timer: 7000,
          });
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          return response.json();
        } else {
          setIsClicked(false);
          return response.json();
        }
      })
      .then(responseJson => {
        swal({
          text:
            responseJson.msg ??
            'Ha habido un error en nuestro sistema. Si el error persiste escribenos a Soporte.',
          button: false,
          timer: 5000,
        });
      })
      .catch(error => {
        setIsClicked(false);
        swal({
          text:
            'Ha habido un error en nuestro sistema. Si el error persiste escribenos a Soporte.',
          button: false,
          timer: 5000,
        });
      });
  };

  // Create a transaction
  const createFund = async () => {
    const jsonBody = JSON.stringify({
      amount: valueToDeposit,
      currency: selectedOption,
      bankId: bankAccountId,
      destinationBank: destinationBank,
    });
    setIsClicked(true);

    // Validate amount to deposit
    if (valueToDeposit === '' || valueToDeposit === '0') {
      swal({
        text: `Coloca el monto a abonar`,
        button: true,
        timer: 5000,
      });
      return;
    }

    // Validate amount range from 20 to 15000
    if (valueToDeposit < 5 || valueToDeposit > 15000) {
      swal({
        text: 'El monto a abonar a enviar debe estar entre 5 y 15000.',
        button: true,
        timer: 5000,
      });
      return;
    }
    let data = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: jsonBody,
    };
    let endpoint = '/client/wallet/deposit';
    let url = `${agentebtcapi}${endpoint}`;

    try {
      const response = await fetch(url, data);

      if (!response.ok) {
        const errorData = await response.json();
        console.log('Error parsed:', errorData);
        swal({
          text:
            errorData && errorData?.type === 'wrong-bank'
              ? errorData.msg
              : 'Ha habido un error al crear la solicitud de abono. Intenta más tarde.',
          button: false,
          timer: 5000,
        });
        setIsClicked(false);
        return;
      }

      const responseJson = await response.json();
      setFundId(responseJson.transaction._id);

      if (selectedOption === 'PEN') {
        ReactGA.event({
          category: 'Deposit',
          action: 'Recarga en PEN',
        });
      } else {
        ReactGA.event({
          category: 'Deposit',
          action: 'Recarga en USD',
        });
      }

      confirmFund({ voucherImage: imageFund }, responseJson.transaction._id);
    } catch (error) {
      console.log('Error sent deposit:', error);
      swal({
        text:
          'Ha habido un error al crear la solicitud de abono. Intenta más tarde.',
        button: false,
        timer: 5000,
      });
    } finally {
      setIsClicked(false);
    }
  };

  // Create BTC, USDT or tronUSDT wallet
  const createWallet = createCurrency => {
    const jsonBody = JSON.stringify({
      currency: createCurrency,
    });
    console.log(`Create Currency: ${createCurrency}`);
    let data = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: jsonBody,
    };
    let endpoint = '/client/wallet';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          /*
          swal({
            text: 'Hubo un error al crear wallet. Contacta a Soporte',
            button: false,
            timer: 5000,
          });
          */
        }
      })
      .then(responseJson => {
        console.log(`CREATE ${createCurrency} WALLET`);
        console.log(responseJson);

        if (!responseJson.address) return;
        switch (createCurrency) {
          case 'BTC':
            setBTCwalletAddress(responseJson.wallet.address);
            break;
          case 'USDT':
            setUSDTwalletAddress(responseJson.wallet.address);
            break;
          case 'tronUSDT':
            console.log('TronWallet');
            //settronUSDTwalletAddress(responseJson.wallet.address);
            break;
        }
      })
      .catch(error => {
        //Apply only if tronUSDT and USDT wallet is working...
        /*
        swal({
          text: `Ha habido un error al crear tu ${createCurrency} wallet. Intenta más tarde o contacta a soporte`,
          button: false,
          timer: 5000,
        });
        */
      });
  };

  // Confirm Fund and upload image
  const confirmFund = (newProp, transactionId) => {
    if (!transactionId) return;
    let body = { ...newProp };

    var formData = new FormData();
    for (let key in body) {
      formData.append(key, body[key]);
    }

    setLoadingbutton(true);

    let endpoint = '/client/wallet/deposit/confirm/' + transactionId;

    http
      .put(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(response => {
        if (response.status === 200) {
          setIsClicked(true);
          setProgress(0);
          return response;
        } else {
          setIsClicked(true);
          swal({
            text:
              'Ha habido un error al confirmar tu abono. Notifica a soporte.',
            button: false,
            timer: 5000,
          });
        }
      })
      .then(responseJson => {
        setIsClicked(true);
        setOpenSend(false);
        setFundCreate(false);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      })
      .catch(error => {
        setIsClicked(true);
        swal({
          text: 'Ha habido un error al confirmar tu abono. Intenta más tarde.',
          button: false,
          timer: 5000,
        });
      });
  };

  useEffect(() => {
    getUser();
    getCryptos();
    //getWallet();
    getBankAccounts();
  }, [currentToken]);

  useEffect(() => {
    // Ignore if selectedOption is not FIAT
    if (
      selectedOption !== 'HNL' &&
      selectedOption !== 'USD' &&
      selectedOption !== 'PEN'
    ) {
      return;
    }

    let foundBank = bankDetailsList.find(
      bank => bank.name === selectedBankName && selectedOption === bank.currency
    );
    if (foundBank) {
      setSelectedBankDetails(foundBank);
    } else {
      foundBank = bankDetailsList.find(
        bank => selectedOption === bank.currency
      );
      setSelectedBankDetails(foundBank);
      setSelectedBankName(foundBank.name);
    }
  }, [selectedBankName, selectedOption]);

  useEffect(() => {
    if (selectedOption === 'HNL' && selectedBankName === 'Banco Ficohsa') {
      setHnlMinDeposit(100);
    } else {
      setHnlMinDeposit(100);
    }
  }, [selectedBankName, selectedOption]);

  useEffect(() => {
    if (selectedOption === 'USD' && selectedBankName === 'Banco Ficohsa') {
      setUsdMinDeposit(5);
    } else if (
      selectedOption === 'USD' &&
      selectedBankName === 'Banco Atlántida'
    ) {
      setUsdMinDeposit(5);
    } else {
      setUsdMinDeposit(5);
    }
  }, [selectedBankName, selectedOption]);

  useEffect(() => {
    console.log('isclicked', isClicked);
  }, [isClicked]);

  //////// END MTC

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleNext = () => {
    if (activeStep === 1) {
      if (imageFund) {
        createFund();
      } else {
        swal({
          text: 'Se requiere una foto o captura de voucher.',
          button: false,
          timer: 5000,
        });
      }
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleNextCrypto = () => {
    if (activeStep === 1) {
      if (hashID) {
        depositCrypto();
      } else {
        swal({
          text: 'Se requiere el ID de transacción',
          button: false,
          timer: 5000,
        });
      }
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    window.location.href = '/app/profile/deposit';
  };

  const onImageVoucher = files => {
    setImageFund(files[files.length - 1]);
  };

  const color = useColorModeValue('white', 'gray.800');

  const [isSmallerThan640] = useMediaQuery('(max-width: 640px)');

  const [isSmallerThan980] = useMediaQuery('(max-width: 980px)');

  const onDestinationBankChange = bank => {
    setDestinationBank(prev => bank);
  };

  /* Steps for crypto */
  const stepsCrypto = [
    {
      label: '¿Qué moneda deseas abonar?',
      content: (
        <Box
          style={{ width: isSmallerThan640 ? '100%' : '50%' }}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          {options.map(value => {
            return (
              <Chip
                color={selectedOption === value.value ? 'primary' : 'default'}
                clickable
                onClick={() => {
                  setSelectedOption(value.value);
                  setSelectedNetwork(
                    cryptos[mapedCryptos[value.value]?.id]?.addresses &&
                      cryptos[mapedCryptos[value.value]?.id]?.addresses[0]?.name
                  );
                  setValueToDeposit();
                  setValueToDepositLabel('');
                }}
                avatar={<Avatar src={value.image} />}
                label={value.value}
                style={{ marginInline: 5, marginBottom: 10 }}
              />
            );
          })}
        </Box>
      ),
    },
    {
      label: 'Deposita y confirma tu abono',
      content: (
        <Box style={{ width: isSmallerThan980 ? '70%' : '50%' }}>
          <Text
            fontSize={'12px'}
            mb={8}
            style={{
              borderWidth: 1,
              borderColor: 'lightgrey',
              padding: 10,
              color: 'gray',
            }}
          >
            <Text
              color="#1A4BEA"
              style={{ display: 'inline', fontWeight: 'bold' }}
            >
              Importante:
            </Text>{' '}
            AgenteBTC no se hace responsable de las monedas enviadas a una
            dirección equivocada. Esta es la dirección que debes brindar para
            recibir tus fondos. Después de realizar un depósito, puedes hacer el
            seguimiento del progreso en la página Operaciones.
          </Text>
          {selectedOption !== 'BTC' ? (
            cryptos[mapedCryptos[selectedOption]?.id]?.addresses.map(
              address => {
                return (
                  <>
                    <Text
                      color="#1A4BEA"
                      fontSize={'15px'}
                      fontWeight="bold"
                      mt={10}
                      mb={10}
                    >
                      Dirección de tu billetera {selectedOption} en la red{' '}
                      {address?.name}.
                    </Text>
                    <Flex
                      style={{
                        borderWidth: 1,
                        borderColor: 'lightgrey',
                        borderRadius: 5,
                        alignItems: 'center',
                        padding: 5,
                        display: 'block',
                      }}
                    >
                      <Box
                        p="4"
                        fontSize={isSmallerThan640 ? '8px' : '13px'}
                        fontWeight="bold"
                      >
                        {address?.address}
                      </Box>
                      <Spacer />
                      <Box p="4" fontSize={isSmallerThan640 ? '8px' : '12px'}>
                        <Button
                          style={{
                            fontSize: isSmallerThan640 ? '8px' : '12px',
                          }}
                          variant="contained"
                          onClick={() => {
                            navigator.clipboard.writeText(address?.address);
                          }}
                          size="small"
                        >
                          Copiar
                        </Button>
                      </Box>
                    </Flex>
                  </>
                );
              }
            )
          ) : (
            <>
              <Text
                color="#1A4BEA"
                fontSize={'15px'}
                fontWeight="bold"
                mt={10}
                mb={10}
              >
                Dirección de tu billetera {selectedOption}{' '}
              </Text>
              <Flex
                style={{
                  borderWidth: 1,
                  borderColor: 'lightgrey',
                  borderRadius: 5,
                  alignItems: 'center',
                  padding: 5,
                }}
              >
                <Box
                  p="4"
                  fontSize={isSmallerThan640 ? '8px' : '13px'}
                  fontWeight="bold"
                >
                  {BTCwalletAddress}
                </Box>
                <Spacer />
                <Box p="4" fontSize={isSmallerThan640 ? '8px' : '12px'}>
                  <Button
                    style={{ fontSize: isSmallerThan640 ? '8px' : '12px' }}
                    variant="contained"
                    onClick={() => {
                      navigator.clipboard.writeText(BTCwalletAddress);
                    }}
                    size="small"
                  >
                    Copiar
                  </Button>
                </Box>
              </Flex>
            </>
          )}
          {selectedOption === 'BTC' || selectedOption === 'CKBTC' ? (
            <Flex align="center" mt={15}>
              <InfoIcon color="#F39200" />
              <Box p="4" backgroundColor="#F3920029" fontSize={'12px'} ml={8}>
                {selectedOption === 'BTC'
                  ? 'Estos abonos demoran un promedio de 30 minutos en ser procesados (luego de 3 confirmaciones de la red).'
                  : 'Estos abonos demoran un promedio de 5 minutos en ser procesados y validados en AgenteBTC.'}
              </Box>
            </Flex>
          ) : (
            <>
              <Text
                color="#1A4BEA"
                fontSize={'15px'}
                fontWeight="bold"
                mt={10}
                mb={10}
              >
                ¿Ya realizaste tu depósito?
              </Text>
              <Flex align="center" mt={15} mb={55}>
                <Input
                  placeholder="Ingresa el Hash ID de la transferencia"
                  size="sm"
                  style={{
                    borderWidth: 1,
                    borderColor: 'lightgrey',
                    borderRadius: 5,
                    padding: 10,
                    width: '70%',
                  }}
                  value={hashID}
                  onChange={event => setHashID(event.target.value)}
                />
                <Select
                  value={{
                    value:
                      selectedNetwork !== ''
                        ? selectedNetwork
                        : cryptos[mapedCryptos[selectedOption]?.id]
                            ?.addresses[0].name,
                    label:
                      selectedNetwork !== ''
                        ? selectedNetwork
                        : cryptos[mapedCryptos[selectedOption]?.id]
                            ?.addresses[0].name,
                  }}
                  isSearchable={false}
                  onChange={network => setSelectedNetwork(network.value)}
                  name="crypto"
                  options={cryptos[
                    mapedCryptos[selectedOption]?.id
                  ]?.addresses.map(address => {
                    return { value: address.name, label: address.name };
                  })}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,

                      height: 46,
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      width: '30%',
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                />
              </Flex>
            </>
          )}
        </Box>
      ),
    },
  ];

  /* Steps for fiat */
  const stepsFiat = [
    /* Choosing a fiat */
    {
      label: '¿Qué moneda deseas abonar?',
      content: (
        <Box
          style={{ width: isSmallerThan640 ? '100%' : '50%' }}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          {options.map(value => {
            return (
              <Chip
                color={selectedOption === value.value ? 'primary' : 'default'}
                clickable
                onClick={() => {
                  setSelectedOption(value.value);
                  setValueToDeposit();
                  setValueToDepositLabel('');
                }}
                avatar={<Avatar src={value.image} />}
                label={value.value}
                style={{ marginInline: 5, marginBottom: 10 }}
              />
            );
          })}
        </Box>
      ),
    },
    {
      label: 'Deposita y confirma tu abono',
      content: (
        <Box style={{ width: isSmallerThan640 ? '100%' : '40%' }}>
          <Text mb="8px" mt={3}>
            Monto a abonar ( Mínimo{' '}
            {selectedOption === 'PEN'
              ? 'S/ 20'
              : selectedOption === 'HNL'
              ? `L/ ${hnlMinDeposit}`
              : `$ ${usdMinDeposit}`}
            )
          </Text>
          <Input
            placeholder="0.00"
            size="sm"
            style={{
              borderWidth: 1,
              borderColor: 'lightgrey',
              borderRadius: 5,
              padding: 10,
              marginBottom: 10,
            }}
            value={valueToDeposit}
            onChange={event => verifyMoneyFormat(event.target.value)}
          />
          {/* Modificar texto ?? cual texto ?? */}
          <Text
            fontSize={'12px'}
            style={{
              borderWidth: 1,
              borderColor: 'lightgrey',
              padding: 10,
              color: 'lightgrey',
            }}
          >
            ¡Bien! Ahora un último paso, necesitamos que deposites el monto
            indicado a nuestra cuenta bancaria oficial. No olvides poner tu
            voucher y clicar en el botón para finalizar.
          </Text>

          {selectedOption === 'PEN' && (
            <Box
              style={{ width: isSmallerThan640 ? '100%' : '80%' }}
              flexDirection={{ base: 'column', md: 'row' }}
              mt={10}
            >
              <Chip
                color={selectedBankName === 'Interbank' ? 'primary' : 'default'}
                clickable
                onClick={() => {
                  setSelectedBankName('Interbank');
                }}
                label={'Interbank'}
                avatar={
                  <Avatar
                    src={require('../../../../media/Logo-INTERBANK.png')}
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: 'white',
                      borderRadius: 20,
                    }}
                  />
                }
                style={{ marginInline: 5, marginBottom: 10 }}
              />

              <Chip
                color={selectedBankName === 'BCP' ? 'primary' : 'default'}
                clickable
                onClick={() => {
                  setSelectedBankName('BCP');
                }}
                label={'BCP'}
                avatar={
                  <Avatar
                    src={require('../../../../media/Logo-BCP.png')}
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: 'white',
                      borderRadius: 20,
                    }}
                  />
                }
                style={{ marginInline: 5, marginBottom: 10 }}
              />

              <Chip
                color={selectedBankName === 'BanBif' ? 'primary' : 'default'}
                clickable
                onClick={() => {
                  setSelectedBankName('BanBif');
                }}
                label={'BanBif'}
                avatar={
                  <Avatar
                    src={require('../../../../media/Logo-BanBif.png')}
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: 'white',
                      borderRadius: 20,
                    }}
                  />
                }
                style={{ marginInline: 5, marginBottom: 10 }}
              />
            </Box>
          )}

          {/* HNL */}
          {selectedOption === 'HNL' && (
            <Box
              style={{ width: isSmallerThan640 ? '100%' : '80%' }}
              flexDirection={{ base: 'column', md: 'row' }}
              mt={10}
            >
              <Chip
                color={
                  selectedBankName === 'Banco Ficohsa' ? 'primary' : 'default'
                }
                clickable
                onClick={() => {
                  setSelectedBankName('Banco Ficohsa');
                }}
                label={'Banco Ficohsa'}
                avatar={
                  <Avatar
                    src={require('../../../../media/Ficohsa.png')}
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: 'white',
                      borderRadius: 20,
                    }}
                  />
                }
                style={{ marginInline: 5, marginBottom: 10 }}
              />

              <Chip
                color={
                  selectedBankName === 'Banco Atlántida' ? 'primary' : 'default'
                }
                clickable
                onClick={() => {
                  setSelectedBankName('Banco Atlántida');
                }}
                label={'Banco Atlántida'}
                avatar={
                  <Avatar
                    src={require('../../../../media/Banco_Atlantida.ico')}
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: 'white',
                      borderRadius: 20,
                    }}
                  />
                }
                style={{ marginInline: 5, marginBottom: 10 }}
              />
            </Box>
          )}

          {/* USD */}
          {selectedOption === 'USD' && (
            <Box
              style={{ width: isSmallerThan640 ? '100%' : '80%' }}
              flexDirection={{ base: 'column', md: 'row' }}
              mt={10}
            >
              <Chip
                color={selectedBankName === 'Interbank' ? 'primary' : 'default'}
                clickable
                onClick={() => {
                  setSelectedBankName('Interbank');
                }}
                label={'Interbank'}
                avatar={
                  <Avatar
                    src={require('../../../../media/Logo-INTERBANK.png')}
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: 'white',
                      borderRadius: 20,
                    }}
                  />
                }
                style={{ marginInline: 5, marginBottom: 10 }}
              />

              <Chip
                color={selectedBankName === 'BCP' ? 'primary' : 'default'}
                clickable
                onClick={() => {
                  setSelectedBankName('BCP');
                }}
                label={'BCP'}
                avatar={
                  <Avatar
                    src={require('../../../../media/Logo-BCP.png')}
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: 'white',
                      borderRadius: 20,
                    }}
                  />
                }
                style={{ marginInline: 5, marginBottom: 10 }}
              />

              <Chip
                color={selectedBankName === 'BanBif' ? 'primary' : 'default'}
                clickable
                onClick={() => {
                  setSelectedBankName('BanBif');
                }}
                label={'BanBif'}
                avatar={
                  <Avatar
                    src={require('../../../../media/Logo-BanBif.png')}
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: 'white',
                      borderRadius: 20,
                    }}
                  />
                }
                style={{ marginInline: 5, marginBottom: 10 }}
              />

              {/* HNL */}
              <Chip
                color={
                  selectedBankName === 'Banco Ficohsa' ? 'primary' : 'default'
                }
                clickable
                onClick={() => {
                  setSelectedBankName('Banco Ficohsa');
                }}
                label={'Banco Ficohsa'}
                avatar={
                  <Avatar
                    src={require('../../../../media/Ficohsa.png')}
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: 'white',
                      borderRadius: 20,
                    }}
                  />
                }
                style={{ marginInline: 5, marginBottom: 10 }}
              />

              <Chip
                color={
                  selectedBankName === 'Banco Atlántida' ? 'primary' : 'default'
                }
                clickable
                onClick={() => {
                  setSelectedBankName('Banco Atlántida');
                }}
                label={'Banco Atlántida'}
                avatar={
                  <Avatar
                    src={require('../../../../media/Banco_Atlantida.ico')}
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: 'white',
                      borderRadius: 20,
                    }}
                  />
                }
                style={{ marginInline: 5, marginBottom: 10 }}
              />
            </Box>
          )}
          {/*  TABLE */}
          <Flex backgroundColor="#F6F6F6" mt="10">
            <Box p="4" bg="red.400" fontSize={'12px'}>
              Banco
            </Box>
            <Spacer />
            <Box p="4" bg="green.400" fontSize={'12px'}>
              {selectedBankDetails.name}
            </Box>
          </Flex>
          <Flex>
            <Box p="4" bg="red.400" fontSize={'12px'}>
              Tipo de cuenta
            </Box>
            <Spacer />
            <Box p="4" bg="green.400" fontSize={'12px'}>
              {selectedBankDetails.type}
            </Box>
          </Flex>
          <Flex backgroundColor="#F6F6F6">
            <Box p="4" bg="red.400" fontSize={'12px'}>
              Número de cuenta
            </Box>
            <Spacer />
            <Box p="4" bg="green.400" fontSize={'12px'}>
              {selectedBankDetails.number}
            </Box>
          </Flex>
          <Flex>
            <Box p="4" bg="red.400" fontSize={'12px'}>
              CCI
            </Box>
            <Spacer />
            <Box p="4" bg="green.400" fontSize={'12px'}>
              {selectedBankDetails.cci}
            </Box>
          </Flex>
          <Flex backgroundColor="#F6F6F6">
            <Box p="4" bg="red.400" fontSize={'12px'}>
              Cuenta a nombre de
            </Box>
            <Spacer />
            <Box p="4" bg="green.400" fontSize={'12px'}>
              {selectedBankDetails.accountName}
            </Box>
          </Flex>
          <Flex>
            <Box p="4" bg="red.400" fontSize={'12px'}>
              {selectedBankDetails.accountName === 'COMPUTE INFINITUM S.A.'
                ? 'RTN'
                : 'RUC'}
            </Box>
            <Spacer />
            <Box p="4" bg="green.400" fontSize={'12px'}>
              {selectedBankDetails.ruc}
            </Box>
          </Flex>
          <Flex mb={25} backgroundColor="#F6F6F6">
            <Box p="4" bg="red.400" fontSize={'12px'}>
              Monto a abonar
            </Box>
            <Spacer />
            <Box p="4" bg="green.400" fontSize={'12px'}>
              {valueToDepositLabel ? valueToDepositLabel : '0.00'}
            </Box>
          </Flex>
          {/* End table */}
          <ImageUploader
            withPreview={true}
            withIcon={true}
            buttonText="Elegir foto o captura de voucher"
            onChange={onImageVoucher}
            imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
            maxFileSize={5242880}
            singleImage={true}
            withLabel={false}
          />

          {loadingButton && (
            <>
              <Line percent={progress} strokeWidth="4" strokeColor="#486FEE" />

              <Clear></Clear>

              <DetailsOperation>
                <DetailTitle>Subiendo voucher... {progress}%</DetailTitle>
              </DetailsOperation>
              <Clear></Clear>
            </>
          )}

          <Text mt={25} fontSize={'12px'} fontWeight="bold">
            Indicar Número de Operación de tu banco
          </Text>
          <Text mb={10} fontSize={'12px'}>
            Opcional, verificación más rápida
          </Text>
          <Input
            placeholder="Número de Operación"
            size="sm"
            mb={10}
            style={{
              borderWidth: 1,
              borderColor: 'lightgrey',
              borderRadius: 5,
              padding: 10,
            }}
            value={operationNumber}
            onChange={event => setOperationNumber(event.target.value)}
          />
          <Text mt={10} mb={10} fontSize={'16px'} fontWeight="bold">
            ¿A qué banco depositaste?<sup>*</sup>
          </Text>
          <AllowedBanksDropdown
            option={selectedOption}
            onDestinationBankChange={onDestinationBankChange}
          />
        </Box>
      ),
    },
  ];

  return (
    <Stack align={'center'} mb={5} w={'100%'}>
      <ThemeProvider theme={theme}>
        <Stack align={'center'} mb={5}>
          <Heading fontSize={'24'} fontWeight={'bold'}>
            Depositar
          </Heading>
        </Stack>
        <Box
          bg={useColorModeValue('white', 'gray.800')}
          pb={5}
          mb={10}
          paddingInline={10}
          w="100%"
          align={'center'}
          alignItems="center"
          style={{ overflow: 'visible' }}
        >
          {/* AFC */}
          <Stepper activeStep={activeStep} orientation="vertical">
            {selectedOption === 'PEN' ||
            selectedOption === 'USD' ||
            selectedOption === 'HNL'
              ? stepsFiat.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      {step.content}
                      <Box sx={{ mb: 2 }}>
                        <div>
                          {(selectedOption === 'USD' && haveUsdAccount) ||
                          (selectedOption === 'PEN' && havePenAccount) ||
                          (selectedOption === 'HNL' && haveHnlAccount) ? (
                            <>
                              <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 1, mr: 1 }}
                                disabled={isClicked}
                              >
                                {index === stepsFiat.length - 1 ? (
                                  isClicked ? (
                                    <Spinner size="xl" />
                                  ) : (
                                    'Confirmar depósito'
                                  )
                                ) : (
                                  'Siguiente'
                                )}
                              </Button>
                              <Button
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Regresar
                              </Button>
                            </>
                          ) : (
                            <>
                              <Text
                                backgroundColor="#486FEE12"
                                fontSize={'14px'}
                                mb={10}
                                style={{
                                  width: isSmallerThan640 ? '100%' : '40%',
                                }}
                                mt={15}
                                borderRadius={8}
                              >
                                Necesitas crear una cuenta en {selectedOption}{' '}
                                para continuar
                              </Text>
                              <Button
                                variant="text"
                                size="medium"
                                style={{ color: '#1A4BEA' }}
                                onClick={() =>
                                  (window.location.href =
                                    '/app/profile/configuration')
                                }
                              >
                                + Añadir Cuenta Bancaria
                              </Button>
                            </>
                          )}
                        </div>
                        {index === stepsFiat.length - 1 && (
                          <Text
                            backgroundColor="#486FEE12"
                            fontSize={'10px'}
                            mb={20}
                            style={{ width: isSmallerThan640 ? '100%' : '40%' }}
                            mt={25}
                            borderRadius={8}
                          >
                            Los abonos pueden demorar hasta 1 día hábil en ser
                            procesado y estar disponible en tu cuenta de Agente
                            BTC, de lunes a viernes entre las 9:00 y 18:00
                            horas. (GMT -5)
                          </Text>
                        )}
                      </Box>
                    </StepContent>
                  </Step>
                ))
              : stepsCrypto.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      {step.content}
                      <Box>
                        <div>
                          {index === stepsCrypto.length - 1 ? null : (
                            <Button
                              variant="contained"
                              onClick={() => {
                                getWallet();
                                handleNext();
                              }}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Siguiente
                            </Button>
                          )}
                          {index === stepsCrypto.length - 1 &&
                          selectedOption !== 'BTC' ? (
                            <Button
                              variant="contained"
                              onClick={() => {
                                handleNextCrypto();
                              }}
                              disabled={isClicked}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {isClicked ? (
                                <Spinner size="xl" />
                              ) : (
                                'Confirmar depósito'
                              )}
                            </Button>
                          ) : null}
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Regresar
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
            {activeStep ===
              (selectedOption !== 'BTC'
                ? stepsFiat.length
                : stepsCrypto.length) && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography
                  style={{
                    color: '#1A4BEA',
                    fontSize: 22,
                    fontWeight: 'bold',
                  }}
                >
                  ¡Felicitaciones!
                </Typography>
                <Image
                  src={ModalImage1}
                  alt="Finish Image"
                  w={320}
                  mr={10}
                  pt={20}
                  pb={20}
                />
                <Typography
                  style={{
                    color: '#1A4BEA',
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}
                >
                  Estamos verificando tu abono
                </Typography>
                <Text color="gray">
                  Tu solicitud de abono se encuentra en proceso
                </Text>
                <Button
                  variant="contained"
                  onClick={handleReset}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Está bien
                </Button>
              </Paper>
            )}
          </Stepper>
        </Box>
      </ThemeProvider>
    </Stack>
  );
};

export default Deposit;
