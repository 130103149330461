import styled from 'styled-components';

const StyledButtonBlueExt = styled.a`
  color: #3E5158;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  font-weight: 600;
  margin-left: 20px;
  padding: 5px 25px;
  @media (max-width: 700px) {

    font-size: 12px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 0px;
    justify-content: center;
    border-radius: 35px
    height: 35px;
  }
`;

const NavigationItems = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 700px) {
    display: block;
  }
`;

export { StyledButtonBlueExt, NavigationItems };
