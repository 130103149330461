import React, { useContext, useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import '../styles.css';

let _scrollPosition = 0;

// Input
const GoodInput = ({
    styles, 
    assets, 
    selectedAsset, 
    onSelectedAssetChange, 
    assetsEnabled = false,
    amount, 
    onChangeAmount,
    ignoreAssets = [],
    dropdownListMarginTop = '0px'
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState({
        symbol:'',
        name:'',
        image:'',
        icon:''
    });
    const scrollRef = useRef();

    // Initial load
    useEffect(() => {
        try{
            // Load selected asset
            if( assets.length > 0 && assetsEnabled ){
                loadSelectedAsset(selectedAsset, assets);
            }

            // Set fixed selected asset
            if( !assetsEnabled ){
                setSelected({
                    symbol:selectedAsset,
                    name:selectedAsset,
                    image:'',
                    icon:selectedAsset
                })
            }
        }catch(error){
            console.log("Error loading selected asset: ",error);
        }
    }, [selectedAsset, assets])

    // Useeffect everytime re render
    useEffect(() => {
        if( scrollRef.current ){
            scrollRef.current.scrollTop = _scrollPosition;
        }
    });

    // Load selected asset
    const loadSelectedAsset = (_selectedAsset, _assets) => {
        try{
            // Find selected asset by symbol
            let _selected = _assets.find(asset => asset.symbol === _selectedAsset);
            if( _selected === undefined ){
                throw new Error("Selected asset not found");
            }

            // Update selected asset
            setSelected({
                symbol:_selected.symbol,
                name:_selected.name,
                image:_selected.image,
                icon:_selected.icon ?? _selected.symbol ?? ''
            });

        }catch(error){
            setSelected({
                symbol:'',
                name:'',
                image:'',
                icon:''
            })
        }
    }

    ///////////// Asset info
    const AssetInfo = ({element}) => {
        return (
            <div className='c-select-input-asset-info-container'>
                <div className='c-select-input-asset-info'>
                    <img 
                        src={element.image} 
                        alt={element.name} 
                        className='c-select-input-asset-icon-image'
                    />
                    <div className='c-select-input-asset-symbol'>{element.symbol}</div>

                    <FontAwesomeIcon 
                        icon={isOpen ? faAngleUp: faAngleDown} 
                        className='c-select-input-input-icon-icon'
                    />

                </div>
            </div>
        )
    }


    ///////////// Asset info
    const AssetInfoInside = ({element}) => {
        return (
            <div className='c-select-input-asset-info-container'>
                <div className='c-select-input-asset-info'>
                    <img 
                        src={element.image} 
                        alt={element.name} 
                        className='c-select-input-asset-icon-image'
                    />
                    <div className='c-select-input-asset-symbol'>{element.symbol}</div>
                </div>
            </div>
        )
    }


    // Dropdown List
    const DropdownList = () => {
        if( assets.length === 0 ){
            return (<></>);
        }

        // Get assets but ignore selected
        let _assets = assets.filter(asset => (asset.symbol !== selected.symbol && !ignoreAssets.includes(asset.symbol)));

        // Get marginLeft depending on symbol length
        let marginLeft = '-18px';
        if( selected.symbol.length === 4 ){
            marginLeft = '-7px';
        }
        if( selected.symbol.length === 5 ){
            marginLeft = '0px';
        }

        return (
            <div className='c-select-input-dropdown-list-container-float'
                style={{marginTop:dropdownListMarginTop}}
            >
                <div className='c-select-input-dropdown-list-container'
                    style={{marginLeft:marginLeft}}
                    ref={scrollRef}
                    onScroll={()=>{
                        _scrollPosition = scrollRef.current.scrollTop;
                    }}
                >
                    {_assets.map((element, index) => (
                        <div 
                            key={index}
                            className='c-select-dropdown-element'
                            onClick={()=>{
                                onSelectedAssetChange(element.symbol)
                                setIsOpen(false);
                            }}
                        >
                            <AssetInfoInside element={element} />
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    //// DropDown list
    const InputDropDownList = () => {
        return(
            <>
                <div>
                    <div className='c-input-dropdown-container' 
                        onClick={()=>setIsOpen(!isOpen)}
                    >
                        <div className='c-select-header'>
                            <AssetInfo element={selected} />
                        </div>
                    </div>

                    {isOpen && <DropdownList />}
                </div>
            </>
        )
    }

    if( selected.name === '' ){
        return (<></>);
    }

    return (
        <div style={{width:'100%'}}>
            <div className='c-input-container' style={styles}>
                <div className='c-input-left-col'>
                    <div className='c-input-left-col-label'>
                        Monto ({selected.symbol})
                    </div>
                    <input className='c-input-left-col-input'
                        placeholder='0'
                        type='text'
                        value={amount}
                        onChange={e => onChangeAmount(e.target.value)}
                        autoComplete='off'
                    />
                </div>

                { !assetsEnabled && 
                    <div className='c-input-right-col-currency'
                        style={{marginRight:'1rem'}}
                    >
                        {selected.symbol}
                    </div>
                }

                { assetsEnabled && 
                    <div className='c-input-right-col-currency'>
                        <InputDropDownList
                        
                        />
                    </div>
                }
            </div>

        </div>
    )

}

export default GoodInput;