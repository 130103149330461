import {
  CloseModal,
  CloseModalIcon,
  Modal,
  ModalButtonBoxKasnt,
  ModalButtonKasnt,
  ModalHeaderKasnt,
  ModalHeaderKasnt2,
  ModalImgKasnt,
  ModalImgKasntOne,
  ModalKasnetOne,
  ModalKasnetOneAdvice,
  ModalText,
  ModalWrapper,
  ModalWrapperKasnt,
} from 'styles/Styled';
import React, { useState } from 'react';

import ReactDOM from 'react-dom';

const KasnetModal = () => {
  const [modalShow, setModalShow] = useState(false);
  if (
    typeof localStorage !== 'undefined' &&
    localStorage.getItem('KasnetModal') === null
  ) {
    setModalShow(false);

    localStorage.setItem('KasnetModal', true);
  }


  return (
    modalShow &&
    ReactDOM.createPortal(
      <>
        <ModalWrapper onClick={() => setModalShow(false)}>
          <Modal>
            {/* <ModalImgKasnt
              src={require('../../media/modal/KasnetModalImage.png')}
            />
            <ModalWrapperKasnt> */}
            <ModalImgKasnt src={'https://i.imgur.com/Np5aPcI.png'} />
            <ModalWrapperKasnt>
              <CloseModal onClick={() => setModalShow(false)}>
                <CloseModalIcon>X</CloseModalIcon>
              </CloseModal>
              <div style={{width: "90%", margin: "10px auto"}}>
                {/* Header Normal
              <ModalHeaderKasnt>
                Ahora recarga tu billetera{' '}
                <a style={{ color: '#1A4BEA' }}>con dinero en efectivo</a>
              </ModalHeaderKasnt>
              */}
                {/* Texto para notificar usuarios */}
              <ModalHeaderKasnt2>
                
                  IMPORTANTE
                
              </ModalHeaderKasnt2>
                <div
                  style={{
                    height: 1,
                    backgroundColor: '#C4C4C4',
                  }}
                />
                {/* Texto usual
                <ModalText
                  style={{ fontSize: 12, textAlign: 'left', paddingTop: 10 }}
                >
                  Solo visita cualquiera de los 12mil agentes KasNet y deposita:
                </ModalText>
                
                <ModalText
                  style={{ fontSize: 12, textAlign: 'left', paddingTop: 5 }}
                >
                  - Mencionando el código de empresa{' '}
                  <b>"AGENTEBTC" o "220121" </b>y luego tu código personal
                </ModalText>
                */}

                {/* Texto para cambio de wallet */}
                <ModalText
                  style={{ fontSize: 12, textAlign: 'left', paddingTop: 10 }}
                >
                  <span style={{color: "#2743EA"}}>
                    La dirección de su billetera (wallet), ha sido reemplazada
                    por una nueva.&nbsp;
                  </span>
                  Esta actualización se ha aplicado a las siguientes criptomonedas:
                </ModalText>

                <div>
                  <img src='https://i.imgur.com/PAiXxTw.png'/>
                </div>

                <ModalText
                  style={{ fontSize: 12, textAlign: 'left', paddingTop: 5 }}
                >
                  Tome en consideración lo anterior para sus futuras
                  operaciones, en caso desee recibir criptomonedas,&nbsp;
                  <b>
                    deberá previamente corroborar que sea a su actual billetera. 
                  </b>
                </ModalText>
              </div>

              {/* Botón usual */}
              {/* <ModalButtonKasnt>
                <ModalButtonBoxKasnt
                  style={{ width: '100%' }}
                  onClick={() => setModalShow(false)}
                >
                  <a href={'/app/profile/bodega'}>Ir a mi código personal</a>
                </ModalButtonBoxKasnt>
              </ModalButtonKasnt> */}
            </ModalWrapperKasnt>
          </Modal>
        </ModalWrapper>
      </>,
      document.querySelector('#modal')
    )
  );
};


const KasnetModal3 = () => {
  const [modalShow, setModalShow] = useState(false);
  if (
      typeof localStorage !== "undefined" &&
      localStorage.getItem("KasnetModal") === null
  ) {
      setModalShow(true);

      localStorage.setItem("KasnetModal", true);
  }
  return (
      modalShow &&
      ReactDOM.createPortal(
          <ModalWrapper onClick={() => setModalShow(false)}>
              <ModalKasnetOne>
                  <ModalImgKasntOne
                      src={require("../../media/modal/AlintiBanner.jpg")}
                  />
              </ModalKasnetOne>
          </ModalWrapper>,
          document.querySelector("#modal")
      )
  );
};

const KasnetModalAdvice = () => {
  const [modalShow, setModalShow] = useState(false);
  if (
      typeof localStorage !== "undefined" &&
      localStorage.getItem("KasnetModal") === null
  ) {
      setModalShow(true);

      localStorage.setItem("KasnetModal", true);
  }
  return (
      modalShow &&
      ReactDOM.createPortal(
          <ModalWrapper onClick={() => setModalShow(false)}>
              <ModalKasnetOneAdvice>
                  <b>Aviso: Mantenimiento programado</b> <br/>
                  Se comunica que habrá mantenimiento de nuestros servidores programado para los días
                  1 y 2 de Noviembre. Durante estos días no se gestionarán operaciones 
                  ni se atenderán consultas. <br/><br/>

                  Agradecemos su comprensión.
              </ModalKasnetOneAdvice>
          </ModalWrapper>,
          document.querySelector("#modal")
      )
  );
};

export default KasnetModal;
