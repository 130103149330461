import React, { useEffect, useContext, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Flex,
  Box,
  Stack,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthContext } from 'Auth';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import { Ring } from 'react-awesome-spinners';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';

import {
  TitleModal,
  BodyModal,
  FooterModal,
  BtnPrimaryLg,
  DepositaContent,
  UbicaAgenteContainer,
  UbicaAgenteVideo,
  DepositTitle,
  ResponsiveIframe,
} from 'styles/Styled';

const Bodega = ({ history }) => {
  const routeChange = () => {
    let path = `/app/profile/validacion`;
    history.push(path);
    setOpenModal(false);
  };
  const { currentToken } = useContext(AuthContext);
  const [user, setUser] = useState('');
  const [openModal, setOpenModal] = useState(false);

  let theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      primary: {
        main: '#1A4BEA',
        dark: '#1A4BEA',
        light: '01fff0',
      },
    },
  });

  const color = useColorModeValue('white', 'gray.800');

  const getUser = () => {
    let data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    currentToken &&
      fetch(url, data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw { type: 'non-user' };
          }
        })
        .then(responseJson => {
          setUser(responseJson);
          if (!responseJson.dni) {
            setOpenModal(true);
          }
        })
        .catch(error => {
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });
        });
  };

  const [isSmallerThan1080] = useMediaQuery('(max-width: 1080px)');
  const [isSmallerThan768] = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Stack align={isSmallerThan768 ? 'center' : 'left'} mb={5} w={'100%'}>
      <ThemeProvider theme={theme}>
        {/* Top section */}
        <Box
          bg={useColorModeValue('#1552F00D', 'gray.800')}
          pb={5}
          mb={10}
          paddingInline={10}
          mt={15}
          w="100%"
          align={'center'}
          alignItems="center"
          style={{ overflow: 'visible', borderRadius: 20 }}
        >
          <Stack
            align={isSmallerThan768 ? 'center' : 'left'}
            mb={5}
            align={isSmallerThan768 ? 'center' : 'left'}
          >
            <div
              style={{
                display: 'flex',
                paddingLeft: 20,
                paddingTop: 15,
              }}
            >
              <img
                src={require('../../../../media/icons/bodega_icon_page.png')}
                width={35}
                style={{ paddingRight: 10 }}
              />
              <p style={{ fontWeight: 'bold', color: '#6B6F7F' }}>
                Deposita con efectivo
              </p>
            </div>
            <div
              style={{
                paddingLeft: 20,
                width: '100%',
                paddingBottom: 5,
                textAlign: 'left',
              }}
            >
              <DepositTitle>
                Haz tu "PAGO / PAGO PARCIAL" en 15 mil agentes KasNet. Ubícanos
                con el código "220121", Abajo las instrucciones.{' '}
                  <img
                    src="https://i.imgur.com/ktNcps9.png"
                    style={{ height: '40px', display: 'inline-block' }}
                  />
              </DepositTitle>
              {/* <p style={{ color: '#6B6F7F' }}>
                Ahora puedes recargar tu cuenta de AgenteBTC, a través de las
                distintas surcusales de KasNet.
              </p> */}
            </div>
            {/* {!isSmallerThan768 && (
              <img
                width={isSmallerThan1080 ? 320 : 350}
                style={{
                  position: 'absolute',
                  right: isSmallerThan1080 ? '2%' : '6%',
                  top: isSmallerThan768 ? '10%' : '15%',
                }}
                src={require('../../../../media/icons/bodega_page_home.png')}
              />
            )} */}
          </Stack>
        </Box>

        {/* Middle section */}
        <DepositaContent
          style={{ display: 'flex', justifyContent: 'space-around' }}
        >
          {/* Left side */}
          <Box
            bg={useColorModeValue('white', 'gray.800')}
            paddingInline={25}
            w={isSmallerThan768 ? '100%' : '55%'}
            align={isSmallerThan768 ? 'center' : 'left'}
            alignItems="left"
            style={{ overflow: 'visible', borderRadius: 20 }}
            maxWidth="620px"
          >
            <Stack
              align={isSmallerThan768 ? 'center' : 'left'}
              mb={5}
              textAlign={isSmallerThan768 ? 'center' : 'left'}
            >
              {/* <div
              style={{
                display: 'flex',
                paddingTop: 20,
                paddingBottom: 5,
              }}
            >
              <img
                src={require('../../../../media/icons/pen_deposit.png')}
                width={45}
                style={{ paddingRight: 10 }}
              />
              <p style={{ fontWeight: 'bold', color: '#6B6F7F' }}>
                Recargar <span style={{ color: '#215DF1' }}>soles</span> en
                centro KasNet
              </p>
            </div> */}
              <div
                style={{
                  paddingTop: 20,
                  borderBlockColor: '#E6E6E6',
                  width: '100%',
                  borderBlockWidth: 1,
                  display: 'flex',
                }}
              >
                <div style={{ width: '100%', paddingBottom: 20 }}>
                  <p
                    style={{
                      color: '#6B6F7F',
                      fontSize: 20,
                      fontWeight: '700',
                    }}
                  >
                    Dicta tu número de DNI para hacer el pago, tu DNI es tu código.
                  </p>
                  <Box
                    bg={useColorModeValue('#1552F0', 'gray.800')}
                    paddingBlock={10}
                    mb={10}
                    mt={5}
                    paddingInline={25}
                    w={isSmallerThan768 ? '100%' : '70%'}
                    align={'center'}
                    alignItems="center"
                    style={{ overflow: 'visible', borderRadius: 20 }}
                  >
                    <p
                      style={{
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: 24,
                      }}
                    >
                      {user.dni ? (
                        user.dni
                      ) : (
                        <div style={{ height: 30 }}>
                          <Ring size={28} />
                        </div>
                      )}
                    </p>
                  </Box>
                </div>
                {/* {!isSmallerThan768 && (
                <img
                  width={140}
                  style={{ marginRight: 20 }}
                  src={require('../../../../media/icons/bodega_section_icon.png')}
                />
              )} */}
              </div>
              {/* {isSmallerThan768 && (
                <img
                  width={270}
                  src={require('../../../../media/icons/bodega_page_home.png')}
                />
              )} */}
            </Stack>
            <div style={{ paddingTop: 15 }}>
              <p
                style={{
                  color: '#3E5158',
                  fontWeight: '700',
                  paddingBottom: 18,
                  fontSize: 18,
                }}
              >
                Para recargar en agencias Kasnet:
              </p>
              <p
                style={{
                  color: '#565e619',
                  fontWeight: '600',
                  textAlign: 'left',
                  paddingBottom: 15,
                }}
              >
                1. Acércate a cualquier centro KasNet.
                <br />
                <span style={{ paddingLeft: 15, color:'#999999', fontSize:'12px' }}>
                  Ejemplo: bodegas, casa de apuestas, farmacias, etc.{' '}
                </span>
              </p>
              <p
                style={{
                  color: '#565e619',
                  fontWeight: '600',
                  textAlign: 'left',
                  paddingBottom: 15,
                }}
              >
                2. Indica que deseas hacer un PAGO PARCIAL al código de recaudo
                "220121" o menciona "AGENTE BTC".
              </p>
              <p
                style={{
                  color: '#565e619',
                  fontWeight: '600',
                  textAlign: 'left',
                  paddingBottom: 15,
                }}
              >
                3. Con tu número de DNI podrás recargar tu billetera.
              </p>
              <p
                style={{
                  color: '#565e619',
                  fontWeight: '600',
                  textAlign: 'left',
                  paddingBottom: 15,
                }}
              >
                4. El monto mínimo de recarga es de 20 soles hasta los 500 soles
                por transacción. (Si el bodeguero te dice que no puedes recargar desde 20 soles, tú le dices que sí puedes).
              </p>
              
              <p
                style={{
                  color: '#565e619',
                  fontWeight: '600',
                  textAlign: 'left',
                  paddingBottom: 15,
                }}
              >
                5. Si no te permiten hacer la recarga comunícate al <a href="https://api.whatsapp.com/send?phone=51975183710" style={{color: 'blue', textDecoration: 'underline'}} >975 183 710</a>, envíanos una foto y la ubicación por el WhatsApp.
              </p>
            </div>
            <Flex
              align="center"
              mt={10}
              mb={20}
              justify={isSmallerThan768 ? 'center' : 'space-between'}
            >
              <Flex
                align="center"
                mt={15}
                display={isSmallerThan768 ? 'block' : 'flex'}
              >
                <InfoIcon color="#F39200" />
                <Box
                  style={{
                    padding: 5,
                    backgroundColor: '#F3920029',
                    fontSize: 11,
                  }}
                  ml={10}
                >
                  Tu recarga se verá reflejada en tu saldo de soles terminada la
                  validación Nivel 1.
                </Box>
              </Flex>
              {/* {!isSmallerThan768 && (
              <img
                width={150}
                style={{ marginRight: 10 }}
                src={require('../../../../media/icons/kasnet_icon.png')}
              />
            )} */}
            </Flex>
          </Box>

          {/* Right side */}
          <UbicaAgenteContainer>
            <div>
              <p style={{ marginTop: '30px', marginBottom: '20px', color:'#6B6F7F'}}>
                Ubica tu agente KasNet más cercano a ti
                <br />
                ingresando a{' '}
                <a
                  href="https://ubicatuagente.agentekasnet.com"
                  style={{ color: 'blue', textDecoration: 'underline' }}
                >
                  este enlace
                </a>
                .
              </p>
            </div>
            <UbicaAgenteVideo>
              <ResponsiveIframe
                src="https://www.youtube.com/embed/4r8tq6KWbo0?si=OshDb3OxD5Y-EuNv"
                title="Encuentra tu agente KasNet más cercano"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></ResponsiveIframe>
            </UbicaAgenteVideo>
          </UbicaAgenteContainer>
        </DepositaContent>
      </ThemeProvider>
      <Modal
        open={openModal}
        center
        closeOnOverlayClick={false}
        showCloseIcon={false}
      >
        <BodyModal>
          <TitleModal>Validación Requerida</TitleModal>

          <FooterModal>
            Necesitas validar tu cuenta para poder realizar depósitos en Centros
            de Pagos y Ventas KasNet
          </FooterModal>

          <BtnPrimaryLg style={{ borderRadius: 20 }} onClick={routeChange}>
            Validar Ahora
          </BtnPrimaryLg>
        </BodyModal>
      </Modal>
    </Stack>
  );
};

export default Bodega;
