import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from 'styles/common/colors';
import { css } from 'styled-components';
import Select from 'react-select';

export const CurrentItemCSS = css`
  color: ${colors.blue_agente_btc} !important;
  border-left: 3px solid ${colors.blue_agente_btc} !important;
  font-weight: bold;
`;

export const ProfileWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 15vh;
  background: linear-gradient(
    180deg,
    rgba(236, 243, 248, 1) 0%,
    rgba(236, 243, 248, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const SocialMedia = styled.div`
  text-align: left;
  width: 320px;
  margin-bottom: 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 6px 9px 6px rgba(0, 0, 0, 0.1);
`;

export const TabSelector = styled(Select)`
  max-width: 100%;
  cursor: pointer;
  margin: 10px;
  border: none;
  box-sizing: border-box;
  color: ${colors.dark_blue_agente_btc};
  outline: none !important;
  text-decoration: none;
  font-family: Montserrat, sant-serif;
  font-size: 18px;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const ContainerProfileData = styled.div`
  max-width: 100%;
  width: 1200px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
`;

export const MenuDashboard = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    display: none;
  }
`;

export const ItemDashboard = styled(Link)`
  width: 100%;
  min-width: 170px;
  cursor: pointer;
  padding: 12px;
  margin: 2px 0px;
  border: none;
  box-sizing: border-box;
  border-left: 3px solid transparent;
  color: ${colors.dark_blue_agente_btc};
  outline: none;
  opacity: 0.8;
  text-decoration: none;
  font-family: Montserrat, sant-serif;
  font-size: 18px;

  ${props => props.ActiveCSS}
  &:hover {
    background: rgba(44, 44, 44, 0.1);
    color: ${colors.dark_blue_agente_btc};
    border-left: 3px solid rgba(44, 44, 44, 0.5);
  }
`;

export const Dashboard = styled.div`
  max-width: 1100px;
  width: 95%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: white;
  margin: 20px 0px 50px 0px;
  box-shadow: 0 6px 9px 6px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Sidebar = styled.div`
  flex: 0 1 19%;
  border-right: 1px solid rgba(44, 44, 44, 0.2);
  @media screen and (max-width: 768px) {
    margin-top: 0px;
    border: none;
  }
`;

export default {
  ProfileWrapper,
  ContainerProfileData,
  Dashboard,
  Sidebar,
  MenuDashboard,
  ItemDashboard,
  TabSelector,
};
