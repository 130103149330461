import styled from 'styled-components';
import colors from './colors';
import Select from 'react-select';
import { HashLink } from 'react-router-hash-link';

export const Header1 = styled.h1`
  font-family: 'Poppins', sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 26x;
  font-weight: 600;
  text-align: center;
`;

export const Header2 = styled.h2`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;

export const Header3 = styled.h3`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 25px;
  margin: 0;
  font-weight: 600;
`;

export const Header4 = styled.h4`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  line-height: 2.2;
`;

export const Header5 = styled.h5`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 14px;
  margin: 0;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;

export const TitleSection = styled.h1`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 25px;
  font-weight: 700;
`;

export const ParagraphSubtitle = styled.p`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 25px;
  font-weight: 300;
`;

export const WarningBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 13px;
  border-radius: 4px;
  background-color: #ffeece;
  margin-top: 20px;
  border: 1px solid #ffc85c;
  left: 0;
  right: 0;
  text-align: center;
  color: #3c4a5b;
`;

export const BtnBlueSm = styled(HashLink)`
  padding: 12px;
  background-color: #1a4bea;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  width: 120px;
  display: inline;
  cursor: pointer;
  margin: 0px 10px;
  text-decoration: none;
  font-weight: 600;
`;

export const Input = styled.input`
  position: relative;
  height: 30px;
  max-width: 400px;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  text-align: left;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #3c4a5b;
  box-sizing: border-box;
  text-align: left;
  padding-left: 10px;
  font-weight: 400;
  display: flex;
  outline: none;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TextData = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  text-align: left;
  font-family: Montserrat;
  font-size: 14px;
  box-sizing: border-box;
  font-weight: 400;
  display: flex;
  outline: none;
  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ContainerLevels = styled.div`
  display: flex;
`;

export const LevelBox = styled.div`
  flex-basis: 100%;
  min-height: 200px;
  padding: 15px;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
  margin: 35px;
  color: #3c4a5b;
  text-align: center;
  @media screen and (max-width: 768px) {
    margin: 5px;
  }
`;

export const LevelBoxTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  padding: 15px 0px;
  text-align: center;
`;

export const LevelBoxDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  padding: 15px 0px;
  text-align: left;
`;

export const LevelBoxList = styled.ul`
  padding-left: 5px;
`;

export const BtnYellowSm = styled(HashLink)`
  padding: 12px;
  background-color: #1a4bea;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  white-space: nowrap;
  width: 120px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
`;

export const Selector = styled(Select)`
  width: 100%;
  font-family: Montserrat;
  font-size: 13px;
  border: none;
  font-weight: 400;
  outline: none;
  background-color: '#fff';
`;

export const SelectorFull = styled(Selector)`
  width: 100%;
`;

// Alternatives

export const Header1White = styled(Header1)`
  color: #ffffff;
`;

export const ParagraphSubtitleWhite = styled(ParagraphSubtitle)`
  color: #ffffff;
`;
