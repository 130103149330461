import React, { useState, useEffect } from "react";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
import { Roller } from "react-awesome-spinners";
// Styled components
import {
  Wrapper,
  Space,
  Heading2,
  BoxBitcoin,
  LoaderWrapper
} from "styles/Styled";
// Get the USD to PEN rate
import { ExchangeProvider } from "ExchangeContext";

import { DateProvider } from "DateContext";
import Calculator from "components/Calculator/index.js";

import Styled from './styles';

const NewOperation = ({ location, history }) => {
  
  const [selectedTab, setSelectedTab] = useState('buy');

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {

    if(location.pathname === `/app/operation/new/sale`){
      setSelectedTab('sale');
    }
    
    scrollTop();
  }, []);

  

  return (
    <ExchangeProvider>
      <DateProvider>
        <Styled.NewOperationWrapper>
          {/* <Calculator selected={selectedTab} className="Calculator" /> */}
        </Styled.NewOperationWrapper>
      </DateProvider>
    </ExchangeProvider>
  );
};

export default withRouter(NewOperation);
