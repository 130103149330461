/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from 'react';
import { StyledButtonBlueExt, NavigationItems } from './style';
import './styles.css';
import agentebtcapi from 'agentebtcapi';
import { CSSTransition } from 'react-transition-group';
import { FiMenu } from 'react-icons/fi';
import { withRouter } from 'react-router';

const Header = ({ location }) => {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 700px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <header className="Header">
      <img src={require('./new_logo.png')} className="Logo" alt="logo" />
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav className="Nav">
          <NavigationItems>
            <a style={{ color: 'black' }}>
              {location.pathname === `/auth/login`
                ? '¿Aún no estás registrado?'
                : '¿Ya tienes una cuenta?'}
            </a>
            <StyledButtonBlueExt
              style={{
                fontStyle: 'normal',
                color: '#3E5158',
                background: `linear-gradient(#FFC500,#F7931E)`,
              }}
              href={
                location.pathname === `/auth/login`
                  ? '/auth/register'
                  : '/auth/login'
              }
            >
              {location.pathname === `/auth/login`
                ? 'Crear cuenta gratis'
                : 'Iniciar Sesión'}
            </StyledButtonBlueExt>
          </NavigationItems>
        </nav>
      </CSSTransition>
      <button onClick={toggleNav} className="Burger">
        <FiMenu />
      </button>
    </header>
  );
};

export default withRouter(Header);
