import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from 'styles/common/colors';

export const TrackingWrapper = styled.div`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 15vh;
  background: linear-gradient(180deg, rgba(236,243,248,1) 0%, rgba(236,243,248,1) 50%, rgba(255,255,255,0) 100%);
  margin-bottom: 100px;
`;

export const ButtonLink = styled(Link)`
  width: 390px;
  max-width: 95%;
  height: 45px;
  border-radius: 5px;
  background-color: ${colors.yellow_agente_btc};
  outline: none;
  border-color: none;
  border: 0px;
  font-weight: 600;
  cursor: pointer;
  color: ${colors.dark_blue_agente_btc};
  text-decoration: none;
  display:flex;
  align-items: center;
  justify-content: center;
`;


export default {
  TrackingWrapper,
  ButtonLink,
}
