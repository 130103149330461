import React, { useContext, useState, useEffect } from 'react';
import ObjectCalculator from '../../../../components/object-calculator/ObjectCalculator';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Visor, EtiquetaPrecioFiat, CryptoIcon } from './styles';
import { StyledSelect } from 'styles/Styled';
import DecimalsNum from 'helpers/decimals';

import { SimpleGrid } from '@chakra-ui/react';

import { Heading, Image, Button } from '@chakra-ui/react';

import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

import { Box, useColorModeValue } from '@chakra-ui/react';

import { AuthContext } from 'Auth';
import { Ring } from 'react-awesome-spinners';

export default function SidebarWithHeader({ history }) {
  const { currentToken } = useContext(AuthContext);

  const [menuFiat, setMenuFiat] = useState(false);

  const [cryptosBalance, setCryptosBalance] = useState({
    BTC: '0.0000',
    RBTC: '0.0000',
    USDC: '0.0000',
    USDT: '0.0000',
    ADA: '0.0000',
    ALIC: '0.0000',
    DOGE: '0.0000',
    MATIC: '0.0000',
    ETH: '0.0000',
    SHIB: '0.0000',
    SOL: '0.0000',
  });

  const [cryptos, setCryptos] = useState([]);
  const [PENBalance, setPENBalance] = useState('0.00');
  const [USDBalance, setUSDBalance] = useState('0.00');
  const [HNLBalance, setHNLBalance] = useState('0.00');
  const [usdtValueinPEN, setUSDTValue] = useState(0);
  const [usdtValueinHNL, setUSDTValueinHNL] = useState(0);
  const [cryptoPricesArray, setCryptoPricesArray] = useState(0);

  const [currency, setCurrency] = useState('PEN');

  const calculatorObserver = data => {
    setUSDTValue(data.rates.USDPENBuy);
    setUSDTValueinHNL(data.rates.USDHNLBuy);
    console.log("Rates: , ",data.rates)
    setCryptoPricesArray(data.cryptoPrices);
  };

  const getUser = () => {
    let data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    currentToken &&
      fetch(url, data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw { type: 'non-user' };
          }
        })
        .then(responseJson => {})
        .catch(error => {
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });
        });
  };

  const getWallet = async () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/wallet';
    let url = `${agentebtcapi}${endpoint}`;
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        console.log(responseJson);

        responseJson.forEach(function(item) {
          if (item.currency == 'BTC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { BTC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'PEN') {
            if (item.balance) {
              setPENBalance(item.balance.toFixed(2));
            }
          }

          if (item.currency == 'HNL') {
            if (item.balance) {
              setHNLBalance(item.balance.toFixed(2));
            }
          }

          if (item.currency == 'USD') {
            if (item.balance) {
              setUSDBalance(item.balance.toFixed(2));
            }
          }

          if (item.currency == 'USDT') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { USDT: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'ADA') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { ADA: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }


          if (item.currency == 'RBTC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { RBTC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'USDC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { USDC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'DOGE') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { DOGE: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'ALIC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { ALIC : item.balance.toFixed(4) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'ETH') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { ETH: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'MATIC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { MATIC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'SHIB') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { SHIB: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'SOL') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { SOL: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }
        });
      })
      .catch(error => {
        //createWallet();
        //swal({text: "Estamos creando tu billetera de Bitcoins, en unos segundos la verás... :)", button: false, timer: 3000});
      });
  };

  const getCryptos = async () => {
    const token = localStorage.getItem('token-agente-btc');
    const endpoint = '/public/cryptos';
    const url = `${agentebtcapi}${endpoint}`;
    const data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setCryptos(responseJson);
      })
      .catch(error => {
        console.log('Ha habido un error en nuestro sistema');
      });
  };

  const handleSelectCurrency = option => {
    setCurrency(option.value);
  };

  const CurrencyOptionsObject = {
    USD: {
      value: 'usd',
      label: 'USD',
      icon: '../../../../images/unitedStates.png',
    },
    PEN: { value: 'pen', label: 'PEN', icon: '../../../../images/peru.png' },
    HNL: { value: 'hnl', label: 'HNL', icon: '../../../../images/honduras.png' },
  };

  const toggleMenuFiat = option => {
    setMenuFiat(!menuFiat);
  };

  useEffect(() => {
    getUser();
    getWallet();
    getCryptos();
    const calculatorObjTmp = new ObjectCalculator(calculatorObserver);
    calculatorObjTmp.convert();
    const interval = setInterval(() => {
      calculatorObjTmp.convert();
    }, 15000);
    return () => {
      clearTimeout(interval);
    };
  }, [currentToken]);

  return (
    <>
      <SimpleGrid spacing="40px">
        <Box
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'2xl'}
          rounded={'md'}
          overflow={'hidden'}
          w={'100%'}
          style={{ overflowX: 'scroll' }}
          mt={10}
          mr={15}
        >
          <Box
            spacing="24px"
            flexDirection={{ base: 'column', md: 'row' }}
            align={'left'}
            justifyContent={'space-between'}
            ml={5}
            display="flex"
          >
            <Box
              spacing="24px"
              align={'left'}
              ml={5}
              flex={1}
              mb={5}
              mt={5}
              alignSelf={'center'}
            >
              <Heading fontSize={'16'} fontWeight={500} fontFamily={'body'}>
                Tus billeteras
              </Heading>
              <p style={{ fontSize: 10, paddingTop: 5 }}>
                El balance en{' '}
                {CurrencyOptionsObject[currency].label == 'USD' ? 'USD' : 'PEN'}{' '}
                corresponde al
                <br /> precio de venta en AgenteBTC
              </p>
            </Box>
            <Box
              align={{ base: 'center', md: 'right' }}
              mb={5}
              flex={1}
              mt={5}
              display={'flex'}
              alignItems="center"
            >
              <Heading
                fontSize={'16'}
                fontWeight={500}
                fontFamily={'body'}
                pr={15}
              >
                Tipo de moneda
              </Heading>
              <Visor>
                <EtiquetaPrecioFiat onClick={toggleMenuFiat}>
                  <div
                    style={{
                      paddingLeft: 20,
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                      }}
                    >
                      <CryptoIcon
                        src={require(CurrencyOptionsObject[currency].label === 'USD' ?
                          '../../../../images/unitedStates.png'
                          : CurrencyOptionsObject[currency].label === 'PEN' ? 
                          '../../../../images/peru.png'
                          : '../../../../images/honduras.png'
                          )}
                      />
                    </div>
                    <i
                      style={{
                        display: 'inline',
                        paddingLeft: 40,
                        color: 'black',
                        fontSize: 18,
                      }}
                    >
                      {CurrencyOptionsObject[currency].label === 'USD' ? 'Dólares'
                        : CurrencyOptionsObject[currency].label === 'PEN' ? 'Soles' : 'Lempiras'}
                    </i>
                    <i
                      style={{
                        display: 'inline',
                        paddingLeft: 10,
                        fontSize: 18,
                      }}
                    >
                      {CurrencyOptionsObject[currency].label == 'USD' ? '$'
                        : CurrencyOptionsObject[currency].label == 'PEN' ? '/S': 'L/'}
                    </i>
                  </div>
                  {menuFiat ? (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      style={{
                        position: 'absolute',
                        right: 16,
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      style={{
                        position: 'absolute',
                        right: 16,
                      }}
                    />
                  )}

                  <StyledSelect
                    value={{
                      value: CurrencyOptionsObject[currency].value,
                      label: CurrencyOptionsObject[currency].label,
                    }}
                    onChange={handleSelectCurrency}
                    styles={{
                      control: styles => ({
                        ...styles,
                        display: 'none',
                      }),
                    }}
                    menuIsOpen={menuFiat}
                    options={[
                      {
                        value: 'PEN',
                        label: (
                          <div
                            style={{
                              paddingLeft: 20,
                              flexDirection: 'row',
                            }}
                          >
                            <div
                              style={{
                                position: 'absolute',
                              }}
                            >
                              <CryptoIcon
                                src={require('../../../../images/peru.png')}
                              />
                            </div>
                            <i
                              style={{
                                display: 'inline',
                                paddingLeft: 40,
                                color: 'black',
                                fontSize: 18,
                              }}
                            >
                              Soles
                            </i>
                            <i
                              style={{
                                display: 'inline',
                                paddingLeft: 10,
                                fontSize: 18,
                              }}
                            >
                              /S
                            </i>
                          </div>
                        ),
                      },
                      {
                        value: 'USD',
                        label: (
                          <div
                            style={{
                              paddingLeft: 20,
                              flexDirection: 'row',
                            }}
                          >
                            <div
                              style={{
                                position: 'absolute',
                              }}
                            >
                              <CryptoIcon
                                src={require('../../../../images/unitedStates.png')}
                              />
                            </div>
                            <i
                              style={{
                                display: 'inline',
                                paddingLeft: 40,
                                color: 'black',
                                fontSize: 18,
                              }}
                            >
                              Dólares
                            </i>
                            <i
                              style={{
                                display: 'inline',
                                paddingLeft: 10,
                                fontSize: 18,
                              }}
                            >
                              $
                            </i>
                          </div>
                        ),
                      },
                      {
                        value: 'HNL',
                        label: (
                          <div
                            style={{
                              paddingLeft: 20,
                              flexDirection: 'row',
                            }}
                          >
                            <div
                              style={{
                                position: 'absolute',
                              }}
                            >
                              <CryptoIcon
                                src={require('../../../../images/honduras.png')}
                              />
                            </div>
                            <i
                              style={{
                                display: 'inline',
                                paddingLeft: 40,
                                color: 'black',
                                fontSize: 18,
                              }}
                            >
                              Lempiras
                            </i>
                            <i
                              style={{
                                display: 'inline',
                                paddingLeft: 10,
                                fontSize: 18,
                              }}
                            >
                              L/
                            </i>
                          </div>
                        ),
                      },
                    ]}
                  />
                </EtiquetaPrecioFiat>
              </Visor>
            </Box>
          </Box>

          <Table variant="simple" style={{ overflowX: 'scroll' }}>
            <Thead>
              <Tr bg={useColorModeValue('gray.100', 'gray.900')}>
                <Th>Moneda</Th>
                <Th>Balance</Th>
                <Th isNumeric>Cantidad</Th>
                <Th>Acción</Th>
              </Tr>
            </Thead>
            <Tbody>
              {/* 
              <Tr>
                <Td fontWeight={500}>
                  {' '}
                  <Image
                    src="https://logowik.com/content/uploads/images/tether-usdt1484.jpg"
                    alt="Tether"
                    style={{
                      width: 30,
                      display: 'inline-block',
                      marginRight: 3,
                    }}
                  />{' '}
                  <span style={{ verticalAlign: 'super' }}>Tether</span>
                </Td>
                <Td>
                  {currency === 'PEN'
                    ? `${'S/ ' +
                        Number.parseFloat(USDTBalance * usdtValueinPEN).toFixed(
                          2
                        )}`
                    : `${'$ ' + Number.parseFloat(USDTBalance).toFixed(2)}`}
                </Td>
                <Td isNumeric>
                  {Number.parseFloat(USDTBalance).toFixed(2)} USDT
                </Td>
                <Td>
                  {' '}
                  <Button
                    style={{ backgroundColor: '#1A4BEA', color: '#fff' }}
                    size="md"
                    onClick={() => history.push('/app/profile/comprar_vender')}
                  >
                    Comprar/Vender
                  </Button>{' '}
                  <Button
                    style={{
                      backgroundColor: '#1E75CC',
                      color: '#fff',
                      alignSelf: 'center',
                      justifySelf: 'center',
                    }}
                    size="md"
                    onClick={() => history.push('/app/profile/withdraw')}
                  >
                    Enviar
                  </Button>{' '}
                  <Button
                    style={{ backgroundColor: '#FFCA36', color: '#fff' }}
                    size="md"
                    onClick={() => history.push('/app/profile/deposit')}
                  >
                    Recibir
                  </Button>{' '}
                  {/* <Button
                    style={{ backgroundColor: '#3AAA35', color: '#fff' }}
                    size="md"
                  >
                    Invertir
                  </Button> 
                </Td>
              </Tr>

              <Tr>
                <Td fontWeight={500}>
                  {' '}
                  <Image
                    src="https://changenow.io/images/cached/usdttrc20.png"
                    alt="Tether"
                    style={{
                      width: 30,
                      display: 'inline-block',
                      marginRight: 3,
                    }}
                  />{' '}
                  <span style={{ verticalAlign: 'super' }}>Tron USDT</span>
                </Td>
                <Td>
                  {currency === 'PEN'
                    ? `${'S/ ' +
                        Number.parseFloat(
                          tronUSDTBalance * usdtValueinPEN
                        ).toFixed(2)}`
                    : `${'$ ' + Number.parseFloat(tronUSDTBalance).toFixed(2)}`}
                </Td>
                <Td isNumeric>
                  {Number.parseFloat(tronUSDTBalance).toFixed(2)} Tron USDT
                </Td>
                <Td>
                  {' '}
                  <Button
                    style={{ backgroundColor: '#1A4BEA', color: '#fff' }}
                    size="md"
                    onClick={() => history.push('/app/profile/comprar_vender')}
                  >
                    Comprar/Vender
                  </Button>{' '}
                  <Button
                    style={{
                      backgroundColor: '#1E75CC',
                      color: '#fff',
                      alignSelf: 'center',
                      justifySelf: 'center',
                    }}
                    size="md"
                    onClick={() => history.push('/app/profile/withdraw')}
                  >
                    Enviar
                  </Button>{' '}
                  <Button
                    style={{ backgroundColor: '#FFCA36', color: '#fff' }}
                    size="md"
                    onClick={() => history.push('/app/profile/deposit')}
                  >
                    Recibir
                  </Button>{' '}
                  {/* <Button
                    style={{ backgroundColor: '#3AAA35', color: '#fff' }}
                    size="md"
                  >
                    Invertir
                  </Button>
                </Td>
              </Tr> */}

              <Tr>
                <Td fontWeight={500}>
                  {' '}
                  <Image
                    src={require('../../../../images/peru.png')}
                    alt="Soles"
                    style={{
                      width: 30,
                      display: 'inline-block',
                      marginRight: 3,
                    }}
                  />{' '}
                  <span style={{ verticalAlign: 'super' }}>Soles Peruanos</span>
                </Td>
                <Td>
                  {currency === 'PEN'
                    ? `${'S/ ' + Number.parseFloat(PENBalance).toFixed(2)}`
                    : `${'$ ' +
                        Number.parseFloat(PENBalance / usdtValueinPEN).toFixed(
                          2
                        )}`}
                </Td>
                <Td isNumeric>{Number.parseFloat(PENBalance).toFixed(2)} S/</Td>
                <Td>
                  <Button
                    style={{
                      backgroundColor: '#1E75CC',
                      color: '#fff',
                      alignSelf: 'center',
                      justifySelf: 'center',
                    }}
                    size="xs"
                    onClick={() => history.push('/app/profile/withdraw')}
                  >
                    Retirar
                  </Button>{' '}
                  <Button
                    style={{ backgroundColor: '#FFCA36', color: '#fff' }}
                    size="xs"
                    onClick={() => history.push('/app/profile/deposit')}
                  >
                    Depositar
                  </Button>
                </Td>
              </Tr>



              <Tr>
                <Td fontWeight={500}>
                  {' '}
                  <Image
                    src={require('../../../../images/unitedStates.png')}
                    alt="Dólares Americanos"
                    style={{
                      width: 30,
                      display: 'inline-block',
                      marginRight: 3,
                    }}
                  />{' '}
                  <span style={{ verticalAlign: 'super' }}>
                    Dólares Americanos
                  </span>
                </Td>
                <Td>
                  {currency === 'PEN'
                    ? `${'S/ ' +
                        Number.parseFloat(USDBalance * usdtValueinPEN).toFixed(
                          2
                        )}`
                    : `${'$ ' + Number.parseFloat(USDBalance).toFixed(2)}`}
                </Td>
                <Td isNumeric>{Number.parseFloat(USDBalance).toFixed(2)} $</Td>
                <Td>
                  <Button
                    style={{
                      backgroundColor: '#1E75CC',
                      color: '#fff',
                      alignSelf: 'center',
                      justifySelf: 'center',
                    }}
                    size="xs"
                    onClick={() => history.push('/app/profile/withdraw')}
                  >
                    Retirar
                  </Button>{' '}
                  <Button
                    style={{ backgroundColor: '#FFCA36', color: '#fff' }}
                    size="xs"
                    onClick={() => history.push('/app/profile/deposit')}
                  >
                    Depositar
                  </Button>
                </Td>
              </Tr>



              <Tr>
                <Td fontWeight={500}>
                  {' '}
                  <Image
                    src={require('../../../../images/honduras.png')}
                    alt="Lempiras Hondureñas"
                    style={{
                      width: 30,
                      display: 'inline-block',
                      marginRight: 3,
                    }}
                  />{' '}
                  <span style={{ verticalAlign: 'super' }}>
                    Lempiras Hondureñas
                  </span>
                </Td>
                <Td>
                  {currency === 'HNL' /////PENDING HNL  Exchange transform..... 
                    ? `${'L/ ' +
                        Number.parseFloat(HNLBalance * usdtValueinPEN).toFixed(
                          2
                        )}`
                    : `${'L/ ' + Number.parseFloat(HNLBalance).toFixed(2)}`}
                </Td>
                <Td isNumeric>{Number.parseFloat(HNLBalance).toFixed(2)} L/</Td>
                <Td>
                  <Button
                    style={{
                      backgroundColor: '#1E75CC',
                      color: '#fff',
                      alignSelf: 'center',
                      justifySelf: 'center',
                    }}
                    size="xs"
                    onClick={() => history.push('/app/profile/withdraw')}
                  >
                    Retirar
                  </Button>{' '}
                  <Button
                    style={{ backgroundColor: '#FFCA36', color: '#fff' }}
                    size="xs"
                    onClick={() => history.push('/app/profile/deposit')}
                  >
                    Depositar
                  </Button>
                </Td>
              </Tr>



              {cryptoPricesArray ? (
                cryptos.map(crypto => {
                  return (
                    <Tr>
                      <Td>
                        <Image
                          src={crypto.image}
                          alt={crypto.name}
                          style={{
                            width: 30,
                            display: 'inline-block',
                            marginRight: 3,
                          }}
                        />{' '}
                        <span style={{ verticalAlign: 'super' }}>
                          {crypto.name}
                        </span>
                      </Td>
                      <Td>
                        {currency === 'PEN'
                          ? `${'S/ ' +
                              Number.parseFloat(
                                (
                                  cryptosBalance[crypto.symbol] *
                                  cryptoPricesArray[crypto.symbol]?.penSell
                                ).toFixed(DecimalsNum('PEN'))
                              )}`
                          : `${'$ ' +
                              Number.parseFloat(
                                (
                                  cryptosBalance[crypto.symbol] *
                                  cryptoPricesArray[crypto.symbol]?.usdSell
                                ).toFixed(DecimalsNum('USD'))
                              )}`}
                      </Td>
                      <Td isNumeric>
                        {Number.parseFloat(
                          Number.parseFloat(
                            cryptosBalance[crypto.symbol]
                          ).toFixed(DecimalsNum(crypto.symbol))
                        )}{' '}
                        {crypto.symbol}
                      </Td>
                      <Td>
                        {' '}
                        <Button
                          style={{ backgroundColor: '#1A4BEA', color: '#fff' }}
                          size="xs"
                          onClick={() =>
                            history.push('/app/profile/comprar_vender')
                          }
                        >
                          { crypto.allowBuy && crypto.allowSell && (
                            'Comprar/Vender'
                          )}
                          { crypto.allowBuy && !crypto.allowSell && (
                            'Comprar'
                          )}
                          { !crypto.allowBuy && crypto.allowSell && (
                            'Vender'
                          )}
                        </Button>{' '}
                        {crypto.allowDeposit ? (
                          <>
                            <Button
                              style={{
                                backgroundColor: '#1E75CC',
                                color: '#fff',
                              }}
                              size="xs"
                              onClick={() =>
                                history.push('/app/profile/withdraw')
                              }
                            >
                              Enviar
                            </Button>{' '}
                          </>
                        )
                        :
                        <>
                          <Button
                            style={{
                              backgroundColor: '#444',
                              color: '#fff',
                            }}
                            size="xs"
                          >
                            Enviar
                          </Button>{' '}
                        </>
                        }

                        {crypto.allowWithdraw ? (
                          <>
                            <Button
                              style={{
                                backgroundColor: '#FFCA36',
                                color: '#fff',
                              }}
                              size="xs"
                              onClick={() =>
                                history.push('/app/profile/deposit')
                              }
                            >
                              Recibir
                            </Button>
                          </>
                        )
                        :
                            <>
                            <Button
                              style={{
                                backgroundColor: '#444',
                                color: '#fff',
                              }}
                              size="xs"
                            >
                              Recibir
                            </Button>
                          </>

                        }
                      </Td>
                    </Tr>
                  );
                })
              ) : (
                <div
                  style={{
                    width: '315%',
                    textAlign: 'center',
                  }}
                >
                  <Ring size={45} />
                </div>
              )}
            </Tbody>
          </Table>
        </Box>
      </SimpleGrid>
    </>
  );
}
