import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import swal from 'sweetalert';
import { AuthContext } from 'Auth';
import { CatcherBotonera, DisabledLink, EditButton } from './styles';
import ReactGA from 'react-ga';

import { Spinner } from '@chakra-ui/react';

import { AlertButton } from 'styles/Styled';

import Alerta from 'components/modals/Alerta';
import DoubleConfirm from 'components/modals/DoubleConfirm';
import agentebtcapi from 'agentebtcapi.js';

library.add(fab);

const Catcher = ({
  title,
  disabled,
  history,
  calculatorViewer,
  noBalance,
  ammountCrypto,
  ammountFiat,
  cryptoName,
  fiatName,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isClicked, setIsClicked] = useState(false);

  const { currentToken } = useContext(AuthContext);

  // Execute convertion in the backend
  const handleSwap = async () => {
    if (!currentToken) {
      swal({
        text: 'Para realizar una transacción, porfavor seleccione una opción',
        button: false,
      });
      history.push(`/login`);
    } else {
      setIsClicked(true);
      // Variables
      let from,
        to,
        amount = '';
      let showError = false;
      let errorMessage = '';

      if (calculatorViewer.transactionType == 'BUY') {
        from = fiatName;
        to = cryptoName;
        amount = ammountFiat;
        if( (from === 'PEN' && amount < 20) || (from === 'USD' && amount < 5) || (from === 'HNL' && amount < 100 ) ){
          swal({
            text: 'Ingrese un monto válido',
            button: false,
          });
          setIsClicked(false);
          return;
        }
      } else {
        from = cryptoName;
        to = fiatName;
        amount = ammountCrypto;
        if( (to === 'PEN' && ammountFiat < 20) || (to === 'USD' && ammountFiat < 5) || (to === 'HNL' && ammountFiat < 100 ) ){
          swal({
            text: 'Ingrese un monto válido',
            button: false,
          });
          setIsClicked(false);
          return;
        }
      }

      const jsonBody = JSON.stringify({
        from: from,
        to: to,
        amount: amount,
      });

      const data = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentToken}`,
        },
        body: jsonBody, // body data type must match "Content-Type" header
      };
      const endpoint = '/client/wallet/convert';
      const url = `${agentebtcapi}${endpoint}`;
      fetch(url, data)
        .then(response => response.json())
        .then(responseJson => {
          console.log("ResponseFiat: ", responseJson)
          if (responseJson.fromWallet && responseJson.toWallet) {
            if (calculatorViewer.transactionType == 'BUY') {
              ReactGA.event({
                category: 'Comprar',
                action: 'Compra de criptos en ' + fiatName,
                label: fiatName + ' - ' + cryptoName,
              });
            } else {
              ReactGA.event({
                category: 'Vender',
                action: 'Venta de criptos en ' + fiatName,
                label: cryptoName + ' - ' + fiatName,
              });
            }
            swal({
              text:
                'Se realizó correctamente la operación :). Te redireccionaremos a Operaciones para visualizarla.',
              button: false,
              timer: 3000,
            });
            setTimeout(function() {
              history.push(`/app/profile/operaciones`);
              setIsClicked(false);
            }, 3000);
          } else {
            swal({
              text: responseJson.msg !== '' ? responseJson.msg :
                'La  transacción no pudo ser realizada en este momento, por favor intente más tarde.',
              button: false,
              timer: 3000,
            });
            setIsClicked(false);
            // setTimeout(function() {
            //   history.push(`/app/profile/operaciones`);
            //   setIsClicked(false);
            // }, 3000);
          }
        })
        .catch(error => {
          console.log("ErrorFiat: ", error);
          setIsClicked(false);
          swal({
            text: 'Lo sentimos no se pudo realizar la operación',
            button: false,
            timer: 3000,
          });
        });
    }
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  if (disabled && ammountFiat === '') {
    return (
      <>
        <CatcherBotonera>
          <DisabledLink>
            {noBalance ? 'No balance' : 'Ingrese la cantidad'}
          </DisabledLink>
        </CatcherBotonera>
      </>
    );
  } else if (isClicked) {
    return (
      <CatcherBotonera>
        <DisabledLink>
          <Spinner size="xl" />
        </DisabledLink>
      </CatcherBotonera>
    );
  } else {
    return (
      <CatcherBotonera>
        {true ? (
          <EditButton onClick={handleSwap}>{title}</EditButton>
        ) : (
          <AlertButton type="button" onClick={handleOpenModal}>
            Are you sure?
          </AlertButton>
        )}
      </CatcherBotonera>
    );
  }
};

export default withRouter(Catcher);
