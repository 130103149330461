import React, { useContext, useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import '../styles.css';

let _scrollPosition = 0;

// Select
const GoodSelect = ({
    styles, 
    assets, 
    selectedAsset, 
    onSelectedAssetChange, 
    dropdownListMarginTop = '-1.5rem',
    ignoreAssets = [],
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState({
        symbol:'',
        name:'',
        image:'',
        icon:''
    });
    const scrollRef = useRef();

    // Initial load
    useEffect(() => {
        try{
            if( assets.length > 0 ){
                loadSelectedAsset(selectedAsset, assets);
            }
        }catch(error){
            console.log("Error loading selected asset: ",error);
        }
    }, [selectedAsset, assets])

    // Useeffect everytime re render
    useEffect(() => {
        if( scrollRef.current ){
            scrollRef.current.scrollTop = _scrollPosition;
        }
    });


    // Load selected asset
    const loadSelectedAsset = (_selectedAsset, _assets) => {
        try{
            // Find selected asset by symbol
            let _selected = _assets.find(asset => asset.symbol === _selectedAsset);
            if( _selected === undefined ){
                throw new Error("Selected asset not found");
            }

            // Update selected asset
            setSelected({
                symbol:_selected.symbol,
                name:_selected.name,
                image:_selected.image,
                icon:_selected.icon ?? _selected.symbol ?? ''
            });

        }catch(error){
            setSelected({
                symbol:'',
                name:'',
                image:'',
                icon:''
            })
        }
    }

    ///////////// Asset info
    const AssetInfo = ({element}) => {
        return (
            <div className='c-select-asset-info-container'>
                <div className='c-select-asset-info-icon-container'>
                    {element.symbol}
                </div>
                <div className='c-select-asset-info'>
                    <img 
                        src={element.image} 
                        alt={element.name} 
                        className='c-select-asset-icon-image'
                    />
                    <div className='c-select-asset-name'>{element.name}</div>
                    <div className='c-select-asset-symbol'>{element.icon}</div>
                </div>
            </div>
        )
    }


    // Dropdown List
    const DropdownList = () => {
        if( assets.length === 0 ){
            return (<></>);
        }

        // Get assets but ignore selected
        let _assets = assets.filter(asset => (asset.symbol !== selected.symbol && !ignoreAssets.includes(asset.symbol)));

        return (
            <div className='c-select-dropdown-list-container-float'
                style={{marginTop:dropdownListMarginTop}}
            >
                <div className='c-select-dropdown-list-container'
                    ref={scrollRef}
                    onScroll={()=>{
                        _scrollPosition = scrollRef.current.scrollTop;
                    }}
                >
                    {_assets.map((element, index) => (
                        <div 
                            key={index}
                            className='c-select-dropdown-element'
                            onClick={()=>{
                                onSelectedAssetChange(element.symbol)
                                setIsOpen(false);
                            }}
                        >
                            <AssetInfo element={element} />
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    if( selected.name === '' ){
        return (<></>);
    }
    return (
        <div style={{width:'100%'}}>
            <div className='c-select-container' style={styles}
                onClick={()=>setIsOpen(!isOpen)}
            >
                <div className='c-select-header'>
                    <AssetInfo element={selected} />
                </div>
                <div className='c-select-icon-container'>
                    <FontAwesomeIcon 
                        icon={isOpen ? faAngleUp: faAngleDown} 
                        className='c-select-icon-icon'
                    />
                </div>
            </div>

            {isOpen && <DropdownList />}
        </div>
    )
}

export default GoodSelect;