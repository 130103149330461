import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'Auth';
import { faFlag, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import swal from 'sweetalert';
import './styles.css';
import TransactionMenu from './subcomponents/TransactionMenu';
import GoodSelect from './subcomponents/GoodSelect';
import GoodInput from './subcomponents/GoodInput';
import { RESPONSE_GET_ASSETS, RESPONSE_GET_PRICE, RESPONSE_GET_USER_DATA, RESPONSE_CONVERT } from './api/apiFunctions';
import { validateInputNumber, delay, FIAT_ASSETS, validateAndFormatConvertionRequest } from './helpers';
import Timer from './subcomponents/Timer';
import MinMaxAmounts from './subcomponents/MinMaxAmounts';

const PRICE_INTERVAL = 10;

const CalculatorV2 = () => {

    const { currentToken } = useContext(AuthContext);

    const [transactionType, setTransactionType] = useState("BUY");
    const [assets, setAssets] = useState([]);
    const [selectedAssetA, setSelectedAssetA] = useState('PEN');
    const [selectedAssetB, setSelectedAssetB] = useState('BTC');
    const [amountA, setAmountA] = useState('');
    const [amountB, setAmountB] = useState('');
    const [minAmount, setMinAmount] = useState(0);
    const [maxAmount, setMaxAmount] = useState(0);
    const [inputChanged, setInputChanged] = useState('A');
    const [rate, setRate] = useState(0);
    const [psst, setPsst] = useState(''); // Price promise token
    const [originalRate, setOriginalRate] = useState(0);
    const [level, setLevel] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [countdown, setCountdown] = useState(PRICE_INTERVAL);
    const [isSending, setIsSending] = useState(false);


    // Initial load
    useEffect(() => {
        getUserData();
        loadAssets();
        fetchData();
    }, [])

    // Calculate amount A or B when rate changes
    useEffect(() => {
        calculateAmount();
    }, [selectedAssetA, selectedAssetB, rate, amountA, amountB, inputChanged])

    // Reset amounts when transaction type changes
    useEffect(() => {
        setAmountA('');
        setAmountB('');
        setRate(0);
        setOriginalRate(0);
        fetchData();
        calculateAmount();
    }, [transactionType]);

    // Calculate amount A or B when rate changes
    useEffect(() => {
        fetchData();
        fetchData();
        calculateAmount();
    }, [selectedAssetA, selectedAssetB])


    /****************************************************/
    /************************** API  ********************/
    /****************************************************/
    // Load assets
    const loadAssets = async () => {
        try{
            let finalAssets = FIAT_ASSETS;
            const response = await RESPONSE_GET_ASSETS();
            if( response instanceof Error ){
                throw new Error("Error loading assets");
            }

            // Add response to final assets
            finalAssets = finalAssets.concat(response);

            // Update assets
            setAssets(finalAssets);
        }catch(error){
            console.log("Error loading assets: ",error);
            setAssets([]);
        }
    }

    // Get price
    const fetchData = async () => {
        try {
            setIsLoading(true);
            // // Get price
            const response = await RESPONSE_GET_PRICE(selectedAssetB, selectedAssetA, transactionType);
            if( response instanceof Error ){
                throw new Error("Error fetching data");
            }
            if( response.toAmount <= 0 ){
                throw new Error("Hubo un error al obtener el precio, por favor intente más tarde.");
            }

            // Artificial delay
            await delay(200);

            if( typeof response.toAmount === 'undefined' || response.toAmount <= 0 ){
                throw new Error("Hubo un error al obtener el precio, por favor intente más tarde.");
            }

            //console.log("Response: ",response);
            if( response.toAmount === 0 ){
                console.log("To Amount is 0: ",response);
            }
            setRate(response.toAmount);
            if( typeof response.originalToAmount !== 'undefined' && response.originalToAmount > 0 ){
                setOriginalRate(response.originalToAmount);
            }else if( originalRate !== 0 ){
                setOriginalRate(0);
            }

            // Set psst token
            if( typeof response.psst !== 'undefined' ){
                setPsst(response.psst);
            }else{
                setPsst('');
                throw new Error("Hubo un error al obtener el precio, por favor intente más tarde.");
            }

            // Reset countdown
            setCountdown(PRICE_INTERVAL);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Get user data ( Get Level )
    const getUserData = async () => {
        try {
            // // Get price
            const response = await RESPONSE_GET_USER_DATA( currentToken );
            if( response instanceof Error ){
                throw new Error(response.message);
            }

            // Validate second level
            if( response.secondLevelApproved === 2 ){
                setLevel(2);
                return;
            }
            setLevel(1);
        } catch (error) {
            setLevel(1);
        }
    };

    /****************************************************/
    /*********************** On Change ******************/
    /****************************************************/
    // Calculate amount B or B
    const calculateAmount = () => {
        // Remove comas from amounts and rate
        let _amountA = amountA.replace(/,/g, '');
        let _amountB = amountB.replace(/,/g, '');
        let _rate = ''; 
        try{
            _rate = rate.toString().replace(/,/g, '');

            if( inputChanged === 'A' ){
                if( _amountA === '' || _amountA === '0' ){
                    setAmountB('');
                    return;
                }
                const value = _amountA / _rate;
                let newAmount = validateInputNumber((value).toFixed(value < 1 ? 8 : 2))
                setAmountB(newAmount);
            }else{
                if( _amountB === '' || _amountB === '0' ){
                    setAmountA('');
                    return;
                }
                const value = _amountB * _rate;
                let newAmount = validateInputNumber((value).toFixed( value < 1 ? 8 : 2))
                setAmountA(newAmount);
            }
        }catch(error){}
    }


    // On change amount A
    const onChangeAmountA = (_amount) => {
        try{
            const formatedAmount = validateInputNumber(_amount);
            if( formatedAmount === null){
                return;
            }

            setInputChanged('A');
            setAmountA(formatedAmount);
        }catch(error){
            console.log("Error changing amount A: ",error);
        }
    }

    // On change amount B
    const onChangeAmountB = (_amount) => {
        try{
            const formatedAmount = validateInputNumber(_amount);
            if( formatedAmount === null){
                return;
            }

            setInputChanged('B');
            setAmountB(formatedAmount);
        }catch(error){
            console.log("Error changing amount B: ",error);
        }
    }

    // On Convert Button Click
    const onConvertButtonClick = async () => {
        if( isSending ){return;}
        try{ 
            // Start sending and wait
            setIsSending(true);
            delay(500);
            // Validate convert requirements
            const convertObject = validateAndFormatConvertionRequest({
                selectedAssetB,
                selectedAssetA,
                amountA,
                amountB,
                transactionType,
                psst,
                minAmount,
                maxAmount
            });
            if( convertObject instanceof Error ){
                throw new Error(convertObject.message);
            }

            // Send convertion request
            let response = await RESPONSE_CONVERT( currentToken, convertObject );
            if( response instanceof Error ){
                throw new Error(response.message);
            }

            // Show success message
            if( response.type !== 'convert' ){
                throw new Error('Error al realizar la conversión. Intente nuevamente en unos minutos.');
            }
            
            swal({
                text: 'La conversión se realizó con éxito.\n Será dirigido a la sección de operaciones.',
                button: false,
                timer: 3000,
            });

            // Redirect to operations
            setTimeout(() => {
                window.location.href = '/app/profile/operaciones';
            }, 3000);
            
        }catch(error){
            setIsSending(false);
            swal({
                text: error.message,
                button: false,
                timer: 5000,
            });
        }
    }

    /****************************************************/
    /*********************** COMPONENTS *****************/
    /****************************************************/
    // Price Rate
    const PriceRate = () => {
        // Get rate in money format with 2 decimals
        let _rate = new Intl.NumberFormat('en-US').format(rate);
        if( rate < 1 ){
            _rate = rate.toFixed(8);
        }

        if( isSending ){
            return (<></>);
        }

        if( isLoading || rate === 0 ){
            return (
                <div className='c-price-rate-container'>
                    <FontAwesomeIcon icon={faSpinner} spin 
                        className='c-countdown-container-loading-icon'
                    />
                </div>
            )
        }

        return (
            <>
                <div className='c-price-rate-container'>
                    1 {selectedAssetB} = {_rate} {selectedAssetA}
                </div>
            </>
        )
    }

    // Price Rate
    const DebugPriceRate = () => {
        if( originalRate === 0 ){
            return(<></>);
        }
        // Get rate in money format with 2 decimals
        let _originalRate = new Intl.NumberFormat('en-US').format(originalRate);
        if( originalRate < 1 ){
            _originalRate = originalRate.toFixed(8);
        }

        if( isLoading || _originalRate === 0 ){
            return (<></>)
        }

        return (
            <>
                <div className='c-price-debug-rate-container'>
                    <FontAwesomeIcon icon={faFlag} 
                        className='c-code-debug-icon'
                    />
                    1 {selectedAssetB} = {_originalRate} {selectedAssetA}
                </div>
            </>
        )
    }


    // Buy Sell Button
    const BuySellButton = () => {
        let message = transactionType === "BUY" ? "Comprar" : "Vender"
        let className = 'c-buy-sell-button-container';
        if( amountA === '' || amountB === '' ){
            message = 'Ingrese un monto';
        }else if( amountA === '0' || amountB === '0' ){
            message = 'Ingrese un monto mayor a 0';
        }else{
            className = 'c-buy-sell-button-container-enabled';
        }

        return (
            <div className={className}
                onClick={()=>{onConvertButtonClick()}}
            >
                { !isSending && message}
                { isSending &&
                    <FontAwesomeIcon icon={faSpinner} spin 
                        className='c-buysell-container-loading-icon'
                    />
                }
            </div>
        )
    }



    /*********************************** BUY SELL COMPONENTS ******************/
    // BUY
    const BuyView = () => {
        return (
            <>
                <GoodSelect
                    styles={{
                        marginBottom:'1rem'
                    }}
                    assets={assets}
                    selectedAsset={selectedAssetA}
                    onSelectedAssetChange={setSelectedAssetA}
                    ignoreAssets={[selectedAssetB]}
                />

                <PriceRate/>
                <DebugPriceRate/>

                <GoodInput
                    styles={{
                        marginTop:'0rem'
                    }}
                    assets={assets}
                    selectedAsset={selectedAssetA}
                    ignoreAssets={[selectedAssetB]}
                    onSelectedAssetChange={setSelectedAssetA}
                    assetsEnabled={false}
                    amount={amountA}
                    onChangeAmount={onChangeAmountA}
                    dropdownListMarginTop='-0.3rem'
                />

                <GoodInput
                    styles={{
                        marginTop:'1rem'
                    }}
                    assets={assets}
                    selectedAsset={selectedAssetB}
                    ignoreAssets={[selectedAssetA]}
                    onSelectedAssetChange={setSelectedAssetB}
                    assetsEnabled={true}
                    amount={amountB}
                    onChangeAmount={onChangeAmountB}
                    dropdownListMarginTop='0px'
                />
            </>
        )
    }

    // SELL
    const SellView = () => {
        return (
            <>
                <GoodSelect
                    styles={{
                        marginBottom:'1rem'
                    }}
                    assets={assets}
                    selectedAsset={selectedAssetB}
                    onSelectedAssetChange={setSelectedAssetB}
                    ignoreAssets={[selectedAssetA]}
                />

                <PriceRate/>
                <DebugPriceRate/>

                <GoodInput
                    styles={{
                        marginTop:'0rem'
                    }}
                    assets={assets}
                    selectedAsset={selectedAssetB}
                    ignoreAssets={[selectedAssetA]}
                    onSelectedAssetChange={setSelectedAssetB}
                    assetsEnabled={false}
                    amount={amountB}
                    onChangeAmount={onChangeAmountB}
                    dropdownListMarginTop='-0.3rem'
                />

                <GoodInput
                    styles={{
                        marginTop:'1rem'
                    }}
                    assets={assets}
                    selectedAsset={selectedAssetA}
                    ignoreAssets={[selectedAssetB]}
                    onSelectedAssetChange={setSelectedAssetA}
                    assetsEnabled={true}
                    amount={amountA}
                    onChangeAmount={onChangeAmountA}
                    dropdownListMarginTop='0px'
                />
            </>
        )
    }
    

    return (
        <>
            <div className='c-wrapper'>
                <div className='c-main-content'>
                    <TransactionMenu
                        transactionType={transactionType}
                        onTransactionTypeChange={setTransactionType}
                    />


                    <div className='c-body-container'>

                        {transactionType === "BUY" ? BuyView() : SellView()}

                        { Timer({
                            isLoading, 
                            isSending,
                            fetch: fetchData,
                            countdown,
                            setCountdown,
                            PRICE_INTERVAL
                        })}

                        <BuySellButton/>

                        <MinMaxAmounts
                            assets={assets}
                            selectedAsset={transactionType === "BUY" ? selectedAssetA : selectedAssetB}
                            transactionType={transactionType}
                            level={level}
                            setMinAmount={setMinAmount}
                            setMaxAmount={setMaxAmount}
                        />

                    </div>
                </div>
            </div>
            <br/>
        </>
    )
};

export default CalculatorV2;
