import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Header2, Header1 } from 'styles/common/index';
import welcomeGif from '../../../media/gif/welcome.gif';
import { ImageModal } from '../../../styles/Styled';

const WelcomePage = ({ history }) => {
  useEffect(() => {
    let timer1 = setTimeout(() => history.push('/auth/login'), 7000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);
  return (
    <>
      <ImageModal src={welcomeGif} style={{ height: 250, width: 250 }} />
      <Header2 style={{ marginBlockStart: 0 }}>Enhorabuena</Header2>
      <Header1>
        Crea una nueva cuenta y ahora puede iniciar sesión en la aplicación.
      </Header1>
    </>
  );
};

export default withRouter(WelcomePage);
