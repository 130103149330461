let endpoint = '';
let webapp = '';

switch (process.env.REACT_APP_ENV_TYPE) {
  case 'local':
    endpoint = process.env.REACT_APP_AGENTEBTC_API_LOCAL;
    webapp = process.env.REACT_APP_AGENTEBTC_WEBAPP_LOCAL;
    break;
  case 'development':
    endpoint = process.env.REACT_APP_AGENTEBTC_API_DEV;
    webapp = process.env.REACT_APP_AGENTEBTC_WEBAPP_DEV;
    break;
  case 'staging':
    endpoint = process.env.REACT_APP_AGENTEBTC_API_TEST;
    webapp = process.env.REACT_APP_AGENTEBTC_WEBAPP_TEST;
    break;
  case 'production':
    endpoint = process.env.REACT_APP_AGENTEBTC_API_PROD;
    webapp = process.env.REACT_APP_AGENTEBTC_WEBAPP_PROS;
    break;
  default:
    endpoint = process.env.REACT_APP_AGENTEBTC_API_DEV;
    webapp = process.env.REACT_APP_AGENTEBTC_WEBAPP_DEV;
}

export default endpoint;
