import React, { useContext, useState, useEffect } from 'react';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';

import { Heading, Stack, Button, Link } from '@chakra-ui/react';

import { Box, useColorModeValue } from '@chakra-ui/react';

import { AuthContext } from 'Auth';

export default function Validation() {
  const { currentToken } = useContext(AuthContext);

  const [firstLevelApproved, setFirstLevelApproved] = useState('');
  const [secondLevelApproved, setSecondLevelApproved] = useState('');

  const getUser = () => {
    let data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    currentToken &&
      fetch(url, data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw { type: 'non-user' };
          }
        })
        .then(responseJson => {
          setFirstLevelApproved(responseJson.firstLevelApproved);
          setSecondLevelApproved(responseJson.secondLevelApproved);
        })
        .catch(error => {
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });
        });
  };

  useEffect(() => {
    getUser();
  }, [currentToken]);

  return (
    <>
      <Stack spacing="24px" align={'center'} mb={5} mt={5}>
        <Heading fontSize={'24'} fontWeight={'bold'} fontFamily={'body'}>
          Validación de cuenta
        </Heading>
      </Stack>
      <Box
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
        pb={5}
        mb={10}
        paddingInline={10}
        w="100%"
        align={{ base: 'center', md: 'flex-start' }}
      >
        <Stack
          spacing="24px"
          align={{ base: 'center', md: 'flex-start' }}
          ml={{ base: '0', md: '10' }}
          mt={10}
        >
          <Heading
            fontSize={'16'}
            fontWeight={500}
            fontFamily={'body'}
            style={{ color: '#4D72F1' }}
          >
            Nivel 1 (Básica)
          </Heading>
        </Stack>
        <Box>
          <Stack
            spacing="24px"
            align={{ base: 'center', md: 'flex-start' }}
            ml={{ base: '0', md: '10' }}
            mb={5}
            mt={5}
            display={{ base: 'flex', md: 'inline-block' }}
          >
            <Heading fontSize={'16'} fontWeight={500} fontFamily={'body'}>
              Requisitos:
            </Heading>
            <Heading
              fontSize={'12'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#707070' }}
              mt={5}
            >
              - Número de documento de <br /> &nbsp; identidad (DNI, CE o
              Pasaporte)
              <br />
              - Número telefónico
              <br />
              - Fotos del documento (Frontal y trasero)
              <br />
              - Nombres completos
              <br />- Nacionalidad
            </Heading>
          </Stack>
          <Stack
            spacing="24px"
            align={{ base: 'center', md: 'flex-start' }}
            ml={{ base: '0', md: '10' }}
            mb={5}
            mt={5}
            display={{ base: 'flex', md: 'inline-block' }}
            position={{ base: 'relative', md: 'absolute' }}
          >
            <Heading fontSize={'16'} fontWeight={500} fontFamily={'body'}>
              Beneficios:
            </Heading>
            <Heading
              fontSize={'12'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#707070' }}
              mt={5}
            >
              - Comprar Bitcoin (Max 5000 USD por día)
              <br />- Vender Bitcoin (Max 5000 USD por día)
            </Heading>
          </Stack>
        </Box>
        <Stack align={{ base: 'center', md: 'end' }}>
          <Link
            href={`/app/profile/validacion/level1`}
            style={{
              textDecoration: 'none',
            }}
          >
            <Button colorScheme="blue" disabled={firstLevelApproved}>
              ¡Validarme!
            </Button>
          </Link>
        </Stack>
      </Box>

      <Box
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
        pb={5}
        paddingInline={10}
        w="100%"
        align={{ base: 'center', md: 'flex-start' }}
      >
        <Stack
          spacing="24px"
          align={{ base: 'center', md: 'flex-start' }}
          ml={{ base: '0', md: '10' }}
          mb={5}
          mt={10}
        >
          <Heading
            fontSize={'16'}
            fontWeight={500}
            fontFamily={'body'}
            style={{ color: '#FFCD40' }}
          >
            Nivel 2 (Avanzada)
          </Heading>
        </Stack>
        <Box>
          <Stack
            spacing="24px"
            align={{ base: 'center', md: 'flex-start' }}
            ml={{ base: '0', md: '10' }}
            display={{ base: 'flex', md: 'inline-block' }}
          >
            <Heading fontSize={'16'} fontWeight={500} fontFamily={'body'}>
              Requisitos:
            </Heading>
            <Heading
              fontSize={'12'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#707070' }}
              mt={5}
            >
              - Comprobante de residencia (Muestra de factura,
              <br /> recibo de luz o recibo de agua)
              <br />
              <br />
              - Selfie con documento de identidad y mensaje escrito.
              <br />
            </Heading>
          </Stack>
          <Stack
            spacing="24px"
            align={{ base: 'center', md: 'flex-start' }}
            ml={{ base: '0', md: '10' }}
            mt={0}
            display={{ base: 'flex', md: 'inline-block' }}
            position={{ base: 'relative', md: 'absolute' }}
          >
            <Heading fontSize={'16'} fontWeight={500} fontFamily={'body'}>
              Beneficios:
            </Heading>
            <Heading
              fontSize={'12'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#707070' }}
              mt={5}
            >
              - Comprar y vender Bitcoin (De 10 k USD hasta 50k USD)
            </Heading>
          </Stack>
        </Box>
        <Stack align={{ base: 'center', md: 'end' }}>
          <Link
            href={`/app/profile/validacion/level2`}
            style={{
              textDecoration: 'none',
            }}
          >
            <Button
              colorScheme="blue"
              disabled={!firstLevelApproved || secondLevelApproved}
            >
              ¡Validarme!
            </Button>
          </Link>
        </Stack>
      </Box>
    </>
  );
}
