import styled from 'styled-components';
import colors from 'styles/common/colors';
import { Link } from 'react-router-dom';
import img from '../../media/loginPage/loginBackground.png';

export const AuthContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  z-index: 0;
  width: 100%;
  background-repeat: round;
  background-color: rgb(244, 250, 255);
  background-image: url(${img});

  @media (max-width: 640px) {
    padding: 0 24px;
  }

  @media (max-width: 320px) {
    padding: 0 16px;
  }
`;

export const AuthBox = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  z-index: 0;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ValidBox = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 640px) {
    display: block;
  }
`;

export const AuthInput = styled.input`
  position: relative;
  height: 40px;
  max-width: 400px;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  text-align: left;
  margin-bottom: 15px;
  margin-top: 5px;
  border: 1px solid rgba(62, 81, 88, 0.5);
  box-sizing: border-box;
  border-radius: 15px;
  padding-left: 20px;
  font-weight: 400;
  display: flex;
  outline: none;
  color: #000000;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const SingleCardRegister = styled.div`
  box-shadow: 4px 4px 4px 4px #eeeeee;
  width: 100%;
  padding: 20px 40px;
  background-color: white;
  z-index: 1;
  border-radius: 15px;
  margin-inline: 10px;
  margin-bottom: 50px;
  @media screen and (max-width: 900px) {
    margin: auto;
    width: 55%;
    margin-block-end: 10px;
  }
  @media (max-width: 640px) {
    width: 65%;
  }
`;

export const SingleCard = styled.div`
  box-shadow: 4px 4px 4px 4px #eeeeee;
  width: 100%;
  padding: 40px;
  background-color: white;
  z-index: 1;
  border-radius: 15px;
  margin-inline: 10px;
  @media screen and (max-width: 900px) {
    margin: auto;
    width: 55%;
    margin-block-end: 10px;
  }
  @media (max-width: 640px) {
    width: 65%;
  }
`;

export const AuthInputPass = styled.input`
  border-width: 0;
  position: relative;
  border-radius: 15px;
  max-width: 400px;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
  display: flex;
  outline: none;
  color: #000000;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const PassWrapper = styled.div`
    position: relative;
    height: 40px;
    max-width: 400px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 5px;
    border: 1px solid rgba(62,81,88, 0.5);
    box-sizing: border-box;
    border-radius: 15px;
    font-weight: 400;
    display: flex;
    outline: none;
    }
`;

export const AuthButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: white;
  background: ${colors.blue_agente_btc};
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  width: 100%;
  height: 45px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 20px;
  box-shadow: 2px 2px 3px rgba(44, 44, 44, 0.2);
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 2px 2px 8px rgba(44, 44, 44, 0.2);
  }
  &:active {
    box-shadow: 2px 2px 3px rgba(44, 44, 44, 0.2);
  }
`;

export const AuthText = styled.div`
  font-family: 'Poppins', sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 13px;
  font-weight: 400;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AuthText2 = styled.div`
  font-family: 'Poppins', sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 13px;
  padding-top: 10px;
  font-weight: 400;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: block;
  text-align: center;
  border-top: 1px solid rgba(62, 81, 88, 0.2);
`;

export const TermsConditionsCheckerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-family: 'Poppins', sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 13px;
  font-weight: 400;
`;

export const AuthLink = styled(Link)`
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 13px;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`;

export const AuthHref = styled.a`
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 13px;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`;

export const Label = styled.p`
  color: #3e5158;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

export const AuthIcon = styled.i`
  position: absolute;
  top: 6px;
  right: 16px;
  color: rgba(62, 81, 88, 0.5);
`;

export const AuthForm = styled.form`
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default {
  AuthContainer,
  AuthBox,
  SingleCardRegister,
  AuthInput,
  SingleCard,
  AuthIcon,
  AuthInputPass,
  PassWrapper,
  AuthButton,
  Label,
  AuthText,
  AuthText2,
  AuthLink,
  AuthForm,
  TermsConditionsCheckerContainer,
  AuthHref,
  ValidBox,
};
