import styled from 'styled-components';
import colors from 'styles/common/colors';

export const VisorPrecio = styled.div`
  height: 40px;
  line-height: 40px
  font-size: 20px;
  color: ${colors.dark_blue_agente_btc};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  opacity: 0.7;
  border-radius: 5px;
  font-weight: 600;
  font-family: Montserrat;
  @media screen and (max-width: 640px) {
    font-size: 15px;
  }

  @media screen and (max-width: 320px) {
    font-size: 15px;
  }
`;

export const CalculatorTabs = styled.div`
  *:first-child {
    border-right: 1px solid grey;
  }
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const CalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 380px;
  @media screen and (max-width: 768px) {
    min-width: 0px;
  }
  @media (max-width: 500px) {
    width: auto;
  }
`;

export const ContainerMinMax = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const BalanceFIATTitle = styled.div`
  width: 100%;
  text-align: left;
  font-size: 20px;
  color: ${colors.dark_blue_agente_btc};

  font-weight: 500;
  letter-spacing: 1px;
  font-family: Montserrat;
  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }

  @media screen and (max-width: 320px) {
    height: 60px;
    font-size: 18px;
  }
`;

export const HeadlineTitle = styled.div`
  width: 100%;
  text-align: left;
  font-size: 24px;
  color: ${colors.dark_blue_agente_btc};
  opacity: 0.7;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: Montserrat;
  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }

  @media screen and (max-width: 320px) {
    height: 60px;
    font-size: 18px;
  }
`;

export const ArrowDown = styled.img`
  width: 16px;
  height: 8px;
  position: absolute;
  right: 5px;
`;

export const ArrowUp = styled.img`
  width: 16px;
  height: 8px;
  position: absolute;
  right: 5px;
  transform: rotate(180deg);
`;

export const EtiquetaPrecioCrypto = styled.div`
  width: 150px;
  position: absolute;
  right: 0px;
  height: 100%;
  background: rgba(255, 200, 92, 0.2);
  font-weight: 600;
  font-size: 28px;
  border-radius: 0px 5px 5px 0px;
  color: rgba(255, 200, 92, 1);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    font-size: 18px;
    width: 100px;
  }
`;

export const EtiquetaNombre = styled.div`
  font-size: 12px;
  font-weight: 400;
  padding-left: 20px;
`;

export const TitleCalculator = styled.div`
  width: 100%;
  font-family: Montserrat;
  color: ${colors.dark_blue_agente_btc};
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 30px;
`;

export const Icon = styled.img`
  width: 20px;
  height: auto;
  margin: 5px;
`;

export const IconClickable = styled.img`
  width: 15px;
  height: auto;
  margin: 5px;
  cursor: pointer;
`;

export const EtiquetaPrecioFiat = styled.div`
  width: 100%;
  position: absolute;
  right: 0px;
  height: 100%;
  font-weight: 600;
  font-size: 28px;
  border-radius: 0px 5px 5px 0px;
  color: rgb(191, 191, 191);
  font-family: Montserrat;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-content: start;
  flex-direction: column;
  text-align: left;
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`;

export const EtiquetaPrecioCrypto2 = styled.div`
  width: 150px;
  position: absolute;
  right: 0px;
  height: 100%;
  background: rgba(255, 200, 92, 0.2);
  font-weight: 600;
  font-size: 28px;
  border-radius: 0px 5px 5px 0px;
  color: rgba(255, 200, 92, 1);
  font-family: Montserrat;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    font-size: 18px;
    width: 100px;
  }
`;

export const IngresoPrecio = styled.input`
  text-align: left;
  align-items: center;
  width: 100%;
  margin: 1px 10px;
  border: none;
  color: #3c4a5b;
  font-weight: 800;
  margin-top: 1px;
  background: transparent;
  &:hover {
    color: blue;
  }
  @media screen and (max-width: 640px) {
    font-size: 24px;
    width: 200px;
  }
`;

export const CryptoIcon = styled.img`
  width: 40px;
  display: inline-block;
  padding-top: 8px;
  padding-right: 15px;
  @media screen and (max-width: 640px) {
    padding-top: 2px;
    height: 24px;
    width: 24px;
    padding-right: 2px;
  }
`;

export const Visor = styled.div`
  position: relative;
  height: 80px;
  width: 100%;
  font-size: 34px;
  margin-top: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  display: flex;
  &:hover {
    border: 1px solid #b3b3b3;
  }
  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media screen and (max-width: 640px) {
    text-align: center;
    font-size: 24px;
  }
`;

export const MinMaxElement = styled.div`
  margin: 20px;
  font-family: Montserrat;
  color: ${colors.dark_blue_agente_btc};
  font-size: 14px;
  text-align: center;
`;

export const FooterCalculator = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-family: Montserrat;
  color: ${colors.dark_blue_agente_btc};
  font-size: 18px;
`;

export const Convers = styled.div`
  *:focus {
    outline: none;
  }
  overflow: visible;
  max-width: 100%;
  vertical-align: middle;
  text-align: center;
  align: center;
  align-items: center;
  position: relative;
  flex: 0 1 50%;
  box-shadow: 0 6px 9px 6px rgba(0, 0, 0, 0.06);
  background: white;
  padding-bottom: 20px;
  box-sizing: bordex-box;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  @media screen and (max-width: 640px) {
    font-size: 15px;
  }
`;

export const Content = styled.div`
  padding: 0px 40px;
`;
