import './styles.css';
import React, { useState } from 'react';
const StopInvesting = props => {
  return (
    <div className="modal-canjear">
      <div onClick={() => props.toggleCanjear()} className="overlay"></div>
      <div className="modal-content-canjear">
        <div className="modal-canjear-body">
          <div className="modal-canjear-title-principal">
            ¿Quieres dejar de generar <br /> rendimientos?
          </div>

          <div className="modal-canjear-body-text">
            Al canjear anticipadamente, perderás los intereses que hayas
            generado hasta la fecha de hoy.
          </div>

          <div
            className="modal-canjear-btn-continue"
            onClick={() => props.toggleCanjear()}
          >
            Continuar invirtiendo
          </div>

          <div
            className="modal-canjear-btn-exit"
            onClick={() => props.canjearAhora()}
          >
            Dejar de invertir
          </div>
        </div>
      </div>
    </div>
  );
};

export default StopInvesting;
