import styled from "styled-components";
import colors from 'styles/common/colors';
import Select from 'react-select'
import { Link } from "react-router-dom";

export const EditOperationWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 15vh;
  background: linear-gradient(180deg, rgba(236,243,248,1) 0%, rgba(236,243,248,1) 50%, rgba(255,255,255,0) 100%);
  margin-bottom: 100px;
`;

export const CopyIcon = styled.img`
  max-width: 15px;
  max-height: 15px;
`;

export const BankAccountNumber = styled.b`
  display: flex;
  flex-direcction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ConfirmButton = styled.button`
  width: 390px;
  max-width: 95%;
  height: 45px;
  border-radius: 5px;
  background-color: ${colors.yellow_agente_btc};
  outline: none;
  border-color: none;
  border: 0px;
  font-weight: 600;
  cursor: pointer;
  color: ${colors.dark_blue_agente_btc};
`;

export const OrderDetail = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 640px) {
    flex-wrap: wrap;
  }

  padding-bottom: 20px;
  border-bottom: ${`1px solid ${colors.yellow_agente_btc}`}
`;

export const DetailItem = styled.div`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 16px;
  margin: 0;
  width: 100%;
  margin-top: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-conten: center;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DetailItemLabel = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-conten: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const DetailTimer = styled.div`
  margin: auto;
  font-family: Montserrat !important;
  font-weight: 500;
  width: 60%;
  text-align: center;
  color: #1A4BEA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-conten: center;
  font-size: 14px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CardAmmount = styled.div`
  width: 200px;
  box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    width: 110px;
  }
`;

export const IconConversion = styled.img`
  width: 50px;
  @media screen and (max-width: 768px) {
    width: 24px;
  }
`;

export const ConversionRate = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 17px;
  color: #FFC85C;
  margin: auto;
  background-color: rgba(255, 200, 92, 0.2);
  border-radius: 20px;
  margin-top: 40px;
  padding: 10px 20px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin-top: 15px;
  }
`;


export const AmmountTitle = styled.div`
  font-family: Montserrat;
  color: ${colors.dark_blue_agente_btc}
  font-weight: 400;
  font-size: 20px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const AmmountNumberFIAT = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  color: #20C997;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const AmmountNumberBTC = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  color: #FFC85C;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const DeletedAmmount = styled.div`
  font-family: Montserrat;
  color: ${colors.dark_blue_agente_btc}
  font-weight: 400;
  font-size: 18px;
  height: 20px;
  text-decoration: line-through;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const DetailItemValue = styled.div`
  text-align: left;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const OperationHeader = styled.div`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 24px;
  text-align: left;
  font-weight: 600;
  margin: 50px;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 10px;
    font-size: 18px;
  }
`;

export const EditOperationContainer = styled.div`
  *:focus {
    outline: none;
  }

  max-width: 95%;
  width: 900px;
  vertical-align: middle;
  text-align: left;
  align: left;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 6px 9px 6px rgba(0, 0, 0, 0.06);
  background: white;
  padding: 20px 20px;
  box-sizing: bordex-box;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px 10px;
  }

  @media screen and (max-width: 640px) {
    font-size: 15px;
  }
`;


export const EditOperationItemList = styled.ul`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 50px;
  padding-top: 10px;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 10px;
    flex-direction: column;
  }
`;

export const TitleEditOperation = styled.div`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  width: 600px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  box-sizing: border-box;
  max-width: 95%;
  margin: 20px 0px 5px;
`;

export const SubtitleEditOperation = styled.div`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  opacity: 0.9;
  width: 600px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  box-sizing: border-box;
  max-width: 95%;
  margin: 20px 0px;
`;

export const EditOperationItem = styled.li`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-top: 5px;
  padding-right: 15px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const EditOperationItemFull = styled.li`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-top: 20px;
  padding-right: 15px;
`;

export const LinkTC = styled.a`
  color: rgba(255, 200, 92, 1);
  text-decoration: underline;

`;

export const EditOperationItemLabel = styled.div`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  opacity: 0.7;
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
`;

export const EditOperationItemLabelDescription = styled.div`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  opacity: 0.9;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
`;

export const TextImageInput = styled.p`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  opacity: 0.5;
  width: 100%;
  font-size: 12px;
  font-weight: 4  00;
  line-height: 1.7;
  box-sizing: border-box;
`;

export const EditOperationItemValue = styled.div`
  width: 50%;
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 13px;
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const TextInput = styled.input`
  width: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  padding-left: 10px;
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  ::placeholder {
    opacity: 0.5;
  }
  font-size: 12px;
  font-weight: 4  00;
  line-height: 1.7;
  text-align: left;

  box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.06);
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ContainerInputImage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.06);
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const ContainerImages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DisplayImage = styled.img`
  display: flex;
  justify-content: flex-start;
  margin: auto;
  margin-bottom: 10px;
  width: 400px;
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;

export  const ContainerAmmounts = styled.div`
  width:80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    width:95%;
  }
`;

export const BankSelector = styled(Select)`
  width: 100%;
  font-family: Montserrat;
  font-size: 13px;
  border: none;
  color: ${colors.dark_blue_agente_btc};
  font-weight: 400;
  outline: none;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }

`;

export const AccountBox = styled.div`
  width: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border: 1px solid rgba(44,44,44,0.05);
  padding: 10px;
  box-sizing: border-box;
`;

export const BankData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 13px;
  font-weight: 400;
  line-height: 2;

`;

export default {
  EditOperationWrapper,
  OrderDetail,
  EditOperationContainer,
  DetailItem,
  DetailItemValue,
  DetailItemLabel,
  EditOperationItemList,
  EditOperationItem,
  EditOperationItemLabel,
  EditOperationItemLabelDescription,
  EditOperationItemValue,
  TextInput,
  DisplayImage,
  ContainerImages,
  BankSelector,
  AccountBox,
  BankData,
  OperationHeader,
  DetailTimer,
  CardAmmount,
  AmmountTitle,
  AmmountNumberFIAT,
  AmmountNumberBTC,
  DeletedAmmount,
  ContainerAmmounts,
  IconConversion,
  ConversionRate,
  ContainerInputImage,
  TextImageInput,
  EditOperationItemFull,
  Form,
  LinkTC,
  ConfirmButton,
  SubtitleEditOperation,
  TitleEditOperation,
  CopyIcon,
  BankAccountNumber,
}
