import React, { useContext, useState, useEffect } from 'react';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import ImageUploader from 'react-images-upload';
import { css } from 'glamor';
import {
  Heading,
  Image,
  Stack,
  Button,
  IconButton,
  Text,
} from '@chakra-ui/react';
import http from 'http-common';
import { Spinner } from '@chakra-ui/react';
import { Line } from 'rc-progress';
import ReactGA from 'react-ga';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FiChevronLeft, FiChevronRight, FiAlertTriangle } from 'react-icons/fi';
import { AuthContext } from 'Auth';
import { Selector } from 'styles/common/index.js';
import { Clear, DetailsOperation, DetailTitle } from 'styles/Styled';

export default function ValidationLevelOne({ history }) {
  const { currentToken } = useContext(AuthContext);

  const [isClicked, setIsclicked] = useState(false);
  const [isClickedCancel, setIsclickedCancel] = useState(false);
  const [imageFundFront, setImageFundFront] = useState('');
  const [imageFundBack, setImageFundBack] = useState('');
  const [progressFront, setProgressFront] = useState(0);
  const [progressBack, setProgressBack] = useState(0);
  const [loadingButtonFront, setLoadingbuttonFront] = useState(false);
  const [loadingButtonBack, setLoadingbuttonBack] = useState(false);

  const [actualPage, setActualPage] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [
    isSecondLevelApprovalPending,
    setIsSecondLevelApprovalPending,
  ] = useState('');

  const handleSelectDocumentType = option => {
    setDocumentType(option.value);
  };

  const onImageFront = files => {
    if (files.length === 1) {
      setImageFundFront(files[files.length - 1]);
    } else {
      setImageFundFront('');
    }
  };

  const onImageBack = files => {
    if (files.length === 1) {
      setImageFundBack(files[files.length - 1]);
    } else {
      setImageFundBack('');
    }
  };

  const DocumentTypeOptions = [
    { value: 'recibo_de_luz_o_agua', label: 'Recibo de luz o agua' },
    { value: 'muestra_de_factura', label: 'Muestra de factura' },
  ];

  const DocumentTypeOptionsObject = {
    recibo_de_luz_o_agua: {
      value: 'recibo_de_luz_o_agua',
      label: 'Recibo de luz o agua',
    },
    muestra_de_factura: {
      value: 'muestra_de_factura',
      label: 'Muestra de factura',
    },
  };

  const getUser = () => {
    let data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    currentToken &&
      fetch(url, data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw { type: 'non-user' };
          }
        })
        .then(responseJson => {
          setDocumentType(responseJson.typeUtilitiesDoc);
          setIsSecondLevelApprovalPending(
            responseJson.isSecondLevelApprovalPending
          );
          if (actualPage === '') {
            setActualPage(
              responseJson.secondLevelStep
                ? isSecondLevelApprovalPending
                  ? 3
                  : responseJson.secondLevelStep
                : isSecondLevelApprovalPending
                ? 3
                : 1
            );
          }
        })
        .catch(error => {
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });
        });
  };

  const requestLevel2 = () => {
    setIsclicked(true);
    let data = {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user/secondlevel/requestvalidation';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          setIsclicked(false);
          getUser();

          toast('Solicitud de validación NIVEL 2 enviada exitosamente :)', {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            bodyClassName: css({ fontFamily: 'Montserrat' }),
          });

          return response.json();
        } else {
          setIsclicked(false);
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {})
      .catch(error => {
        setIsclicked(false);
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  const validateUser = async bodyRequest => {
    let body = { ...bodyRequest };
    var formData = new FormData();
    for (let key in body) {
      formData.append(key, body[key]);
    }

    let data = {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify(body),
    };
    let endpoint = '/client/user/secondlevel/update';
    let url = `${agentebtcapi}${endpoint}`;
    currentToken &&
      (await fetch(url, data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw { type: 'non-user' };
          }
        })
        .then(responseJson => {})
        .catch(error => {
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });
        }));
  };

  const cancelRequestLevel2 = () => {
    setIsclickedCancel(true);
    let data = {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user/secondlevel/cancelrequestvalidation';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          setIsclickedCancel(false);
          getUser();
          return response.json();
        } else {
          setIsclickedCancel(false);
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        getUser();
      })
      .catch(error => {
        setIsclickedCancel(false);
        swal({
          text: 'Ha habido un error en nuestro sistema',
          button: false,
          timer: 1000,
        });
      });
  };

  const onDropDNIFront = files => {
    //toast("Preparando foto delantera de documento, espere unos segundos ...", {position: toast.POSITION.TOP_CENTER, hideProgressBar: false, bodyClassName: css({fontFamily: 'Montserrat'})});

    setLoadingbuttonFront(true);

    if (files === undefined) {
      swal({
        text:
          'Tu imagen no cumple los requisitos, debe ser menor a 5MB y de los siguientes formatos: JPG, JPEG o PNG. Intenta recortarla o comprimirla.',
        button: false,
        timer: 5000,
      });

      return false;
    }

    let body = { utilitiesWater: files };
    var formData = new FormData();
    for (let key in body) {
      formData.append(key, body[key]);
    }

    let endpoint = '/client/user/secondlevel/update';
    let url = `${agentebtcapi}${endpoint}`;

    http
      .put(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgressFront(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(response => {
        if ((response.type = 'user-updated')) {
          setActualPage(actualPage + 1);
        } else {
          swal({
            text:
              'Lo sentimos, hubo un error en el sistema y no pudimos actualizar los datos. Intente nuevamente más adelante.',
            button: false,
            timer: 1000,
          });
        }
      });
  };

  const onDropDNIBack = files => {
    //toast("Preparando foto delantera de documento, espere unos segundos ...", {position: toast.POSITION.TOP_CENTER, hideProgressBar: false, bodyClassName: css({fontFamily: 'Montserrat'})});
    setLoadingbuttonBack(true);

    if (files === undefined) {
      swal({
        text:
          'Tu imagen no cumple los requisitos, debe ser menor a 5MB y de los siguientes formatos: JPG, JPEG o PNG. Intenta recortarla o comprimirla.',
        button: false,
        timer: 5000,
      });

      return false;
    }

    let body = { pictureWithDocument: files };
    var formData = new FormData();
    for (let key in body) {
      formData.append(key, body[key]);
    }

    let endpoint = '/client/user/secondlevel/update';
    let url = `${agentebtcapi}${endpoint}`;

    http
      .put(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgressBack(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(response => {
        if ((response.type = 'user-updated')) {
          requestLevel2();
        } else {
          swal({
            text:
              'Lo sentimos, hubo un error en el sistema y no pudimos actualizar los datos. Intente nuevamente más adelante.',
            button: false,
            timer: 1000,
          });
        }
      });
  };

  useEffect(() => {
    getUser();
  }, [currentToken, actualPage]);

  const RenderSteps = () => {
    if (actualPage === 1) {
      return (
        <>
          <Stack align={'center'} pt={10}>
            <Heading
              fontSize={'16'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#4D72F1' }}
            >
              Verificando tu cuenta en Nivel 2
            </Heading>
          </Stack>
          <Box align={'center'}>
            <Stack
              spacing="24px"
              align={'center'}
              mb={5}
              mt={5}
              width={{ base: '70%', md: '30%' }}
            >
              <Image
                src={require('../../../../../images/ModalImage1.png')}
                alt="Validation Image"
                w={200}
              />
              <Text fontSize={'12'}>¿Tienes tus documentos a la mano?</Text>
              <Text fontSize={'12'}>
                Vamos a pedir una foto de documento de residencia y un selfie
                para la verificaciòn
              </Text>
              <Stack flexDirection="row">
                <FiAlertTriangle size={40} color="#FFCA36" />
                <Text fontSize={'12'}>
                  Asegúrate que en la foto del selfie se vea correctamente tu
                  rostro y lo indicado en el momento
                </Text>
              </Stack>
              <Stack flexDirection="row">
                <FiAlertTriangle size={40} color="#FFCA36" />
                <Text fontSize={'12'}>
                  Asegúrate que la foto no esté movida o borrosa y que tus datos
                  se lean correctamente
                </Text>
              </Stack>
            </Stack>
          </Box>
          <Stack align={'center'}>
            <Button
              colorScheme="blue"
              onClick={() => {
                ReactGA.event({
                  category: 'Validación',
                  action: 'Inicio de validación nivel 2',
                });
                if (actualPage < 7) setActualPage(actualPage + 1);
              }}
            >
              Siguiente
            </Button>
          </Stack>
        </>
      );
    }
    if (actualPage === 2) {
      return (
        <>
          <Stack align={'left'} pt={10}>
            <Heading
              fontSize={'16'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#4D72F1' }}
            >
              Comprobante de residencia
            </Heading>
          </Stack>
          <Box align={'left'}>
            <Text fontSize={14} mt={5}>
              Necesitas subir un documento no mayor a 3 meses que lleve escrito
              tu domicilio y nombre completo. Puede ser un recibo de luz o agua,
              internet, estado de cuenta bancaria o estado de tarjeta de
              crédito.
            </Text>
            <Box
              flexDirection="column"
              width={{ base: '80%', md: '25%' }}
              px={3}
            >
              <Text mb={3} mt={5} align="start">
                Selecciona tipo de documento
              </Text>
              <Selector
                required
                placeholder="Tipo de documento"
                options={DocumentTypeOptions}
                value={DocumentTypeOptionsObject[documentType]}
                onChange={handleSelectDocumentType}
              />
            </Box>
          </Box>
          <Box align={'center'}>
            <Stack align={'center'} mb={5} mt={5}>
              <Heading fontSize={18}>Documento de residencia</Heading>
              <Stack flexDirection={{ base: 'column', md: 'row' }}>
                <Box>
                  <ImageUploader
                    key={actualPage}
                    withPreview={true}
                    withIcon={true}
                    buttonText="Busca archivos"
                    onChange={onImageFront}
                    imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                    maxFileSize={5242880}
                    withLabel={true}
                    label="Arrastra y suelta aquí o"
                    fileContainerStyle={{
                      borderWidth: 2,
                      borderColor: 'gray',
                      width: 250,
                      borderStyle: 'dashed',
                    }}
                    buttonStyles={{
                      backgroundColor: '#fff',
                      color: '#4D72F1',
                      fontWeight: 'bold',
                    }}
                  />
                  {loadingButtonFront && (
                    <>
                      <Line
                        percent={progressFront}
                        strokeWidth="4"
                        strokeColor="#486FEE"
                      />

                      <Clear></Clear>

                      <DetailsOperation>
                        <DetailTitle>
                          Subiendo documento... {progressFront}%
                        </DetailTitle>
                      </DetailsOperation>
                      <Clear></Clear>
                    </>
                  )}
                  <Text fontSize={10} color="gray" style={{ marginTop: 0 }}>
                    Tipos de archivos aceptados: .jpg, .jpeg, .gif, .png
                  </Text>
                </Box>
                <Box>
                  <Stack flexDirection="row" mt={2} ml={4}>
                    <div
                      style={{
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        backgroundColor: '#7B827C',
                        alignSelf: 'center',
                        marginRight: 10,
                      }}
                    />
                    <Text style={{ marginTop: 0 }}>
                      Documento de residencia
                    </Text>
                  </Stack>
                  <Stack flexDirection="row" mt={2} ml={4}>
                    <div
                      style={{
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        backgroundColor: '#7B827C',
                        alignSelf: 'center',
                        marginRight: 10,
                      }}
                    />
                    <Text style={{ marginTop: 0 }}>Selfie de verificación</Text>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Box>
          <Stack align={'center'}>
            <Button
              colorScheme="blue"
              onClick={() => {
                if (actualPage < 3) {
                  const bodyRequest = {
                    typeUtilitiesDoc: documentType,
                    secondLevelStep: actualPage + 1,
                  };
                  onDropDNIFront(imageFundFront);
                  validateUser(bodyRequest);
                }
              }}
              disabled={imageFundFront === ''}
            >
              Siguiente
            </Button>
          </Stack>
        </>
      );
    }
    if (actualPage === 3) {
      return (
        <>
          <Stack align={'left'} pt={10}>
            <Heading
              fontSize={'16'}
              fontWeight={500}
              fontFamily={'body'}
              style={{ color: '#4D72F1' }}
            >
              Selfie de verificación
            </Heading>
          </Stack>
          <Box align={'left'}>
            <Text fontSize={14} mt={5}>
              Sube una foto tu documento en la mano, y un papel con el siguiente
              mensaje escrito:
            </Text>
            <Stack flexDirection={{ base: 'column', md: 'row' }} mt={10}>
              <Image
                src={require('../../../../../images/validationImage.png')}
                alt="Validation Image"
                w={320}
                mr={10}
              />
              <Box>
                <Text fontSize={14} fontWeight="bold">
                  "Estoy subiendo mi selfie desde mi cuenta personal en
                  agentebtc.com, sin asesoría o requerimiento de ninguna otra
                  persona. [mi firma] [fecha dd/mm/aa]"
                </Text>
                <Text fontSize={14} mt={5} style={{ color: '#4D72F1' }}>
                  *Recordar colocar la fecha de hoy y tu firma correctamente
                </Text>
              </Box>
            </Stack>
          </Box>
          <Box align={'center'}>
            <Stack align={'center'} mb={5} mt={5}>
              <Heading fontSize={18}>Selfie de verificación</Heading>
              <Stack flexDirection={{ base: 'column', md: 'row' }}>
                <Box>
                  <ImageUploader
                    key={actualPage}
                    withPreview={true}
                    withIcon={true}
                    buttonText="Busca archivos"
                    onChange={onImageBack}
                    imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                    maxFileSize={5242880}
                    withLabel={true}
                    label="Arrastra y suelta aquí o"
                    fileContainerStyle={{
                      borderWidth: 2,
                      borderColor: 'gray',
                      width: 250,
                      borderStyle: 'dashed',
                    }}
                    buttonStyles={{
                      backgroundColor: '#fff',
                      color: '#4D72F1',
                      fontWeight: 'bold',
                    }}
                  />
                  {loadingButtonBack && (
                    <>
                      <Line
                        percent={progressBack}
                        strokeWidth="4"
                        strokeColor="#486FEE"
                      />

                      <Clear></Clear>

                      <DetailsOperation>
                        <DetailTitle>
                          Subiendo documento... {progressBack}%
                        </DetailTitle>
                      </DetailsOperation>
                      <Clear></Clear>
                    </>
                  )}
                  <Text fontSize={10} color="gray" style={{ marginTop: 0 }}>
                    Tipos de archivos aceptados: .jpg, .jpeg, .gif, .png
                  </Text>
                </Box>
                <Box>
                  <Stack flexDirection="row" mt={2} ml={4}>
                    <div
                      style={{
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        backgroundColor: '#94DA9F',
                        alignSelf: 'center',
                        marginRight: 10,
                      }}
                    />
                    <Text style={{ marginTop: 0 }}>
                      Documento de residencia
                    </Text>
                  </Stack>
                  <Stack flexDirection="row" mt={2} ml={4}>
                    <div
                      style={{
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        backgroundColor: isSecondLevelApprovalPending
                          ? '#94DA9F'
                          : '#7B827C',
                        alignSelf: 'center',
                        marginRight: 10,
                      }}
                    />
                    <Text style={{ marginTop: 0 }}>Selfie de verificación</Text>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Box>
          <Stack align={'center'}>
            {isSecondLevelApprovalPending && (
              <Button
                colorScheme="red"
                onClick={() => {
                  cancelRequestLevel2();
                }}
              >
                {isClickedCancel ? <Spinner size="lg" /> : 'Cancelar solicitud'}
              </Button>
            )}
            <Button
              disabled={isSecondLevelApprovalPending || imageFundBack === ''}
              colorScheme="blue"
              onClick={() => {
                if (actualPage < 3) {
                }
                onDropDNIBack(imageFundBack);
              }}
            >
              {isClicked ? <Spinner size="lg" /> : 'Confirmar datos'}
            </Button>
          </Stack>
        </>
      );
    }
  };

  return (
    <>
      <Stack align={'center'}>
        <Heading fontSize={'24'} fontWeight={'bold'} fontFamily={'body'}>
          Validación Nivel 2
        </Heading>
      </Stack>
      <Stack
        align={'end'}
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
        mb={3}
        mr={3}
      >
        <IconButton
          disabled={isSecondLevelApprovalPending}
          variant="outline"
          onClick={() => {
            if (actualPage > 1) setActualPage(actualPage - 1);
          }}
          aria-label="open menu"
          icon={<FiChevronLeft />}
        />
        <Text style={{ marginTop: 0, paddingInline: 15 }}>
          Paso {actualPage} de 3
        </Text>
        <IconButton
          disabled={isSecondLevelApprovalPending}
          variant="outline"
          onClick={() => {
            if (actualPage < 3) setActualPage(actualPage + 1);
          }}
          aria-label="open menu"
          style={{ marginTop: 0 }}
          icon={<FiChevronRight />}
        />
      </Stack>
      <Box
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
        pb={5}
        mb={10}
        paddingInline={10}
        w="100%"
        align={{ base: 'center', md: 'flex-start' }}
        style={{ overflow: 'visible' }}
      >
        {RenderSteps()}
      </Box>
    </>
  );
}
