import React, { Component } from 'react';
import swal from 'sweetalert';
// Access history through withRouter
import { withRouter } from 'react-router';

class CountdownTimer extends Component {
  state = {
    minutes: 20,
    seconds: 0,
  };

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        // Se acabo el tiempo
        if (minutes === 0) {
          clearInterval(this.myInterval);
          swal({
            text: 'El tiempo para completar la operación terminó.',
            button: false,
            time: 4000,
          });
          this.props.history.push(`/`);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    const { minutes, seconds } = this.state;
    return (
      <div>
        {minutes === 0 && seconds === 0 ? (
          <span>Se acabó el tiempo :( </span>
        ) : (
          <span>
            Tienes {minutes}:{seconds < 10 ? `0${seconds}` : seconds} minutos
            para concretar tu operación y conservar la tasa de cambio actual.
          </span>
        )}
      </div>
    );
  }
}

export default withRouter(CountdownTimer);
